import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";

export default function Post40() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const titleStyleObj = {
		fontSize: width > 720 ? "1.9em" : "1.6em",
		fontWeight: "700",
		mb: "calc(0.2em)",
		mt: "calc(13px + 1.2em)",
		lineHeight: "1.5",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "#111",
	};

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			{!loading && (
				<React.Fragment>
					<Container
						sx={{
							width: "100%",
							height: "30%",
							paddingTop: width < 720 ? "150px" : "200px",
							paddingBottom: width < 720 ? "50px" : "100px",
							paddingLeft: width < 720 ? "15px" : "auto",
							paddingRight: width < 720 ? "15px" : "auto",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							backgroundColor: "#161616",
							maxWidth: "none !important",
						}}
					>
						<Typography
							align="center"
							sx={{
								fontSize: width < 720 ? "1.5rem" : "2.5rem",
								lineHeight: width < 720 ? "2rem" : "3rem",
								textAlign: "center",
								maxWidth: "700px",
								fontWeight: "700",
								color: "#FFF",
								padding: "20px",
							}}
						>
							Metaverso: quais profissões irão lidar com a
							tecnologia?
						</Typography>
					</Container>
					<Box
						sx={{
							width: "100%",
							height: "100%",
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
							mt: "100px",
							mb: "200px",
							backgroundColor: "#FFF",
						}}
					>
						<Container
							sx={{
								maxWidth: "770px !important",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "flex-start",
								paddingLeft: width < 720 ? "22px" : "auto",
								paddingRight: width < 720 ? "22px" : "auto",
							}}
						>
							<Typography sx={textStyleObj}>
								<p>
									Com o grande desenvolvimento do blockchain e
									do metaverso, analistas apontam que novos
									profissionais, de diversas áreas, serão
									necessários para aprimorar essas
									tecnologias.
								</p>
								<br />
								<p>
									Segundo a StartSe, plataforma de
									conhecimento em negócios, basicamente, as
									profissões em ascensão na área são aquelas
									voltadas para a capacidade de entender e
									criar avatares, bem como as que exigem
									competência em design e storytelling – a
									arte de contar, desenvolver e adaptar
									histórias.
								</p>
								<br />
								<p>
									“As profissões do futuro serão as mesmas de
									hoje, mas todas serão focadas no mercado
									descentralizado”, afirma Cristiano Kruel,
									chefe de inovação da StartSe.
								</p>
								<br />
								<p>
									Segundo levantamento realizado pela CNN
									Brasil Business, em parceria com professores
									e empresas, os oito perfis profissionais que
									podem surgir com o avanço dessas tecnologias
									são: programadores, engenheiros de software,
									desenvolvedores de redes móveis e fixas,
									designers, especialistas em direito no
									metaverso, gerentes de produto e marketing,
									storytelling (contadores de histórias),
									profissionais em segurança de sistema e
									segurança cibernética.
								</p>
							</Typography>

							<Typography sx={titleStyleObj}>
								Programadores
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Esses profissionais terão a função de
									desenvolver redes descentralizadas e códigos
									de softwares que gerenciam ativos digitais,
									além de pensar em formas de fazer com que os
									jogadores continuem entrando no programa.
								</p>
								<br />
								<p>
									Segundo a professora do Instituto de Ensino
									e Pesquisa (Insper), Tatiana Revoredo, os
									jogos em blockchain, por exemplo, sempre
									precisam se otimizar para que novos usuários
									entrem na plataforma e comprem suas
									criptomoedas.
								</p>
								<br />
								<p>
									“Pois, caso contrário, o rendimento de um
									determinado ativo digital pode até crescer,
									mas cairá e todo recurso colocado pelo
									usuário [para começar a jogar] poderá ser
									desvalorizado”, afirma.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								Engenheiros de software
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Já estão em alta empregos para
									desenvolvimento e manutenção de realidade
									aumentada (AR) e realidade virtual (VR).
									Para os próximos anos, Tatiana afirma que as
									empresas vão precisar de engenheiros de
									software com habilidades nessas duas
									tecnologias.
								</p>
								<br />
								<p>
									Para ela, as habilidades necessárias vão
									além do conhecimento básico de informática.
									O profissional deverá estar familiarizado
									com tecnologias 3D interativas, com o
									objetivo de desenvolver novas plataformas de
									computação, softwares e aplicativos para os
									mundos virtuais do metaverso.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								Desenvolvedores de redes móveis e fixas
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Colocando à prova as redes fixas e móveis,
									também se faz necessário fornecer
									simultaneamente velocidades aprimoradas e
									latência ultrabaixa e uniforme. A realização
									desses sistemas exigirá grandes otimizações
									da tecnologia.
								</p>
								<br />
								<p>
									Assim, para Caroline Dalmolin, Head da Meta,
									dona do Facebook e Instagram, os
									desenvolvedores de rede precisarão otimizar
									os sistemas de velocidade.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								Designers
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Segundo Igor Hosse, professor da Anhembi
									Morumbi, o metaverso terá um conjunto
									diverso de atividades – como shows, jogos,
									compras, estudos e trabalhos –, que
									precisarão ser desenvolvidas por
									profissionais que consigam levar
									“experiências significativas” para os
									usuários, a partir do entendimento de suas
									necessidades, motivações e características.
								</p>
								<br />
								<p>
									Igor afirma que o designer precisará ver as
									potencialidades das tecnologias atuais –
									como a realidade virtual e a realidade
									aumentada – e daquelas que ainda estão por
									vir.
								</p>
								<br />
								<p>
									Outra área que o designer poderá trabalhar é
									na parte de governança do metaverso, já que
									ele possibilita que os usuários participem
									da criação dos mundos, opinando e decidindo
									o futuro das experiências.
								</p>
								<br />
								<p>
									“E para ser possível, novas formas de
									governança deverão ser desenvolvidas para
									que a comunidade se sinta engajada e
									participativa”, afirma o professor.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								Especialistas em direito no metaverso
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Muitas vezes, para acompanhar a inovação que
									vem com as tecnologias, as leis precisam ser
									reformuladas, simplificadas ou expandidas.
									Um dos exemplos foi o Marco Civil da
									Internet e na Lei Geral de Proteção de Dados
									(LGPD).
								</p>
								<br />
								<p>
									Com o avanço do metaverso, assuntos
									relacionados à tecnologia blockchain – como
									smart contracts, organizações
									descentralizadas (DAOs), NFTs e criptoativos
									– precisaram ser debatidos.
								</p>
								<br />
								<p>
									Segundo Hosse, especialistas em direito
									ajudarão a criar um ambiente judicialmente
									mais seguro, e que, assim, estimula a
									geração de novos negócios.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								Gerente de produto e marketing
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Segundo especialistas, para melhorar a
									experiência do usuário, resolvendo falhas e
									impulsionando o desenvolvimento de produtos,
									em parceria com múltiplas equipes de
									engenheiros e designers, serão necessários
									gerentes de produto nas empresas.
								</p>
								<br />
								<p>
									Segundo Tatiana Revoredo, os profissionais
									precisarão criar e executar campanhas
									publicitárias imersivas, e experiências que
									combinem o ambiente físico e digital.
								</p>
								<br />
								<p>
									Dos 3.200 empregos publicados na página de
									vagas da Meta, cerca de 2.400 são para
									vários tipos de gerente de produto.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								Storytelling (contadores de histórias)
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Segundo Tatiana, esses profissionais serão
									responsáveis pela elaboração de enredos
									envolventes e convincentes para vários
									gêneros de jogos, como esporte, ação,
									simulação, entre outros.
								</p>
								<br />
								<p>
									A professora afirma que eles precisarão
									criar narrativas eficazes, capazes de
									envolver as emoções humanas e “realmente
									emocionar um determinado público”.
								</p>
								<br />
								<p>
									Esses profissionais trabalharão ao lado dos
									programadores, designers e desenvolvedores
									para criar a aparência de um jogo, ajudando
									no desenvolvimento de melhores narrativas.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								Segurança de sistema e segurança cibernética
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									A Head da Meta, Caroline Dalmolin, afirma
									que profissionais que garantam a segurança
									de pessoas e dados no ambiente virtual serão
									essenciais para esse novo contexto.
								</p>
								<br />
								<p>
									“A integração de diferentes ambientes
									reunirá uma quantidade considerável de dados
									e, portanto, se tornará atrativo para
									ataques cibernéticos”, disse.
								</p>
								<br />
								<p>
									Assim, de acordo com ela, também será muito
									importante que as empresas se protejam e
									tenham profissionais especializados, que
									consigam corrigir qualquer problema de
									segurança o mais rápido possível.
								</p>
							</Typography>
						</Container>
					</Box>
					<ResponsiveFooter />
				</React.Fragment>
			)}
		</Box>
	);
}
