import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export default function Post30() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const titleStyleObj = {
		fontSize: width > 720 ? "1.9em" : "1.6em",
		fontWeight: "700",
		mb: "calc(0.2em)",
		mt: "calc(13px + 1.2em)",
		lineHeight: "1.5",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "#111",
	};

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	const textTableStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "center",
		color: "rgba(0,0,0,.74)",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			{!loading && (
				<React.Fragment>
					<Container
						sx={{
							width: "100%",
							height: "30%",
							paddingTop: width < 720 ? "150px" : "200px",
							paddingBottom: width < 720 ? "50px" : "100px",
							paddingLeft: width < 720 ? "15px" : "auto",
							paddingRight: width < 720 ? "15px" : "auto",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							backgroundColor: "#161616",
							maxWidth: "none !important",
						}}
					>
						<Typography
							align="center"
							sx={{
								fontSize: width < 720 ? "1.5rem" : "2.5rem",
								lineHeight: width < 720 ? "2rem" : "3rem",
								textAlign: "center",
								maxWidth: "700px",
								fontWeight: "700",
								color: "#FFF",
								padding: "20px",
							}}
						>
							Altas na pandemia: mercado subiu 31% e conta de luz,
							33%
						</Typography>
					</Container>
					<Box
						sx={{
							width: "100%",
							height: "100%",
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
							mt: "100px",
							mb: "200px",
							backgroundColor: "#FFF",
						}}
					>
						<Container
							sx={{
								maxWidth: "770px !important",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "flex-start",
								paddingLeft: width < 720 ? "22px" : "auto",
								paddingRight: width < 720 ? "22px" : "auto",
							}}
						>
							<Typography sx={textStyleObj}>
								<p>
									De acordo com o levantamento feito pela CNN
									Brasil, desde março de 2020, primeiro mês da
									pandemia de Covid-19 no Brasil, até
									fevereiro de 2022, o Índice de Preços ao
									Consumidor Amplo (IPCA) teve um aumento de
									16,3%, reflexo das diversas altas de
									produtos e serviços no período.
								</p>
								<br />
								<p>
									Segundo o Instituto Brasileiro de Geografia
									e Estatística (IBGE), em nota divulgada
									nesta sexta-feira (8), o IPCA de março teve
									alta de 1,26%, 0,61 ponto percentual acima
									da taxa de 1,01% de fevereiro. Essa é a
									maior variação para um mês de março desde
									1994.
								</p>
								<br />
								<p>
									O aumento no preço de centenas de produtos
									vem deixando a vida bem mais cara, em um
									intervalo curto de tempo. Entre eles estão
									desde os alimentos, combustíveis e
									eletrônicos, até materiais de construção,
									carros e bicicletas. Todos esses itens
									tiveram altas maiores que 20% nesses dois
									anos de contexto pandêmico.
								</p>
								<br />
								<p>
									“Foram dois anos bem atípicos, com aumentos
									muito expressivos e em categorias que pesam
									muito para as famílias, como os alimentos”,
									disse a economista Juliana Inhasz,
									professora da faculdade de economia e
									negócios Insper.
								</p>
								<br />
								<p>
									“Em uma situação normal, a inflação pode
									subir 5% ou 6%, sempre com alguns preços
									aumentando mais do que isso e, outros,
									menos, de maneira que uma coisa vai
									compensando a outra para o consumidor. Mas
									não foi o que vimos dessa vez. Quase tudo
									subiu, e subiu muito”, complementou.
								</p>
								<br />
								<p>
									Entre os 375 produtos e serviços que o IBGE
									acompanha mensalmente para calcular a
									variação do IPCA, desde o começo da
									pandemia, apenas 22 não foram reajustados ou
									tiveram qualquer queda de preço. Isso
									representa menos de 6% do total.
								</p>
								<br />
								<p>
									Paralelamente, 242 produtos e serviços – ou
									seja, 64% de tudo que os brasileiros
									geralmente compram – tiveram aumentos
									superiores a 10%.
								</p>
								<br />
								<p>
									Com as altas, está uma mistura de mudança de
									hábitos de consumo da população com
									interrupções de cadeias produtivas, preços
									de commodities em alta e picos na cotação do
									dólar, que atingiram fortemente o país.
								</p>
							</Typography>
							<TableContainer
								component={Paper}
								elevation={0}
								sx={{
									display: "flex",
									width: "100%",
									justifyContent: "center",
								}}
							>
								<Table
									sx={{ minWidth: "220", width: "270px" }}
									size="small"
									aria-label="a dense table"
								>
									<TableHead>
										<TableRow>
											<TableCell align="left">
												<Typography sx={textStyleObj}>
													<b>
														Alimentos com maiores
														altas
													</b>
												</Typography>
											</TableCell>
											<TableCell align="center">
												<Typography
													sx={textTableStyleObj}
												>
													<b>% da alta</b>
												</Typography>
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell scope="row" align="left">
												<Typography sx={textStyleObj}>
													Abobrinha
												</Typography>
											</TableCell>
											<TableCell align="right">
												<Typography
													sx={textTableStyleObj}
													align="right"
												>
													120,1%
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell scope="row" align="left">
												<Typography sx={textStyleObj}>
													Óleo de Soja
												</Typography>
											</TableCell>
											<TableCell align="right">
												<Typography
													sx={textTableStyleObj}
												>
													108,1%
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell scope="row" align="left">
												<Typography sx={textStyleObj}>
													Mamão
												</Typography>
											</TableCell>
											<TableCell align="right">
												<Typography
													sx={textTableStyleObj}
												>
													104,7%
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell scope="row" align="left">
												<Typography sx={textStyleObj}>
													Cenoura
												</Typography>
											</TableCell>
											<TableCell align="right">
												<Typography
													sx={textTableStyleObj}
												>
													102%
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell scope="row" align="left">
												<Typography sx={textStyleObj}>
													Pimentão
												</Typography>
											</TableCell>
											<TableCell align="right">
												<Typography
													sx={textTableStyleObj}
												>
													82,3%
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell scope="row" align="left">
												<Typography sx={textStyleObj}>
													Repolho
												</Typography>
											</TableCell>
											<TableCell align="right">
												<Typography
													sx={textTableStyleObj}
												>
													82%
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell scope="row" align="left">
												<Typography sx={textStyleObj}>
													Laranja Baía
												</Typography>
											</TableCell>
											<TableCell align="right">
												<Typography
													sx={textTableStyleObj}
												>
													79,8%
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell scope="row" align="left">
												<Typography sx={textStyleObj}>
													Mandioca
												</Typography>
											</TableCell>
											<TableCell align="right">
												<Typography
													sx={textTableStyleObj}
												>
													78,9%
												</Typography>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
							<Typography sx={titleStyleObj}>
								Supermercado
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Os alimentos foram, de longe, os produtos
									que mais sofreram altas. Fazer as compras no
									supermercado ficou cerca de 31,5% mais caro
									desde o começo de 2020, de acordo com os
									dados do IPCA.
								</p>
								<br />
								<p>
									Das 50 maiores altas dos últimos dois anos,
									42 são alimentos: as frutas estão cerca de
									36,7% mais caras, as carnes subiram 40,7%, o
									frango 41,8%, as folhas e verduras 53% e, os
									legumes, 60,7%.
								</p>
								<br />
								<p>
									No topo da lista das maiores altas estão a
									cenoura, o mamão, o óleo de soja e a
									abobrinha, todos com aumento superior a 100%
									no acumulado do período.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								Energia, materiais de construção e eletrônicos
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Sem contar os alimentos, as maiores altas da
									lista também revelam alguns setores que
									foram atingidos especialmente nesses dois
									anos de crise sanitária e lockdowns.
								</p>
								<br />
								<p>
									É o caso de materiais ligados a reformas,
									como o tijolo, que teve alta de 29%; as
									tintas, com 31,8%; os revestimentos, como
									pisos e azulejos, com 38%; e os materiais
									hidráulicos, que ficaram 52% mais caros.
								</p>
								<br />
								<p>
									“Foi uma pressão de demanda. Muita gente
									ficou em casa. Para alguns, sobrou renda e
									as pessoas foram reformar a casa”, explica
									Juliana Inhasz.
								</p>
								<br />
								<p>
									Também se destacaram os produtos ligados a
									energia, que apareceram no tipo do ranking
									das maiores altas. Hoje, a conta de luz está
									33% mais cara que dois anos atrás, fruto
									também do aumento adicional pela crise
									hídrica que secou as hidrelétricas em 2021.
								</p>
								<br />
								<p>
									A disparada na cotação do petróleo também
									atingiu o preço do gás encanado, que teve
									alta de 30,3%, do gás veicular (41,7%), da
									gasolina (44,6%), do botijão de gás (46%) e
									do diesel (47%), além do etanol (47,1%) –
									que acabou sob uma pressão tripla de custos:
									concorrência do petróleo e do açúcar, e o
									impacto das secas de 2021.
								</p>
								<br />
								<p>
									Eletrônicos e eletrodomésticos também
									tiveram altas, devido a uma mistura de dólar
									e componentes importados caros, e um
									crescimento inesperado por produtos de
									trabalho remoto. O preço das televisões
									subiu 40,5%, dos videogames, 44,1% e, das
									geladeiras, 32%.
								</p>
							</Typography>
							<TableContainer
								component={Paper}
								elevation={0}
								sx={{
									display: "flex",
									width: "100%",
									justifyContent: "center",
								}}
							>
								<Table
									sx={{ minWidth: "280px", width: "360px" }}
									size="small"
									aria-label="a dense table"
								>
									<TableHead>
										<TableRow>
											<TableCell align="left">
												<Typography sx={textStyleObj}>
													<b>
														Produtos com maiores
														altas (sem contar os
														alimentos)
													</b>
												</Typography>
											</TableCell>
											<TableCell align="center">
												<Typography
													sx={textTableStyleObj}
												>
													<b>% da alta</b>
												</Typography>
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell scope="row" align="left">
												<Typography sx={textStyleObj}>
													Pneu
												</Typography>
											</TableCell>
											<TableCell align="right">
												<Typography
													sx={textTableStyleObj}
													align="right"
												>
													55,7%
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell scope="row" align="left">
												<Typography sx={textStyleObj}>
													Material Hidráulico
												</Typography>
											</TableCell>
											<TableCell align="right">
												<Typography
													sx={textTableStyleObj}
												>
													52,5%
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell scope="row" align="left">
												<Typography sx={textStyleObj}>
													Etanol
												</Typography>
											</TableCell>
											<TableCell align="right">
												<Typography
													sx={textTableStyleObj}
												>
													47,1%
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell scope="row" align="left">
												<Typography sx={textStyleObj}>
													Óleo Diesel
												</Typography>
											</TableCell>
											<TableCell align="right">
												<Typography
													sx={textTableStyleObj}
												>
													47,1%
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell scope="row" align="left">
												<Typography sx={textStyleObj}>
													Botijão de Gás
												</Typography>
											</TableCell>
											<TableCell align="right">
												<Typography
													sx={textTableStyleObj}
												>
													46,8%
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell scope="row" align="left">
												<Typography sx={textStyleObj}>
													Gasolina
												</Typography>
											</TableCell>
											<TableCell align="right">
												<Typography
													sx={textTableStyleObj}
												>
													44,6%
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell scope="row" align="left">
												<Typography sx={textStyleObj}>
													Videogame
												</Typography>
											</TableCell>
											<TableCell align="right">
												<Typography
													sx={textTableStyleObj}
												>
													44,1%
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell scope="row" align="left">
												<Typography sx={textStyleObj}>
													Colchão
												</Typography>
											</TableCell>
											<TableCell align="right">
												<Typography
													sx={textTableStyleObj}
												>
													44%
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell scope="row" align="left">
												<Typography sx={textStyleObj}>
													Gás Veicular
												</Typography>
											</TableCell>
											<TableCell align="right">
												<Typography
													sx={textTableStyleObj}
												>
													41,7%
												</Typography>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
							<Typography sx={titleStyleObj}>
								Carros e bicicletas
							</Typography>
							<TableContainer
								component={Paper}
								elevation={0}
								sx={{
									display: "flex",
									width: "100%",
									justifyContent: "center",
								}}
							>
								<Table
									sx={{ minWidth: "280px", width: "360px" }}
									size="small"
									aria-label="a dense table"
								>
									<TableHead>
										<TableRow>
											<TableCell align="left">
												<Typography sx={textStyleObj}>
													<b>
														Produtos e serviços
														ligados a veículos
													</b>
												</Typography>
											</TableCell>
											<TableCell align="center">
												<Typography
													sx={textTableStyleObj}
												>
													<b>% da alta</b>
												</Typography>
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell scope="row" align="left">
												<Typography sx={textStyleObj}>
													Pneu
												</Typography>
											</TableCell>
											<TableCell align="right">
												<Typography
													sx={textTableStyleObj}
													align="right"
												>
													55,7%
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell scope="row" align="left">
												<Typography sx={textStyleObj}>
													Óleo Lubrificante
												</Typography>
											</TableCell>
											<TableCell align="right">
												<Typography
													sx={textTableStyleObj}
												>
													27%
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell scope="row" align="left">
												<Typography sx={textStyleObj}>
													Bicicleta
												</Typography>
											</TableCell>
											<TableCell align="right">
												<Typography
													sx={textTableStyleObj}
												>
													25,1%
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell scope="row" align="left">
												<Typography sx={textStyleObj}>
													Automóvel Novo
												</Typography>
											</TableCell>
											<TableCell align="right">
												<Typography
													sx={textTableStyleObj}
												>
													24,3%
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell scope="row" align="left">
												<Typography sx={textStyleObj}>
													Automóvel Usado
												</Typography>
											</TableCell>
											<TableCell align="right">
												<Typography
													sx={textTableStyleObj}
												>
													23,2%
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell scope="row" align="left">
												<Typography sx={textStyleObj}>
													Motocicleta
												</Typography>
											</TableCell>
											<TableCell align="right">
												<Typography
													sx={textTableStyleObj}
												>
													19,7%
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell scope="row" align="left">
												<Typography sx={textStyleObj}>
													Acessórios e Peças
												</Typography>
											</TableCell>
											<TableCell align="right">
												<Typography
													sx={textTableStyleObj}
												>
													17,4%
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell scope="row" align="left">
												<Typography sx={textStyleObj}>
													Pintura de Veículo
												</Typography>
											</TableCell>
											<TableCell align="right">
												<Typography
													sx={textTableStyleObj}
												>
													14,5%
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell scope="row" align="left">
												<Typography sx={textStyleObj}>
													Conserto de Carros
												</Typography>
											</TableCell>
											<TableCell align="right">
												<Typography
													sx={textTableStyleObj}
												>
													13,1%
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell scope="row" align="left">
												<Typography sx={textStyleObj}>
													Aluguel de Veículo
												</Typography>
											</TableCell>
											<TableCell align="right">
												<Typography
													sx={textTableStyleObj}
												>
													12,9%
												</Typography>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
							<Typography sx={textStyleObj}>
								<p>
									Os automóveis também sofreram aumentos,
									principalmente por causa da falta de chips
									para produção, que atingiu toda indústria
									global. Com a falta dessas peças no Brasil,
									houve um atraso na produção, gerando
									encarecimento no preço dos carros novos.
								</p>
								<br />
								<p>
									Com zero quilômetros mais caros, os
									brasileiros aumentaram a demanda por usados,
									que também tiveram uma disparada de preços.
								</p>
								<br />
								<p>
									Hoje, um automóvel novo custa 24% mais do
									que há dois anos e, um usado, 23%. As
									bicicletas também tiveram altas de cerca de
									25% nos últimos dois anos, enquanto as motos
									encareceram 19%.
								</p>
								<br />
								<p>
									Outros produtos e serviços ligados aos
									veículos, como pneus, consertos e alugueis,
									também estão mais caros.
								</p>
							</Typography>
						</Container>
					</Box>
					<ResponsiveFooter />
				</React.Fragment>
			)}
		</Box>
	);
}
