import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";

export default function Post33() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const titleStyleObj = {
		fontSize: width > 720 ? "1.9em" : "1.6em",
		fontWeight: "700",
		mb: "calc(0.2em)",
		mt: "calc(13px + 1.2em)",
		lineHeight: "1.5",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "#111",
	};

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			{!loading && (
				<React.Fragment>
					<Container
						sx={{
							width: "100%",
							height: "30%",
							paddingTop: width < 720 ? "150px" : "200px",
							paddingBottom: width < 720 ? "50px" : "100px",
							paddingLeft: width < 720 ? "15px" : "auto",
							paddingRight: width < 720 ? "15px" : "auto",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							backgroundColor: "#161616",
							maxWidth: "none !important",
						}}
					>
						<Typography
							align="center"
							sx={{
								fontSize: width < 720 ? "1.5rem" : "2.5rem",
								lineHeight: width < 720 ? "2rem" : "3rem",
								textAlign: "center",
								maxWidth: "700px",
								fontWeight: "700",
								color: "#FFF",
								padding: "20px",
							}}
						>
							Chocolate em alta: Entenda o aumento dos
							microempreendedores neste ramo alimentício
						</Typography>
					</Container>
					<Box
						sx={{
							width: "100%",
							height: "100%",
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
							mt: "100px",
							mb: "200px",
							backgroundColor: "#FFF",
						}}
					>
						<Container
							sx={{
								maxWidth: "770px !important",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "flex-start",
								paddingLeft: width < 720 ? "22px" : "auto",
								paddingRight: width < 720 ? "22px" : "auto",
							}}
						>
							<Typography sx={textStyleObj}>
								<p>
									Diante do mês de Páscoa, muitos
									empreendedores começaram a apostar na
									fabricação de chocolate em diferentes
									formatos e decorações, a fim de atrair ainda
									mais o mercado consumidor dentro deste ramo.
									Os microempreendedores estão com todas as
									apostas para a construção de catálogos,
									divulgação dos seus produtos e dando início
									às encomendas.
								</p>
								<br />
								<p>
									Segundo o Sebrae (Serviço Brasileiro de
									Apoio às Micro e Pequenas Empresas), ocorreu
									um aumento significativo do número de
									pequenos negócios que fabricam produtos
									derivados do chocolate durante a pandemia da
									Covid-19, ainda mais com a proposta de serem
									mais “em conta” do que os ovos de páscoa do
									mercado.
								</p>
								<br />
								<p>
									Diante da análise de dados, a área aumentou
									57% em 2021, se comparado com o resultado de
									2019. Esses dados foram obtidos a partir da
									Classificação Nacional de Atividades
									Econômicas (CNAE), que é formalizada pelos
									empreendedores no momento da abertura do
									negócio.
								</p>
							</Typography>

							<Typography sx={titleStyleObj}>
								Faturamento empreendedor
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Segundo os dados do faturamento, a pesquisa
									do Sebrae mostrou que 42% dos empreendedores
									venderam mais em 2020 do que em relação a
									2019.
								</p>
								<br />
								<p>
									Além disso, os dados também indicam que o
									número de empresas abertas no setor foi
									maior do que o fechamento de firmas por três
									anos seguidos. A exemplo de 2021, foram
									registradas 2.397 aberturas e 883
									encerramentos. Afinal, a maioria dos
									negócios (2.319) foram abertos por
									microempreendedores individuais (MEIs). Os
									demais envolvem microempresas (72) e
									empresas de pequeno porte (6).
								</p>
								<br />
								<p>
									De acordo com os especialistas dessa área, o
									setor de chocolates começou a atrair os
									microempreendedores por ser uma área que não
									exige formação profissional prévia, além da
									matéria-prima ser acessível. Além disso, o
									crescimento do setor está relacionado à
									falta de barreiras para a entrada no
									mercado, visto que está tudo aumentando de
									preços em espaços convencionais, como
									grandes empresas.
								</p>
								<br />
								<p>
									De acordo com os dados, o índice de
									consumidores que pretendem presentear alguém
									durante a Páscoa também aumentou. Passou de
									60,79% (2021) para 66,86% (2022). Entre os
									33,14% dos entrevistados que não possuem a
									intenção de presentear na Páscoa de 2022,
									destaca-se que 40,59% alegam dificuldade
									financeira para comprar presentes. Outros
									34,71% disseram estar desempregados.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								Aumento do chocolate
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									O preço do chocolate é um fator que também é
									preocupante, não só neste setor, mas em toda
									a questão alimentícia brasileira. A
									estimativa é que o chocolate esteja 15% mais
									caro em relação ao ano passado. Apesar da
									alta de preços para os consumidores, o setor
									de comércio espera um aumento de 18% nas
									vendas.
								</p>
								<br />
								<p>
									Segundo o levantamento feito pela Associação
									Brasileira dos Supermercados (Abras), os
									dados apontame que a venda de ovos de Páscoa
									e produtos da época devem apresentar um
									crescimento considerável em relação ao ano
									passado, de até 12%.
								</p>
								<br />
								<p>
									A nível econômico, o economista William
									Baghdassarian, do Instituto Brasileiro de
									Mercado de Capitais (IBMEC), explica que o
									aumento do chocolate é causado por conta de
									uma alta cambial desde o começo do ano.
								</p>
							</Typography>
						</Container>
					</Box>
					<ResponsiveFooter />
				</React.Fragment>
			)}
		</Box>
	);
}
