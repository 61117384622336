import {
	Box,
	Typography,
	Container,
	Divider,
	ListItem,
	List,
	Button,
	Grid,
} from "@mui/material";
import React from "react";
import ResponsiveAppBar from "../../components/AppBar";
import useWindowDimensions from "../../hooks/windowDimension";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ResponsiveFooter from "../../components/Footer";
import santander_sx_home from "../../assets/santander_sx_home.png";
import santander_sx_internacional from "../../assets/santander_sx_internacional.png";
import useLoader from "../../hooks/useLoader";
import Preloader from "../../components/Preloader";

const divGridStyle = {
	height: "auto",
	width: "100%",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "flex-start",
	textAlign: "left",
};

const typographyGridStyle = {
	fontSize: "0.9rem",
	color: "var(--textGray)",
	lineHeight: "1.5rem",
	mb: "20px",
};

export default function SantanderSXExt() {
	const { width } = useWindowDimensions();
	const loading = useLoader();

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && <Preloader />}
			<Container
				sx={{
					width: "100vw",
					height: "100%",
					mt: width < 720 ? "150px" : "200px",
					mb: "40px",
				}}
			>
				<Typography variant="h5" align="center">
					Pague zero anuidade com cadastro Pix!
				</Typography>
				<Typography
					align="center"
					sx={{
						fontSize: "1.1rem",
						fontWeight: "600",
						mt: "10px",
					}}
				>
					Solicite Conta + Cartão e aproveite 2 formas diferentes de
					ter isenção na anuidade do seu Cartão SX.
				</Typography>
				<Box
					sx={{
						width: "100%",
						height: "auto",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						mt: "30px",
					}}
				>
					<img
						alt="santander_sx_home"
						src={santander_sx_home}
						loading="lazy"
						style={{
							width: "80%",
							height: "100%",
							maxWidth: "450px",
						}}
					/>
				</Box>
			</Container>
			<Divider variant="middle" />
			<Container
				sx={{
					display: width < 720 ? "flex" : "none",
					alignItems: "center",
					justifyContent: "center",
					mt: "100px",
					mb: "100px",
				}}
			>
				<div id="cartoes.informacash_top_mob"></div>
			</Container>
			<Container
				sx={{
					display: width > 720 ? "flex" : "none",
					alignItems: "center",
					justifyContent: "center",
					mt: "100px",
					mb: "100px",
				}}
			>
				<div id="cartoes.informacash_top_desk"></div>
			</Container>
			<Container
				sx={{
					width: "100vw",
					height: "100%",
					mt: "70px",
					mb: "30px",
					pl: "7%",
					pr: "4%",
				}}
			>
				<Typography
					variant="h5"
					align="left"
					sx={{
						lineHeight: "2rem",
						fontWeight: "700",
						mb: "5px",
					}}
				>
					Por que escolher o SX?
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "1.5rem",
						fontWeight: "400",
						mb: "20px",
					}}
				>
					Tenha facilidade no seu dia a dia!
					<br />
				</Typography>

				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Tenha anuidade grátis em dobro
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Limite a partir de R$ 250,00
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Tenha cartão de crédito e débito em um mesmo cartão.
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Descontos exclusivos e cashback com os parceiros Esfera
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Cobertura Internacional
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Benefícios do cartão Visa
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Parcelamento de faturas em até 24x
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Saque de dinheiro no crédito: faça saques na função crédito
					e pague só quando chegar a fatura.
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Cartão sustentável
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mt: "10px",
						mb: "10px",
					}}
				>
					Clique no botão para obter o seu cartão e comece a facilitar
					o seu dia a dia!
					<br />
				</Typography>
			</Container>
			<Container
				sx={{
					width: "100vw",
					height: "100%",
					mt: "40px",
					mb: "30px",
					pl: "7%",
					pr: "4%",
				}}
			>
				<Typography
					variant="h5"
					align="left"
					sx={{
						lineHeight: "2rem",
						fontWeight: "700",
						mb: "10px",
					}}
				>
					Mais informações sobre o SX.
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mt: "10px",
						mb: "10px",
					}}
				>
					A combinação perfeita para você. Saiba mais sobre a
					anuidade, renda mínima e benefícios exigidos para obter o
					cartão.
					<br />
				</Typography>
				<Container
					sx={{
						display: width < 720 ? "flex" : "none",
						alignItems: "center",
						justifyContent: "center",
						mt: "100px",
						mb: "100px",
					}}
				>
					<div
						class="pg-lazy"
						data-gpt-parent="cartoes.informacash_mid_mob"
					></div>
				</Container>
				<Container sx={{ width: "90%", mb: "20px", mt: "30px" }}>
					<Grid container spacing={2} alignItems="stretch">
						<Grid item xs={12} sm={6}>
							<Box
								sx={{
									width: "100%",
									height: "100%",
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<img
									alt="santander_sx_internacional"
									src={santander_sx_internacional}
									loading="lazy"
									style={{
										width: "80%",
										height: "100%",
										maxWidth: "350px",
										marginBottom: "20px",
									}}
								/>
								<Typography variant="h6" align="center">
									<b>Internacional Visa</b>
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6}>
							<div style={divGridStyle}>
								<Typography
									variant="p"
									align="left"
									sx={typographyGridStyle}
								>
									<b>Anuidade:</b> 12 parcelas de R$ 33,25
									<br />
									Livre de anuidade: acumulando no mínimo R$
									100 em compras no crédito a cada fatura ou
									cadastrando e mantendo o CPF e celular como
									chaves PIX no Santander.
									<br />
									<b>Bandeira:</b> Visa
									<br />
									<b> Renda Mínima:</b> Sem conta corrente: R$
									1045,00 | Com conta corrente: R$ 500,00
									<br />
									<b>Benefícios:</b> Programa de benefícios
									Vai de Visa, cartão físico e digital,
									cobertura internacional, a partir de R$
									250,00 de limite e descontos com parceiros
									Esfera.
								</Typography>
							</div>
						</Grid>
					</Grid>
				</Container>
				<Container
					sx={{
						display: width > 720 ? "flex" : "none",
						alignItems: "center",
						justifyContent: "center",
						mt: "100px",
						mb: "100px",
					}}
				>
					<div
						class="pg-lazy"
						data-gpt-parent="cartoes.informacash_mid_desk"
					></div>
				</Container>
				<Typography
					align="left"
					variant="h5"
					sx={{
						lineHeight: "2.1rem",
						mb: "10px",
						fontWeight: "700",
					}}
				>
					Como solicitar o seu SX?
				</Typography>
				<List
					sx={{
						listStyleType: "decimal",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<ListItem
						sx={{
							justifyContent: "left",
							pb: "0 !important",
							pt: "0 !important",
							pl: "20px",
							mb: "5px",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "1rem",
								display: "list-item",
							}}
						>
							Clique em <b>solicitar agora</b>
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "left",
							pb: "0 !important",
							pt: "0 !important",
							pl: "20px",
							mb: "5px",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "1rem",
								display: "list-item",
							}}
						>
							Abra uma conta no Santander
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "left",
							pb: "0 !important",
							pt: "0 !important",
							pl: "20px",
							mb: "5px",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "1rem",
								display: "list-item",
							}}
						>
							Preencha seus dados
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "left",
							pb: "0 !important",
							pt: "0 !important",
							pl: "20px",
							mb: "5px",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "1rem",
								display: "list-item",
							}}
						>
							Receba o seu SX em casa ou use de forma digital.
						</Typography>
					</ListItem>
				</List>
			</Container>
			<Container
				sx={{
					width: "100%",
					height: "100%",
					mb: "50px",
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Button
					variant="contained"
					size="large"
					disableElevation
					sx={{
						fontWeight: "700",
						backgroundColor: "var(--infoCashBlue)",
					}}
					href="https://www.santander.com.br/cartoes/lead/sx?ic=cartaosx_lpcartao_pecaaqui"
				>
					Solicitar agora
				</Button>
			</Container>
			<ResponsiveFooter />
		</Box>
	);
}
