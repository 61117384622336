import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";

export default function Post47() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const titleStyleObj = {
		fontSize: width > 720 ? "1.9em" : "1.6em",
		fontWeight: "700",
		mb: "calc(0.2em)",
		mt: "calc(13px + 1.2em)",
		lineHeight: "1.5",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "#111",
	};

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			{!loading && (
				<React.Fragment>
					<Container
						sx={{
							width: "100%",
							height: "30%",
							paddingTop: width < 720 ? "150px" : "200px",
							paddingBottom: width < 720 ? "50px" : "100px",
							paddingLeft: width < 720 ? "15px" : "auto",
							paddingRight: width < 720 ? "15px" : "auto",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							backgroundColor: "#161616",
							maxWidth: "none !important",
						}}
					>
						<Typography
							align="center"
							sx={{
								fontSize: width < 720 ? "1.5rem" : "2.5rem",
								lineHeight: width < 720 ? "2rem" : "3rem",
								textAlign: "center",
								maxWidth: "700px",
								fontWeight: "700",
								color: "#FFF",
								padding: "20px",
							}}
						>
							Juros no Brasil; Ibovespa com alta de 1,2%; dólar a
							R$4,84
						</Typography>
					</Container>
					<Box
						sx={{
							width: "100%",
							height: "100%",
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
							mt: "100px",
							mb: "200px",
							backgroundColor: "#FFF",
						}}
					>
						<Container
							sx={{
								maxWidth: "770px !important",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "flex-start",
								paddingLeft: width < 720 ? "22px" : "auto",
								paddingRight: width < 720 ? "22px" : "auto",
							}}
						>
							<Typography sx={textStyleObj}>
								<p>
									Nesta quinta-feira (24), por volta das 14h,
									o Ibovespa, mais importante indicador do
									desempenho médio das cotações das ações
									negociadas na B3, subia 1,27%, sendo cotado
									a 118.949 pontos, acompanhando o exterior e
									intensificando a alta após fala do
									presidente do Banco Central, Roberto Campos
									Melo, que afirma que o ciclo de elevação de
									juros no Brasil deve se encerrar em maio.
								</p>
								<br />
								<p>
									Isso significa uma taxa Selic final de
									12,75% – menor que o esperado pelo mercado
									e, assim, favorecendo a renda variável, mais
									especificamente as ações de setores como
									tecnologia e varejo.
								</p>
								<br />
								<p>
									Na manhã desta quinta, o dólar recuava
									0,015%, valendo R$ 4,843, após chegar na
									mínima de R$ 4,677, refletindo a situação
									dos juros brasileiros menores que o previsto
									e intensificação de um aperto monetário
									maior nos Estados Unidos após a divulgação
									dos pedidos semanais de auxílio-desemprego
									abaixo do esperado.
								</p>
								<br />
								<p>
									Isso significa uma taxa Selic final de
									12,75% – menor que o esperado pelo mercado
									e, assim, favorecendo a renda variável, mais
									especificamente as ações de setores como
									tecnologia e varejo.
								</p>
								<br />
								<p>
									A moeda norte-americana, mesmo avançando no
									exterior, ronda a estabilidade em relação ao
									real, por causa tanto do fluxo de entrada de
									capital em 2022, como dos altos juros do
									Brasil, um dos mais altos do mundo.
								</p>
								<br />
								<p>
									Essa alta é vista nos ativos considerados
									desconectados na bolsa de valores e na
									busca, por parte dos investidores, por
									mercados produtores de commodities, as quais
									tiveram os preços disparados com a guerra na
									Ucrânia.
								</p>
								<br />
								<p>
									Nesta quinta-feira (24), o Banco Central
									divulgou o Relatório Trimestral de Inflação.
									Nele, consta que a projeção da inflação para
									2022 passou de 4,7% para 7,1%, valor acima
									do teto da meta, que era de 5%.
								</p>
								<br />
								<p>
									Existe ainda um outro cenário, cuja
									autarquia considera o cenário mais provável,
									que prevê que o petróleo passará de US$ 100
									em 2022, com inflação de 6,3% no ano – ainda
									acima do teto da meta – e 3,1% em 2023.
								</p>
								<br />
								<p>
									Na quarta-feira (23), o dólar teve uma queda
									de 1,44%, ficando a R$ 4,844, o menor valor
									para encerramento desde 13 de março de 2020.
									Enquanto isso, o Ibovespa subiu 0,16%, aos
									117.457,34 pontos, maior patamar desde 6 de
									setembro de 2021.
								</p>
							</Typography>

							<Typography sx={titleStyleObj}>Petróleo</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Desde o começo da guerra entre Rússia e
									Ucrânia, há exatamente 1 mês, os mercados de
									petróleo mostram a maior volatilidade em
									dois anos, com preços chegando a bater
									níveis vistos somente em 2008.
								</p>
								<br />
								<p>
									Recentemente, houve um movimento de recuo,
									mas o barril tipo Brent voltou a custar US$
									120, devido aos temores de dificuldades da
									Rússia – um dos maiores exportadores do
									mundo – para atender a demanda por petróleo
									e as discussões sobre um possível embargo da
									União Europeia para a compra de petróleo
									russo.
								</p>
								<br />
								<p>
									Comparado com anos anteriores, o petróleo
									segue com valores altos, explicados pelo
									descompasso entre a oferta e demanda da
									commodity, já que os principais produtores,
									reunidos na Opep+, ainda não retornaram aos
									níveis de produção pré-pandemia. Esse quadro
									foi intensificado com as tensões na Europa.
								</p>
								<br />
								<p>
									A elevação do preço dos combustíveis também
									é uma consequência direta da alta do barril
									de petróleo. No Brasil, isso acelerou a
									discussão sobre projetos de lei que
									buscassem conter a alta nos preços dos
									combustíveis.
								</p>
							</Typography>

							<Typography sx={titleStyleObj}>
								Commodities
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									A disparada nos preços das commodities
									favorece o mercado brasileiro e seus efeitos
									tem ajudado a superar os riscos da guerra na
									Ucrânia, beneficiando o real até o momento.
								</p>
								<br />
								<p>
									O ciclo está ligado, em parte, à elevação
									dos preços do petróleo e do ferro, que
									aconteceu devido à elevada demanda criada na
									retomada econômica dos países. O ciclo de
									alta de juros nos Estados Unidos e a saída
									da renda variável no país também alimentam
									essa movimentação.
								</p>
								<br />
								<p>
									Outro fator por trás dessa migração são as
									expectativas de mais medidas pró-crescimento
									na China, que acabam aumentando as
									expectativas de uma recuperação na demanda
									de metais, o que leva a uma alta nos preços,
									reforçadas com a crise na Ucrânia.
								</p>
								<br />
								<p>
									Porém, com intervenções do governo chinês no
									mercado e o surgimento de um novo surto de
									Covid-19 no país, ainda existem pressões de
									queda, com uma cotação instável que continua
									em níveis elevados.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								Conflito na Ucrânia
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Do ponto de vista econômico, os
									acontecimentos mais recentes envolvendo a
									guerra entre Rússia e Ucrânia foi o anúncio,
									por parte dos países da União Europeia, de
									novas sanções contra a Rússia, que viu sua
									moeda despencar e atingir uma mínima
									histórica. Os principais setores afetados
									foram o de energia, aço e defesa.
								</p>
								<br />
								<p>
									As sanções de maior impacto econômico para a
									Rússia estão ligadas à expulsão dos bancos
									russos do Swift, meio global de
									processamento de pagamentos.
								</p>
							</Typography>
						</Container>
					</Box>
					<ResponsiveFooter />
				</React.Fragment>
			)}
		</Box>
	);
}
