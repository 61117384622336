import {
	Box,
	Typography,
	Container,
	Divider,
	Button,
	Grid,
	List,
	ListItem,
} from "@mui/material";
import React from "react";
import ResponsiveAppBar from "../../components/AppBar";
import ResponsiveFooter from "../../components/Footer";
import useWindowDimensions from "../../hooks/windowDimension";
import { useLocation } from "react-router-dom";
import meliuz_home from "../../assets/meliuz_home.png";
import Preloader from "../../components/Preloader";
import useLoader from "../../hooks/useLoader";

const divGridStyle = {
	height: "80px",
	width: "100%",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	backgroundColor: "var(--bgGray)",
	borderRadius: "5px",
	textAlign: "center",
	padding: "0 15px",
};

const typographyGridStyle = {
	fontSize: "1.1rem",
	fontWeight: "bold",
	color: "var(--textGray)",
};

export default function Meliuz() {
	const { width } = useWindowDimensions();
	const loading = useLoader();
	const { search } = useLocation();

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && <Preloader />}

			<Container
				sx={{
					width: "100vw",
					height: "100%",
					mt: width < 720 ? "150px" : "200px",
					mb: "40px",
				}}
			>
				<Typography variant="h5" align="center">
					Novo cartão Méliuz 100% gratuito e com 2% de cashback
				</Typography>
				<Typography
					align="center"
					sx={{
						fontSize: "1.1rem",
						fontWeight: "600",
						mt: "10px",
					}}
				>
					Sem burocracia e com a união do útil ao agradável: gratuito,
					sem anuidade e com cashback ou criptoback!
				</Typography>
				<Box
					sx={{
						width: "100%",
						height: "auto",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						mt: "30px",
					}}
				>
					<img
						alt="meliuz_home"
						src={meliuz_home}
						loading="lazy"
						style={{
							width: "80%",
							height: "100%",
							maxWidth: "450px",
						}}
					/>
				</Box>
			</Container>
			<Divider variant="middle" />
			<Container
				sx={{
					display: width < 720 ? "flex" : "none",
					alignItems: "center",
					justifyContent: "center",
					mt: "100px",
					mb: "100px",
				}}
			>
				<div id="cartoes.informacash_top_mob"></div>
			</Container>
			<Container
				sx={{
					display: width > 720 ? "flex" : "none",
					alignItems: "center",
					justifyContent: "center",
					mt: "100px",
					mb: "100px",
				}}
			>
				<div id="cartoes.informacash_top_desk"></div>
			</Container>
			<Container
				sx={{
					width: "100vw",
					height: "100%",
					mt: "100px",
					mb: "80px",
					pl: "7%",
					pr: "4%",
				}}
			>
				<Typography
					variant="p"
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						color: "var(--textGray)",
					}}
				>
					Com o novo cartão de crédito Méliuz, você tem benefícios com
					até 2% e cashback ou criptoback! Inovador e gratuito, os
					investidores de Bitcoin e outras criptomoedas poderão usar
					um cartão físico ou digital para aumentar as chances de
					mexer com o seu dinheiro. Um cartão sem anuidade e sem
					limite de gasto mensal!
				</Typography>
			</Container>
			<Container
				sx={{
					width: "100%",
					height: "auto",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					maxWidth: "810px",
				}}
			>
				<Button
					variant="contained"
					size="large"
					disableElevation
					sx={{
						fontWeight: "700",
						backgroundColor: "var(--infoCashBlue)",
					}}
					href={
						window.location.hostname !== "localhost"
							? `https://cartoes.informacash.com.br/meliuz-solicitar${search}`
							: `./meliuz-solicitar`
					}
				>
					Quero saber mais
				</Button>
			</Container>
			<Container
				sx={{
					width: "100%",
					height: "100%",
					mt: "80px",
					mb: "40px",
					pl: "7%",
					pr: "4%",
				}}
			>
				<Typography
					variant="h5"
					align="center"
					sx={{
						lineHeight: "2rem",
						fontWeight: "700",
						mb: "10px",
					}}
				>
					Tipos de Cartões
				</Typography>
				<Divider variant="middle" sx={{ mb: "20px" }} />
				<Typography
					align="left"
					sx={{
						mt: "20px",
						fontSize: "1.2rem",
						fontWeight: "700",
					}}
				>
					Méliuz Mastercard
				</Typography>
				<List
					sx={{
						listStyleType: "disc",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							1 cartão físico
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							1 cartão virtual
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							Cartão de crédito e débito Mastercard®
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							Cobertura internacional
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							Anuidade grátis
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							Cashback ou criptoback em até 2%
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							Saque em banco 24h
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							Compras parceladas
						</Typography>
					</ListItem>
				</List>
			</Container>
			<Container
				sx={{
					display: width < 720 ? "flex" : "none",
					alignItems: "center",
					justifyContent: "center",
					mt: "100px",
					mb: "100px",
				}}
			>
				<div id="cartoes.informacash_mid_mob"></div>
			</Container>
			<Container
				sx={{
					display: width > 720 ? "flex" : "none",
					alignItems: "center",
					justifyContent: "center",
					mt: "100px",
					mb: "100px",
				}}
			>
				<div id="cartoes.informacash_mid_desk"></div>
			</Container>
			<Container sx={{ width: "90%", mb: "40px", mt: "30px" }}>
				<Grid container spacing={2} alignItems="stretch">
					<Grid item xs={12} sm={6}>
						<div style={divGridStyle}>
							<Typography
								variant="p"
								align="center"
								sx={typographyGridStyle}
							>
								Cashback e criptoback em 2%
							</Typography>
						</div>
					</Grid>
					<Grid item xs={12} sm={6}>
						<div style={divGridStyle}>
							<Typography
								variant="p"
								align="center"
								sx={typographyGridStyle}
							>
								Anuidade grátis
							</Typography>
						</div>
					</Grid>
					<Grid item xs={12} sm={6}>
						<div style={divGridStyle}>
							<Typography
								variant="p"
								align="center"
								sx={typographyGridStyle}
							>
								Cartão físico e virtual
							</Typography>
						</div>
					</Grid>
					<Grid item xs={12} sm={6}>
						<div style={divGridStyle}>
							<Typography
								variant="p"
								align="center"
								sx={typographyGridStyle}
							>
								Cobertura internacional
							</Typography>
						</div>
					</Grid>
				</Grid>
			</Container>
			<Container
				sx={{
					width: "100%",
					height: "auto",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					maxWidth: "810px",
					mb: "20%",
				}}
			>
				<Button
					variant="contained"
					size="large"
					disableElevation
					sx={{
						fontWeight: "700",
						backgroundColor: "var(--infoCashBlue)",
					}}
					href={
						window.location.hostname !== "localhost"
							? `https://cartoes.informacash.com.br/meliuz-solicitar${search}`
							: `./meliuz-solicitar`
					}
				>
					Quero solicitar meu cartão
				</Button>
			</Container>
			<ResponsiveFooter />
		</Box>
	);
}
