import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export default function Post45() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const titleStyleObj = {
		fontSize: width > 720 ? "1.9em" : "1.6em",
		fontWeight: "700",
		mb: "calc(0.2em)",
		mt: "calc(13px + 1.2em)",
		lineHeight: "1.5",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "#111",
	};

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			{!loading && (
				<React.Fragment>
					<Container
						sx={{
							width: "100%",
							height: "30%",
							paddingTop: width < 720 ? "150px" : "200px",
							paddingBottom: width < 720 ? "50px" : "100px",
							paddingLeft: width < 720 ? "15px" : "auto",
							paddingRight: width < 720 ? "15px" : "auto",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							backgroundColor: "#161616",
							maxWidth: "none !important",
						}}
					>
						<Typography
							align="center"
							sx={{
								fontSize: width < 720 ? "1.5rem" : "2.5rem",
								lineHeight: width < 720 ? "2rem" : "3rem",
								textAlign: "center",
								maxWidth: "700px",
								fontWeight: "700",
								color: "#FFF",
								padding: "20px",
							}}
						>
							Entenda onde investir com o aumento da taxa Selic
						</Typography>
					</Container>
					<Box
						sx={{
							width: "100%",
							height: "100%",
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
							mt: "100px",
							mb: "200px",
							backgroundColor: "#FFF",
						}}
					>
						<Container
							sx={{
								maxWidth: "770px !important",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "flex-start",
								paddingLeft: width < 720 ? "22px" : "auto",
								paddingRight: width < 720 ? "22px" : "auto",
							}}
						>
							<Typography sx={textStyleObj}>
								<p>
									Entenda onde investir com a elevação da
									Selic em alta indicado para o mês de abril.
									Segundo o presidente do Banco Central, o
									pico da inflação no Brasil será em abril,
									quando a taxa Selic atingirá 12,75%.
								</p>
								<br />
								<p>
									Diante dos conflitos, a inflação em vários
									setores e produtos acabou atingindo uma
									maior elevação no mercado econômico. As
									projeções da taxa aumentaram de 8,25% ao ano
									para 8,75%, indicando a renda fixa como a
									preferência dos brasileiros.
								</p>
							</Typography>

							<Typography sx={titleStyleObj}>
								Impacto nos investimentos com o aumento da taxa
								Selic
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Em resumo, a Selic é a taxa básica de juros
									da economia. Essa taxa é o principal
									instrumento de política monetária utilizado
									pelo Banco Central (BC) para controlar a
									inflação.
								</p>
								<br />
								<p>
									Além disso, ela influencia todas as taxas de
									juros do país, como as taxas de juros dos
									empréstimos, dos financiamentos e das
									aplicações financeiras. A taxa Selic
									refere-se à taxa de juros apurada nas
									operações de empréstimos de um dia entre as
									instituições financeiras que utilizam
									títulos públicos federais como garantia.
								</p>
								<br />
								<p>
									Além disso, dentro do universo dos
									investimentos, a Selic tem um impacto direto
									sobre a rentabilidade de diversos produtos
									que são atrelados a ela. Sendo destaque, os
									maiores são os de renda fixa.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								A importância da taxa básica de juros
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									A Selic é o principal instrumento do Banco
									Central do Brasil para controlar a inflação.
									Basicamente a taxa Selic afeta o custo do
									dinheiro no país.
								</p>
								<br />
								<p>
									Afinal, quando a taxa básica de juros
									aumenta, o crédito para as empresas
									investirem torna-se mais caro. Dessa forma,
									a atividade econômica esfria, ajudando a
									conter a inflação, o que evita a corrosão do
									poder de compra da moeda. Ao contrário
									disso, quando a taxa é reduzida, acontece
									que o financiamento do investimento e do
									consumo tende a ficar mais barato, aquecendo
									a economia.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								Qual o valor da Selic hoje?
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									O valor da Selic hoje é de 11,75% ao ano. O
									Copom elevou a taxa básica de juros durante
									o mês de março, mas a probabilidade é que
									chegue até 14,25% até agosto deste ano.
								</p>
								<br />
								<p>
									O rápido aumento da Selic pelo Banco Central
									busca ancorar as projeções de inflação no
									país, em meio ao aumento observado nos
									preços das commodities.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								E quais os investimentos afetados pela taxa
								Selic?
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									A taxa Selic sempre afeta bastante os preços
									do mercado financeiro, incluindo o aumento
									da inflação. A exemplo disso, a taxa básica
									de juros mais alta tende a reduzir a demanda
									dos investidores por ativos de risco, como
									ações.
								</p>
								<br />
								<p>
									Além disso, o aumento da Selic também pode
									influenciar o preço do dólar no Brasil, ao
									reforçar o fluxo estrangeiro de
									investimentos em títulos do país. O aumento
									da Selic é bastante negativo para quem
									precisa de um empréstimo, mas pode ser
									positivo para outros tipos de investimentos.
								</p>
								<br />
								<p>
									Veja exemplos de investimentos que
									rentabilizam, apesar do aumento da taxa
									Selic:
								</p>
							</Typography>
							<TableContainer component={Paper} elevation={0}>
								<Table
									sx={{ minWidth: 340 }}
									size="small"
									aria-label="a dense table"
								>
									<TableBody>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
												verticalAlign: "top",
											}}
										>
											<TableCell scope="row" align="left">
												<Typography sx={textStyleObj}>
													Tesouro Direto Selic
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography sx={textStyleObj}>
													O Tesouro Direto Selic rende
													100% da taxa básica de
													juros. A última alta feita
													pelo BC ele passou a render
													11,75% ao ano.
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
												verticalAlign: "top",
											}}
										>
											<TableCell scope="row" align="left">
												<Typography sx={textStyleObj}>
													LCI – Letra de Crédito
													Imobiliário:
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography sx={textStyleObj}>
													As LCIs foram criadas para
													impulsionar o mercado de
													crédito imobiliário no país.
													São títulos emitidos por
													bancos que emprestam esse
													recurso para empresas
													ligadas ao mercado
													imobiliário. É isenta de
													imposto de renda.
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
												verticalAlign: "top",
											}}
										>
											<TableCell scope="row" align="left">
												<Typography sx={textStyleObj}>
													LCA – Letra de Crédito do
													Agronegócio
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography sx={textStyleObj}>
													As LCAs incentivam o
													agronegócio, também são
													isentas de IR e contam com a
													garantia do FGC.
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
												verticalAlign: "top",
											}}
										>
											<TableCell scope="row" align="left">
												<Typography sx={textStyleObj}>
													LC – Letra de Câmbio
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography sx={textStyleObj}>
													As LCs seguem o mesmo
													princípio dos CDBs, mas ao
													invés de emprestar aos
													bancos, o investidor
													empresta para instituições
													financeiras de crédito, mais
													conhecidas como financeiras.
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
												verticalAlign: "top",
											}}
										>
											<TableCell scope="row" align="left">
												<Typography sx={textStyleObj}>
													Fundos de investimento
													Referenciados DI
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography sx={textStyleObj}>
													Os Fundos DI, como o nome
													sugere, buscam obter
													retornos que acompanham o
													CDI. Suas características
													principais são: baixa
													volatilidade e baixas taxas
													de administração.Com
													frequência são utilizados
													como caixa ou reserva de
													emergência por possuírem
													retornos próximos ao CDI,
													aliado a liquidez imediata
													ou D+1.
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
												verticalAlign: "top",
											}}
										>
											<TableCell scope="row" align="left">
												<Typography sx={textStyleObj}>
													CDB – Certificado de
													Depósito Bancário3
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography sx={textStyleObj}>
													Os CDBs também são ótimas
													oportunidades para quem
													busca se beneficiar de mais
													um aumento da taxa Selic.
													Essa opção historicamente
													segue a Selic, por isso,
													quando a Selic sobe o CDI
													acompanha e vice-versa.
												</Typography>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
							<Typography sx={titleStyleObj}>
								Projeções para os próximos meses:
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									O BNP Paribas (PA:BNPP) espera que o cenário
									de inflação mais pressionado do que o
									esperado em 2022 faça com que o Banco
									Central a subir a taxa Selic a 14,25% até
									agosto de 2022.
								</p>
								<br />
								<p>
									O novo prognóstico prevê ajuste de 1 ponto
									percentual na Selic no próximo encontro do
									Comitê de Política Monetária (Copom) do
									Banco Central (BC), em maio, seguido de
									altas de 1 e 0,5 pontos. Anteriormente, a
									estimativa do credor francês para os juros
									básicos ao final deste ano era de 13,25%.
								</p>
							</Typography>
						</Container>
					</Box>
					<ResponsiveFooter />
				</React.Fragment>
			)}
		</Box>
	);
}
