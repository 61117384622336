import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Link, Grid, Typography } from "@mui/material";

const ResponsiveFooter = () => {
	return (
		<Box
			sx={{
				width: "100vw",
				height: "275px",
				backgroundColor: "var(--bgGray)",
				padding: "30px 15px",
			}}
		>
			<Container sx={{ width: "100%", mb: "40px", mt: "30px" }}>
				<Grid
					container
					spacing={2}
					alignItems="stretch"
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Grid item>
						<Link
							href="https://informacash.com/politica-privacidade/"
							underline="none"
							sx={{
								fontSize: "0.75rem",
								fontWeight: "500",
								color: "var(--footerBlack)",
								letterSpacing: ".075em",
								textTransform: "uppercase",
								opacity: "initial",
							}}
						>
							Políticas de privacidade
						</Link>
					</Grid>
					<Grid item>
						<Link
							href="https://informacash.com.br/termos-de-uso/"
							underline="none"
							sx={{
								fontSize: "0.75rem",
								fontWeight: "500",
								color: "var(--footerBlack)",
								letterSpacing: ".075em",
								textTransform: "uppercase",
								opacity: "initial",
							}}
						>
							Termos de uso
						</Link>
					</Grid>
					<Grid item>
						<Link
							href="https://informacash.com.br/contato/"
							underline="none"
							sx={{
								fontSize: "0.75rem",
								fontWeight: "500",
								color: "var(--footerBlack)",
								letterSpacing: ".075em",
								textTransform: "uppercase",
								opacity: "initial",
							}}
						>
							Contato
						</Link>
					</Grid>
				</Grid>
				<Typography
					align="center"
					sx={{
						mt: "40px",
						fontSize: "0.75rem",
						fontWeight: "500",
						letterSpacing: ".11em",
						textTransform: "uppercase",
						opacity: "initial",
						color: "#616161",
					}}
				>
					© INFORMACASH - ALL RIGHTS RESERVED
				</Typography>
			</Container>
		</Box>
	);
};
export default ResponsiveFooter;
