import {
	Box,
	Typography,
	Container,
	Divider,
	Button,
	Grid,
	List,
	ListItem,
} from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../components/AppBar";
import ResponsiveFooter from "../../components/Footer";
import useWindowDimensions from "../../hooks/windowDimension";
import { useLocation, useNavigate } from "react-router-dom";
import atacadao_home from "../../assets/atacadao_home.png";
import { MoonLoader } from "react-spinners";
import Preloader from "../../components/Preloader";
import useLoader from "../../hooks/useLoader";

const divGridStyle = {
	height: "80px",
	width: "100%",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	backgroundColor: "var(--bgGray)",
	borderRadius: "5px",
	textAlign: "center",
	padding: "0 15px",
};

const typographyGridStyle = {
	fontSize: "1.1rem",
	fontWeight: "bold",
	color: "var(--textGray)",
};

export default function Atacadao() {
	const { width } = useWindowDimensions();
	const { search } = useLocation();
	const loading = useLoader();

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && <Preloader />}

			<Container
				sx={{
					width: "100vw",
					height: "100%",
					mt: width < 720 ? "150px" : "200px",
					mb: "40px",
				}}
			>
				<Typography variant="h5" align="center">
					Parcelamentos e descontos exclusivos com o cartão de crédito
					Atacadão!
				</Typography>
				<Typography
					align="center"
					sx={{
						fontSize: "1.1rem",
						fontWeight: "600",
						mt: "10px",
					}}
				>
					Parcele as suas compras do mês com descontos exclusivos!
				</Typography>
				<Box
					sx={{
						width: "100%",
						height: "auto",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						mt: "30px",
					}}
				>
					<img
						alt="atacadao_home"
						src={atacadao_home}
						loading="lazy"
						style={{
							width: "80%",
							height: "100%",
							maxWidth: "700px",
						}}
					/>
				</Box>
			</Container>
			<Divider variant="middle" />
			<Container
				sx={{
					display: width < 720 ? "flex" : "none",
					alignItems: "center",
					justifyContent: "center",
					mt: "100px",
					mb: "100px",
				}}
			>
				<div id="cartoes.informacash_top_mob"></div>
			</Container>
			<Container
				sx={{
					display: width > 720 ? "flex" : "none",
					alignItems: "center",
					justifyContent: "center",
					mt: "100px",
					mb: "100px",
				}}
			>
				<div id="cartoes.informacash_top_desk"></div>
			</Container>
			<Container
				sx={{
					width: "100vw",
					height: "100%",
					mt: "100px",
					mb: "80px",
					pl: "7%",
					pr: "4%",
				}}
			>
				<Typography
					variant="p"
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						color: "var(--textGray)",
					}}
				>
					O cartão de crédito Atacadão é a melhor opção para quem
					deseja ter um crédito pessoal, parcelamento fácil exclusivo
					com descontos, além de ter saque rápido e a disponibilidade
					de PagContas, sem te deixar no aperto. Disponível em duas
					categorias, ele se encontra em: Visa Internacional e
					Mastercard Internacional. Tenha parcelamentos exclusivos e
					um cartão que aceita em qualquer franquia Atacadão,
					Carrefour.
				</Typography>
			</Container>
			<Container
				sx={{
					width: "100%",
					height: "auto",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					maxWidth: "810px",
				}}
			>
				<Button
					variant="contained"
					size="large"
					disableElevation
					sx={{
						fontWeight: "700",
						backgroundColor: "var(--infoCashBlue)",
					}}
					href={
						window.location.hostname !== "localhost"
							? `https://cartoes.informacash.com.br/atacadao-solicitar?${search}`
							: `./atacadao-solicitar`
					}
				>
					Quero saber mais
				</Button>
			</Container>
			<Container
				sx={{
					width: "100%",
					height: "100%",
					mt: "80px",
					mb: "40px",
					pl: "7%",
					pr: "4%",
				}}
			>
				<Typography
					variant="h5"
					align="center"
					sx={{
						lineHeight: "2rem",
						fontWeight: "700",
						mb: "10px",
					}}
				>
					Tipos de Cartões
				</Typography>
				<Divider variant="middle" sx={{ mb: "20px" }} />
				<Typography
					align="left"
					sx={{
						mt: "20px",
						fontSize: "1.2rem",
						fontWeight: "700",
					}}
				>
					Mastercard Internacional
				</Typography>
				<List
					sx={{
						listStyleType: "disc",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							1 cartão físico
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							Cartão de crédito Mastercard®
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							Renda mínima R $1.212,00
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							Anuidade de 12x por R$ 11,99
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							Seguro proteção de preço
						</Typography>
					</ListItem>
				</List>
				<Typography
					align="left"
					sx={{
						mt: "20px",
						fontSize: "1.2rem",
						fontWeight: "700",
					}}
				>
					Visa Internacional
				</Typography>
				<List
					sx={{
						listStyleType: "disc",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							1 cartão físico
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							Cartão de crédito Visa
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							Renda mínima a partir de R$ 1.050,00
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							Anuidade de 12x por R$ 11,99
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							Serviço de assistência em viagens
						</Typography>
					</ListItem>
				</List>
			</Container>
			<Container
				sx={{
					display: width < 720 ? "flex" : "none",
					alignItems: "center",
					justifyContent: "center",
					mt: "100px",
					mb: "100px",
				}}
			>
				<div id="cartoes.informacash_mid_mob"></div>
			</Container>
			<Container
				sx={{
					display: width > 720 ? "flex" : "none",
					alignItems: "center",
					justifyContent: "center",
					mt: "100px",
					mb: "100px",
				}}
			>
				<div id="cartoes.informacash_mid_desk"></div>
			</Container>
			<Container sx={{ width: "90%", mb: "40px", mt: "30px" }}>
				<Grid container spacing={2} alignItems="stretch">
					<Grid item xs={12} sm={6}>
						<div style={divGridStyle}>
							<Typography
								variant="p"
								align="center"
								sx={typographyGridStyle}
							>
								Descontos e parcelamentos exclusivos
							</Typography>
						</div>
					</Grid>
					<Grid item xs={12} sm={6}>
						<div style={divGridStyle}>
							<Typography
								variant="p"
								align="center"
								sx={typographyGridStyle}
							>
								Disponível para redes Atacadão e Carrefour
							</Typography>
						</div>
					</Grid>
					<Grid item xs={12} sm={6}>
						<div style={divGridStyle}>
							<Typography
								variant="p"
								align="center"
								sx={typographyGridStyle}
							>
								Parcelamento fácil
							</Typography>
						</div>
					</Grid>
					<Grid item xs={12} sm={6}>
						<div style={divGridStyle}>
							<Typography
								variant="p"
								align="center"
								sx={typographyGridStyle}
							>
								Saque rápido
							</Typography>
						</div>
					</Grid>
				</Grid>
			</Container>
			<Container
				sx={{
					width: "100%",
					height: "auto",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					maxWidth: "810px",
					mb: "20%",
				}}
			>
				<Button
					variant="contained"
					size="large"
					disableElevation
					sx={{
						fontWeight: "700",
						backgroundColor: "var(--infoCashBlue)",
					}}
					href={
						window.location.hostname !== "localhost"
							? `https://cartoes.informacash.com.br/atacadao-solicitar${search}`
							: `./atacadao-solicitar`
					}
				>
					Quero solicitar meu cartão
				</Button>
			</Container>
			<ResponsiveFooter />
		</Box>
	);
}
