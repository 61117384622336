import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";

export default function Post43() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const titleStyleObj = {
		fontSize: width > 720 ? "1.9em" : "1.6em",
		fontWeight: "700",
		mb: "calc(0.2em)",
		mt: "calc(13px + 1.2em)",
		lineHeight: "1.5",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "#111",
	};

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			{!loading && (
				<React.Fragment>
					<Container
						sx={{
							width: "100%",
							height: "30%",
							paddingTop: width < 720 ? "150px" : "200px",
							paddingBottom: width < 720 ? "50px" : "100px",
							paddingLeft: width < 720 ? "15px" : "auto",
							paddingRight: width < 720 ? "15px" : "auto",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							backgroundColor: "#161616",
							maxWidth: "none !important",
						}}
					>
						<Typography
							align="center"
							sx={{
								fontSize: width < 720 ? "1.5rem" : "2.5rem",
								lineHeight: width < 720 ? "2rem" : "3rem",
								textAlign: "center",
								maxWidth: "700px",
								fontWeight: "700",
								color: "#FFF",
								padding: "20px",
							}}
						>
							Medida Provisória estabelece regras para o trabalho
							remoto
						</Typography>
					</Container>
					<Box
						sx={{
							width: "100%",
							height: "100%",
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
							mt: "100px",
							mb: "200px",
							backgroundColor: "#FFF",
						}}
					>
						<Container
							sx={{
								maxWidth: "770px !important",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "flex-start",
								paddingLeft: width < 720 ? "22px" : "auto",
								paddingRight: width < 720 ? "22px" : "auto",
							}}
						>
							<Typography sx={textStyleObj}>
								<p>
									Nesta segunda-feira (28), no Diário Oficial
									da União, foi publicada a medida provisória
									1.108, que regulamenta as regras para o
									trabalho remoto, o home office ou
									teletrabalho. A medida provisória (MP) já
									havia sido anunciada pelo governo, na
									sexta-feira (25).
								</p>
								<br />
								<p>
									As medidas provisórias têm força de lei
									assim que são publicadas no Diário Oficial
									da União. Porém, para se tornarem leis
									definitivas, precisam ser aprovadas pelo
									Congresso Nacional.
								</p>
								<br />
								<p>
									Segundo o advogado Ricardo Calcini,
									especialista em relações trabalhistas e
									sindicais e professor de pós-graduação da
									Faculdades Metropolitanas Unidas, a
									Constituição Federal prevê que medidas
									provisórias façam alterações sobre a
									legislação trabalhista.
								</p>
								<br />
								<p>
									Segundo o governo, o objetivo das novas
									regras é ajustar a legislação às
									necessidades do trabalho remoto, que se
									tornou ainda mais comum na pandemia.
								</p>
								<br />
								<p>
									“Temos que aproveitar o momento para, ao
									longo da tramitação da MP, aprimorar seu
									texto, para que tenhamos, em definitivo, uma
									nova legislação sobre trabalho à distância”,
									disse Calcini.
								</p>
								<br />
								<p>
									Entre as mudanças propostas pela medida,
									está a possibilidade de adoção do modelo
									híbrido, alternando entre o home office e o
									trabalho presencial, e a contratação por
									controle de jornada ou de produção.
								</p>
							</Typography>

							<Typography sx={titleStyleObj}>
								Mudanças causadas pela medida provisória
							</Typography>
							<Typography
								sx={{
									ml: "25px",
									lineHeight: "2rem",
									pr: "10px",
								}}
							>
								<ul>
									<li>
										A prestação de serviços na modalidade
										remota deverá constar expressamente no
										contrato individual;{" "}
									</li>
									<li>
										Possibilidade de adoção do modelo
										híbrido pelas empresas, com prevalência
										do trabalho remoto sobre o presencial ou
										vice-versa;
									</li>
									<li>
										O trabalho remoto poderá ser contratado
										por jornada ou por produção;
									</li>
									<li>
										No contrato por produção, não será
										aplicado o capítulo da CLT que discorre
										sobre a duração do trabalho e que prevê
										o controle da jornada;
									</li>
									<li>
										Para atividades em que o controle de
										jornada não é necessário, o trabalhador
										pode exercer suas tarefas a hora em que
										desejar;
									</li>
									<li>
										Caso a contratação seja por jornada, a
										medida provisória permite o controle da
										jornada pela empresa, viabilizando o
										pagamento de horas extras caso
										ultrapassada a jornada regular;
									</li>
									<li>
										Trabalhadores com deficiência ou com
										filhos até quatro anos terão prioridade
										para as vagas em trabalho remoto;
									</li>
									<li>
										O home office também poderá ser aplicado
										a aprendizes e estagiários;
									</li>
									<li>
										A presença do trabalhador no ambiente de
										trabalho para realização de tarefas
										específicas, mesmo que de forma
										habitual, não descaracteriza o trabalho
										remoto.
									</li>
								</ul>
							</Typography>
							<Typography sx={titleStyleObj}>Salários</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Segundo Bruno Dalcomo, secretário-executivo
									do Ministério do Trabalho, a medida
									provisória assegura que não há possibilidade
									de redução salarial por acordo individual ou
									com o sindicato. “Não existe nenhuma
									diferença em termos de pagamento de salário
									para quem trabalha de forma presencial ou
									remota”, afirmou.
								</p>
								<br />
								<p>
									No caso de trabalho remoto controlado por
									jornada ou por produtividade, se mantém o
									que for acordado em negociação individual
									com a empresa, sem mudanças na remuneração
									em nenhum dos casos.
								</p>
								<br />
								<p>
									No caso de trabalho remoto controlado por
									jornada ou por produtividade, se mantém o
									que for acordado em negociação individual
									com a empresa, sem mudanças na remuneração
									em nenhum dos casos.
								</p>
								<br />
								<p>
									No caso de trabalho remoto por produção, não
									haverá pagamento de horas extras nem de
									adicional noturno, justamente por não estar
									sujeito a controle de jornada.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								Contribuições para a Previdência
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Segundo Dalcomo, não há alterações nas
									regras previdenciárias. Isso quer dizer que
									o trabalhador em teletrabalho está sujeito
									às mesmas regras do Instituto Nacional do
									Seguro Social (INSS), válidas para o
									trabalho presencial.
								</p>
								<br />
								<p>
									“Não existe diferença nenhuma em termos de
									proteção previdenciária para quem trabalha
									presencial ou remoto”, afirmou o secretário.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								Equipamentos
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Bruno Dalcomo afirmou que a medida
									provisória deixa claro como funcionará a
									questão das despesas dos funcionários no
									home office.
								</p>
								<br />
								<p>
									Existe a possibilidade de reembolso para os
									funcionários que trabalharem remotamente, e
									as empresas ficam autorizadas a pagar
									eventuais gastos dos trabalhadores com luz,
									internet e equipamentos, por exemplo. Esses
									reembolsos não poderão ser descontados dos
									salários.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								Flexibilidades
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									A medida provisória define as regras para o
									funcionário de trabalho remoto que passa a
									residir em localidade diferente da qual foi
									contratado.
								</p>
								<br />
								<p>
									De acordo com Dalcomo, vale a legislação da
									onde o trabalhador celebrou o contrato, mas
									ele pode se deslocar, até mesmo, para outro
									país. Isso pode constar em acordo
									individual. Assim, quem trabalha no Brasil
									para uma empresa de outro país, segue a
									legislação trabalhista brasileira.
								</p>
								<br />
								<p>
									Segundo o ministro do Trabalho e
									Previdência, Onyx Lorenzoni, o objetivo da
									medida é que os trabalhadores em trabalho
									híbrido e remoto possam se movimentar com
									maior liberdade possível, através de acordos
									individuais com o empregador.
								</p>
							</Typography>
						</Container>
					</Box>
					<ResponsiveFooter />
				</React.Fragment>
			)}
		</Box>
	);
}
