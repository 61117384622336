import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";

export default function Post21() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const titleStyleObj = {
		fontSize: width > 720 ? "1.9em" : "1.6em",
		fontWeight: "700",
		mb: "calc(0.2em)",
		mt: "calc(13px + 1.2em)",
		lineHeight: "1.5",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "#111",
	};

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			<Container
				sx={{
					width: "100%",
					height: "30%",
					paddingTop: width < 720 ? "150px" : "200px",
					paddingBottom: width < 720 ? "50px" : "100px",
					paddingLeft: width < 720 ? "15px" : "auto",
					paddingRight: width < 720 ? "15px" : "auto",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					backgroundColor: "#161616",
					maxWidth: "none !important",
				}}
			>
				<Typography
					align="center"
					sx={{
						fontSize: width < 720 ? "1.5rem" : "2.5rem",
						lineHeight: width < 720 ? "2rem" : "3rem",
						textAlign: "center",
						maxWidth: "700px",
						fontWeight: "700",
						color: "#FFF",
						padding: "20px",
					}}
				>
					Grandes bancos brasileiros tiveram lucro histórico na
					pandemia
				</Typography>
			</Container>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
					mt: "100px",
					mb: "200px",
					backgroundColor: "#FFF",
				}}
			>
				<Container
					sx={{
						maxWidth: "770px !important",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "flex-start",
						paddingLeft: width < 720 ? "22px" : "auto",
						paddingRight: width < 720 ? "22px" : "auto",
					}}
				>
					<Typography sx={textStyleObj}>
						O ano de 2021, com o contexto pandêmico, infelizmente
						ficou marcado para o Brasil. O país encerrou o ano com
						412.880 mortes por Covid-19, número esse que,
						atualmente, ultrapassa os 600 mil. No ano passado,
						porém, os grandes bancos brasileiros registraram o maior
						volume de lucros desde 2006.
					</Typography>
					<Typography sx={textStyleObj}>
						Somente as quatro maiores instituições financeiras com
						ações negociadas na Bolsa de Valores de São Paulo –
						Banco do Brasil, Bradesco, Itaú e Santander – lucraram
						juntas o equivalente a R$ 81,6 bilhões.
					</Typography>
					<Typography sx={textStyleObj}>
						O valor é o maior já registrado pela Economatica,
						empresa de dados que acompanha os resultados contábeis
						dessas instituições financeiras há 15 anos.
					</Typography>
					<Typography sx={textStyleObj}>
						Os dados obtidos apontam que o lucro somado desses
						quatro bancos aumento 32,5% de 2020 para 2021. Por outro
						lado, de acordo com o Instituto Brasileiro de Geografia
						e Estatística (IBGE), entre novembro de 2020 e novembro
						de 2021, a renda média do trabalhador caiu 11,4%.
					</Typography>
					<Typography sx={textStyleObj}>
						De acordo com a pesquisa feita pelo IBGE, nomeada
						Pesquisa Nacional por Amostra de Domicílios Contínua
						(Pnad Contínua), o trabalhador brasileiro recebia R$
						2.444 por mês, até novembro de 2021 – o menor valor já
						registrado pelo IBGE, que faz esse levantamento desde
						2012.
					</Typography>
					<Typography sx={titleStyleObj}>
						Lucros individuais dos bancos
					</Typography>
					<Typography sx={textStyleObj}>
						Dos quatro bancos listados na pesquisa da Economatica,
						três deles (Banco do Brasil, Bradesco e Itaú) tiveram
						lucros individuais recordes no período.
					</Typography>
					<Typography sx={textStyleObj}>
						O Itaú, maior banco do país, foi o que mais lucrou em
						2021: cerca de R$ 26,9 bilhões, 45% mais do que em 2020.
						Já o Bradesco lucrou R$ 26,2 bilhões no ano passado,
						34,7% a mais do que no em 2020. E o Banco do Brasil teve
						R$ 21 bilhões de lucro, 51,4% a mais que o ano anterior.
					</Typography>
					<Typography sx={textStyleObj}>
						“O bom desempenho é explicado por menores despesas com
						provisões de crédito (-40,2%), crescimento da carteira
						de crédito, incremento nas receitas de prestação de
						serviços e na margem financeira bruta”, declarou o
						representante do Banco do Brasil, em comunicado a
						respeito do lucro recorde de 2021.
					</Typography>
					<Typography sx={textStyleObj}>
						Simone Deos, professora do Instituto de Economia da
						Universidade Estadual de Campinas (Unicamp), explicou
						que as provisões de crédito são uma previsão de perda
						por inadimplência, que os bancos contabilizam em seus
						balanços.
					</Typography>
					<Typography sx={textStyleObj}>
						Segundo ela, com o início da pandemia em 2020, as
						instituições financeiras aumentaram essas provisões,
						considerando que o risco de inadimplência havia subido.
						Em 2021, elas entenderam que esse risco caiu. Assim, as
						previsões de perda foram reduzidas e o lucros dos bancos
						cresceu.
					</Typography>
					<Typography sx={titleStyleObj}>Papel dos juros</Typography>
					<Typography sx={textStyleObj}>
						Segundo Deos, somente essas previsões não explicam o
						lucro tão alto dos bancos brasileiros, que, segundo ela,
						foi tão fora dos padrões internacionais do sistema
						bancário mundial.
					</Typography>
					<Typography sx={textStyleObj}>
						A professora explicou que o aumento da taxa básica de
						juros, a Selic, também colaborou com o resultado dos
						bancos em 2021.
					</Typography>
					<Typography sx={textStyleObj}>
						Até março de 2021, a Selic era 2% ao ano. Em dezembro,
						ela já estava em 9,75% ao ano. Hoje, é 10,75% ao ano.
					</Typography>
					<Typography sx={textStyleObj}>
						Como essa taxa serve de base para os juros da economia,
						os bancos também aumentaram os juros de seus
						empréstimos. Eles passaram a ganhar mais com cada
						operação e, assim, lucrar mais.
					</Typography>
					<Typography sx={textStyleObj}>
						“Não é à toa que o ‘mercado’, representado pelos
						economistas de bancos, sempre indica que se aumente a
						taxa de juros para dar conta da inflação”, ressaltou a
						professora. “É uma advocacia em causa própria”, disse
						Simone.
					</Typography>
					<Typography sx={textStyleObj}>
						Ela complementou dizendo que, historicamente, os bancos
						brasileiros cobram juros bem mais altos do que a Selic.
						Por exemplo, enquanto a taxa Selic é de 10,75% ao ano,
						os juros de empréstimos chegam a dez vezes isso.
					</Typography>
					<Typography sx={textStyleObj}>
						Uma pesquisa realizada pelo Procon-SP indica que os
						juros médios de um empréstimo pessoal em bancos
						comerciais são de 115,2% ao ano. Enquanto os juros
						médios do rotativo do cartão de crédito chegam a 150,59%
						ao ano.
					</Typography>
					<Typography sx={titleStyleObj}>
						Consequências para a economia
					</Typography>
					<Typography sx={textStyleObj}>
						“Taxas de juros muito altas têm um efeito de depressão
						sobre a economia”, disse Simone, indicando que essas
						altas taxas enriquecem os bancos, mas prejudicam o
						desenvolvimento econômico do país como um todo.
					</Typography>
					<Typography sx={textStyleObj}>
						“As pessoas podem pensar que, se os bancos têm
						resultados extraordinários, isso é bom para a economia.
						Mas a verdade é que não”, acrescentou.
					</Typography>
				</Container>
			</Box>
			<ResponsiveFooter />
		</Box>
	);
}
