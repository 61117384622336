import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";

export default function Post44() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const titleStyleObj = {
		fontSize: width > 720 ? "1.9em" : "1.6em",
		fontWeight: "700",
		mb: "calc(0.2em)",
		mt: "calc(13px + 1.2em)",
		lineHeight: "1.5",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "#111",
	};

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			{!loading && (
				<React.Fragment>
					<Container
						sx={{
							width: "100%",
							height: "30%",
							paddingTop: width < 720 ? "150px" : "200px",
							paddingBottom: width < 720 ? "50px" : "100px",
							paddingLeft: width < 720 ? "15px" : "auto",
							paddingRight: width < 720 ? "15px" : "auto",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							backgroundColor: "#161616",
							maxWidth: "none !important",
						}}
					>
						<Typography
							align="center"
							sx={{
								fontSize: width < 720 ? "1.5rem" : "2.5rem",
								lineHeight: width < 720 ? "2rem" : "3rem",
								textAlign: "center",
								maxWidth: "700px",
								fontWeight: "700",
								color: "#FFF",
								padding: "20px",
							}}
						>
							Banco da Inglaterra planeja regulamentar
							criptomoedas
						</Typography>
					</Container>
					<Box
						sx={{
							width: "100%",
							height: "100%",
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
							mt: "100px",
							mb: "200px",
							backgroundColor: "#FFF",
						}}
					>
						<Container
							sx={{
								maxWidth: "770px !important",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "flex-start",
								paddingLeft: width < 720 ? "22px" : "auto",
								paddingRight: width < 720 ? "22px" : "auto",
							}}
						>
							<Typography sx={textStyleObj}>
								<p>
									Na última quinta-feira (24), o Banco da
									Inglaterra (BoE) começou a planejar a
									primeira estrutura regulatória do Reino
									Unido para criptoativos. O Banco afirmou
									que, embora o setor ainda seja pequeno, o
									crescimento acelerado dos criptoativos e
									finanças descentralizadas (DeFi), se não
									regulamentado, pode representar riscos para
									a estabilidade financeira no futuro.
								</p>
								<br />
								<p>
									As criptomoedas estão no foco das
									autoridades por causa da preocupação de que
									sejam usadas para contornar as sanções
									impostas à Rússia, desde o início da guerra
									contra a Ucrânia.
								</p>
								<br />
								<p>
									“Apesar de criptoativos provavelmente não
									serem capazes de fornecerem uma forma viável
									de contornar as sanções em escala
									atualmente, a possibilidade de existência de
									tal comportamento ressalta a importância em
									assegurar que a inovação em criptomoedas
									seja acompanhada por políticas públicas
									efetivas”, disse, em comunicado desta
									quinta, o Comitê de Política Financeira do
									Banco da Inglaterra.
								</p>
							</Typography>

							<Typography sx={titleStyleObj}>
								Como será a regulamentação
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Segundo o Comitê, a regulamentação do setor
									deve ocorrer com base na “equivalência”.
									Isso quer dizer que qualquer serviço
									financeiro relacionado com as criptomoedas
									que desempenha uma função semelhante aos
									serviços financeiros já existentes devem
									estar sujeitos às mesmas leis que esses.
								</p>
								<br />
								<p>
									Até que os criptoativos sejam totalmente
									regulados, o Banco da Inglaterra está focado
									em garantir que os riscos ao setor bancário
									sejam controlados. Sam Woods,
									vice-presidente do Banco, conversou com as
									instituições financeiras na última quinta,
									pontuando o interesse de bancos e empresas
									em investir no setor.
								</p>
								<br />
								<p>
									Segundo Woods, os riscos das criptomoedas
									devem ser considerados totalmente pelos
									bancos, que precisarão adaptar as
									estratégias internas e sistemas atuais de
									gerenciamento de riscos. “Também esperamos
									que as empresas discutam com prudência o
									tratamento proposto das exposições de
									criptoativos com seus supervisores”.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>Salários</Typography>
							<Typography sx={textStyleObj}>
								<p>
									O Comitê também afirmou que uma grande
									stablecoin – ou seja, uma criptomoeda de
									baixa volatilidade que é lastreada por uma
									moeda fiduciária (euro, dólar, etc.) ou
									outro ativo real (ouro, petróleo, etc.),
									garantindo pouca variação de seu preço –
									ainda pode “atender às suas expectativas”,
									desde que ocorra a regulamentação e
									estruturação para reduzir os riscos.
								</p>
								<br />
								<p>
									Ainda segundo o Comitê, o Banco da
									Inglaterra e a Autoridade de Conduta
									Financeira irão realizar mais estudos
									delimitando regras para as stablecoins, e
									vão estruturar um “modelo” regulatório para
									elas em 2023.
								</p>
								<br />
								<p>
									O Comitê aconselhou as instituições
									financeiras a “tomar uma abordagem
									especialmente cautelosa e prudente para
									qualquer adoção” de ativos criptográficos ou
									DeFi até que a estrutura regulatória seja
									mais robusta. E também lembrou as empresas
									de suas “obrigações existentes quando estão
									interagindo ou expostas a criptoativos e
									serviços relacionados”, as quais foram
									citadas no comunicado.
								</p>
								<br />
								<p>
									Esses não foram todos os documentos
									relacionados a criptoativos divulgados pelo
									Banco da Inglaterra. “Respostas ao Documento
									de Discussão do Banco da Inglaterra sobre
									novas formas de dinheiro digital” também
									apareceram e se referem à uma discussão, do
									ano passado, sobre CBDCs – moedas digitais
									emitidas por governos e Bancos Centrais.
								</p>
							</Typography>
						</Container>
					</Box>
					<ResponsiveFooter />
				</React.Fragment>
			)}
		</Box>
	);
}
