import {
	Box,
	Typography,
	Container,
	Grid,
	Divider,
	Card,
	CardMedia,
	CardContent,
} from "@mui/material";
import React from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { useNavigate } from "react-router-dom";

const blogPostsArr = [
	{
		PostNum: 1,
		PostTitle:
			"EUA eleva a sua taxa de juros: Entenda o impacto da economia mundial",
		PostURL: "/news/economia-news/economia",
	},
	{
		PostNum: 2,
		PostTitle: "Frete marítimo brasileiro registra alta de preços",
		PostURL:
			"/news/mundo/frete-maritimo-brasileiro-registra-alta-de-precos",
	},
	{
		PostNum: 3,
		PostTitle:
			"Aposta para 2022: Apps para saúde mental permanecem em alta",
		PostURL: "/news/tecnologia/aposte-em-apps-de-saude-mental",
	},
	{
		PostNum: 4,
		PostTitle: " C6 Bank sofre golpe com prejuízo milionário",
		PostURL: "/economia/poder-de-compra-do-real-caiu-30-em-5-anos",
	},
	{
		PostNum: 5,

		PostTitle: "Poder de compra do Real caiu 30% em 5 anos",
		PostURL: "/news/c6-bank-sofre-golpe-com-prejuizo-milionario",
	},
	{
		PostNum: 6,
		PostTitle: "Pensa em investir nos carros populares? Entenda mais aqui",
		PostURL: "/news/economia-news/investimento-em-carros-populares",
	},
	{
		PostNum: 7,
		PostTitle: "Fim de abril: Entenda as altas e baixas das criptomoedas",
		PostURL: "/criptomoedas/entenda-as-altas-e-baixas-das-criptos",
	},
	{
		PostNum: 8,
		PostTitle: "Na China, Apple perde 1º lugar na venda de celulares",
		PostURL: "/news/na-china-apple-perde-1o-lugar-na-venda-de-celulares",
	},
	{
		PostNum: 9,
		PostTitle: "Telegram permite transferência de criptomoedas via app",
		PostURL: "/criptomoedas/telegram-permite-transferencia-de-criptomoedas",
	},
	{
		PostNum: 10,
		PostTitle:
			"Comeback: Orkut está de volta para a internet, afirma criador da plataforma",
		PostURL: "/news/tecnologia/orkut-retorna-a-internet",
	},

	{
		PostNum: 11,
		PostTitle: "Dólar valoriza 7,5% em uma semana",
		PostURL: "/news/dolar-valoriza-75-em-uma-semana",
	},

	{
		PostNum: 12,
		PostTitle: "Entenda Renda Fixa de uma vez por todas",
		PostURL: "/investimento/entenda-renda-fixa-de-uma-vez-por-todas",
	},

	{
		PostNum: 13,
		PostTitle: "Boletim Focus: Inflação a 7,65% e Selic a 13,25%",
		PostURL: "/news/boletim-focus-inflacao-a-765-e-selic-a-1325",
	},

	{
		PostNum: 14,
		PostTitle:
			"INSS: Pagamento do 13º salário dos aposentados do começou nesta segunda (25)",
		PostURL: "/news/economia-news/pagamento-do-inss-se-inicia",
	},

	{
		PostNum: 15,
		PostTitle: "Imposto de renda: Entenda como declarar as criptomoedas",
		PostURL: "/criptomoedas/entenda-como-declarar-criptomoedas",
	},

	{
		PostNum: 16,
		PostTitle: "Biden diz que 70% da inflação se dá pela guerra na Ucrânia",
		PostURL:
			"/news/biden-diz-que-70-da-inflacao-se-da-pela-guerra-na-ucrania",
	},

	{
		PostNum: 17,
		PostTitle: "DeFi: Como ter renda passiva com criptomoedas?",
		PostURL: "/criptomoedas/defi-como-ter-renda-passiva-com-criptomoedas",
	},

	{
		PostNum: 18,
		PostTitle: "O que é CRI e CRA? Entenda qual o melhor investimento",
		PostURL: "/investimento/o-que-e-cri-e-cra-investimentos/",
	},

	{
		PostNum: 19,
		PostTitle: "Wine: Agora é possível pagar vinhos com criptomoedas",
		PostURL: "/criptomoedas/pagamento-de-vinhos-via-criptomoedas",
	},

	{
		PostNum: 20,
		PostTitle: "Brasil se destaca entre os bancos mais rentáveis do mundo",
		PostURL: "/news/economia-news/brasil-4-bancos-rentaveis",
	},

	{
		PostNum: 21,
		PostTitle:
			"Grandes bancos brasileiros tiveram lucro histórico na pandemia",
		PostURL:
			"/news/economia-news/grandes-bancos-brasileiros-tiveram-lucro-historico-na-pandemia",
	},

	{
		PostNum: 22,
		PostTitle:
			"Redução na conta de energia cai em média para R$21, segundo a FGV",
		PostURL: "/news/reducao-de-energia-cai-para-r21",
	},

	{
		PostNum: 23,
		PostTitle: "LCI e LCA: entenda mais sobre os investimentos",
		PostURL: "/investimento/lci-e-lca-entenda-mais-sobre-os-investimentos",
	},

	{
		PostNum: 24,
		PostTitle:
			"Tesouro Direto: Entenda como funciona e saiba como iniciar os seus investimentos",
		PostURL: "/investimento/saiba-como-investir-no-tesouro-direto/",
	},

	{
		PostNum: 25,
		PostTitle: "Elon Musk faz oferta para comprar 100% do Twitter",
		PostURL: "/news/elon-musk-faz-oferta-para-comprar-100-do-twitter",
	},

	{
		PostNum: 26,
		PostTitle: "Inadimplência ultrapassou a marca de 65 milhões de pessoas",
		PostURL:
			"/news/inadimplencia-ultrapassou-a-marca-de-65-milhoes-de-pessoas/",
	},

	{
		PostNum: 27,
		PostTitle:
			"Bancos norte-americanos ganham rentabilidade com dívida russa",
		PostURL: "/news/economia-news/dividas-russas-para-bancos-eua/",
	},

	{
		PostNum: 28,
		PostTitle:
			"Tecnologia: Mineração de Bitcoin agora alimentada por energia solar",
		PostURL: "/criptomoedas/mineracao-de-bitcoin-energia-solar/",
	},

	{
		PostNum: 29,
		PostTitle: "CDB: veja o que é, quais os tipos e como investir",
		PostURL:
			"/investimento/cdb-veja-o-que-e-quais-os-tipos-e-como-investir/",
	},

	{
		PostNum: 30,
		PostTitle: "Altas na pandemia: mercado subiu 31% e conta de luz, 33%",
		PostURL: "/news/altas-na-pandemia-mercado-subiu-31-e-conta-de-luz-33/",
	},

	{
		PostNum: 31,
		PostTitle:
			"Fim de tarifa extra: Conta de energia cairá 18% em maio, diz ministro da economia",
		PostURL: "/news/economia-news/conta-de-energia-diminui-em-maio/",
	},

	{
		PostNum: 32,
		PostTitle: "Amazon vai lançar satélites para fornecer internet",
		PostURL: "/news/amazon-vai-lancar-satelites-para-fornecer-internet/",
	},

	{
		PostNum: 33,
		PostTitle:
			"Chocolate em alta: Entenda o aumento dos microempreendedores neste ramo alimentício",
		PostURL:
			"/news/economia-news/chocolate-em-alta-entenda-o-aumento-dos-microempreendedores-neste-ramo-alimenticio/",
	},

	{
		PostNum: 34,
		PostTitle: "Setor de energia deve migrar para fontes renováveis",
		PostURL: "/news/setor-de-energia-deve-migrar-para-fontes-renovaveis/",
	},

	{
		PostNum: 35,
		PostTitle:
			"Expansão do e-commerce: Pix parcelado cresce nas instituições financeiras",
		PostURL:
			"/news/economia-news/expansao-do-e-commerce-pix-parcelado-cresce-nas-instituicoes-financeiras/",
	},

	{
		PostNum: 36,
		PostTitle: "Poluição via satélites pode alterar a visão do céu noturno",
		PostURL:
			"/news/tecnologia/poluicao-via-satelites-pode-alterar-a-visao-do-ceu-noturno/",
	},

	{
		PostNum: 37,
		PostTitle: "Receita prorroga prazo de entrega do Imposto de Renda",
		PostURL: "/news/receita-prorroga-prazo-de-entrega-do-imposto-de-renda/",
	},

	{
		PostNum: 38,
		PostTitle: "Real e Ibovespa têm segunda maior valorização do mundo",
		PostURL:
			"/news/real-e-ibovespa-tem-segunda-maior-valorizacao-do-mundo/",
	},

	{
		PostNum: 39,
		PostTitle: "Servidores do Banco Central dão início a greve nesta sexta",
		PostURL:
			"/news/servidores-do-banco-central-dao-inicio-a-greve-nesta-sexta/",
	},

	{
		PostNum: 40,
		PostTitle: "Metaverso: quais profissões irão lidar com a tecnologia?",
		PostURL:
			"/news/metaverso-quais-profissoes-irao-lidar-com-a-tecnologia/",
	},

	{
		PostNum: 41,
		PostTitle:
			"Veículos elétricos crescem em até 80% durante o ano de 2022",
		PostURL: "/news/tecnologia/veiculos-eletricos-crescem-80/",
	},

	{
		PostNum: 42,
		PostTitle:
			"Inflação: Preço dos carros possuem aumento de 17,03%, segundo o IPC-10",
		PostURL: "/economia/inflacao-preco-dos-carros-1703/",
	},

	{
		PostNum: 43,
		PostTitle: "Medida Provisória estabelece regras para o trabalho remoto",
		PostURL:
			"/news/medida-provisoria-estabelece-regras-para-o-trabalho-remoto/",
	},

	{
		PostNum: 44,
		PostTitle: "Banco da Inglaterra planeja regulamentar criptomoedas",
		PostURL: "/news/banco-da-inglaterra-planeja-regulamentar-criptomoedas/",
	},

	{
		PostNum: 45,
		PostTitle: "Entenda onde investir com o aumento da taxa Selic",
		PostURL:
			"/news/economia-news/entenda-onde-investir-com-o-aumento-da-taxa-selic/",
	},

	{
		PostNum: 46,
		PostTitle: "Clear Corretora vs Toro Investimento: qual a melhor",
		PostURL:
			"/investimento/clear-corretora-vs-toro-investimento-qual-a-melhor/",
	},

	{
		PostNum: 47,
		PostTitle: "Juros no Brasil; Ibovespa com alta de 1,2%; dólar a R$4,84",
		PostURL: "/news/juros-no-brasil-ibovespa-com-alta-de-12-dolar-a-r484/",
	},

	{
		PostNum: 48,
		PostTitle: "B3 tem lucro de R$ 4,7 bi em 2021",
		PostURL: "/news/b3-tem-lucro-em-2021/",
	},

	{
		PostNum: 49,
		PostTitle: "Telegram: STF revoga ordem de bloqueio do aplicativo",
		PostURL: "/news/telegram-stf-revoga-ordem-de-bloqueio-do-aplicativo/",
	},

	{
		PostNum: 50,
		PostTitle: "Previsões do Banco Central para 2022 têm alta da inflação",
		PostURL:
			"/news/previsoes-do-banco-central-para-2022-tem-alta-da-inflacao/",
	},
];

export default function BlogHome() {
	const { width } = useWindowDimensions();
	const navigate = useNavigate();
	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />

			<Container
				sx={{
					width: "100%",
					height: "100%",
					mt: width < 720 ? "150px" : "150px",
					mb: "200px",
					display: "flex",
					flexDirection: "column",
					justifyContent: "flex-start",
					alignItems: "center",
				}}
			>
				<Box
					sx={{
						width: "100%",
						height: "auto",
						mb: "40px",
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
						maxWidth: "1080px",
					}}
				>
					<Typography
						align="left"
						sx={{
							fontWeight: "600",
							fontSize: "2.1rem",
							color: "var(--textGray)",
						}}
					>
						Posts
					</Typography>
					<Divider
						variant="middle"
						sx={{ width: "100%", mt: "10px" }}
					/>
				</Box>

				<Grid
					container
					spacing={3}
					sx={{
						width: "100%",
						height: "auto",
						maxWidth: "1080px",
						justifyContent: "center",
					}}
					alignItems="stretch"
				>
					{blogPostsArr.map((post) => {
						return (
							<Grid
								item
								xs={12}
								sm={3}
								sx={{
									maxWidth: "85%",
									mb: "20px",
									display: "flex",
								}}
								key={post.PostNum}
							>
								<Card
									elevation={0}
									variant="outlined"
									sx={{
										borderRadius: "11px",
										cursor: "pointer",
									}}
									onClick={() => navigate(`${post.PostURL}`)}
								>
									<CardMedia component="img" height="auto" />
									<CardContent>
										<Typography
											variant="h6"
											align="left"
											sx={{
												fontWeight: "400",
												color: "var(--textGray)",
											}}
										>
											{post.PostTitle}
										</Typography>
									</CardContent>
								</Card>
							</Grid>
						);
					})}
				</Grid>
			</Container>
			<ResponsiveFooter />
		</Box>
	);
}
