import React, { useLayoutEffect } from "react";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	useLocation,
	Navigate,
} from "react-router-dom";
import Main from "../Pages/Main";
import SecondPage from "../Pages/SecondPage";
import Home from "../Pages/Home";
import ItauUniclass from "../Pages/ItauUniclass";
import ItauUniclassExt from "../Pages/ItauUniclassExt";
import PaoDeAcucar from "../Pages/PaoAcucar";
import PaoDeAcucarExt from "../Pages/PaoAcucarExt";
import PortoSeguro from "../Pages/PortoSeguro";
import PortoSeguroExt from "../Pages/PortoSeguroExt";
import Next from "../Pages/Next";
import NextExt from "../Pages/NextExt";
import Picpay from "../Pages/Picpay";
import PicpayExt from "../Pages/PicpayExt";
import Atacadao from "../Pages/Atacadao";
import AtacadaoExt from "../Pages/AtacadaoExt";
import Latam from "../Pages/Latam";
import LatamExt from "../Pages/LatamExt";
import ClickItau from "../Pages/ClickItau";
import ClickItauExt from "../Pages/ClickItauExt";
import WillBank from "../Pages/WillBank";
import WillBankExt from "../Pages/WillBankExt";
import BancoPan from "../Pages/BancoPan";
import BancoPanExt from "../Pages/BancoPanExt";
import ItiItau from "../Pages/ItiItau";
import ItiItauExt from "../Pages/ItiItauExt";
import SantanderSX from "../Pages/SantanderSX";
import SantanderSXExt from "../Pages/SantanderSXExt";
import C6Bank from "../Pages/C6Bank";
import C6BankExt from "../Pages/C6BankExt";
import Buscape from "../Pages/Buscape";
import BuscapeExt from "../Pages/BuscapeExt";
import Meliuz from "../Pages/Meliuz";
import MeliuzExt from "../Pages/MeliuzExt";
import Post1 from "../components/Blog/Post1";
import Post2 from "../components/Blog/Post2";
import Post3 from "../components/Blog/Post3";
import Post4 from "../components/Blog/Post4";
import Post5 from "../components/Blog/Post5";
import Post6 from "../components/Blog/Post6";
import Post7 from "../components/Blog/Post7";
import Post8 from "../components/Blog/Post8";
import Post9 from "../components/Blog/Post9";
import Post10 from "../components/Blog/Post10";
import Post11 from "../components/Blog/Post11";
import Post12 from "../components/Blog/Post12";
import Post13 from "../components/Blog/Post13";
import Post14 from "../components/Blog/Post14";
import Post15 from "../components/Blog/Post15";
import Post16 from "../components/Blog/Post16";
import Post17 from "../components/Blog/Post17";
import Post18 from "../components/Blog/Post18";
import Post19 from "../components/Blog/Post19";
import Post20 from "../components/Blog/Post20";
import Post21 from "../components/Blog/Post21";
import Post22 from "../components/Blog/Post22";
import Post23 from "../components/Blog/Post23";
import Post24 from "../components/Blog/Post24";
import Post25 from "../components/Blog/Post25";
import Post26 from "../components/Blog/Post26";
import Post27 from "../components/Blog/Post27";
import Post28 from "../components/Blog/Post28";
import Post29 from "../components/Blog/Post29";
import Post30 from "../components/Blog/Post30";
import Post31 from "../components/Blog/Post31";
import Post32 from "../components/Blog/Post32";
import Post33 from "../components/Blog/Post33";
import Post34 from "../components/Blog/Post34";
import Post35 from "../components/Blog/Post35";
import Post36 from "../components/Blog/Post36";
import Post37 from "../components/Blog/Post37";
import Post38 from "../components/Blog/Post38";
import Post39 from "../components/Blog/Post39";
import Post40 from "../components/Blog/Post40";
import Post41 from "../components/Blog/Post41";
import Post42 from "../components/Blog/Post42";
import Post43 from "../components/Blog/Post43";
import Post44 from "../components/Blog/Post44";
import Post45 from "../components/Blog/Post45";
import Post46 from "../components/Blog/Post46";
import Post47 from "../components/Blog/Post47";
import Post48 from "../components/Blog/Post48";
import Post49 from "../components/Blog/Post49";
import Post50 from "../components/Blog/Post50";
import BlogHome from "../components/Blog/HomeBlog";

const Wrapper = ({ children }) => {
	const location = useLocation();
	useLayoutEffect(() => {
		document.documentElement.scrollTo(0, 0);
	}, [location.pathname]);
	return children;
};

export default function PublicRoutes() {
	return (
		<Router>
			<Wrapper>
				<Routes>
					<Route
						exact
						path="/"
						element={<Navigate to="/cartoes-informacash" />}
					/>
					<Route
						exact
						path="/cartoes-informacash"
						element={<Home />}
					/>
					<Route exact path="/super-digital" element={<Main />} />
					<Route
						exact
						path="/super-digital-solicitar"
						element={<SecondPage />}
					/>
					<Route
						exact
						path="/itau-uniclass"
						element={<ItauUniclass />}
					/>
					<Route
						exact
						path="/itau-uniclass-solicitar"
						element={<ItauUniclassExt />}
					/>
					<Route
						exact
						path="/itau-pao-de-acucar"
						element={<PaoDeAcucar />}
					/>
					<Route
						exact
						path="/itau-pao-de-acucar-solicitar"
						element={<PaoDeAcucarExt />}
					/>
					<Route
						exact
						path="/porto-seguro"
						element={<PortoSeguro />}
					/>
					<Route
						exact
						path="/porto-seguro-solicitar"
						element={<PortoSeguroExt />}
					/>
					<Route exact path="/next" element={<Next />} />
					<Route exact path="/next-solicitar" element={<NextExt />} />
					<Route exact path="/picpay" element={<Picpay />} />
					<Route exact path="/picpay" element={<Picpay />} />
					<Route
						exact
						path="/picpay-solicitar"
						element={<PicpayExt />}
					/>
					<Route exact path="/atacadao" element={<Atacadao />} />
					<Route
						exact
						path="/atacadao-solicitar"
						element={<AtacadaoExt />}
					/>
					<Route exact path="/latam" element={<Latam />} />
					<Route
						exact
						path="/latam-solicitar"
						element={<LatamExt />}
					/>
					<Route exact path="/clickitau" element={<ClickItau />} />
					<Route
						exact
						path="/clickitau-solicitar"
						element={<ClickItauExt />}
					/>
					<Route exact path="/willbank" element={<WillBank />} />
					<Route
						exact
						path="/willbank-solicitar"
						element={<WillBankExt />}
					/>
					<Route exact path="/bancopan" element={<BancoPan />} />
					<Route
						exact
						path="/bancopan-solicitar"
						element={<BancoPanExt />}
					/>
					<Route exact path="/itiitau" element={<ItiItau />} />
					<Route
						exact
						path="/itiitau-solicitar"
						element={<ItiItauExt />}
					/>
					<Route
						exact
						path="/santandersx"
						element={<SantanderSX />}
					/>
					<Route
						exact
						path="/santandersx-solicitar"
						element={<SantanderSXExt />}
					/>
					<Route exact path="/c6bank" element={<C6Bank />} />
					<Route
						exact
						path="/c6bank-solicitar"
						element={<C6BankExt />}
					/>
					<Route exact path="/buscape" element={<Buscape />} />
					<Route
						exact
						path="/buscape-solicitar"
						element={<BuscapeExt />}
					/>
					<Route exact path="/meliuz" element={<Meliuz />} />
					<Route
						exact
						path="/meliuz-solicitar"
						element={<MeliuzExt />}
					/>

					<Route
						exact
						path="/news/economia-news/economia"
						element={<Post1 />}
					/>
					<Route
						exact
						path="/news/mundo/frete-maritimo-brasileiro-registra-alta-de-precos"
						element={<Post2 />}
					/>
					<Route
						exact
						path="/news/tecnologia/aposte-em-apps-de-saude-mental"
						element={<Post3 />}
					/>
					<Route
						exact
						path="/economia/poder-de-compra-do-real-caiu-30-em-5-anos"
						element={<Post4 />}
					/>
					<Route
						exact
						path="/news/c6-bank-sofre-golpe-com-prejuizo-milionario"
						element={<Post5 />}
					/>
					<Route
						exact
						path="/news/economia-news/investimento-em-carros-populares"
						element={<Post6 />}
					/>
					<Route
						exact
						path="/criptomoedas/entenda-as-altas-e-baixas-das-criptos"
						element={<Post7 />}
					/>
					<Route
						exact
						path="/news/na-china-apple-perde-1o-lugar-na-venda-de-celulares"
						element={<Post8 />}
					/>
					<Route
						exact
						path="/criptomoedas/telegram-permite-transferencia-de-criptomoedas"
						element={<Post9 />}
					/>
					<Route
						exact
						path="/news/tecnologia/orkut-retorna-a-internet"
						element={<Post10 />}
					/>
					<Route
						exact
						path="/news/dolar-valoriza-75-em-uma-semana"
						element={<Post11 />}
					/>
					<Route
						exact
						path="/investimento/entenda-renda-fixa-de-uma-vez-por-todas"
						element={<Post12 />}
					/>
					<Route
						exact
						path="/news/boletim-focus-inflacao-a-765-e-selic-a-1325"
						element={<Post13 />}
					/>
					<Route
						exact
						path="/news/economia-news/pagamento-do-inss-se-inicia"
						element={<Post14 />}
					/>
					<Route
						exact
						path="/criptomoedas/entenda-como-declarar-criptomoedas"
						element={<Post15 />}
					/>
					<Route
						exact
						path="/news/biden-diz-que-70-da-inflacao-se-da-pela-guerra-na-ucrania"
						element={<Post16 />}
					/>
					<Route
						exact
						path="/criptomoedas/defi-como-ter-renda-passiva-com-criptomoedas"
						element={<Post17 />}
					/>
					<Route
						exact
						path="/investimento/o-que-e-cri-e-cra-investimentos/"
						element={<Post18 />}
					/>
					<Route
						exact
						path="/criptomoedas/pagamento-de-vinhos-via-criptomoedas"
						element={<Post19 />}
					/>
					<Route
						exact
						path="/news/economia-news/brasil-4-bancos-rentaveis"
						element={<Post20 />}
					/>
					<Route
						exact
						path="/news/economia-news/grandes-bancos-brasileiros-tiveram-lucro-historico-na-pandemia"
						element={<Post21 />}
					/>
					<Route
						exact
						path="/news/reducao-de-energia-cai-para-r21"
						element={<Post22 />}
					/>
					<Route
						exact
						path="/investimento/lci-e-lca-entenda-mais-sobre-os-investimentos"
						element={<Post23 />}
					/>
					<Route
						exact
						path="/investimento/saiba-como-investir-no-tesouro-direto/"
						element={<Post24 />}
					/>
					<Route
						exact
						path="/news/elon-musk-faz-oferta-para-comprar-100-do-twitter"
						element={<Post25 />}
					/>
					<Route
						exact
						path="/news/inadimplencia-ultrapassou-a-marca-de-65-milhoes-de-pessoas/"
						element={<Post26 />}
					/>
					<Route
						exact
						path="/news/economia-news/dividas-russas-para-bancos-eua/"
						element={<Post27 />}
					/>
					<Route
						exact
						path="/criptomoedas/mineracao-de-bitcoin-energia-solar/"
						element={<Post28 />}
					/>
					<Route
						exact
						path="/investimento/cdb-veja-o-que-e-quais-os-tipos-e-como-investir/"
						element={<Post29 />}
					/>
					<Route
						exact
						path="/news/altas-na-pandemia-mercado-subiu-31-e-conta-de-luz-33/"
						element={<Post30 />}
					/>
					<Route
						exact
						path="/news/economia-news/conta-de-energia-diminui-em-maio/"
						element={<Post31 />}
					/>
					<Route
						exact
						path="/news/amazon-vai-lancar-satelites-para-fornecer-internet/"
						element={<Post32 />}
					/>
					<Route
						exact
						path="/news/economia-news/chocolate-em-alta-entenda-o-aumento-dos-microempreendedores-neste-ramo-alimenticio/"
						element={<Post33 />}
					/>
					<Route
						exact
						path="/news/setor-de-energia-deve-migrar-para-fontes-renovaveis/"
						element={<Post34 />}
					/>
					<Route
						exact
						path="/news/economia-news/expansao-do-e-commerce-pix-parcelado-cresce-nas-instituicoes-financeiras/"
						element={<Post35 />}
					/>
					<Route
						exact
						path="/news/tecnologia/poluicao-via-satelites-pode-alterar-a-visao-do-ceu-noturno/"
						element={<Post36 />}
					/>
					<Route
						exact
						path="/news/receita-prorroga-prazo-de-entrega-do-imposto-de-renda/"
						element={<Post37 />}
					/>
					<Route
						exact
						path="/news/real-e-ibovespa-tem-segunda-maior-valorizacao-do-mundo/"
						element={<Post38 />}
					/>
					<Route
						exact
						path="/news/servidores-do-banco-central-dao-inicio-a-greve-nesta-sexta/"
						element={<Post39 />}
					/>
					<Route
						exact
						path="/news/metaverso-quais-profissoes-irao-lidar-com-a-tecnologia/"
						element={<Post40 />}
					/>
					<Route
						exact
						path="/news/tecnologia/veiculos-eletricos-crescem-80/"
						element={<Post41 />}
					/>
					<Route
						exact
						path="/economia/inflacao-preco-dos-carros-1703/"
						element={<Post42 />}
					/>
					<Route
						exact
						path="/news/medida-provisoria-estabelece-regras-para-o-trabalho-remoto/"
						element={<Post43 />}
					/>
					<Route
						exact
						path="/news/banco-da-inglaterra-planeja-regulamentar-criptomoedas/"
						element={<Post44 />}
					/>
					<Route
						exact
						path="/news/economia-news/entenda-onde-investir-com-o-aumento-da-taxa-selic/"
						element={<Post45 />}
					/>
					<Route
						exact
						path="/investimento/clear-corretora-vs-toro-investimento-qual-a-melhor/"
						element={<Post46 />}
					/>
					<Route
						exact
						path="/news/juros-no-brasil-ibovespa-com-alta-de-12-dolar-a-r484/"
						element={<Post47 />}
					/>
					<Route
						exact
						path="/news/b3-tem-lucro-em-2021/"
						element={<Post48 />}
					/>
					<Route
						exact
						path="/news/telegram-stf-revoga-ordem-de-bloqueio-do-aplicativo/"
						element={<Post49 />}
					/>
					<Route
						exact
						path="/news/previsoes-do-banco-central-para-2022-tem-alta-da-inflacao/"
						element={<Post50 />}
					/>
					<Route exact path="/home-blog" element={<BlogHome />} />
				</Routes>
			</Wrapper>
		</Router>
	);
}
