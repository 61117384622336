import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

export default function Post12() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const titleStyleObj = {
		fontSize: width > 720 ? "1.9em" : "1.6em",
		fontWeight: "700",
		mb: "calc(0.2em)",
		mt: "calc(13px + 1.2em)",
		lineHeight: "1.5",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "#111",
	};

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	const tableCell = {
		fontSize: width > 720 ? "1.3em" : "1em",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			<Container
				sx={{
					width: "100%",
					height: "30%",
					paddingTop: width < 720 ? "150px" : "200px",
					paddingBottom: width < 720 ? "50px" : "100px",
					paddingLeft: width < 720 ? "15px" : "auto",
					paddingRight: width < 720 ? "15px" : "auto",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					backgroundColor: "#161616",
					maxWidth: "none !important",
				}}
			>
				<Typography
					align="center"
					sx={{
						fontSize: width < 720 ? "1.5rem" : "2.5rem",
						lineHeight: width < 720 ? "2rem" : "3rem",
						textAlign: "center",
						maxWidth: "700px",
						fontWeight: "700",
						color: "#FFF",
						padding: "20px",
					}}
				>
					Entenda Renda Fixa de uma vez por todas
				</Typography>
			</Container>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
					mt: "100px",
					mb: "200px",
					backgroundColor: "#FFF",
				}}
			>
				<Container
					sx={{
						maxWidth: "770px !important",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "flex-start",
						paddingLeft: width < 720 ? "22px" : "auto",
						paddingRight: width < 720 ? "22px" : "auto",
					}}
				>
					<Typography sx={textStyleObj}>
						Poupança, Tesouro Direto e CDB são os tipos de
						investimento em Renda Fixa mais conhecidos e
						frequentemente são recomendados para quem está começando
						no mundo do investimento. Mas, o que é Renda Fixa? E,
						afinal, por que ela é tão popular?
					</Typography>
					<Typography sx={textStyleObj}>
						A Renda Fixa tem um papel importante no mundo do mercado
						financeiro, podendo ser considerada, principalmente por
						investidores iniciantes, como o primeiro lugar para
						construir uma reserva de emergência.
					</Typography>
					<Typography sx={textStyleObj}>
						Mas, além disso, a Renda Fixa é interessante também para
						quem está começando a se aventurar no mundo dos
						investimentos por conta da segurança que ela garante.
					</Typography>
					<Typography sx={titleStyleObj}>O que é?</Typography>
					<Typography sx={textStyleObj}>
						Basicamente, a Renda Fixa é uma modalidade na qual o
						investidor já tem, no momento da aplicação, a{" "}
						<b>
							garantia de retorno sobre o valor aplicado no
							momento do vencimento.
						</b>{" "}
						Isso quer dizer que ela é um tipo de aplicação onde
						existe uma certeza de retorno.
					</Typography>
					<Typography sx={textStyleObj}>
						Ela é ideal para os investidores que procuram segurança
						e rendimento mais estáveis, sendo assim, recomenda-se
						que ela seja o primeiro tipo de investimento que você
						deve fazer caso não tenha uma reserva de emergência.
					</Typography>
					<Typography sx={textStyleObj}>
						Resumindo, quando você é um investidor de Renda Fixa,
						você “empresta dinheiro” para as instituições
						financeiras que emitem o título. Em troca, recebe uma
						taxa de rentabilidade do investimento, e esse valor é
						acrescido de juros.
					</Typography>
					<Typography sx={titleStyleObj}>
						Tipos de Renda Fixa
					</Typography>
					<Typography sx={textStyleObj}>
						Existem dois tipos de investimentos em Renda Fixa: os
						prefixados e os pós-fixados.
					</Typography>
					<TableContainer sx={{ mt: "30px" }}>
						<Table size="small" aria-label="a dense table">
							<TableBody>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Prefixada
									</TableCell>
									<TableCell sx={tableCell} align="right">
										Logo que o investimento é realizado, é
										possível saber exatamente qual será o
										retorno no fim da aplicação. Por
										exemplo, 6% ao ano.
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Pós Fixada
									</TableCell>
									<TableCell sx={tableCell} align="right">
										Neste caso, o rendimento é atrelado a
										índices econômicos. Por exemplo, 100% do
										CDI.
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
					<Typography sx={textStyleObj}>
						Em linhas gerais, os prefixados apresentam menores
						riscos para o investidor, mas, geralmente, os pós
						fixados também são considerados investimentos bem
						seguros.
					</Typography>
					<Typography sx={textStyleObj}>
						<Typography sx={titleStyleObj}>Vale a pena?</Typography>
						Diferente da Renda Variável – que, mesmo que pareça mais
						vantajosa e com mais chances de ganho, é imprevisível e
						cercada de oscilações –, a Renda Fixa oferece
						rendimentos constantes e estáveis, o que traz mais
						tranquilidade ao investidor principalmente pensando a
						longo prazo.
					</Typography>
					<Typography sx={textStyleObj}>
						A Renda Fixa é uma ótima opção para:
					</Typography>
					<Typography sx={textStyleObj}>
						- Investidores iniciantes, que ainda não possuem tanto
						conhecimento no mundo do mercado financeiro;
					</Typography>
					<Typography sx={textStyleObj}>
						- Investidores que querem uma reserva de emergência, ao
						passo que ela não possui oscilações e deixa a
						possibilidade de retirar a qualquer momento;
					</Typography>
					<Typography sx={textStyleObj}>
						- Investidores conservadores, já que, além da segurança
						e estabilidade, a Renda Fixa protege o patrimônio da
						inflação.
					</Typography>
					<Typography sx={textStyleObj}>
						- Investidores que pensam a longo prazo, como
						aposentadorias.
					</Typography>
					<Typography sx={textStyleObj}>
						Alguns títulos de Renda Fixa também trazem maior
						segurança por serem assegurados pelo FGC, o Fundo
						Garantidor de Créditos. Caso a instituição que emitiu o
						título entre em falência, o FGC garante que o investidor
						recupere até R$ 250 mil por CPF e por instituição
						financeira.
					</Typography>
					<Typography sx={titleStyleObj}>
						Investimentos em Renda Fixa
					</Typography>
					<Typography sx={textStyleObj}>
						Alguns dos principais tipos de investimento em Renda
						Fixa são: Tesouro direto, CDBs, LCIs e LCAs. Eles podem
						ser divididos em títulos públicos e privados.
					</Typography>
					<Typography sx={textStyleObj}>
						Existem muitas possibilidades de aplicações de Renda
						Fixa. Assim, é necessário avaliar o seu perfil de
						investidor e objetivo, bem como analisar o produto
						financeiro antes de realizar o investimento.
					</Typography>
					<Typography sx={titleStyleObj}>Títulos Públicos</Typography>
					<TableContainer sx={{ mt: "30px" }}>
						<Table size="small" aria-label="a dense table">
							<TableBody>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Tesouro Direto
									</TableCell>
									<TableCell sx={tableCell} align="right">
										Possibilita a negociação online de
										títulos públicos por pessoas físicas.
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Títulos Públicos Federais
									</TableCell>
									<TableCell sx={tableCell} align="right">
										São títulos emitidos pelo Governo, por
										meio do Tesouro Nacional, com a
										finalidade de captar recursos para o
										financiamento da atividade pública.
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
					<Typography sx={titleStyleObj}>Títulos Privados</Typography>
					<TableContainer sx={{ mt: "30px" }}>
						<Table size="small" aria-label="a dense table">
							<TableBody>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Certificado de Depósito Bancário (CDB)
									</TableCell>
									<TableCell sx={tableCell} align="right">
										São títulos emitidos pelos bancos e
										caixas econômicas vendidos aos
										investidores como forma de captação de
										recursos.
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										CRA (Certificado de Recebíveis do
										Agronegócio)
									</TableCell>
									<TableCell sx={tableCell} align="right">
										Títulos vinculados a direitos
										creditórios de negócios realizados por
										produtores rurais ou suas cooperativas.
										Têm ligação com o financiamento da
										atividade agropecuária.
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										CRI (Certificado de Recebíveis
										Imobiliários)
									</TableCell>
									<TableCell sx={tableCell} align="right">
										São títulos ligados a atividade
										imobiliários, representativos de
										parcelas de um direito creditório.
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Debêntures
									</TableCell>
									<TableCell sx={tableCell} align="right">
										São valores mobiliários de dívidas de
										médio e longo prazo de Sociedades
										Anônimas (emissoras).
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										DPGE (Depósito a prazo com garantia do
										FGC)
									</TableCell>
									<TableCell sx={tableCell} align="right">
										São depósitos a prazo emitidos por
										instituições financeiras. Algumas
										instituições autorizadas a emitir esse
										depósito são: bancos comerciais,
										múltiplos, de desenvolvimento e
										investimento, sociedades de crédito,
										financiamento e investimento e as caixas
										econômicos.
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										FIDC (Fundo de Investimento em Direitos
										Creditórios)
									</TableCell>
									<TableCell sx={tableCell} align="right">
										Fundo de investimento que destina a
										maior parcela de seu patrimônio líquido
										para aplicação em direitos e títulos
										representativos de créditos que vêm de
										operações comerciais, industriais,
										imobiliárias, financeiras ou de
										prestação de serviços.
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										LC (Letra de Câmbio)
									</TableCell>
									<TableCell sx={tableCell} align="right">
										Instrumento de captação das financeiras,
										com o objetivo de financiar suas
										atividades, em troca de uma
										rentabilidade estabelecida previamente.
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										LCA (Letra de Crédito do Agronegócio)
									</TableCell>
									<TableCell sx={tableCell} align="right">
										Título emitido por instituições
										financeiras públicas e privadas, ligado
										a direitos de crédito provenientes do
										agronegócio.
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										LCI (Letra de Crédito Imobiliário)
									</TableCell>
									<TableCell sx={tableCell} align="right">
										Título emitido por instituições
										financeiras públicas e privadas,
										vinculado a direitos creditórios
										originários do setor imobiliário.
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Letra Financeira
									</TableCell>
									<TableCell sx={tableCell} align="right">
										Instrumento de arrecadação de recursos
										exclusivo das instituições financeiras.
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
					<Typography sx={titleStyleObj}>
						Vantagens da Renda Fixa
					</Typography>
					<Typography sx={textStyleObj}>
						Segundo Felipe Moreira, editor do site Eu Quero
						Investir, algumas vantagens da Renda Fixa são:
					</Typography>
					<Typography sx={textStyleObj}>
						<b>- Acessibilidade:</b> Muitos investimentos com
						rentabilidade superior apenas estão disponíveis para
						investidores com alto potencial de aporte. Muitos fundos
						permitem aplicações mínimas que são acessíveis ao
						investidor que tem pouco para aplicar.
					</Typography>
					<Typography sx={textStyleObj}>
						<b>- Gestão Profissional:</b> Terceirizar a uma equipe
						especializada a gestão do capital. Ter os recursos
						investidos por profissionais com anos de experiência de
						mercado e conhecimento amplo sobre o assunto.
					</Typography>
					<Typography sx={textStyleObj}>
						<b>- Diversificação:</b> Os gestores conseguem reduzir
						os riscos alocando os recursos em diversos ativos. No
						caso de um Fundo de Ações, por exemplo, a carteira é
						composta por diversos papéis não correlacionados. Já no
						caso de Fundos de Crédito Privado a equipe de gestão
						seleciona empresas de setores distintos para mitigar o
						risco específico setorial
					</Typography>
					<Typography sx={textStyleObj}>
						<b>- Liquidez:</b> É muito comum utilizar os fundos como
						uma reserva de emergência, ou seja, um investimento
						semelhante à tradicional poupança onde você deixa os
						recursos com resgate imediato.
					</Typography>
					<Typography sx={textStyleObj}>
						<b>- Transparência:</b> Todos os fundos devem ter em seu
						regulamento a política de investimento, o grau de risco
						e a exposição da carteira em determinados ativos. Este
						documento é disponibilizado no site da CVM e pode ser
						consultado por qualquer investidor.
					</Typography>
					<Typography sx={titleStyleObj}>
						Desvantagens da Renda Fixa
					</Typography>
					<Typography sx={textStyleObj}>
						<b>- Perda da autonomia:</b> Ao investir em fundo de
						investimentos, você não escolhe quais ativos serão
						comprados. Isso fica a cargo do Gestor do fundo.
					</Typography>
					<Typography sx={textStyleObj}>
						<b>- Taxas:</b> Dependendo do fundo, as taxas cobradas
						são muito altas, não compensando a aplicação.
					</Typography>
					<Typography sx={textStyleObj}>
						<b>- Come cotas:</b> A antecipação do imposto gera perda
						de rentabilidade. Uma vez que foi descontado, o
						investidor não tem mais aquele valor rentabilizando.
					</Typography>
					<Typography sx={textStyleObj}>
						Agora você já sabe mais sobre o fundo de investimento em
						Renda Fixa! Caso esteja pensando em aplicar nesse tipo,
						sugerimos que leia cada vez mais sobre o investimento,
						para decidir se ele com seu perfil, e estude as opções
						disponíveis com seu banco ou corretora de investimentos.
					</Typography>
				</Container>
			</Box>
			<ResponsiveFooter />
		</Box>
	);
}
