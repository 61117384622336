import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";

export default function Post25() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			<Container
				sx={{
					width: "100%",
					height: "30%",
					paddingTop: width < 720 ? "150px" : "200px",
					paddingBottom: width < 720 ? "50px" : "100px",
					paddingLeft: width < 720 ? "15px" : "auto",
					paddingRight: width < 720 ? "15px" : "auto",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					backgroundColor: "#161616",
					maxWidth: "none !important",
				}}
			>
				<Typography
					align="center"
					sx={{
						fontSize: width < 720 ? "1.5rem" : "2.5rem",
						lineHeight: width < 720 ? "2rem" : "3rem",
						textAlign: "center",
						maxWidth: "700px",
						fontWeight: "700",
						color: "#FFF",
						padding: "20px",
					}}
				>
					Elon Musk faz oferta para comprar 100% do Twitter
				</Typography>
			</Container>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
					mt: "100px",
					mb: "200px",
					backgroundColor: "#FFF",
				}}
			>
				<Container
					sx={{
						maxWidth: "770px !important",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "flex-start",
						paddingLeft: width < 720 ? "22px" : "auto",
						paddingRight: width < 720 ? "22px" : "auto",
					}}
				>
					<Typography sx={textStyleObj}>
						Depois de comprar 9,2% em participação no Twitter,
						gastando cerca de US$ 2,6 bilhões, o dono da Tesla, Elon
						Musk, fez uma proposta para adquirir totalmente a rede
						social nesta quinta-feira (14).
					</Typography>
					<Typography sx={textStyleObj}>
						De acordo com documento enviado à Comissão de Valores
						Mobiliários dos Estados Unidos (SEC, da sigla em
						inglês), Musk propôs comprar todas as cotas que ainda
						não possui da empresa, a US$ 54,20 cada – o que levaria
						o Twitter a valer US$ 43,4 bilhões.
					</Typography>
					<Typography sx={textStyleObj}>
						A oferta representa um aumento de 38% sobre o preço de
						fechamento em 1º de abril – último dia antes do
						bilionário revelar que se tornou o maior acionista do
						Twitter –, e de 18% sobre o preço de fechamento da
						última quarta (13).
					</Typography>
					<Typography sx={textStyleObj}>
						No documento enviado à SEC, Musk afirmou que essa foi
						sua “melhor e última oferta”, acrescentando que, se não
						for aceita, ele terá que reconsiderar sua posição atual
						de acionista. Musk enviou uma carta com a proposta à
						empresa na quarta-feira (13) à noite, segundo o
						documento.
					</Typography>
					<Typography sx={textStyleObj}>
						“Investi no Twitter porque acredito em seu potencial
						para ser a plataforma de livre expressão em todo o
						mundo, e acredito que a livre expressão é um imperativo
						social para uma democracia funcional”, disse ele na
						carta.
					</Typography>
					<Typography sx={textStyleObj}>
						“Entretanto, desde que fiz meu investimento, agora
						percebo que a empresa não prosperará nem servirá a este
						imperativo da sociedade em sua forma atual. O Twitter
						precisa ser transformado em uma empresa de capital
						fechado”, completou.
					</Typography>
					<Typography sx={textStyleObj}>
						Elon Musk comentou sobre sua proposta em uma publicação
						no Twitter. Escreveu “Eu fiz uma oferta”, compartilhando
						o documento enviado à SEC.
					</Typography>
					<Typography sx={textStyleObj}>
						No início do mês, após comprar 9,2% das ações do
						Twitter, Musk inicialmente aceitou a oferta de um
						assento no conselho de administração da empresa, em um
						acordo que limitava sua participação em 14,9%.
					</Typography>
					<Typography sx={textStyleObj}>
						No último domingo (10), o CEO do Twitter, Parag Agrawal,
						revelou que Musk decidiu não fazer parte do conselho de
						administração, o que acabou eliminando esse limite.
					</Typography>
					<Typography sx={textStyleObj}>
						Nos dias que se seguiram, Musk não tocou no assunto
						sobre seus planos para o Twitter. Também não revelou
						como pretende financiar sua compra, mas disse ter
						contratado Morgan Stanley, uma grande empresa de
						serviços financeiros de Nova Iorque, como sua assessora
						para a transação.
					</Typography>
					<Typography sx={textStyleObj}>
						Mesmo que Musk seja a pessoa mais rica do mundo, a
						grande maioria de seu patrimônio líquido – que
						corresponde a cerca de US$ 274 bilhões – está vinculada
						a suas participações na Tesla, empresa de capital
						aberto, e na SpaceX, de capital privado.
					</Typography>
					<Typography sx={textStyleObj}>
						As ações do Twitter (TWTR) subiram 12% nas negociações
						nesta quinta-feira (14); as ações da Tesla (TSLA) caíram
						2%.
					</Typography>
					<Typography sx={textStyleObj}>
						O Twitter não teve nenhuma resposta oficial imediata
						quando perguntado sobre a oferta.
					</Typography>
				</Container>
			</Box>
			<ResponsiveFooter />
		</Box>
	);
}
