import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";

export default function Post13() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const titleStyleObj = {
		fontSize: width > 720 ? "1.9em" : "1.6em",
		fontWeight: "700",
		mb: "calc(0.2em)",
		mt: "calc(13px + 1.2em)",
		lineHeight: "1.5",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "#111",
	};

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			<Container
				sx={{
					width: "100%",
					height: "30%",
					paddingTop: width < 720 ? "150px" : "200px",
					paddingBottom: width < 720 ? "50px" : "100px",
					paddingLeft: width < 720 ? "15px" : "auto",
					paddingRight: width < 720 ? "15px" : "auto",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					backgroundColor: "#161616",
					maxWidth: "none !important",
				}}
			>
				<Typography
					align="center"
					sx={{
						fontSize: width < 720 ? "1.5rem" : "2.5rem",
						lineHeight: width < 720 ? "2rem" : "3rem",
						textAlign: "center",
						maxWidth: "700px",
						fontWeight: "700",
						color: "#FFF",
						padding: "20px",
					}}
				>
					Boletim Focus: Inflação a 7,65% e Selic a 13,25%
				</Typography>
			</Container>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
					mt: "100px",
					mb: "200px",
					backgroundColor: "#FFF",
				}}
			>
				<Container
					sx={{
						maxWidth: "770px !important",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "flex-start",
						paddingLeft: width < 720 ? "22px" : "auto",
						paddingRight: width < 720 ? "22px" : "auto",
					}}
				>
					<Typography sx={textStyleObj}>
						Segundo o Boletim Focus, no período de quatro semanas, a
						projeção do mercado financeiro para a inflação em 2022
						avançou de 6,86%, no fim de março, para 7,65%, nesta
						semana. Na semana passada, a previsão era de 7,46%, o
						que representa a 15ª alta seguida na estimativa.
					</Typography>
					<Typography sx={textStyleObj}>
						Divulgado na última terça-feira (26) pelo Banco Central
						(BC), o Boletim atualiza as estimativas do mercado
						financeiro após três semanas sem informações, por parte
						do BC, por conta da greve dos servidores públicos. A
						última edição do documento foi publicada no dia 28 de
						março.
					</Typography>
					<Typography sx={textStyleObj}>
						O Boletim reúne a estimativa de mais de 100 instituições
						do mercado financeiro para os principais indicadores
						econômicos e, geralmente, é publicado às 8h25 de toda
						segunda-feira.
					</Typography>
					<Typography sx={textStyleObj}>
						De acordo com o documento, foram 15 altas semanais
						consecutivas na mediana das previsões para o IPCA
						(Índice de Preços ao Consumidor Amplo). A expectativa
						para 2023 passou de 3,8% no fim de março, para 4% nesta
						semana – com alta de três semanas consecutivas.
					</Typography>
					<Typography sx={textStyleObj}>
						Os índices esperados para ambos os anos, 2022 e 2023,
						estão acima do centro da meta definida pelo Conselho
						Monetário Nacional (CMN), de 3,5% e 3,25%,
						respectivamente. Porém, se confirmado o valor do IPCA
						previsto para 2022, será o segundo ano de rompimento do
						limite superior da meta de inflação, que não deveria
						ultrapassar 5%.
					</Typography>
					<Typography sx={textStyleObj}>
						Assim, os analistas também elevaram as previsões para a
						Selic, a taxa básica de juros do Brasil, que deve
						alcançar os 13,25% ao ano até o fim de 2022. Essa taxa é
						a principal ferramenta do Banco Central para controlar a
						pressão inflacionária.
					</Typography>
					<Typography sx={textStyleObj}>
						Por outro lado, a expectativa para a Selic em 2023 se
						manteve em 0% ao ano. Nas próximas reuniões, as decisões
						do Comitê de Política Monetária (Copom) já miram a meta
						de 2023 – e não mais deste ano, visto que as mudanças
						nos juros demoram entre seis e dezoito meses para ter
						impacto na economia.
					</Typography>
					<Typography sx={titleStyleObj}>
						Crescimento da atividade econômica
					</Typography>
					<Typography sx={textStyleObj}>
						A estimativa do crescimento econômico para 2022 avançou
						de 0,5%, há quatro semanas, para 0,65%. Fora as quatro
						altas consecutivas ao logo desse mês, mas que tiveram
						divulgações suspensas.
					</Typography>
					<Typography sx={textStyleObj}>
						Porém, no mesmo período, o mercado reduziu a previsão de
						crescimento do Produto Interno Bruto (PIB) de 1,3% para
						1% em 2023.
					</Typography>
				</Container>
			</Box>
			<ResponsiveFooter />
		</Box>
	);
}
