import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";

export default function Post16() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			<Container
				sx={{
					width: "100%",
					height: "30%",
					paddingTop: width < 720 ? "150px" : "200px",
					paddingBottom: width < 720 ? "50px" : "100px",
					paddingLeft: width < 720 ? "15px" : "auto",
					paddingRight: width < 720 ? "15px" : "auto",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					backgroundColor: "#161616",
					maxWidth: "none !important",
				}}
			>
				<Typography
					align="center"
					sx={{
						fontSize: width < 720 ? "1.5rem" : "2.5rem",
						lineHeight: width < 720 ? "2rem" : "3rem",
						textAlign: "center",
						maxWidth: "700px",
						fontWeight: "700",
						color: "#FFF",
						padding: "20px",
					}}
				>
					Biden diz que 70% da inflação se dá pela guerra na Ucrânia
				</Typography>
			</Container>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
					mt: "100px",
					mb: "200px",
					backgroundColor: "#FFF",
				}}
			>
				<Container
					sx={{
						maxWidth: "770px !important",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "flex-start",
						paddingLeft: width < 720 ? "22px" : "auto",
						paddingRight: width < 720 ? "22px" : "auto",
					}}
				>
					<Typography sx={textStyleObj}>
						Na última quarta-feira (20), o presidente dos Estados
						Unidos, Joe Biden, afirmou que 70% da inflação do mês de
						março no país, o que influenciou os preços do gás e de
						outros produtos de energia, se deve aos impactos da
						guerra entre Rússia e Ucrânia.
					</Typography>
					<Typography sx={textStyleObj}>
						Em mensagem no Twitter, o presidente diz estar “fazendo
						tudo que pode” para reduzir os preços e lidar com a
						inflação gerada pelo conflito no Leste Europeu.
					</Typography>
					<Typography sx={textStyleObj}>
						“Além da questão dos preços do gás, pedi ao Congresso
						que aja imediatamente para diminuir o custo das contas
						de serviços públicos, de medicamentos de prescrição e de
						outros itens”, afirmou Biden na rede social,
						acrescentando que seu objetivo é reduzir pressões
						inflacionárias.
					</Typography>
					<Typography sx={textStyleObj}>
						No Twitter, Biden começou dizendo que sabe que as
						famílias estão lutando com os preços altos, já que
						cresceu em uma família onde, se o preço da gasolina
						subia, eles sentiam.
					</Typography>
					<Typography sx={textStyleObj}>
						“Vamos ser absolutamente claros sobre{" "}
						<b>
							por que os preços estão altos agora: COVID e
							Vladimir Putin”
						</b>
						, afirmou.
					</Typography>
					<Typography sx={textStyleObj}>
						Complementou dizendo que as invasões de Putin na Ucrânia
						aumentaram o preço do gás e dos alimentos em todo o
						mundo, e isso é comprovado pelos dados inflacionários
						dos últimos meses.
					</Typography>
					<Typography sx={textStyleObj}>
						Após dizer que tem feito de tudo para baixar os preços,
						Biden disse que autorizou a liberação de um milhão de
						barris por dia, enquanto trabalha com produtores de
						petróleo para aumentar a produção, junto com aliados de
						todo o mundo.
					</Typography>
					<Typography sx={textStyleObj}>
						Ele encerrou dizendo que além do preço do gás, também
						solicitou ao Congresso que “se mova imediatamente para
						reduzir o custo das contas de serviços públicos das
						famílias, medicamentos prescritos e mais”.
					</Typography>
					<Typography sx={textStyleObj}>
						Segundo ele, ao mesmo tempo que isso reduz o déficit,
						também reduz a pressão inflacionária, e faria uma grande
						diferença para as famílias.
					</Typography>
				</Container>
			</Box>
			<ResponsiveFooter />
		</Box>
	);
}
