import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";

export default function Post49() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const titleStyleObj = {
		fontSize: width > 720 ? "1.9em" : "1.6em",
		fontWeight: "700",
		mb: "calc(0.2em)",
		mt: "calc(13px + 1.2em)",
		lineHeight: "1.5",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "#111",
	};

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			{!loading && (
				<React.Fragment>
					<Container
						sx={{
							width: "100%",
							height: "30%",
							paddingTop: width < 720 ? "150px" : "200px",
							paddingBottom: width < 720 ? "50px" : "100px",
							paddingLeft: width < 720 ? "15px" : "auto",
							paddingRight: width < 720 ? "15px" : "auto",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							backgroundColor: "#161616",
							maxWidth: "none !important",
						}}
					>
						<Typography
							align="center"
							sx={{
								fontSize: width < 720 ? "1.5rem" : "2.5rem",
								lineHeight: width < 720 ? "2rem" : "3rem",
								textAlign: "center",
								maxWidth: "700px",
								fontWeight: "700",
								color: "#FFF",
								padding: "20px",
							}}
						>
							Telegram: STF revoga ordem de bloqueio do aplicativo
						</Typography>
					</Container>
					<Box
						sx={{
							width: "100%",
							height: "100%",
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
							mt: "100px",
							mb: "200px",
							backgroundColor: "#FFF",
						}}
					>
						<Container
							sx={{
								maxWidth: "770px !important",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "flex-start",
								paddingLeft: width < 720 ? "22px" : "auto",
								paddingRight: width < 720 ? "22px" : "auto",
							}}
						>
							<Typography sx={textStyleObj}>
								<p>
									Alexandre de Moraes, ministro do Supremo
									Tribunal Federal (STF), revogou no último
									domingo (20) a ordem de bloqueio, em todo
									Brasil, do aplicativo de mensagens Telegram.
									Segundo o magistrado, a suspensão foi
									revogada porque o Telegram cumpriu a série
									de exigências feitas pelo TSE, até então
									ignoradas pelo aplicativo.
								</p>
								<br />
								<p>
									Entre as medidas tomadas pelo aplicativo, em
									resposta ao STF, estão a indicação de um
									representante oficial no Brasil e a remoção
									de links que permitem baixar documentos de
									inquéritos sigilosos e não concluídos do
									canal oficial do presidente Jair Bolsonaro
									(PL).
								</p>
								<br />
								<p>
									O Telegram indicou o advogado Alan Campos
									Elias Thomaz como seu representante legal no
									Brasil, dizendo que o especialista em
									direito digital está em contato direto com a
									alta administração da empresa, podendo
									responder prontamente a demandas judiciais.
								</p>
							</Typography>

							<Typography sx={titleStyleObj}>
								Medidas adotadas pelo Telegram
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Entre algumas medidas para controle da
									desinformação, adotadas pela empresa, estão:
								</p>
							</Typography>
							<Typography
								sx={{
									ml: "25px",
									lineHeight: "2rem",
									pr: "10px",
								}}
							>
								<ul>
									<li>
										Monitoramento manual e diário dos 100
										canais mais populares no Brasil;
									</li>
									<li>
										Monitoramento das referências ao
										Telegram em toda imprensa brasileira;
									</li>
									<li>
										Capacidade de marcar postagens
										específicas nos canais analisados como
										“imprecisas”;
									</li>
									<li>
										Restrição de postagens de usuários
										banidos por espalhar desinformação;
									</li>
									<li>
										Promoção de conteúdos em fontes
										verificadas;
									</li>
									<li>
										Atualização dos termos de serviço para
										incluir essas mudanças.
									</li>
								</ul>
							</Typography>

							<Typography sx={textStyleObj}>
								<p>
									Além de deletar mensagens do canal de
									Bolsonaro, que permitia baixar documentos de
									um inquérito sobre uma suposta invasão ao
									sistema eleitoral brasileiro, o Telegram
									bloqueou, a pedido de Moraes, o canal do
									jornalista Claudio Lessa, por disseminação
									de desinformação.
								</p>
								<br />
								<p>
									Em mensagem a Alexandre de Moraes, o
									fundador do Telegram, Pavel Durov, se
									desculpou pelo atraso em seguir as
									diretrizes do TSE, dizendo que o pedido do
									Tribunal foi encaminhado a um e-mail
									destinado a perguntas gerais dos usuários,
									que estava sobrecarregado, principalmente
									pela guerra entre Rússia e Ucrânia.
								</p>
								<br />
								<p>
									“Temos certeza de que tais lapsos não
									ocorrerão no futuro e respeitosamente
									pedimos ao Tribunal que permita que o
									Telegram continue suas operações no Brasil,
									dando-nos a chance de demonstrar que
									melhoramos significativamente nossos
									procedimentos”, afirmou Durov.
								</p>
								<br />
								<p>
									Com o cumprimento das exigências do Supremo
									Tribunal Federal, Moraes removeu o bloqueio
									do aplicativo. “Considerando o atendimento
									integral das decisões (…), revogo a decisão
									de completa e íntegra suspensão do
									funcionamento do Telegram no Brasil”,
									conclui a decisão.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								Por que houve o bloqueio do aplicativo?
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Na quinta-feira (17), Alexandre de Moraes
									justificou a decisão de suspender o
									aplicativo citando a Polícia Federal, que
									argumentou que “o aplicativo Telegram é
									notoriamente conhecido por sua postura de
									não cooperar com autoridades judiciais e
									policiais de diversos países, inclusive
									colocando essa atitude não colaborativa como
									uma vantagem em relação a outros aplicativos
									de comunicação, o que o torna um terreno
									livre para proliferação de diversos
									conteúdos, inclusive com repercussão na área
									criminal”.
								</p>
								<br />
								<p>
									Moraes afirmou que o Telegram se recusou a
									cumprir as determinações judiciais. O
									aplicativo bloqueou os perfis ligados ao
									blogueiro Allan dos Santos, como resposta ao
									pedido judicial feito em fevereiro, mas não
									bloqueou o perfil criado como substituto
									logo em seguida, e também não forneceu
									informações solicitadas sobre monetização
									dos canais.
								</p>
								<br />
								<p>
									Na sexta (18), o diretor executivo do
									Telegram, Pavel Durov, divulgou um
									comunicado em seu canal oficial sobre a
									situação do Brasil: “Parece que tivemos um
									problema com e-mails entre nossos endereços
									corporativos no telegram.org e o Supremo
									Tribunal brasileiro. Como resultado dessa
									falha de comunicação, o Tribunal decidiu
									bloquear o Telegram por não estar
									respondendo.”
								</p>
								<br />
								<p>
									“Em nome da nossa equipe, peço desculpas ao
									Supremo Tribunal brasileiro por nossa
									negligência. Definitivamente, poderíamos ter
									feito um trabalho melhor. (…) Como dezenas
									de milhões de brasileiros dependem do
									Telegram para se comunicar com a família,
									amigos e colegas, peço ao Tribunal que
									considere adiar sua decisão por alguns dias
									a seu critério para nos permitir remediar a
									situação nomeando um representante no Brasil
									e criando uma estrutura para reagir de forma
									rápida a futuras questões urgentes como
									essa”, disse.
								</p>
								<br />
								<p>
									O comunicado conclui com “(…) tenho certeza
									de que uma vez estabelecido um canal de
									comunicação confiável, conseguiremos
									concluir com eficiência os pedidos de
									suspensão de canais públicos ilegais no
									Brasil.”
								</p>
							</Typography>
						</Container>
					</Box>
					<ResponsiveFooter />
				</React.Fragment>
			)}
		</Box>
	);
}
