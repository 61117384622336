import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";

export default function Post39() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			{!loading && (
				<React.Fragment>
					<Container
						sx={{
							width: "100%",
							height: "30%",
							paddingTop: width < 720 ? "150px" : "200px",
							paddingBottom: width < 720 ? "50px" : "100px",
							paddingLeft: width < 720 ? "15px" : "auto",
							paddingRight: width < 720 ? "15px" : "auto",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							backgroundColor: "#161616",
							maxWidth: "none !important",
						}}
					>
						<Typography
							align="center"
							sx={{
								fontSize: width < 720 ? "1.5rem" : "2.5rem",
								lineHeight: width < 720 ? "2rem" : "3rem",
								textAlign: "center",
								maxWidth: "700px",
								fontWeight: "700",
								color: "#FFF",
								padding: "20px",
							}}
						>
							Servidores do Banco Central dão início a greve nesta
							sexta
						</Typography>
					</Container>
					<Box
						sx={{
							width: "100%",
							height: "100%",
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
							mt: "100px",
							mb: "200px",
							backgroundColor: "#FFF",
						}}
					>
						<Container
							sx={{
								maxWidth: "770px !important",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "flex-start",
								paddingLeft: width < 720 ? "22px" : "auto",
								paddingRight: width < 720 ? "22px" : "auto",
							}}
						>
							<Typography sx={textStyleObj}>
								<p>
									Nesta sexta-feira (1), os servidores do
									Banco Central anunciaram uma greve por tempo
									indeterminado. O Sindicato Nacional dos
									Funcionários do Banco Central (Sinal) disse,
									por meio de nota, que serviços como o PIX
									podem ser impactados pela greve, já que não
									constam no escopo da lei como serviço
									essencial.{" "}
								</p>
								<br />
								<p>
									O presidente do sindicato, Fabio Faiad,
									afirmou, em entrevista à CNN Brasil
									Business, que “todos os setores do banco
									podem ser prejudicados” com a paralisação.
								</p>
								<br />
								<p>
									A previsão do sindicato é que entre 60% e
									70% da categoria entre em greve. Desse modo,
									serviços como o{" "}
									<b>
										PIX, distribuição de moedas e cédulas e
										divulgação de boletins, poderão sofrer
										interrupções.
									</b>
								</p>
								<br />
								<p>
									“A greve poderá interromper parcialmente o
									PIX e a distribuição de moedas e cédulas. E
									poderá interromper, parcial ou totalmente, a
									divulgação do boletim Focus e de diversas
									Taxas, o monitoramento e a manutenção do
									Sistema de Pagamentos Brasileiro (SPB) e da
									mesa de operações do Demab, o atendimento ao
									público e outras atividades”, disse Fabio
									Faiad.
								</p>
								<br />
								<p>
									A Sinal publicou, em seu canal do YouTube,
									um vídeo a respeito da paralisação:
								</p>
								<div
									style={{
										width: "100%",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										marginTop: "20px",
										marginBottom: "20px",
									}}
								>
									<iframe
										width={width > 720 ? "500" : "340"}
										height={width > 720 ? "360" : "270"}
										src="https://www.youtube.com/embed/rKb0BqJhHd0"
										title="YouTube video player"
										frameborder="0"
										allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
										allowfullscreen
									></iframe>
								</div>
								<br />
								<p>
									Desde o dia 17 de março, os servidores do BC
									realizaram uma série de protestos com
									paralisações pontuais, o que gerou falhas na
									coleta e divulgação de indicadores e dados
									financeiros.
								</p>
								<br />
								<p>
									A pesquisa Focus, realizada semanalmente
									pelo Banco Central, por exemplo, está sendo
									divulgada com 1h30 de atraso. Na segunda, a
									instituição afirmou que as notas mensais do
									setor externo, crédito e contas fiscais –
									que seriam divulgadas nesta semana – não
									serão publicadas, sem previsão de nova data.
								</p>
								<br />
								<p>
									Os funcionários do Banco, que estão sem
									reajuste salariam há três anos, pedem uma
									recomposição de mais de 20% e uma
									reestruturação de carreira, principalmente
									para analistas e técnicos. Também Segundo o
									sindicato, a paralisação, aprovada em
									assembleia na última segunda-feira (28),
									conta com a participação de 1.300
									servidores, dos quais 90% votaram a favor da
									greve.
								</p>
								<br />
								<p>
									Em nota, o presidente do Sinal ainda lamenta
									que o presidente do Banco Central, Roberto
									Campos Neto, tenha decidido tirar férias
									neste momento. “E infelizmente, nessa hora
									tão importante, o Presidente do BC viajou de
									férias para Miami, o que não ajuda para
									encontrarmos uma solução para a crise!”,
									afirma Faiad.
								</p>
								<br />
								<p>
									Segundo o Diário Oficial da União, o diretor
									de Regulação do BC, Otávio Damaso, irá
									substituir Campos Neto a partir desta
									quinta-feira (31) até 4 de abril.
								</p>
							</Typography>
						</Container>
					</Box>
					<ResponsiveFooter />
				</React.Fragment>
			)}
		</Box>
	);
}
