import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";

export default function Post6() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const titleStyleObj = {
		fontSize: width > 720 ? "1.9em" : "1.6em",
		fontWeight: "700",
		mb: "calc(0.2em)",
		mt: "calc(13px + 1.2em)",
		lineHeight: "1.5",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "#111",
	};

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			<Container
				sx={{
					width: "100%",
					height: "30%",
					paddingTop: width < 720 ? "150px" : "200px",
					paddingBottom: width < 720 ? "50px" : "100px",
					paddingLeft: width < 720 ? "15px" : "auto",
					paddingRight: width < 720 ? "15px" : "auto",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					backgroundColor: "#161616",
					maxWidth: "none !important",
				}}
			>
				<Typography
					align="center"
					sx={{
						fontSize: width < 720 ? "1.5rem" : "2.5rem",
						lineHeight: width < 720 ? "2rem" : "3rem",
						textAlign: "center",
						maxWidth: "700px",
						fontWeight: "700",
						color: "#FFF",
						padding: "20px",
					}}
				>
					Pensa em investir nos carros populares? Entenda mais aqui
				</Typography>
			</Container>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
					mt: "100px",
					mb: "200px",
					backgroundColor: "#FFF",
				}}
			>
				<Container
					sx={{
						maxWidth: "770px !important",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "flex-start",
						paddingLeft: width < 720 ? "22px" : "auto",
						paddingRight: width < 720 ? "22px" : "auto",
					}}
				>
					<Typography sx={textStyleObj}>
						Se você pensa em investir nos carros populares em 2022,
						essa matéria é para você. Uma pesquisa feita pela CNN,
						calculou gastos para um carro popular que utiliza
						gasolina comum e o resultado foi R$30 mais caro em 2022
						do que nos anos anteriores.
					</Typography>
					<Typography sx={textStyleObj}>
						Além disso, nos primeiros quatro meses do ano, o preço
						da gasolina comum subiu aproximadamente 10% para os
						brasileiros. Ilustrando com dados, o litro do
						combustível saltou de R$ 6,596 para R$ 7,283 no período
						entre 2 de janeiro e 30 de abril, segundo dados da
						Agência Nacional do Petróleo, Gás Natural e
						Biocombustíveis (ANP), compilados pela CNN.
					</Typography>
					<Typography sx={textStyleObj}>
						Na prática, o resultado mostra que, atualmente, encher o
						tanque do carro no Brasil ficou R$ 30 mais caro, quando
						comparado com o início de 2022. Para uma análise de um
						período de 12 meses, o valor mais que triplica: um
						adicional de R$ 100 para abastecer o automóvel, a cada
						parada no posto de combustível.
					</Typography>
					<Typography sx={titleStyleObj}>
						O aumento da gasolina
					</Typography>
					<Typography sx={textStyleObj}>
						De acordo com o pesquisador da Fundação Getúlio Vargas
						(FGV) Joelson Sampaio explica que o aumento no custo da
						gasolina comum, no curto prazo, está relacionado com os
						impactos da guerra no leste europeu, entre Ucrânia e
						Rússia.
					</Typography>
					<Typography sx={textStyleObj}>
						Portanto, referente aos últimos 12 meses, o economista
						aponta a pandemia de Covid-19 e a pressão inflacionária
						como principais “vilões” da alta no combustível.
					</Typography>
					<Typography sx={textStyleObj}>
						“O custo do petróleo aumenta desde o início da pandemia,
						devido ao choque de oferta mundial. Isso fez com que o
						preço da commodity explodisse, prejudicando a economia
						de todos os países, principalmente aqueles mais
						dependentes do insumo, como é o caso do Brasil”,
						explicou Sampaio.
					</Typography>
					<Typography sx={textStyleObj}>
						De toda forma, o economista da FGV ainda relacionou o
						valor necessário para encher o tanque do carro com o
						salário mínimo.
					</Typography>
					<Typography sx={textStyleObj}>
						“Para quem depende do carro diariamente para o
						transporte, pesa bastante no orçamento mensal. Um tanque
						cheio representa quase um quarto do salário mínimo hoje
						em dia. E além disso tudo, em paralelo com a alta que já
						se concretizou, nós seguimos com uma inflação altíssima
						e a guerra acontecendo na Europa”, finalizou Joelson
						Sampaio.
					</Typography>
					<Typography sx={titleStyleObj}>
						Veículos elétricos
					</Typography>
					<Typography sx={textStyleObj}>
						Uma boa opção e que está crescendo bastante no mercado
						financeiro são os veículos elétricos, em que possuem
						bastante vantagens a longo prazo do que os veículos que
						funcionam pelo petróleo.
					</Typography>
					<Typography sx={textStyleObj}>
						De acordo com o vice-presidente do segmento de
						bicicletas da Abraciclo, Cyro Gazola, o aumento da
						procura começou durante a pandemia, período em que as
						pessoas buscavam atividades para fazer fora de casa.
					</Typography>
					<Typography sx={textStyleObj}>
						De acordo com a entidade, as vendas de veículos
						eletrificados no Brasil tiveram o melhor mês de
						fevereiro da série histórica, o que equivale a um
						aumento de 147% sobre fevereiro de 2021 (1.389) e de 34%
						sobre janeiro de 2022 (2.558).
					</Typography>
					<Typography sx={textStyleObj}>
						Para os especialistas, os principais fatores para o
						público buscar esse tipo de veículo está dentro da
						conscientização da emissão de carbono, que passou a ser
						um assunto cotidiano na sociedade. Além disso, a questão
						da elevação dos preços da gasolina, que tem assustado
						ainda mais a população.
					</Typography>
				</Container>
			</Box>
			<ResponsiveFooter />
		</Box>
	);
}
