import React from "react";

import { MoonLoader } from "react-spinners";
import { Typography, Box } from "@mui/material";

export default function Preloader() {
	return (
		<Box
			sx={{
				position: "fixed",
				top: "0px",
				right: "0px",
				bottom: "0px",
				left: "0px",
				width: "100%",
				height: "100%",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				zIndex: "10000000",
				backgroundPosition: "center",
				backgroundColor: "rgba(255, 255, 255,0.95)",
			}}
		>
			<MoonLoader size={120} color="#1AA3DA" />
			<Typography
				sx={{
					fontSize: "1.8rem",
					mt: "20px",
					fontWeight: "500",
				}}
			>
				Aguarde...
			</Typography>
		</Box>
	);
}
