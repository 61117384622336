import {
	Box,
	Typography,
	Container,
	Divider,
	ListItem,
	List,
	Button,
	Grid,
} from "@mui/material";
import React from "react";
import ResponsiveAppBar from "../../components/AppBar";
import useWindowDimensions from "../../hooks/windowDimension";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ResponsiveFooter from "../../components/Footer";
import latam_pass_gold from "../../assets/latam_pass_gold.png";
import latam_pass_platinum from "../../assets/latam_pass_platinum.png";
import latam_pass_internacional from "../../assets/latam_pass_internacional.png";
import latam_pass_black from "../../assets/latam_pass_black.png";
import latam_pass_infinite from "../../assets/latam_pass_infinite.png";
import latam_all_cards from "../../assets/latam_all_cards.png";
import useLoader from "../../hooks/useLoader";
import Preloader from "../../components/Preloader";

const divGridStyle = {
	height: "auto",
	width: "100%",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "flex-start",
	textAlign: "left",
};

const typographyGridStyle = {
	fontSize: "0.9rem",
	color: "var(--textGray)",
	lineHeight: "1.5rem",
	mb: "20px",
};

export default function LatamExt() {
	const { width } = useWindowDimensions();
	const loading = useLoader();

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && <Preloader />}
			<Container
				sx={{
					width: "100vw",
					height: "100%",
					mt: width < 720 ? "150px" : "200px",
					mb: "40px",
				}}
			>
				<Typography variant="h5" align="center">
					Cartão de Crédito Latam Pass Itaucard
				</Typography>
				<Typography
					align="center"
					sx={{
						fontSize: "1.1rem",
						fontWeight: "600",
						mt: "10px",
					}}
				>
					Peça o cartão que possui os benefícios para você viver a
					melhor experiência possível!
				</Typography>
				<Box
					sx={{
						width: "100%",
						height: "auto",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						mt: "30px",
					}}
				>
					<img
						alt="latam_all_cards"
						src={latam_all_cards}
						loading="lazy"
						style={{
							width: "80%",
							height: "100%",
							maxWidth: "300px",
						}}
					/>
				</Box>
			</Container>
			<Divider variant="middle" />
			<Container
				sx={{
					display: width < 720 ? "flex" : "none",
					alignItems: "center",
					justifyContent: "center",
					mt: "100px",
					mb: "100px",
				}}
			>
				<div id="cartoes.informacash_top_mob"></div>
			</Container>
			<Container
				sx={{
					display: width > 720 ? "flex" : "none",
					alignItems: "center",
					justifyContent: "center",
					mt: "100px",
					mb: "100px",
				}}
			>
				<div id="cartoes.informacash_top_desk"></div>
			</Container>
			<Container
				sx={{
					width: "100vw",
					height: "100%",
					mt: "70px",
					mb: "30px",
					pl: "7%",
					pr: "4%",
				}}
			>
				<Typography
					variant="h5"
					align="left"
					sx={{
						lineHeight: "2rem",
						fontWeight: "700",
						mb: "5px",
					}}
				>
					Por que escolher o Latam Pass Itaucard?
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "1.5rem",
						fontWeight: "400",
						mb: "20px",
					}}
				>
					Entre os principais benefícios do cartão, estão:
					<br />
				</Typography>

				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Programa de pontos vantajoso: cada compra é convertida em
					pontos que podem ser trocados por passagens, descontos ou
					brindes. Tudo isso, com valores acima da média de cartões
					semelhantes.
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Seguro e assistência de viagem: viaje sem se preocupar já
					que o seguro pode cobrir o despacho de bagagem, aluguel de
					automóveis, hospedagens e compras internacionais, por
					exemplo.
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Benefícios em aeroportos nacionais e internacionais: é
					possível optar pela sala de espera VIP, realizar o embarque
					preferencial e solicitar um upgrade de cabine.
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Aproxime e pague: é possível pagar por aproximação e
					utilizar carteiras digitais.
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Tag Itaú livre de mensalidade: peça seu adesivo, cadastre
					seu cartão Latam Pass Itaú e passe no automático em pedágios
					e estacionamentos de todo Brasil.
				</Typography>
				<Container
					sx={{
						display: width < 720 ? "flex" : "none",
						alignItems: "center",
						justifyContent: "center",
						mt: "100px",
						mb: "100px",
					}}
				>
					<div
						class="pg-lazy"
						data-gpt-parent="cartoes.informacash_mid_mob"
					></div>
				</Container>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					Clique no botão para obter o seu cartão e comece a viajar de
					uma forma simples, econômica e cheia de benefícios!
				</Typography>
			</Container>
			<Container
				sx={{
					width: "100vw",
					height: "100%",
					mt: "60px",
					mb: "30px",
					pl: "7%",
					pr: "4%",
				}}
			>
				<Typography
					variant="h5"
					align="left"
					sx={{
						lineHeight: "2rem",
						fontWeight: "700",
						mb: "10px",
					}}
				>
					Qual a melhor escolha para o seu perfil?
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mt: "10px",
						mb: "10px",
					}}
				>
					Para saber qual versão do cartão combina com você, é
					necessário comparar e analisar qual lista de benefícios se
					encaixa com a sua realidade. Entre os cartões Latam Pass
					Itaucard, estão:
					<br />
				</Typography>
				<Container
					sx={{
						display: width > 720 ? "flex" : "none",
						alignItems: "center",
						justifyContent: "center",
						mt: "100px",
						mb: "100px",
					}}
				>
					<div
						class="pg-lazy"
						data-gpt-parent="cartoes.informacash_mid_desk"
					></div>
				</Container>
				<Container sx={{ width: "90%", mb: "40px", mt: "30px" }}>
					<Grid container spacing={2} alignItems="stretch">
						<Grid item xs={12} sm={6}>
							<Box
								sx={{
									width: "100%",
									height: "100%",
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<img
									alt="latam_pass_gold"
									src={latam_pass_gold}
									loading="lazy"
									style={{
										width: "80%",
										height: "100%",
										maxWidth: "350px",
										marginBottom: "20px",
									}}
								/>
								<Typography variant="h6" align="center">
									<b>Latam Pass Gold</b>
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6}>
							<div style={divGridStyle}>
								<Typography
									variant="p"
									align="left"
									sx={typographyGridStyle}
								>
									<b> Renda Mínima:</b> R$ 2.500
									<br />
									<b>Anuidade:</b> Grátis gastando R$2.000 por
									fatura
									<br />
									<b>Pontuação:</b> 1 US$ = 2 pontos
									<br />
									<b>Principais benefícios:</b> Garantia
									estendida original; proteção de compras e
									preços; serviço de assistência em viagem.
								</Typography>
							</div>
						</Grid>
						<Container
							sx={{
								display: width < 720 ? "flex" : "none",
								alignItems: "center",
								justifyContent: "center",
								mt: "100px",
								mb: "100px",
							}}
						>
							<div
								class="pg-lazy"
								data-gpt-parent="cartoes.informacash_mid_mob"
							></div>
						</Container>
						<Grid item xs={12} sm={6}>
							<Box
								sx={{
									width: "100%",
									height: "100%",
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<img
									alt="latam_pass_platinum"
									src={latam_pass_platinum}
									loading="lazy"
									style={{
										width: "80%",
										height: "100%",
										maxWidth: "350px",
										marginBottom: "20px",
									}}
								/>
								<Typography variant="h6" align="center">
									<b>Latam Pass Platinum</b>
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6}>
							<div style={divGridStyle}>
								<Typography
									variant="p"
									align="left"
									sx={typographyGridStyle}
								>
									<b> Renda Mínima:</b> R$7.000
									<br />
									<b>Anuidade:</b> grátis gastando R$4.000 por
									fatura
									<br />
									<b>Pontuação:</b> 1 US$ = 4 pontos
									<br />
									<b>Principais benefícios:</b> Seguro médico
									em viagens; assistência global de
									emergência; benefício Masterseguro de
									automóveis; Mastercard Platinum Concierge.
								</Typography>
							</div>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Box
								sx={{
									width: "100%",
									height: "100%",
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<img
									alt="latam_pass_internacional"
									src={latam_pass_internacional}
									loading="lazy"
									style={{
										width: "80%",
										height: "100%",
										maxWidth: "350px",
										marginBottom: "20px",
									}}
								/>
								<Typography variant="h6" align="center">
									<b>Latam Pass Internacional</b>
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6}>
							<div style={divGridStyle}>
								<Typography
									variant="p"
									align="left"
									sx={typographyGridStyle}
								>
									<b> Renda Mínima:</b> R$800
									<br />
									<b>Anuidade:</b> grátis gastando R$1.000 por
									fatura
									<br />
									<b>Pontuação:</b> 1 US$ = até 2,5 pontos
									<br />
									<b>Principais benefícios:</b> Mastercard
									Global Service; Mastercard Surpreenda.
								</Typography>
							</div>
						</Grid>
						<Container
							sx={{
								display: width < 720 ? "flex" : "none",
								alignItems: "center",
								justifyContent: "center",
								mt: "100px",
								mb: "100px",
							}}
						>
							<div
								class="pg-lazy"
								data-gpt-parent="cartoes.informacash_mid_mob"
							></div>
						</Container>
						<Container
							sx={{
								display: width > 720 ? "flex" : "none",
								alignItems: "center",
								justifyContent: "center",
								mt: "100px",
								mb: "100px",
							}}
						>
							<div
								class="pg-lazy"
								data-gpt-parent="cartoes.informacash_mid_desk"
							></div>
						</Container>
						<Grid item xs={12} sm={12}>
							<div style={divGridStyle}>
								<Typography
									align="left"
									sx={{
										fontSize: "1rem",
										lineHeight: "1.6rem",
										fontWeight: "400",
										mb: "20px",
									}}
								>
									Além disso, você pode escolher entre os
									cartões Visa e Mastercard, e decidir qual
									das versões é a melhor para você! Para os
									clientes Visa, existe a possibilidade de
									adquirir o cartão Visa Infinite e, para os
									clientes Mastercard, o Mastercard Black.
									Ambos possuem renda mínima de R$10.000,00,
									anuidade grátis gastando R$20.000 de fatura
									e uma lista extensa de benefícios.
								</Typography>
							</div>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Box
								sx={{
									width: "100%",
									height: "100%",
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<img
									alt="latam_pass_black"
									src={latam_pass_black}
									loading="lazy"
									style={{
										width: "80%",
										height: "100%",
										maxWidth: "350px",
										marginBottom: "20px",
									}}
								/>
								<Typography variant="h6" align="center">
									<b>Latam Pass Visa Black</b>
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Box
								sx={{
									width: "100%",
									height: "100%",
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<img
									alt="latam_pass_infinite"
									src={latam_pass_infinite}
									loading="lazy"
									style={{
										width: "80%",
										height: "100%",
										maxWidth: "350px",
										marginBottom: "20px",
									}}
								/>
								<Typography variant="h6" align="center">
									<b>Latam Pass Mastercard Infinite</b>
								</Typography>
							</Box>
						</Grid>
					</Grid>
				</Container>
				<Container
					sx={{
						display: width < 720 ? "flex" : "none",
						alignItems: "center",
						justifyContent: "center",
						mt: "100px",
						mb: "100px",
					}}
				>
					<div
						class="pg-lazy"
						data-gpt-parent="cartoes.informacash_mid_mob"
					></div>
				</Container>
				<Typography
					align="left"
					variant="h5"
					sx={{
						lineHeight: "2.1rem",
						mb: "10px",
						fontWeight: "700",
					}}
				>
					Como solicitar o seu Latam Pass Itaucard?
				</Typography>
				<List
					sx={{
						listStyleType: "decimal",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<ListItem
						sx={{
							justifyContent: "left",
							pb: "0 !important",
							pt: "0 !important",
							pl: "20px",
							mb: "5px",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "1rem",
								display: "list-item",
							}}
						>
							Clique em <b>solicitar agora</b>
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "left",
							pb: "0 !important",
							pt: "0 !important",
							pl: "20px",
							mb: "5px",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "1rem",
								display: "list-item",
							}}
						>
							Preencha os seus dados iniciais: nome, endereço,
							documentação e renda
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "left",
							pb: "0 !important",
							pt: "0 !important",
							pl: "20px",
							mb: "5px",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "1rem",
								display: "list-item",
							}}
						>
							Aguarde a análise e aprovação do Itaú
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "left",
							pb: "0 !important",
							pt: "0 !important",
							pl: "20px",
							mb: "5px",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "1rem",
								display: "list-item",
							}}
						>
							Receba o seu Latam Pass Itaucard em casa
						</Typography>
					</ListItem>
				</List>
			</Container>
			<Container
				sx={{
					width: "100%",
					height: "100%",
					mb: "50px",
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Button
					variant="contained"
					size="large"
					disableElevation
					sx={{
						fontWeight: "700",
						backgroundColor: "var(--infoCashBlue)",
					}}
					href="https://igoal.go2cloud.org/aff_c?offer_id=5491&aff_id=1673&url_id=43829"
				>
					Solicitar agora
				</Button>
			</Container>
			<ResponsiveFooter />
		</Box>
	);
}
