import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";

export default function Post32() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const titleStyleObj = {
		fontSize: width > 720 ? "1.9em" : "1.6em",
		fontWeight: "700",
		mb: "calc(0.2em)",
		mt: "calc(13px + 1.2em)",
		lineHeight: "1.5",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "#111",
	};

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			{!loading && (
				<React.Fragment>
					<Container
						sx={{
							width: "100%",
							height: "30%",
							paddingTop: width < 720 ? "150px" : "200px",
							paddingBottom: width < 720 ? "50px" : "100px",
							paddingLeft: width < 720 ? "15px" : "auto",
							paddingRight: width < 720 ? "15px" : "auto",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							backgroundColor: "#161616",
							maxWidth: "none !important",
						}}
					>
						<Typography
							align="center"
							sx={{
								fontSize: width < 720 ? "1.5rem" : "2.5rem",
								lineHeight: width < 720 ? "2rem" : "3rem",
								textAlign: "center",
								maxWidth: "700px",
								fontWeight: "700",
								color: "#FFF",
								padding: "20px",
							}}
						>
							Amazon vai lançar satélites para fornecer internet
						</Typography>
					</Container>
					<Box
						sx={{
							width: "100%",
							height: "100%",
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
							mt: "100px",
							mb: "200px",
							backgroundColor: "#FFF",
						}}
					>
						<Container
							sx={{
								maxWidth: "770px !important",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "flex-start",
								paddingLeft: width < 720 ? "22px" : "auto",
								paddingRight: width < 720 ? "22px" : "auto",
							}}
						>
							<Typography sx={textStyleObj}>
								<p>
									Na última terça-feira (5), a Amazon anunciou
									acordos inéditos com as empresas
									Arianespace, Blue Origin e United Launch
									Alliance (ULA). O objetivo dos acordos é
									concretizar mais de 80 lançamentos especiais
									para colocar milhares de satélites em órbita
									baixa na Terra, a fim de fornecer internet
									banda larga para milhões de pessoas.
								</p>
								<br />
								<p>
									“O Projeto Kuiper fornecerá banda larga
									rápida e acessível a milhões de usuários em
									comunidades marginalizadas em todo o mundo”,
									disse o vice-presidente da Amazon, Dave
									Limp.
								</p>
								<br />
								<p>
									Segundo a empresa, em comunicado, se trata
									da “maior encomenda de foguetes da
									história”. “Os contratos preveem até 83
									lançamentos em um período de cinco anos,
									permitindo que a Amazon implante a maioria
									de sua constelação de 3.236 satélites”,
									informou.
								</p>
								<br />
								<p>
									O custo total do Projeto Kuiper da Amazon e
									o cronograma de lançamentos ainda não foram
									divulgados. “Ainda temos muito trabalho, mas
									a equipe continua alcançando marco após
									marco em todos os aspectos do nosso sistema
									de satélites”, celebrou Limp, no mesmo
									comunicado.
								</p>
							</Typography>

							<Typography sx={titleStyleObj}>
								Outros projetos espaciais
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Apesar da grandeza, o projeto de internet
									rápida por satélite da Amazon não é o
									primeiro.
								</p>
								<br />
								<p>
									Elon Musk, multimilionário e presidente da
									empresa espacial Space X, já programou um
									total de 12 mil satélites, dos quais cerca
									de 2 mil já estão em órbita, com o objetivo
									de criar sua própria rede de internet – a
									Starlink, que já atende vários países.
								</p>
								<br />
								<p>
									A empresa britânica de comunicações One Web
									também já enviou 428 de 648 dos satélites
									previstos para seu próprio serviço.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								Projeto Kuiper – EUA e França
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									A United Launch Alliance (ULA) obteve a
									maior parte dos contratos do Projeto Kuiper
									da Amazon, com 38 lançamentos. Este
									empreendimento conjunto, formado pelos
									grandes americanos Boeing e Lockheed Martin,
									anunciou que investirá em uma segunda
									plataforma neste contexto da história
									espacial, o que permitirá um maior ritmo de
									lançamentos de seu foguete Vulcan Centaur.
								</p>
								<br />
								<p>
									Já a Blue Origin, fundada, assim como a
									Amazon, por Jeff Bezos, fará 12 lançamentos
									para seu futuro foguete New Glen, e ainda
									terá a opção de 15 adicionais. A empresa
									também terá benefícios com o contrato com a
									ULA, porque fabrica os motores do foguete da
									concorrente, o Vulcan Centaur.
								</p>
								<br />

								<p>
									A única colaboradora não americana, a
									francesa Arianespace, ficou responsável por
									18 lançamentos, pelo foguete Ariane 6.
								</p>
								<br />
								<p>
									O Projeto Kuiper tem o objetivo de fornecer
									internet de alta velocidade para escolas,
									residências, operações de emergência e
									outros locais sem conexão confiável. Segundo
									a Amazon, já foram investidos mais de US$ 10
									bilhões na iniciativa.
								</p>
								<br />
								<p>
									De acordo com o vice-presidente de
									tecnologia do Projeto Kuiper, Rajeev Badyal,
									a Amazon planejou, desde o começo, contratar
									várias empresas de lançamentos espaciais,
									buscando, assim, reduzir os riscos de
									atrasos e economizar dinheiro em relação à
									concorrência.
								</p>
								<br />
								<p>
									“Esses enormes foguetes de carga pesada
									também nos permitem implantar mais de nossa
									constelação [satélite] com menos
									lançamentos”, afirmou Badyal.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								Grandes expectativas para o Mercado
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									É esperado que esse grande número de
									lançamentos impulsione o setor de internet
									rápida por satélite nos Estados Unidos e na
									Europa.
								</p>
								<br />
								<p>
									“Este contrato, o maior que já assinamos, é
									um grande momento na história da
									Arianespace”, disse o diretor-executivo da
									empresa, Stephane Israel, no comunicado.
								</p>
								<br />
								<p>
									Já o diretor da ULA, Tory Bruno, afirmou que
									esse é o “início de uma era emocionante”
									para sua empresa e toda indústria de
									lançamentos espaciais dos Estados Unidos.
								</p>
							</Typography>
						</Container>
					</Box>
					<ResponsiveFooter />
				</React.Fragment>
			)}
		</Box>
	);
}
