import {
	Box,
	Typography,
	Container,
	Divider,
	ListItem,
	List,
	Button,
	Grid,
} from "@mui/material";
import React from "react";
import ResponsiveAppBar from "../../components/AppBar";
import useWindowDimensions from "../../hooks/windowDimension";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ResponsiveFooter from "../../components/Footer";
import PSGOLD from "../../assets/PSGOLD.png";
import PSINT from "../../assets/PSINT.png";
import PSPLAT from "../../assets/PSPLAT.png";
import PSMBLACK from "../../assets/PSMBLACK.png";
import PSVINF from "../../assets/PSVINF.png";
import PortoSeguro1 from "../../assets/PortoSeguro1.png";
import useLoader from "../../hooks/useLoader";
import Preloader from "../../components/Preloader";

const divGridStyle = {
	height: "100%",
	width: "100%",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	textAlign: "left",
};

const typographyGridStyle = {
	fontSize: "0.9rem",
	color: "var(--textGray)",
	lineHeight: "1.5rem",
	mb: "30px",
};

export default function PortoSeguroExt() {
	const { width } = useWindowDimensions();
	const loading = useLoader();

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && <Preloader />}
			<Container
				sx={{
					width: "100vw",
					height: "100%",
					mt: width < 720 ? "150px" : "200px",
					mb: "40px",
				}}
			>
				<Typography variant="h5" align="center">
					Cartão de Crédito Porto Seguro Bank
				</Typography>
				<Typography
					align="center"
					sx={{
						fontSize: "1.1rem",
						fontWeight: "600",
						mt: "10px",
					}}
				>
					Muita facilidade e economia para o seu dia a dia!
				</Typography>
				<Box
					sx={{
						width: "100%",
						height: "auto",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						mt: "30px",
					}}
				>
					<img
						alt="porto seguro cards"
						src={PortoSeguro1}
						loading="lazy"
						style={{
							width: "80%",
							height: "100%",
							maxWidth: "450px",
						}}
					/>
				</Box>
			</Container>
			<Divider variant="middle" />
			<Container
				sx={{
					display: width < 720 ? "flex" : "none",
					alignItems: "center",
					justifyContent: "center",
					mt: "100px",
					mb: "100px",
				}}
			>
				<div id="cartoes.informacash_top_mob"></div>
			</Container>
			<Container
				sx={{
					display: width > 720 ? "flex" : "none",
					alignItems: "center",
					justifyContent: "center",
					mt: "100px",
					mb: "100px",
				}}
			>
				<div id="cartoes.informacash_top_desk"></div>
			</Container>
			<Container
				sx={{
					width: "100vw",
					height: "100%",
					mt: "70px",
					mb: "30px",
					pl: "7%",
					pr: "4%",
				}}
			>
				<Typography
					variant="h5"
					align="left"
					sx={{
						lineHeight: "2rem",
						fontWeight: "700",
						mb: "5px",
					}}
				>
					Por que escolher o Cartão de Crédito Porto Seguro Bank?
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "1.5rem",
						fontWeight: "400",
						mb: "20px",
					}}
				>
					Entre os principais benefícios do cartão, estão:
					<br />
				</Typography>

				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Aplicativo Porto Seguro: onde é possível realizar diversas
					consultas e solicitar serviços com muita praticidade.
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					PortoPlus: o programa onde os pontos acumulados no cartão
					podem ser trocados por descontos em viagens, serviços para
					sua casa e carro, além de produtos de lojas parceiras e
					milhas aéreas.
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Descontos na contratação e na franquia do Seguro Auto: ao
					contratar ou renovar o Seguro Auto da Porto Seguros e Azul
					Seguro Auto, cliente do Cartão de Crédito Porto Seguro Bank
					conta com 5% de desconto e parcelamento em até 12x sem
					juros.
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Período de teste: ao solicitar o Cartão de Crédito Porto
					Seguro, você conta com 1 ano ou 6 meses de isenção de
					anuidade para conhecer e aproveitar todos os benefícios e
					vantagens que ele oferece.
				</Typography>
				<Container
					sx={{
						display: width < 720 ? "flex" : "none",
						alignItems: "center",
						justifyContent: "center",
						mt: "100px",
						mb: "100px",
					}}
				>
					<div
						class="pg-lazy"
						data-gpt-parent="cartoes.informacash_mid_mob"
					></div>
				</Container>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Com os cartões Pão de Açúcar Itaucard é possível comprar no
					exterior sem pagar a ágio/spread – tarifa cobrada pelos
					bancos que deixam suas compras até 7% mais caras.
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Crediário do Cartão Porto Seguro Bank: escolha onde e quando
					parcelar suas compras. Com o crediário, os clientes podem
					parcelar as compras diretamente pela maquininha, utilizando
					o limite de crédito do Cartão Porto Seguro.
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mt: "30px",
					}}
				>
					Clique no botão para obter o seu cartão e comece a controlar
					os seus gastos de uma forma simples, econômica e cheia de
					benefícios!
				</Typography>
			</Container>
			<Container
				sx={{
					width: "100vw",
					height: "100%",
					mt: "40px",
					mb: "30px",
					pl: "7%",
					pr: "4%",
				}}
			>
				<Typography
					variant="h5"
					align="left"
					sx={{
						lineHeight: "2rem",
						fontWeight: "700",
						mb: "10px",
					}}
				>
					Qual a melhor escolha para o seu perfil?
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mt: "10px",
						mb: "10px",
					}}
				>
					Para saber qual versão do cartão combina com você, é
					necessário comparar e analisar qual a relação entre
					anuidade, renda mínima e benefícios exigida se encaixa com a
					sua realidade. Entre os Cartões de Crédito Porto Seguro,
					estão:
					<br />
				</Typography>
				<Container
					sx={{
						display: width < 720 ? "flex" : "none",
						alignItems: "center",
						justifyContent: "center",
						mt: "100px",
						mb: "100px",
					}}
				>
					<div
						class="pg-lazy"
						data-gpt-parent="cartoes.informacash_mid_mob"
					></div>
				</Container>
				<Container
					sx={{
						display: width > 720 ? "flex" : "none",
						alignItems: "center",
						justifyContent: "center",
						mt: "100px",
						mb: "100px",
					}}
				>
					<div
						class="pg-lazy"
						data-gpt-parent="cartoes.informacash_mid_desk"
					></div>
				</Container>
				<Container sx={{ width: "90%", mb: "20px", mt: "30px" }}>
					<Grid container spacing={2} alignItems="stretch">
						<Grid item xs={12} sm={6}>
							<Box
								sx={{
									width: "100%",
									height: "100%",
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<img
									alt="Porto Seguro Internacional Card"
									src={PSINT}
									loading="lazy"
									style={{
										width: "80%",
										height: "100%",
										maxWidth: "350px",
										marginBottom: "20px",
									}}
								/>
								<Typography variant="h6" align="center">
									<b>Internacional</b>
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6}>
							<div style={divGridStyle}>
								<Typography
									variant="p"
									align="left"
									sx={typographyGridStyle}
								>
									<b>Anuidade no 1º ano:</b> gratuita
									<br />
									<b> Renda Mínima:</b> R$ 1.000
									<br />
									<b>Benefícios:</b> PortoPlus, acesso ao
									aplicativo a às carteiras digitais, cartão
									adicional sem anuidade
									<br />
									<b>Pontuação:</b> US$ 1 = 1 ponto
									<br />
									<b>Cobertura:</b> internacional
								</Typography>
							</div>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Box
								sx={{
									width: "100%",
									height: "100%",
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<img
									alt="Porto Seguro Gold Card"
									src={PSGOLD}
									loading="lazy"
									style={{
										width: "80%",
										height: "100%",
										maxWidth: "350px",
										marginBottom: "20px",
									}}
								/>
								<Typography variant="h6" align="center">
									<b>Gold</b>
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6}>
							<div style={divGridStyle}>
								<Typography
									variant="p"
									align="left"
									sx={typographyGridStyle}
								>
									<b>Anuidade no 1º ano:</b> gratuita
									<br />
									<b> Renda Mínima:</b> R$ 5.000
									<br />
									<b>Benefícios:</b> PortoPlus, acesso ao
									aplicativo a às carteiras digitais, cartão
									adicional sem anuidade, proteção de compra
									para produtos adquiridos com o cartão
									<br />
									<b>Pontuação:</b> US$ 1 = 1 ponto
									<br />
									<b>Cobertura:</b> internacional
								</Typography>
							</div>
						</Grid>
						<Container
							sx={{
								display: width < 720 ? "flex" : "none",
								alignItems: "center",
								justifyContent: "center",
								mt: "100px",
								mb: "100px",
							}}
						>
							<div
								class="pg-lazy"
								data-gpt-parent="cartoes.informacash_mid_mob"
							></div>
						</Container>
						<Grid item xs={12} sm={6}>
							<Box
								sx={{
									width: "100%",
									height: "100%",
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<img
									alt="Porto Seguro Platinum Card"
									src={PSPLAT}
									loading="lazy"
									style={{
										width: "80%",
										height: "100%",
										maxWidth: "350px",
										marginBottom: "20px",
									}}
								/>
								<Typography variant="h6" align="center">
									<b>Platinum</b>
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6}>
							<div style={divGridStyle}>
								<Typography
									variant="p"
									align="left"
									sx={typographyGridStyle}
								>
									<b>Anuidade no 1º ano:</b> gratuita
									<br />
									<b> Renda Mínima:</b> R$ 10.000
									<br />
									<b>Benefícios:</b> PortoPlus, acesso ao
									aplicativo a às carteiras digitais, cartão
									adicional sem anuidade, seguro viagem
									gratuito, desconto na sala VIP Latitude
									<br />
									<b>Pontuação:</b> US$ 1 = 1,5 ponto
									<b>Cobertura:</b> internacional
								</Typography>
							</div>
						</Grid>
						<Container
							sx={{
								display: width > 720 ? "flex" : "none",
								alignItems: "center",
								justifyContent: "center",
								mt: "100px",
								mb: "100px",
							}}
						>
							<div
								class="pg-lazy"
								data-gpt-parent="cartoes.informacash_mid_desk"
							></div>
						</Container>
						<Grid item xs={12} sm={6}>
							<Box
								sx={{
									width: "100%",
									height: "100%",
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<img
									alt="Porto Seguro MasterCard Black"
									src={PSMBLACK}
									loading="lazy"
									style={{
										width: "80%",
										height: "100%",
										maxWidth: "350px",
										marginBottom: "20px",
									}}
								/>
								<Typography variant="h6" align="center">
									<b>Mastercard Black</b>
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6}>
							<div style={divGridStyle}>
								<Typography
									variant="p"
									align="left"
									sx={typographyGridStyle}
								>
									<b> Renda Mínima:</b> Associação mediante
									convite
									<br />
									<b>Benefícios:</b> PortoPlus, acesso ao
									aplicativo a às carteiras digitais, cartão
									adicional sem anuidade, seguro viagem
									gratuito, seguros e benefícios para as
									viagens, 10 acessos gratuitos por ano em
									mais de 2 mil salas VIP
									<br />
									<b>Pontuação:</b> US$ 1 = até 3 pontos
									<br />
									<b>Cobertura:</b> internacional
								</Typography>
							</div>
						</Grid>
						<Container
							sx={{
								display: width < 720 ? "flex" : "none",
								alignItems: "center",
								justifyContent: "center",
								mt: "100px",
								mb: "100px",
							}}
						>
							<div
								class="pg-lazy"
								data-gpt-parent="cartoes.informacash_mid_mob"
							></div>
						</Container>
						<Grid item xs={12} sm={6}>
							<Box
								sx={{
									width: "100%",
									height: "100%",
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<img
									alt="Porto Seguro Visa Infinite Card"
									src={PSVINF}
									loading="lazy"
									style={{
										width: "80%",
										height: "100%",
										maxWidth: "350px",
										marginBottom: "20px",
									}}
								/>
								<Typography variant="h6" align="center">
									<b>Porto Seguro Visa Infinite</b>
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6}>
							<div style={divGridStyle}>
								<Typography
									variant="p"
									align="left"
									sx={typographyGridStyle}
								>
									<b> Renda Mínima:</b> Associação mediante
									convite
									<br />
									<b>Benefícios:</b> PortoPlus, acesso ao
									aplicativo a às carteiras digitais, cartão
									adicional sem anuidade, seguro viagem
									gratuito, seguros e benefícios para as
									viagens, 10 acessos gratuitos por ano em
									mais de 2 mil salas VIP
									<br />
									<b>Pontuação:</b> US$ 1 = até 3 pontos
									<br />
									<b>Cobertura:</b> internacional
								</Typography>
							</div>
						</Grid>
					</Grid>
				</Container>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2.1rem",
						mb: "20px",
						fontWeight: "400",
					}}
				>
					Solicite já o Cartão de Crédito Porto Seguro Bank que mais
					combina com você!
				</Typography>
				<Typography
					align="left"
					variant="h5"
					sx={{
						lineHeight: "2.1rem",
						mb: "10px",
						fontWeight: "700",
					}}
				>
					Como solicitar o seu Cartão de Crédito Porto Seguro Bank?
				</Typography>
				<List
					sx={{
						listStyleType: "decimal",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<ListItem
						sx={{
							justifyContent: "left",
							pb: "0 !important",
							pt: "0 !important",
							pl: "20px",
							mb: "5px",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "1rem",
								display: "list-item",
							}}
						>
							Clique em <b>solicitar agora</b>
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "left",
							pb: "0 !important",
							pt: "0 !important",
							pl: "20px",
							mb: "5px",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "1rem",
								display: "list-item",
							}}
						>
							Preencha os seus dados
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "left",
							pb: "0 !important",
							pt: "0 !important",
							pl: "20px",
							mb: "5px",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "1rem",
								display: "list-item",
							}}
						>
							Aguarde a confirmação do banco
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "left",
							pb: "0 !important",
							pt: "0 !important",
							pl: "20px",
							mb: "5px",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "1rem",
								display: "list-item",
							}}
						>
							Receba o seu Cartão de Crédito Porto Seguro Bank em
							casa
						</Typography>
					</ListItem>
				</List>
			</Container>
			<Container
				sx={{
					width: "100%",
					height: "100%",
					mb: "50px",
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Button
					variant="contained"
					size="large"
					disableElevation
					sx={{
						fontWeight: "700",
						backgroundColor: "var(--infoCashBlue)",
					}}
					href="https://www.portoseguro.com.br/cartao-de-credito/dicas-de-seguranca"
				>
					Solicitar agora
				</Button>
			</Container>
			<ResponsiveFooter />
		</Box>
	);
}
