import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";

export default function Post22() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const titleStyleObj = {
		fontSize: width > 720 ? "1.9em" : "1.6em",
		fontWeight: "700",
		mb: "calc(0.2em)",
		mt: "calc(13px + 1.2em)",
		lineHeight: "1.5",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "#111",
	};

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			<Container
				sx={{
					width: "100%",
					height: "30%",
					paddingTop: width < 720 ? "150px" : "200px",
					paddingBottom: width < 720 ? "50px" : "100px",
					paddingLeft: width < 720 ? "15px" : "auto",
					paddingRight: width < 720 ? "15px" : "auto",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					backgroundColor: "#161616",
					maxWidth: "none !important",
				}}
			>
				<Typography
					align="center"
					sx={{
						fontSize: width < 720 ? "1.5rem" : "2.5rem",
						lineHeight: width < 720 ? "2rem" : "3rem",
						textAlign: "center",
						maxWidth: "700px",
						fontWeight: "700",
						color: "#FFF",
						padding: "20px",
					}}
				>
					Redução na conta de energia cai em média para R$21, segundo
					a FGV
				</Typography>
			</Container>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
					mt: "100px",
					mb: "200px",
					backgroundColor: "#FFF",
				}}
			>
				<Container
					sx={{
						maxWidth: "770px !important",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "flex-start",
						paddingLeft: width < 720 ? "22px" : "auto",
						paddingRight: width < 720 ? "22px" : "auto",
					}}
				>
					<Typography sx={textStyleObj}>
						Segundo a Fundação Getúlio Vargas (FGV), a conta de
						energia cai em média para R$21, diminuindo assim, a
						inflação de maio. A queda prevista é menor do que os 20%
						anunciados pelo governo. Entretanto, os economistas
						relatam que a expectativa é que ocorra uma redução de
						serviços e produtos industrializados.
					</Typography>
					<Typography sx={textStyleObj}>
						Diante do início da bandeira verde, que começou no
						último sábado (16), a conta de energia residencial da
						população brasileira pode reduzir em torno de R$21 ao
						final do mês, iniciando em maio. O valor previsto, em
						porcentagem, equivale entre 10% a 12% a menos na
						cobrança mensal.
					</Typography>
					<Typography sx={textStyleObj}>
						Segundo o economista Alberto Ajzental, os dados
						previstos foram calculados de acordo com o consumo médio
						mensal dos brasileiros, equivalente a 150KWh. Além
						disso, até o dia 15 de abril, a bandeira de escassez
						hídrica, a mais cara de todas as tarifas, acrescentava
						R$ 14,20 a cada 100 KWh consumidos.
					</Typography>
					<Typography sx={titleStyleObj}>
						Contexto das tarifas extras na conta de energia
					</Typography>
					<Typography sx={textStyleObj}>
						Para contexto histórico, a bandeira de escassez hídrica
						entrou em vigor em agosto de 2021, durante o período de
						seca. Na prática, ela representou um aumento nas contas
						de energia dos consumidores, ainda mais após a crise
						financeira do país.
					</Typography>
					<Typography sx={textStyleObj}>
						Durante o evento, o ministro da economia ressaltou que o
						governo está mudando o contexto sobre o meio ambiente, a
						nível internacional. Além disso, ele afirma que o Brasil
						está tendo um papel importante para garantir a segurança
						energética em escala global. O ministro se refere ao fim
						da bandeira de escassez hídrica desde setembro de 2021
					</Typography>
					<Typography sx={textStyleObj}>
						Apesar dessa afirmação, com redução em cerca de 20% com
						essa medida, muitos especialistas alertam que a queda de
						taxas será diluída com os reajustes tarifários das
						distribuidoras, ainda não definidos neste ano.
					</Typography>
					<Typography sx={titleStyleObj}>
						Queda da inflação
					</Typography>
					<Typography sx={textStyleObj}>
						Diante do fim da tarifa extra, a expectativa é que a
						conta de energia gere impactos mais positivos para a
						inflação. Segundo o economista André Braz, da FGV, o fim
						da bandeira pode ter um recuo de 0,3% no IPCA (Índice de
						preços ao consumidor) de abril. Entretanto, segundo
						Ajzental, a projeção é de 0,6% na inflação.
					</Typography>
					<Typography sx={textStyleObj}>
						Além disso, a energia elétrica residencial faz parte de
						um subitem de habitação e hoje em dia, possui um peso de
						4,95% no IPCA, contribuindo para elevar a inflação. A
						expectativa é que ocorra um alívio para prestadores de
						serviços e também para a indústria.
					</Typography>
					<Typography sx={textStyleObj}>
						Segundo os economistas, a inflação de bens duráveis, a
						exemplo da televisão, fogão, eletrodomésticos,
						computadores e carros, tem relação direta com os custos
						do setor industrial, que gerou aumento em função do
						elevado preço da energia.
					</Typography>
					<Typography sx={textStyleObj}>
						“A primeira coisa é que essa queda na tarifa trará um
						impacto relevante para as famílias. Mas, a longo prazo e
						de forma indireta, a queda na conta de luz vai atingir a
						atividade industrial que se refletirá nos bens duráveis
						e nos prestadores de serviços. Se a energia fica mais
						barata, o setor industrial tem uma pressão de custos
						menor”, explicou Braz.
					</Typography>
					<Typography sx={titleStyleObj}>
						Alívio para o brasileiro
					</Typography>
					<Typography sx={textStyleObj}>
						Nos últimos 12 meses, a energia elétrica obteve alta de
						28,52%. O gás de cozinha, por exemplo, aumentou 29,56%
						no acumulado, segundo os dados da FGV.
					</Typography>
					<Typography sx={textStyleObj}>
						Esse tipo de custo faz parte do grupo “habitação” no
						IPCA e foi responsável pela maior pressão inflacionária
						para as famílias de renda mais baixa no Brasil.
					</Typography>
					<Typography sx={textStyleObj}>
						Segundo os dados, nos últimos 10 anos a energia elétrica
						vem sendo crucial nas famílias brasileiras. Dessa forma,
						o IPCA cresceu em torno de 6%, e a eletricidade aumentou
						a 8,5% ao ano. Ou seja, caso não aconteçam os reajustes
						no custo do quilowatt-hora (KWh), a tendência é de que
						aconteça um alívio para as famílias em relação às contas
						de energia.
					</Typography>
				</Container>
			</Box>
			<ResponsiveFooter />
		</Box>
	);
}
