import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";

export default function Post28() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const titleStyleObj = {
		fontSize: width > 720 ? "1.9em" : "1.6em",
		fontWeight: "700",
		mb: "calc(0.2em)",
		mt: "calc(13px + 1.2em)",
		lineHeight: "1.5",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "#111",
	};

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			{!loading && (
				<React.Fragment>
					<Container
						sx={{
							width: "100%",
							height: "30%",
							paddingTop: width < 720 ? "150px" : "200px",
							paddingBottom: width < 720 ? "50px" : "100px",
							paddingLeft: width < 720 ? "15px" : "auto",
							paddingRight: width < 720 ? "15px" : "auto",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							backgroundColor: "#161616",
							maxWidth: "none !important",
						}}
					>
						<Typography
							align="center"
							sx={{
								fontSize: width < 720 ? "1.5rem" : "2.5rem",
								lineHeight: width < 720 ? "2rem" : "3rem",
								textAlign: "center",
								maxWidth: "700px",
								fontWeight: "700",
								color: "#FFF",
								padding: "20px",
							}}
						>
							Tecnologia: Mineração de Bitcoin agora alimentada
							por energia solar
						</Typography>
					</Container>
					<Box
						sx={{
							width: "100%",
							height: "100%",
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
							mt: "100px",
							mb: "200px",
							backgroundColor: "#FFF",
						}}
					>
						<Container
							sx={{
								maxWidth: "770px !important",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "flex-start",
								paddingLeft: width < 720 ? "22px" : "auto",
								paddingRight: width < 720 ? "22px" : "auto",
							}}
						>
							<Typography sx={textStyleObj}>
								<p>
									Diante da tecnologia se reinventando a cada
									dia, as empresas BlockStream e Block
									anunciaram na última sexta-feira (08), uma
									mineradora de criptomoedas que será
									alimentada por energia solar e por baterias
									da Tesla. O projeto tem por principal
									objetivo mostrar que a mineração de Bitcoin
									(BTC) pode ser feita 100% com energia
									renovável.
								</p>
								<br />
								<p>
									Os empresários Adam Back e Jack Dorse,
									representando a Blockstream e a Block,
									respectivamente, estão construindo uma
									mineradora de criptomoedas piloto no Texas,
									localizada nos EUA. Adam Back anunciou o
									projeto na Conferência Bitcon 2022,
									realizada em Miami.
								</p>
								<br />
								<p>
									Além disso, o empresário ressaltou que
									construir coisas é a melhor solução para
									discutir esses projetos. O piloto pode
									mostrar que a mineração de Bitcoin consegue
									financiar instalações de energia renovável.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								Bitcon em energia solar?
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									A empresa Block, antiga Square e a
									Blockstream, fizeram uma conexão e acordo
									ainda em junho de 2021. Durante esse acordo,
									a empresa de Jack Dorsey anunciou que
									investiria US$ 5 milhões em uma mineradora
									movida a energia solar, juntamente com os
									seus novos parceiros. O custo do projeto até
									então, já totaliza US$ 12 milhões e será
									dividido igualmente entre a Blockstream e a
									Block.
								</p>
								<br />
								<p>
									Segundo as informações operacionais e
									financeiras em tempo real sobre a
									mineradora, incluindo seu consumo de energia
									e hashrate, ficarão disponíveis publicamente
									em um painel, afirmam os empresários. Dessa
									forma, o objetivo é que os dados sejam
									usados para ressaltar o debate público sobre
									a mineração fora da rede (off-grid), e
									mostrem se a atividade pode financiar a
									expansão da energia solar.
								</p>
								<br />
								<p>
									“Nós atingimos um número de pessoas
									semelhantes ao do início da pandemia, mas as
									causas são diferentes”, disse Patrícia
									Camillo, gerente da Serasa.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								Sustentabilidade nas empresas
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									O projeto dos empresários é desenvolvimento
									tanto tecnológico, quanto sustentável. A
									nível do mercado financeiro, mostrar os
									dados publicamente e de forma off-grid faz
									com que a expansão de energia solar e
									criptomoedas entrem em trends topics.
								</p>
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Além disso, o projeto será completamente
									fora da rede, e terá um painel solar da
									Tesla de 3,8 MW alimentando a mineradora,
									bem como baterias de 12 megawatts-hora (MWh)
									da montadora armazenando o excesso de
									eletricidade produzida durante o dia para
									que o local possa funcionar à noite e
									durante os dias sem sol.
								</p>
								<br />
								<p>
									Contudo, a mineração de Bitcoin está sob
									ataque contínuo por sua suposta pegada de
									carbono. Na última semana, Greenpeace,
									Environmental Working Group e Chris Larsen,
									da Ripple, lançaram uma campanha para mudar
									o modelo de prova de trabalho
									(proof-of-work, ou PoW) da criptomoeda, que
									requer muita energia para funcionar.
								</p>
								<br />
								<p>
									Esse projeto pode atuar como um consumidor
									básico de eletricidade, fazendo com que os
									projetos de energia renovável se torne
									financeiramente viáveis e permitindo mais
									investimentos em ativos dessa área. Ou seja,
									revolucionário.
								</p>
							</Typography>
						</Container>
					</Box>
					<ResponsiveFooter />
				</React.Fragment>
			)}
		</Box>
	);
}
