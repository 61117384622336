import {
	Box,
	Typography,
	Container,
	Divider,
	Button,
	Grid,
	List,
	ListItem,
} from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../components/AppBar";
import ResponsiveFooter from "../../components/Footer";
import useWindowDimensions from "../../hooks/windowDimension";
import { useLocation, useNavigate } from "react-router-dom";
import itau_uniclass from "../../assets/itau_uni.png";
import useLoader from "../../hooks/useLoader";
import Preloader from "../../components/Preloader";

const divGridStyle = {
	height: "80px",
	width: "100%",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	backgroundColor: "var(--bgGray)",
	borderRadius: "5px",
	textAlign: "center",
	padding: "0 15px",
};

const typographyGridStyle = {
	fontSize: "1.1rem",
	fontWeight: "bold",
	color: "var(--textGray)",
};

export default function ItauUniclass() {
	const { width } = useWindowDimensions();
	const loading = useLoader();
	const { search } = useLocation();

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && <Preloader />}

			<Container
				sx={{
					width: "100vw",
					height: "100%",
					mt: width < 720 ? "150px" : "200px",
					mb: "40px",
				}}
			>
				<Typography variant="h5" align="center">
					Cartão de Crédito Uniclass Visa Signature
				</Typography>
				<Typography
					align="center"
					sx={{
						fontSize: "1.1rem",
						fontWeight: "600",
						mt: "10px",
					}}
				>
					O cartão sem anuidade que facilita o seu dia a dia e está ao
					seu lado em suas conquistas.
				</Typography>
				<Box
					sx={{
						width: "100%",
						height: "auto",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						mt: "30px",
					}}
				>
					<img
						alt="itau_uniclass"
						src={itau_uniclass}
						loading="lazy"
						style={{
							width: "80%",
							height: "100%",
							maxWidth: "450px",
						}}
					/>
				</Box>
			</Container>
			<Divider variant="middle" />
			<Container
				sx={{
					display: width < 720 ? "flex" : "none",
					alignItems: "center",
					justifyContent: "center",
					mt: "100px",
					mb: "100px",
				}}
			>
				<div id="cartoes.informacash_top_mob"></div>
			</Container>
			<Container
				sx={{
					display: width > 720 ? "flex" : "none",
					alignItems: "center",
					justifyContent: "center",
					mt: "100px",
					mb: "100px",
				}}
			>
				<div id="cartoes.informacash_top_desk"></div>
			</Container>
			<Container
				sx={{
					width: "100vw",
					height: "100%",
					mt: "100px",
					mb: "80px",
					pl: "7%",
					pr: "4%",
				}}
			>
				<Typography
					variant="p"
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						color: "var(--textGray)",
					}}
				>
					O cartão de crédito Uniclass Visa Signature foi feito para
					facilitar suas conquistas! Ele possui anuidade gratuita,
					para você se preocupar apenas em aproveitar os benefícios.
					Também conta com seguros e assistências, descontos com lojas
					parceiras e facilidades para pagamentos de boletos
					diretamente com o cartão! Tudo isso com até 3 cartões
					adicionais gratuitos!
				</Typography>
			</Container>
			<Container
				sx={{
					width: "100%",
					height: "auto",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					maxWidth: "810px",
				}}
			>
				<Button
					variant="contained"
					size="large"
					disableElevation
					sx={{
						fontWeight: "700",
						backgroundColor: "var(--infoCashBlue)",
					}}
					href={
						window.location.hostname !== "localhost"
							? `https://cartoes.informacash.com.br/itau-uniclass-solicitar${search}`
							: `./itau-uniclass-solicitar`
					}
				>
					Quero saber mais
				</Button>
			</Container>
			<Container
				sx={{
					width: "100%",
					height: "100%",
					mt: "80px",
					mb: "40px",
					pl: "7%",
					pr: "4%",
				}}
			>
				<Typography
					variant="h5"
					align="center"
					sx={{
						lineHeight: "2rem",
						fontWeight: "700",
						mb: "10px",
					}}
				>
					Tipos de Conta Itaú Uniclass
				</Typography>
				<Divider variant="middle" sx={{ mb: "20px" }} />
				<Typography
					variant="p"
					align="left"
					sx={{
						fontSize: "1rem",
						lineHeight: "1.5rem",
						fontWeight: "400",
						color: "var(--textGray)",
					}}
				>
					Para obter o cartão de crédito Uniclass Visa Signature e
					aproveitar todos os seus benefícios é necessário abrir uma
					conta Itaú Uniclass. Confira as opções de conta e veja o que
					faz sentido para você.
				</Typography>
				<Typography
					align="left"
					sx={{
						mt: "20px",
						fontSize: "1.2rem",
						fontWeight: "700",
					}}
				>
					Itaú Uniclass 3.0
				</Typography>
				<List
					sx={{
						listStyleType: "disc",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							Pacote mais básico
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							Tarifa mensal de R$ 61,65
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							Saques ilimitados; 30 folhas de cheque; 4 DOC/TED
						</Typography>
					</ListItem>
				</List>
				<Typography
					align="left"
					sx={{
						mt: "20px",
						fontSize: "1.2rem",
						fontWeight: "700",
					}}
				>
					Itaú Uniclass 4.0
				</Typography>
				<List
					sx={{
						listStyleType: "disc",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							Pacote intermediário
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							Tarifa mensal de R$ 76,90
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							Saques ilimitados; 40 folhas de cheque; 7 DOC/TED
						</Typography>
					</ListItem>
				</List>
			</Container>
			<Container
				sx={{
					display: width < 720 ? "flex" : "none",
					alignItems: "center",
					justifyContent: "center",
					mt: "100px",
					mb: "100px",
				}}
			>
				<div id="cartoes.informacash_mid_mob"></div>
			</Container>
			<Container
				sx={{
					display: width > 720 ? "flex" : "none",
					alignItems: "center",
					justifyContent: "center",
					mt: "100px",
					mb: "100px",
				}}
			>
				<div id="cartoes.informacash_mid_desk"></div>
			</Container>
			<Container sx={{ width: "90%", mb: "40px", mt: "30px" }}>
				<Grid container spacing={2} alignItems="stretch">
					<Grid item xs={12} sm={4}>
						<div style={divGridStyle}>
							<Typography
								variant="p"
								align="center"
								sx={typographyGridStyle}
							>
								Anuidade gratuita e renda mínima de R$ 5 mil
							</Typography>
						</div>
					</Grid>
					<Grid item xs={12} sm={4}>
						<div style={divGridStyle}>
							<Typography
								variant="p"
								align="center"
								sx={typographyGridStyle}
							>
								Tag Itaú: estacionamentos e pedágios sem filas
							</Typography>
						</div>
					</Grid>
					<Grid item xs={12} sm={4}>
						<div style={divGridStyle}>
							<Typography
								variant="p"
								align="center"
								sx={typographyGridStyle}
							>
								Pague por aproximação, pelo cartão ou celular
							</Typography>
						</div>
					</Grid>
					<Grid item xs={12} sm={4}>
						<div style={divGridStyle}>
							<Typography
								variant="p"
								align="center"
								sx={typographyGridStyle}
							>
								Disponível para todas as carteiras digitais
							</Typography>
						</div>
					</Grid>
					<Grid item xs={12} sm={4}>
						<div style={divGridStyle}>
							<Typography
								variant="p"
								align="center"
								sx={typographyGridStyle}
							>
								Até 3 cartões adicionais gratuitos
							</Typography>
						</div>
					</Grid>
				</Grid>
			</Container>
			<Container
				sx={{
					width: "100%",
					height: "auto",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					maxWidth: "810px",
					mb: "20%",
				}}
			>
				<Button
					variant="contained"
					size="large"
					disableElevation
					sx={{
						fontWeight: "700",
						backgroundColor: "var(--infoCashBlue)",
					}}
					href={
						window.location.hostname !== "localhost"
							? `https://cartoes.informacash.com.br/itau-uniclass-solicitar${search}`
							: `./itau-uniclass-solicitar`
					}
				>
					Quero solicitar meu cartão
				</Button>
			</Container>
			<ResponsiveFooter />
		</Box>
	);
}
