import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";

export default function Post37() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const titleStyleObj = {
		fontSize: width > 720 ? "1.9em" : "1.6em",
		fontWeight: "700",
		mb: "calc(0.2em)",
		mt: "calc(13px + 1.2em)",
		lineHeight: "1.5",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "#111",
	};

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			{!loading && (
				<React.Fragment>
					<Container
						sx={{
							width: "100%",
							height: "30%",
							paddingTop: width < 720 ? "150px" : "200px",
							paddingBottom: width < 720 ? "50px" : "100px",
							paddingLeft: width < 720 ? "15px" : "auto",
							paddingRight: width < 720 ? "15px" : "auto",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							backgroundColor: "#161616",
							maxWidth: "none !important",
						}}
					>
						<Typography
							align="center"
							sx={{
								fontSize: width < 720 ? "1.5rem" : "2.5rem",
								lineHeight: width < 720 ? "2rem" : "3rem",
								textAlign: "center",
								maxWidth: "700px",
								fontWeight: "700",
								color: "#FFF",
								padding: "20px",
							}}
						>
							Receita prorroga prazo de entrega do Imposto de
							Renda
						</Typography>
					</Container>
					<Box
						sx={{
							width: "100%",
							height: "100%",
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
							mt: "100px",
							mb: "200px",
							backgroundColor: "#FFF",
						}}
					>
						<Container
							sx={{
								maxWidth: "770px !important",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "flex-start",
								paddingLeft: width < 720 ? "22px" : "auto",
								paddingRight: width < 720 ? "22px" : "auto",
							}}
						>
							<Typography sx={textStyleObj}>
								<p>
									A Receita Federal prorrogou até dia 31 de
									maio o prazo de entrega da declaração de
									ajuste anual do Imposto de Renda de 2022 –
									um mês a mais do prazo original previsto,
									dia 29 de abril. A resolução foi publicada
									nesta terça-feira (5), no Diário Oficial da
									União (DOU), e foi assinada por Julio Cesar
									Vieira Gomes, Secretário Especial da
									Receita.{" "}
								</p>
								<br />
								<p>
									A entrega da declaração do Imposto de Renda
									começou no dia 7 de março. Segundo a Receita
									Federal, até esta segunda (4), mais de 10
									milhões de declarações foram entregues. Quem
									não cumprir o prazo terá que pagar multa no
									valor de 1% ao mês em relação ao valor do
									imposto de renda devido.
								</p>
								<br />
								<p>
									Segundo Richard Domingos, diretor executivo
									da Confirp Contabilidade, é importante
									continuar acompanhando a situação da
									declaração do Imposto de Renda após a
									entrega. “O ideal é continuar consultando ao
									aplicativo da Receita Federal, o
									processamento da declaração”, aifirmou em
									entrevista à CNN Brasil.
								</p>
								<br />
								<p>
									“Se constatar inconsistência, o contribuinte
									pode explicar o motivo e fazer a retificação
									sem nenhum tipo de penalidade para corrigir
									a emissão de lançamento ou um feito
									incorretamente”, completou.
								</p>
								<br />
								<p>
									As restituições do Imposto de Renda para
									Pessoa Física 2022 serão feitas em cinco
									lotes:
								</p>
							</Typography>
							<Typography
								sx={{
									ml: "25px",
									lineHeight: "2rem",
									pr: "10px",
								}}
							>
								<ul>
									<li>31 de maio de 2022 </li>
									<li>20 de junho de 2022</li>
									<li>29 de julho de 2022</li>
									<li>31 de agosto de 2022</li>
									<li>30 de setembro de 2022</li>
								</ul>
							</Typography>
							<Typography sx={titleStyleObj}>
								Quem deve declarar
							</Typography>
							<Typography
								sx={{
									ml: "25px",
									lineHeight: "2rem",
									pr: "10px",
								}}
							>
								<ul>
									<li>
										Quem recebeu rendimentos tributáveis
										acima de R$ 28.559,70 em 2021. Lembrando
										que Auxílio Emergencial é considerado
										rendimento tributável;{" "}
									</li>
									<li>
										Contribuintes que receberam rendimentos
										isentos, não tributáveis ou tributados
										exclusivamente na fonte, cuja soma tenha
										sido superior a R$ 40 mil no ano
										passado;
									</li>
									<li>
										Quem obteve, em qualquer mês de 2021,
										ganho de capital na alienação de bens ou
										direitos, sujeito à incidência do
										imposto, ou realizou operações em bolsas
										de valores, de mercadorias, de futuros e
										assemelhadas;
									</li>
									<li>
										Quem teve, em 2021, receita bruta em
										valor superior a R$ 142.798,50 em
										atividade rural;
									</li>
									<li>
										Quem tinha, até 31 de dezembro de 2021,
										a posse ou a propriedade de bens ou
										direitos, inclusive terra nua, de valor
										total superior a R$ 300 mil;
									</li>
									<li>
										Quem passou para a condição de residente
										no Brasil em qualquer mês e se
										encontrava nessa condição até 31 de
										dezembro de 2021;
									</li>
									<li>
										Quem teve isenção de imposto sobre o
										ganho de capital na venda de imóveis
										residenciais, seguido de aquisição de
										outro imóvel residencial no prazo de 180
										dias.
									</li>
								</ul>
							</Typography>
							<Typography sx={titleStyleObj}>
								Como declarar
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									O programa para fazer a declaração deve ser
									baixado no site da Receita Federal e tem
									versões disponíveis para diferentes
									aparelhos. O preenchimento em celulares,
									porém, não pode ser feito em alguns casos:
									pessoas que tenham recebido rendimento
									tributável ou não superior a R$ 5 milhões em
									2021; do exterior; relativo à recuperação da
									parcela isenta da atividade rural ou
									correspondente a lucro em venda de imóvel
									residencial para aquisição de outro imóvel.
								</p>
								<br />
								<p>
									A declaração também pode ser feita online,
									acessando o portal e-Cac, na página “Meu
									Imposto de Renda”.
								</p>
								<br />
								<p>
									A declaração pré-preenchida ficará
									disponível, a partir do dia 15 de março,
									para qualquer pessoa que possua conta gov.br
									nos níveis ouro ou prata, e pode ser feita
									tanto pelo e-Cac como pelo programa, em um
									computador, celular ou tablet.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								Documentos necessários
							</Typography>
							<Typography
								sx={{
									ml: "25px",
									lineHeight: "2rem",
									pr: "10px",
								}}
							>
								<ul>
									<li>
										Informe de rendimentos do seu
										empregador, onde consta tudo que foi
										retido na fonte;
									</li>
									<li>CPF de todos os dependentes; </li>
									<li>
										Comprovante de renda de aposentados e
										pensionistas do INSS;
									</li>
									<li>
										Informe de investimentos do banco ou na
										corretora;
									</li>
									<li>
										Recibos de despesas com médicos,
										dentistas, profissionais de saúde
										(fisioterapia, psicologia) e planos de
										saúde. Devem conter informações
										detalhadas, como nome, endereço e CPF ou
										CNPJ do prestador, qual o serviço
										prestado, quem se beneficiou do serviço
										(com nome e CPF);
									</li>
									<li>
										Documentos de compra e venda de bens,
										que tenham preço do bem, valor de
										compra, de venda e algum valor que possa
										ter sido financiado;
									</li>
									<li>
										Prestações e mensalidade de escola ou
										cursos de pós-graduação que são sujeitos
										a deduções;
									</li>
									<li>
										Papéis de doações, consórcios,
										empréstimos e heranças;
									</li>
								</ul>
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Se possível, recupere a declaração do ano
									anterior, isso vai ajudar a preencher o
									documento deste ano.
								</p>
							</Typography>
						</Container>
					</Box>
					<ResponsiveFooter />
				</React.Fragment>
			)}
		</Box>
	);
}
