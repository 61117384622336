import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
:root{
  --green: #00854A;
  --greenCheck: #16C60C;
  --textGray: #494949;
  --grayFont: #636363;
  --bgGray: #F5F5F5;
  --menuGray: #8c8c8c;
  --appBarBlue: #100c2a;
  --infoCashBlue: #1AA3DA;
  --footerGray: #b4b4b4;
  --footerBlack: #161616;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
::-webkit-scrollbar {
  display: none;
}
body {
  background: #FFFFFF;
  color: #29292e;
}
body,
input,
button,
textarea {
  font: 500 16px "Roboto", sans-serif;
}
`;
