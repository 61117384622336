import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";

export default function Post27() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const titleStyleObj = {
		fontSize: width > 720 ? "1.9em" : "1.6em",
		fontWeight: "700",
		mb: "calc(0.2em)",
		mt: "calc(13px + 1.2em)",
		lineHeight: "1.5",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "#111",
	};

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			{!loading && (
				<React.Fragment>
					<Container
						sx={{
							width: "100%",
							height: "30%",
							paddingTop: width < 720 ? "150px" : "200px",
							paddingBottom: width < 720 ? "50px" : "100px",
							paddingLeft: width < 720 ? "15px" : "auto",
							paddingRight: width < 720 ? "15px" : "auto",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							backgroundColor: "#161616",
							maxWidth: "none !important",
						}}
					>
						<Typography
							align="center"
							sx={{
								fontSize: width < 720 ? "1.5rem" : "2.5rem",
								lineHeight: width < 720 ? "2rem" : "3rem",
								textAlign: "center",
								maxWidth: "700px",
								fontWeight: "700",
								color: "#FFF",
								padding: "20px",
							}}
						>
							Bancos norte-americanos ganham rentabilidade com
							dívida russa
						</Typography>
					</Container>
					<Box
						sx={{
							width: "100%",
							height: "100%",
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
							mt: "100px",
							mb: "200px",
							backgroundColor: "#FFF",
						}}
					>
						<Container
							sx={{
								maxWidth: "770px !important",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "flex-start",
								paddingLeft: width < 720 ? "22px" : "auto",
								paddingRight: width < 720 ? "22px" : "auto",
							}}
						>
							<Typography sx={textStyleObj}>
								<p>
									Diante do conflito da Rússia e da Ucrânia, o
									volume de negociação da dívida corporativa
									russa obteve um alto recorde de dois anos
									desde que o país invadiu o território
									ucraniano ainda este ano. Os bancos
									norte-americanos saíram da Rússia, mas isso
									não significa que eles deixaram de ganhar
									dinheiro com a invasão da Ucrânia por meio
									do Kremlin.
								</p>
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Essa dívida russa está diretamente associada
									à campanha do presidente russo, Vladimir
									Putin, e às sanções criaram uma janela para
									um novo tipo de arbitragem. A compra de
									títulos do governo russo ou corporativos
									muito baratos, juntamente com “swaps” de
									inadimplência, que atuam como um seguro.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								Como funciona esse tipo de negociação?
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Em resumo, a negociação é um pouco falha.
									Porém, os investidores institucionais
									procuram se livrar rapidamente de qualquer
									coisa relacionada à Rússia. Ou seja, os
									preços dos títulos caíram mais rápido do que
									o preço para protegê-los, que aumentou.
								</p>
								<br />
								<p>
									Além disso, de acordo com a Bloomberg News,
									a quantidade de negociação da dívida
									corporativa russa teve alta recorde de dois
									anos desde que a Rússia invadiu o território
									ucraniano.
								</p>
								<br />
								<p>
									Os dados do MarketAxess mostram que a dívida
									russa foi negociada em um volume de US$ 7
									bilhões entre 24 de fevereiro e 7 de abril,
									acima dos US$ 5 bilhões no mesmo período de
									2021, equivalendo um aumento de 35%
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								Sobre os títulos e dívida russa
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Economicamente, os títulos russos estão
									sendo negociados de forma bem agressiva.
								</p>
								<br />
								<p>
									Nichols destaca que está recebendo
									constantemente ligações de analistas
									interessados ​​em saber se a negociação em
									potencial faz sentido. Além disso, o custo
									para segurar a dívida russa cresceu para
									4.300 pontos-base em 5 de abril, acima dos
									2.800 do dia anterior.
								</p>
								<br />
								<p>
									Entretanto, as taxas dos títulos caíram
									bastante. Falando sobre títulos com
									vencimento em 2028, eles estão sendo
									negociados a apenas US$ 0,34 no dólar. Esse
									fato significa que pode custar pouco mais de
									US$ 4 milhões para garantir US$ 10 milhões
									em títulos russos, segundo o The Economist.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								Aumento do preço de títulos disparam
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Além de tudo isso, o preço dos títulos
									norte-americanos também disparam. Fundos de
									hedge, como Aurelius Capital Management,
									GoldenTree Asset Management e Silver Point
									Capital, aumentaram sua exposição aos
									mercados russos, principalmente comprando
									títulos corporativos, informou o Financial
									Times.
								</p>
								<br />
								<p>
									Além disso, instituições financeiras
									americanas como JPMorgan Chase e Goldman
									Sachs estão facilitando esses negócios,
									conectando clientes que querem deixar fundos
									de hedge que possuem maior tolerância ao
									risco e menos dilema moral sobre a compra de
									dívida russa.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								Atualizações sobre as sanções econômicas
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									A nível de atualizações, as novas sanções do
									Tesouro dos EUA é que bloquearam o acesso
									russo para qualquer dólar que eles
									detivessem em bancos americanos.
								</p>
								<br />
								<p>
									Além disso, o Congresso dos EUA votou para
									que removessem o status comercial da nação
									mais favorecida da Rússia, passando por um
									rebaixamento econômico que poderia abrir
									caminhos para sanções mais profundas e
									controles de importação de produtos
									essenciais para a Rússia, a exemplo de
									produtos químicos e aço.
								</p>
								<br />
								<p>
									A remoção desse status, disse Nichols,
									cortaria a integração da Rússia na economia
									global.
								</p>
							</Typography>
						</Container>
					</Box>
					<ResponsiveFooter />
				</React.Fragment>
			)}
		</Box>
	);
}
