import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";

export default function Post5() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const titleStyleObj = {
		fontSize: width > 720 ? "1.9em" : "1.6em",
		fontWeight: "700",
		mb: "calc(0.2em)",
		mt: "calc(13px + 1.2em)",
		lineHeight: "1.5",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "#111",
	};

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			<Container
				sx={{
					width: "100%",
					height: "30%",
					paddingTop: width < 720 ? "150px" : "200px",
					paddingBottom: width < 720 ? "50px" : "100px",
					paddingLeft: width < 720 ? "15px" : "auto",
					paddingRight: width < 720 ? "15px" : "auto",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					backgroundColor: "#161616",
					maxWidth: "none !important",
				}}
			>
				<Typography
					align="center"
					sx={{
						fontSize: width < 720 ? "1.5rem" : "2.5rem",
						lineHeight: width < 720 ? "2rem" : "3rem",
						textAlign: "center",
						maxWidth: "700px",
						fontWeight: "700",
						color: "#FFF",
						padding: "20px",
					}}
				>
					C6 Bank sofre golpe com prejuízo milionário
				</Typography>
			</Container>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
					mt: "100px",
					mb: "200px",
					backgroundColor: "#FFF",
				}}
			>
				<Container
					sx={{
						maxWidth: "770px !important",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "flex-start",
						paddingLeft: width < 720 ? "22px" : "auto",
						paddingRight: width < 720 ? "22px" : "auto",
					}}
				>
					<Typography sx={textStyleObj}>
						A Polícia Civil de São Paulo investiga o desvio de R$ 23
						milhões no C6 Bank, ocorrido por uma possível falha nos
						sistemas do banco.
					</Typography>
					<Typography sx={textStyleObj}>
						Segundo dados divulgados pela Veja, a fraude teria
						ocorrido no serviço CDB Crédito oferecida pela empresa,
						a partir de uma brecha que permitia usar o valor total
						disponibilizado junto do saque de dinheiro aplicado.
					</Typography>
					<Typography sx={textStyleObj}>
						Nessa modalidade o usuário tem como limite de crédito o
						mesmo valor que aplicou no CDB (Certificado de Depósito
						Bancário). Com limite máximo de R$ 10 mil concedido pelo
						C6, não importando se o valor aplicado for maior que
						isso.
					</Typography>
					<Typography sx={textStyleObj}>
						O serviço CDB Crédito bloqueia parte do valor investido
						pelo cliente até o pagamento da fatura – e foi nesse
						momento que ocorreu o desvio.
					</Typography>
					<Typography sx={textStyleObj}>
						Os cinco correntistas investigados pelo golpe, em sua
						maior parte moradores da Baixada Fluminense (RJ),
						acharam uma maneira de usar todo o limite do cartão de
						crédito e ainda resgatar todo o valor investido na renda
						fixa antes que ele fosse bloqueado como garantia do
						crédito.<b> No total, R$ 23 milhões foram desviados.</b>
					</Typography>
					<Typography sx={textStyleObj}>
						A reportagem da Veja afirma que a Polícia Civil
						investiga se o golpe foi orquestrado por organizações
						criminosas especializadas, já que a maior parte da
						atividade ocorreu em um ponto específico do Rio de
						Janeiro, ou se o problema foi descoberto por um morador
						do local que, em seguida, passou a informação para
						outras pessoas próximas.
					</Typography>
					<Typography sx={textStyleObj}>
						Ainda segundo dados da Veja, o C6 Bank está entrando na
						Justiça para bloquear a conta corrente de usuários
						associados com a fraude. A fintech disse que, por ora,
						não comentará o caso.
					</Typography>
					<Typography sx={titleStyleObj}>
						Outras fraudes no C6 Bank
					</Typography>
					<Typography sx={textStyleObj}>
						O desvio de R$ 23 milhões, porém, não é o primeiro golpe
						sofrido pelo C6 Bank. No começo de abril, o banco sofreu
						um prejuízo de R$ 50 milhões após usuários terem
						solicitado, em contas de cerca de 200 correntistas da
						instituição financeira, o aumento do limite do cheque
						especial para sacar valores, em um processo conhecido
						popularmente como “rachadinha”.
					</Typography>
					<Typography sx={textStyleObj}>
						A suspeita é que a fraude era chefiada por uma
						funcionária da Concentrix, empresa que administra e
						cuida do relacionamento entre os clientes e a
						instituição financeira, que convidou 200 clientes do C6
						Bank para participar do esquema.
					</Typography>
					<Typography sx={textStyleObj}>
						Esses clientes solicitavam um aumento do limite do
						cheque especial para o banco. Quem tinha a solicitação
						aceita realizava transferências para os outros
						participantes via PIX. Segundo reportagens da época, um
						dos envolvidos chegou a ter o limite aumentado para R$ 1
						milhão, com promessas de que receberia, em sua conta
						pessoal, R$ 100 mil após o fim do esquema.
					</Typography>
					<Typography sx={textStyleObj}>
						Na época do crime, a Polícia Civil de São Paulo
						investigava se os correntistas que tiveram as contas
						usadas na fraude eram laranjas ou se foram vítimas dos
						fraudadores. O C6 Banc também não comentou o ocorrido.
					</Typography>
				</Container>
			</Box>
			<ResponsiveFooter />
		</Box>
	);
}
