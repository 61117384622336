import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import logo_informacash from "../../assets/logo_informacash.png";
import { Button } from "@mui/material";

import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";

const pages = ["Cartões", "Blog"];

const ResponsiveAppBar = () => {
	const [anchorElNav, setAnchorElNav] = useState(null);
	const navigate = useNavigate();

	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	return (
		<AppBar
			position="static"
			sx={{
				boxShadow: "none",
				backgroundColor: "var(--appBarBlue)",
				minWidth: "auto",
				position: "fixed",
				zIndex: "100000000",
				top: "0",
			}}
		>
			<script src="//c.pubguru.net/pg.cartoes.informacash.js" async>
				{" "}
			</script>
			<Container maxWidth="xl">
				<Toolbar disableGutters>
					<Box
						sx={{
							width: "150px",
							height: "auto",
							mr: "10px",
							pb: "3px",
							display: {
								xs: "none",
								md: "flex",
								cursor: "pointer",
							},
						}}
						onClick={() => navigate("/cartoes-informacash")}
					>
						<img
							src={logo_informacash}
							alt="logo"
							style={{
								width: "100%",
								height: "100%",
							}}
						/>
					</Box>

					<Box
						sx={{
							display: { xs: "flex", md: "none" },
						}}
					>
						<IconButton
							size="large"
							aria-label="account of current user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={handleOpenNavMenu}
							color="inherit"
						>
							<MenuIcon />
						</IconButton>

						<Menu
							id="menu-appbar"
							anchorEl={anchorElNav}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "left",
							}}
							keepMounted
							transformOrigin={{
								vertical: "top",
								horizontal: "left",
							}}
							open={Boolean(anchorElNav)}
							onClose={handleCloseNavMenu}
							sx={{
								display: { xs: "block", md: "none" },
							}}
						>
							{pages.map((page) => (
								<MenuItem
									key={page}
									onClick={() => {
										page === "Cartões"
											? navigate("/cartoes-informacash")
											: navigate("/home-blog");
									}}
								>
									<Typography textAlign="center">
										{page}
									</Typography>
								</MenuItem>
							))}
						</Menu>
					</Box>
					<Box
						sx={{
							width: "150px",
							height: "auto",
							minWidth: "110px",
							display: {
								xs: "flex",
								md: "none",
								cursor: "pointer",
							},
						}}
						onClick={() => navigate("/cartoes-informacash")}
					>
						<img
							src={logo_informacash}
							alt="logo"
							style={{ width: "100%", height: "100%" }}
						/>
					</Box>
					<Box
						sx={{
							flexGrow: 1,
							display: { xs: "none", md: "flex" },
						}}
					>
						{pages.map((page) => (
							<Button
								key={page}
								onClick={() => {
									page === "Cartões"
										? navigate("/cartoes-informacash")
										: navigate("/home-blog");
								}}
								sx={{
									my: 2,
									color: "white",
									display: "block",
									fontWeight: "500",
								}}
							>
								{page}
							</Button>
						))}
					</Box>
				</Toolbar>
			</Container>
		</AppBar>
	);
};
export default ResponsiveAppBar;
