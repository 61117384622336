import { useState, useEffect } from "react";

export default function useLoader() {
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const checkIfAdsLoaded = () => {
			window.scrollTo(0, 5);
			document.body.style.overflow = "hidden";
			if (document.querySelector(".pg-loaded") != null) {
				setTimeout(() => {
					document.body.style.overflow = "visible";
					setLoading(false);
				}, 1200);
			} else {
				setTimeout(() => {
					checkIfAdsLoaded();
				}, 2000);
			}
		};

		checkIfAdsLoaded();
	}, [loading]);

	return loading;
}
