import {
	Box,
	Typography,
	Container,
	Divider,
	ListItem,
	List,
	Button,
	Grid,
} from "@mui/material";
import React from "react";
import ResponsiveAppBar from "../../components/AppBar";
import useWindowDimensions from "../../hooks/windowDimension";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ResponsiveFooter from "../../components/Footer";
import PaoDeAcucar1 from "../../assets/PaoDeAcucar1.png";
import PaoDeAcucarGold from "../../assets/PaoDeAcucarGold.png";
import PaoDeAcucarPlat from "../../assets/PaoDeAcucarPlat.png";
import PaoDeAcucarInt from "../../assets/PaoDeAcucarInt.png";
import Preloader from "../../components/Preloader";
import useLoader from "../../hooks/useLoader";

const divGridStyle = {
	height: "auto",
	width: "100%",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "flex-start",
	textAlign: "left",
};

const typographyGridStyle = {
	fontSize: "0.9rem",
	color: "var(--textGray)",
	lineHeight: "1.5rem",
	mb: "20px",
};

export default function PaoDeAcucarExt() {
	const { width } = useWindowDimensions();
	const loading = useLoader();

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && <Preloader />}
			<Container
				sx={{
					width: "100vw",
					height: "100%",
					mt: width < 720 ? "150px" : "200px",
					mb: "40px",
				}}
			>
				<Typography variant="h5" align="center">
					Acumule pontos com o cartão Pão de Açúcar Itaucard!
				</Typography>
				<Typography
					align="center"
					sx={{
						fontSize: "1.1rem",
						fontWeight: "600",
						mt: "10px",
					}}
				>
					Ganhe 1 ponto acumulado por apenas R$ 1 gasto!
				</Typography>
				<Box
					sx={{
						width: "100%",
						height: "auto",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						mt: "30px",
					}}
				>
					<img
						alt="itau_uniclass"
						src={PaoDeAcucar1}
						loading="lazy"
						style={{
							width: "80%",
							height: "100%",
							maxWidth: "450px",
						}}
					/>
				</Box>
			</Container>
			<Divider variant="middle" />
			<Container
				sx={{
					display: width < 720 ? "flex" : "none",
					alignItems: "center",
					justifyContent: "center",
					mt: "100px",
					mb: "100px",
				}}
			>
				<div id="cartoes.informacash_top_mob"></div>
			</Container>
			<Container
				sx={{
					display: width > 720 ? "flex" : "none",
					alignItems: "center",
					justifyContent: "center",
					mt: "100px",
					mb: "100px",
				}}
			>
				<div id="cartoes.informacash_top_desk"></div>
			</Container>
			<Container
				sx={{
					width: "100vw",
					height: "100%",
					mt: "70px",
					mb: "30px",
					pl: "7%",
					pr: "4%",
				}}
			>
				<Typography
					variant="h5"
					align="left"
					sx={{
						lineHeight: "2rem",
						fontWeight: "700",
						mb: "5px",
					}}
				>
					Por que escolher o cartão Pão de Açúcar Itaucard?
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "1.5rem",
						fontWeight: "400",
						mb: "20px",
					}}
				>
					Quanto mais você usa, mais vantagens ganha!
					<br />
				</Typography>

				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Ganhe descontos e milhas aéreas! Com o Programa de Pontos do
					Cartão Pão de Açúcar mais Itaú, as compras viram pontos que
					podem ser trocados por descontos em compras de produtos nas
					Lojas Pão de Açúcar ou milhas aéreas.
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					O cartão Pão de Açúcar Itaucard oferece 1 ponto a cada R$ 1
					gasto. Ou seja, a conversão não tem relação com o câmbio,
					oferecendo mais pontos que os cartões de crédito com mais
					prestígio do país.
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					As três versões do cartão (Gold, Platinum e o Internacional)
					são equipadas com tecnologia de pagamento por aproximação e
					são compatíveis com as carteiras digitais Apple Pay, Samsung
					Pay e Google Pay.
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Você pode solicitar até dois cartões adicionais pagando 50%
					do valor da anuidade do titular. O melhor de tudo é que o
					acúmulo de pontos ocorre em uma única conta, sendo possível
					alcançar os benefícios ainda mais rápido.
				</Typography>
				<Container
					sx={{
						display: width < 720 ? "flex" : "none",
						alignItems: "center",
						justifyContent: "center",
						mt: "100px",
						mb: "100px",
					}}
				>
					<div
						class="pg-lazy"
						data-gpt-parent="cartoes.informacash_mid_mob"
					></div>
				</Container>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Com os cartões Pão de Açúcar Itaucard é possível comprar no
					exterior sem pagar a ágio/spread – tarifa cobrada pelos
					bancos que deixam suas compras até 7% mais caras.
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					O cartão também oferece os benefícios das bandeiras
					Mastercard e Visa, que podem oferecer seguro viagem, seguro
					para bagagem despachada, concierge, proteção de preço,
					dentre outros.
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					Clique no botão para obter o seu cartão e comece a controlar
					os seus gastos de uma forma simples, econômica e cheia de
					benefícios!
				</Typography>
			</Container>
			<Container
				sx={{
					width: "100vw",
					height: "100%",
					mt: "60px",
					mb: "30px",
					pl: "7%",
					pr: "4%",
				}}
			>
				<Typography
					variant="h5"
					align="left"
					sx={{
						lineHeight: "2rem",
						fontWeight: "700",
						mb: "10px",
					}}
				>
					Qual a melhor escolha para o seu perfil?
				</Typography>
				<Container
					sx={{
						display: width > 720 ? "flex" : "none",
						alignItems: "center",
						justifyContent: "center",
						mt: "100px",
						mb: "100px",
					}}
				>
					<div
						class="pg-lazy"
						data-gpt-parent="cartoes.informacash_mid_desk"
					></div>
				</Container>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mt: "10px",
						mb: "10px",
					}}
				>
					Para saber qual versão do cartão combina com você, é
					necessário comparar e analisar qual a relação entre
					anuidade, renda mínima e benefícios exigida se encaixa com a
					sua realidade:
					<br />
				</Typography>
				<Container
					sx={{
						display: width < 720 ? "flex" : "none",
						alignItems: "center",
						justifyContent: "center",
						mt: "100px",
						mb: "100px",
					}}
				>
					<div
						class="pg-lazy"
						data-gpt-parent="cartoes.informacash_mid_mob"
					></div>
				</Container>
				<Container sx={{ width: "90%", mb: "40px", mt: "30px" }}>
					<Grid container spacing={2} alignItems="stretch">
						<Grid item xs={12} sm={6}>
							<Box
								sx={{
									width: "100%",
									height: "100%",
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<img
									alt="Pao de açucar Gold Card"
									src={PaoDeAcucarGold}
									loading="lazy"
									style={{
										width: "80%",
										height: "100%",
										maxWidth: "350px",
										marginBottom: "20px",
									}}
								/>
								<Typography variant="h6" align="center">
									<b>Gold</b>
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6}>
							<div style={divGridStyle}>
								<Typography
									variant="p"
									align="left"
									sx={typographyGridStyle}
								>
									<b>Anuidade:</b> 12x R$ 33,75 ou R$405,00
									<br />
									<b>Bandeira:</b> Visa ou Mastercard®
									<br />
									<b> Renda Mínima:</b> R$ 2.500
									<br />
									<b>Benefícios:</b> R$1,00 = 1 ponto = 0,70
									milhas (Smiles ou Multiplus) ou 1 milha
									(Azul) ou R$0,025 de desconto nas lojas Pão
									de Açúcar.
									<br />
									<b>
										Resgate (compras no Pão de Açúcar):
									</b>{" "}
									Até dois resgates anuais de até 14 mil
									pontos cada e de no mínimo 4 mil pontos.
								</Typography>
							</div>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Box
								sx={{
									width: "100%",
									height: "100%",
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<img
									alt="Pao de açucar Platinum Card"
									src={PaoDeAcucarPlat}
									loading="lazy"
									style={{
										width: "80%",
										height: "100%",
										maxWidth: "350px",
										marginBottom: "20px",
									}}
								/>
								<Typography variant="h6" align="center">
									<b>Platinum</b>
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6}>
							<div style={divGridStyle}>
								<Typography
									variant="p"
									align="left"
									sx={typographyGridStyle}
								>
									<b>Anuidade:</b> 12x R$ 54,17 ou R$650,00
									<br />
									<b>Bandeira:</b> Visa ou Mastercard®
									<br />
									<b> Renda Mínima:</b> R$ 5.000
									<br />
									<b>Benefícios:</b> R$1,00 = 1 ponto = 0,88
									milhas (Smiles ou Multiplus) ou 1 milha
									(Azul) ou R$0,025 de desconto nas lojas Pão
									de Açúcar.
									<br />
									<b>
										Resgate (compras no Pão de Açúcar):
									</b>{" "}
									Ilimitados mas respeitando o máximo de até
									45 mil pontos cada e o mínimo de 4 mil
									pontos.
								</Typography>
							</div>
						</Grid>
						<Container
							sx={{
								display: width < 720 ? "flex" : "none",
								alignItems: "center",
								justifyContent: "center",
								mt: "100px",
								mb: "100px",
							}}
						>
							<div
								class="pg-lazy"
								data-gpt-parent="cartoes.informacash_mid_mob"
							></div>
						</Container>
						<Container
							sx={{
								display: width > 720 ? "flex" : "none",
								alignItems: "center",
								justifyContent: "center",
								mt: "100px",
								mb: "100px",
							}}
						>
							<div
								class="pg-lazy"
								data-gpt-parent="cartoes.informacash_mid_desk"
							></div>
						</Container>
						<Grid item xs={12} sm={12}>
							<div style={divGridStyle}>
								<Typography
									align="left"
									sx={{
										fontSize: "1rem",
										lineHeight: "1.6rem",
										fontWeight: "400",
										mb: "20px",
									}}
								>
									Além dos cartões Gold e Platinum, o Pão de
									Açúcar Itaucard oferece o cartão
									Internacional. Com ele, você consegue
									descontos e condições especiais para compras
									em supermercados da marca, além de poder
									comprar produtos dentro e fora do Brasil.
								</Typography>
							</div>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Box
								sx={{
									width: "100%",
									height: "100%",
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<img
									alt="Pao de açucar Platinum Card"
									src={PaoDeAcucarInt}
									loading="lazy"
									style={{
										width: "80%",
										height: "100%",
										maxWidth: "350px",
										marginBottom: "20px",
									}}
								/>
								<Typography variant="h6" align="center">
									<b>Internacional</b>
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6}>
							<div style={divGridStyle}>
								<Typography
									variant="p"
									align="left"
									sx={typographyGridStyle}
								>
									<b>Anuidade:</b> Grátis
									<br />
									<b>Bandeira:</b> Visa ou Mastercard®
									<br />
									<b> Renda Mínima:</b> R$ 1.000
									<br />
									<b>Benefícios:</b> Descontos exclusivos
									através do aplicativo, site ou loja
									renovadas a cada 14 dias. Principalmente em
									alimentos, como vinhos, cervejas e queijos,
									além de teatros e cinemas.
								</Typography>
							</div>
						</Grid>
					</Grid>
				</Container>
				<Typography
					align="left"
					variant="h5"
					sx={{
						lineHeight: "2.1rem",
						mb: "10px",
						fontWeight: "700",
					}}
				>
					Como solicitar o seu cartão de crédito Pão de Açúcar
					Itaucard?
				</Typography>
				<Container
					sx={{
						display: width < 720 ? "flex" : "none",
						alignItems: "center",
						justifyContent: "center",
						mt: "100px",
						mb: "100px",
					}}
				>
					<div
						class="pg-lazy"
						data-gpt-parent="cartoes.informacash_mid_mob"
					></div>
				</Container>
				<List
					sx={{
						listStyleType: "decimal",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<ListItem
						sx={{
							justifyContent: "left",
							pb: "0 !important",
							pt: "0 !important",
							pl: "20px",
							mb: "5px",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "1rem",
								display: "list-item",
							}}
						>
							Clique em <b>solicitar agora</b>
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "left",
							pb: "0 !important",
							pt: "0 !important",
							pl: "20px",
							mb: "5px",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "1rem",
								display: "list-item",
							}}
						>
							Preencha os seus dados
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "left",
							pb: "0 !important",
							pt: "0 !important",
							pl: "20px",
							mb: "5px",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "1rem",
								display: "list-item",
							}}
						>
							Aguarde a confirmação do banco
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "left",
							pb: "0 !important",
							pt: "0 !important",
							pl: "20px",
							mb: "5px",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "1rem",
								display: "list-item",
							}}
						>
							Receba o seu cartão de crédito Pão de Açúcar
							Itaucard em casa
						</Typography>
					</ListItem>
				</List>
			</Container>
			<Container
				sx={{
					width: "100%",
					height: "100%",
					mb: "50px",
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Button
					variant="contained"
					size="large"
					disableElevation
					sx={{
						fontWeight: "700",
						backgroundColor: "var(--infoCashBlue)",
					}}
					href="https://pln.brtrk2.com/aff_c?offer_id=7729&aff_id=14205"
				>
					Solicitar agora
				</Button>
			</Container>
			<ResponsiveFooter />
		</Box>
	);
}
