import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";

export default function Post20() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const titleStyleObj = {
		fontSize: width > 720 ? "1.9em" : "1.6em",
		fontWeight: "700",
		mb: "calc(0.2em)",
		mt: "calc(13px + 1.2em)",
		lineHeight: "1.5",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "#111",
	};

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			<Container
				sx={{
					width: "100%",
					height: "30%",
					paddingTop: width < 720 ? "150px" : "200px",
					paddingBottom: width < 720 ? "50px" : "100px",
					paddingLeft: width < 720 ? "15px" : "auto",
					paddingRight: width < 720 ? "15px" : "auto",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					backgroundColor: "#161616",
					maxWidth: "none !important",
				}}
			>
				<Typography
					align="center"
					sx={{
						fontSize: width < 720 ? "1.5rem" : "2.5rem",
						lineHeight: width < 720 ? "2rem" : "3rem",
						textAlign: "center",
						maxWidth: "700px",
						fontWeight: "700",
						color: "#FFF",
						padding: "20px",
					}}
				>
					Brasil se destaca entre os bancos mais rentáveis do mundo
				</Typography>
			</Container>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
					mt: "100px",
					mb: "200px",
					backgroundColor: "#FFF",
				}}
			>
				<Container
					sx={{
						maxWidth: "770px !important",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "flex-start",
						paddingLeft: width < 720 ? "22px" : "auto",
						paddingRight: width < 720 ? "22px" : "auto",
					}}
				>
					<Typography sx={textStyleObj}>
						Entre os 10 bancos mais rentáveis do mundo, 4 são
						brasileiros. Segundo estudo da consultoria Economática,
						entre os 39 bancos do ranking, EUA lidera com 19 bancos,
						seguidos do Brasil, Canadá e Reino Unido, destacando-se
						com 4 bancos cada país. O Japão e a Coreia do Sul
						possuem três bancos, além de que a Espanha e a Índia
						possuem dois bancos nesse ranking.
					</Typography>
					<Typography sx={textStyleObj}>
						A empresa de informações financeiras Economática
						realizou um estudo sobre a rentabilidade dos grandes
						bancos, os que possuem US$ 100 bilhões em ativos em
						2021. Entre os 10 primeiros dessa lista, 4 são
						brasileiros. De 2016 a 2019, os bancos nacionais tiveram
						uma sequência de crescimento, que foi interrompida pela
						pandemia, em 2020.
						<b>
							Porém, ano passado, houve uma recuperação de 4,4
							pontos.
						</b>
					</Typography>
					<Typography sx={textStyleObj}>
						Segundo os dados, as 374 empresas brasileiras analisadas
						pela Economatica tiveram um retorno de 14,9% em 2021,
						com alta de 4,7 pontos sobre 2020. Já as 3.871 empresas
						americanas tiveram um retorno de 5,9% em 2020, obtendo
						avanço de 5,6 pontos sobre o ano anterior.
					</Typography>
					<Typography sx={titleStyleObj}>
						Analisando o ranking com dados
					</Typography>
					<Typography sx={textStyleObj}>
						O ranking é liderado pelos americanos Capital One
						(retorno sobre o patrimônio, ROE, de 20,4%) e Ally
						Financial (ROE de 19,3%). Na sequência aparecem
						Santander Brasil (18,9%), o canadense RBC (17,3%), Itaú
						(17,3%), o americano J.P. Morgan (16,9%), Banco do
						Brasil (15,7%), Bradesco (15,2%), o canadense Bank of
						Nova Scotia (15,1%) e o americano SVB Financial (15,0%).
					</Typography>
					<Typography sx={textStyleObj}>
						Além disso, a Economatica verificou o desempenho dos
						bancos que lideram a lista na última década. Os bancos
						brasileiros Itaú, Bradesco e BB obtiveram uma queda
						expressiva no ROE, Capital One, Ally Financial, J. P.
						Morgan, SBV Financial e Santander Brasil tiveram uma
						alta substancial.
					</Typography>
					<Typography sx={textStyleObj}>
						“É possível observar que a diferença de ROE entre os
						bancos mais rentáveis e os demais em 2010 era mais
						distante em termos percentuais, e, atualmente, passaram
						a convergir para valores aproximados”, diz o estudo.
					</Typography>
					<Typography sx={textStyleObj}>
						Dessa forma, a rentabilidade mediana dos quatro bancos
						brasileiros em 2021 foi de 16,5%, com queda de 6,6
						pontos percentuais em relação a 2010. Os brasileiros
						tiveram de 2016 a 2019 uma sequência de crescimento, que
						foi interrompida pelo resultado de 2020. Em resumo, os
						bancos brasileiros seguem com retornos maiores que os 19
						bancos americanos da lista, cujo ROE mediano é de 11,8%.
					</Typography>
					<Typography sx={titleStyleObj}>
						Por que o Brasil se destaca?
					</Typography>
					<Typography sx={textStyleObj}>
						Diante de várias situações, as taxas de juros dos bancos
						brasileiros estão acima dos padrões internacionais.
						Diante desse fato, o lucro torna-se positivo para os
						bancos, mas acaba sendo prejudicial para o
						desenvolvimento econômico do país.
					</Typography>
					<Typography sx={textStyleObj}>
						Segundo economistas, apenas as taxas não explicam um
						lucro tão alto dos bancos brasileiros, sendo fora dos
						padrões internacionais do sistema bancário mundial. Eles
						afirmam que o aumento da Selic também colaborou bastante
						com os resultados dos bancos em 2021.
					</Typography>
					<Typography sx={textStyleObj}>
						Segundo os dados, até março de 2021, a Selic era 2% ao
						ano. Atualmente, a taxa equivale a <b>10,75% ao ano.</b>
					</Typography>
					<Typography sx={titleStyleObj}>
						Veja o ranking dos 10 bancos mais rentáveis
					</Typography>
					<Typography sx={textStyleObj}>
						1 – Capital One (EUA): US$ 432,4 bilhões em ativos e ROE
						de 20,4%;
						<br />
						2 – Ally Financial (EUA): US$ 182,1 bilhões em ativos e
						ROE de 19,3%;
						<br />
						3 – Santander (Brasil): US$ 172,6 bilhões em ativos e
						ROE de 18,9%;
						<br />
						4 – Royal Bank of Canada (Canadá): US$ 1,379 trilhões em
						ativos e ROE de 17,3%;
						<br />
						5 – Itaú Unibanco (Brasil): US$ 388,1 bilhões em ativos
						e ROE de 17,3%;
						<br />
						6 – JP Morgan (EUA): US$ 3,743 trilhões em ativos e ROE
						de 16,9%;
						<br />
						7 – Banco do Brasil (Brasil): US$ 346,3 bilhões em
						ativos e ROE de 15,2%;
						<br />
						8 – Bradesco (Brasil): US$ 296,3 bilhões em ativos e ROE
						de 15,2%;
						<br />
						9 – Bank of Nova Scotia (Canadá): US$ 980,7 bilhões em
						ativos e ROE de 15,1%;
						<br />
						10 – SVB Financial Group (EUA): 211,5 bilhões em ativos
						e ROE de 15%
						<br />
					</Typography>
				</Container>
			</Box>
			<ResponsiveFooter />
		</Box>
	);
}
