import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";

export default function Post48() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const titleStyleObj = {
		fontSize: width > 720 ? "1.9em" : "1.6em",
		fontWeight: "700",
		mb: "calc(0.2em)",
		mt: "calc(13px + 1.2em)",
		lineHeight: "1.5",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "#111",
	};

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			{!loading && (
				<React.Fragment>
					<Container
						sx={{
							width: "100%",
							height: "30%",
							paddingTop: width < 720 ? "150px" : "200px",
							paddingBottom: width < 720 ? "50px" : "100px",
							paddingLeft: width < 720 ? "15px" : "auto",
							paddingRight: width < 720 ? "15px" : "auto",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							backgroundColor: "#161616",
							maxWidth: "none !important",
						}}
					>
						<Typography
							align="center"
							sx={{
								fontSize: width < 720 ? "1.5rem" : "2.5rem",
								lineHeight: width < 720 ? "2rem" : "3rem",
								textAlign: "center",
								maxWidth: "700px",
								fontWeight: "700",
								color: "#FFF",
								padding: "20px",
							}}
						>
							B3 tem lucro de R$ 4,7 bi em 2021
						</Typography>
					</Container>
					<Box
						sx={{
							width: "100%",
							height: "100%",
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
							mt: "100px",
							mb: "200px",
							backgroundColor: "#FFF",
						}}
					>
						<Container
							sx={{
								maxWidth: "770px !important",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "flex-start",
								paddingLeft: width < 720 ? "22px" : "auto",
								paddingRight: width < 720 ? "22px" : "auto",
							}}
						>
							<Typography sx={textStyleObj}>
								<p>
									A B3 – bolsa de valores oficial do Brasil,
									supervisionada pela Comissão de Valores
									Mobiliários (CVM) – registrou lucro líquido
									atribuído aos acionistas de R$ 4,7 bilhões
									no ano passado, representando uma alta de
									13,6% em relação ao lucro reportado em 2020.
								</p>
								<br />
								<p>
									No quarto trimestre de 2021, entre outubro e
									dezembro, a companhia teve lucro líquido
									atribuído aos acionistas de R$ 1,09 bilhão,
									o que representa uma queda de 0,5% em
									relação ao mesmo intervalo do ano anterior e
									de 7,2% em relação ao terceiro trimestre.
								</p>
								<br />
								<p>
									Quanto às despesas da B3, elas somaram R$
									810 milhões no quarto trimestre, uma alta de
									12,1% em 12 meses e 14,6% no trimestre. As
									receitas totais da bolsa somam R$ 2,42
									bilhões, queda de 4,2% em relação ao quarto
									trimestre do ano passado e de 3,2% na
									comparação com o terceiro trimestre.
								</p>
								<br />
								<p>
									A B3 afirma que, no quarto trimestre do ano
									passado, as receitas foram impactadas
									positivamente pela reversão de provisão não
									recorrentes. Se tirado esse efeito, as
									receitas ficariam em linha com o quarto
									trimestre do ano passado.
								</p>
							</Typography>

							<Typography sx={titleStyleObj}>2021</Typography>
							<Typography sx={textStyleObj}>
								<p>
									O ano de 2021 foi significante para o
									mercado de capitais brasileiro. As empresas
									que negociaram suas ações na bolsa de
									valores no ano passaram captaram cerca de R$
									596 bilhões. De acordo com a Associação
									Brasileira das Entidades dos Mercados
									Financeiro e de Capitais (Anbima), o valor é
									um recorde histórico.
								</p>
								<br />
								<p>
									Com a alta da inflação e da taxa de juros,
									muitas empresas decidiram cancelar ofertas
									públicas iniciais de ações previstas para
									2022.
								</p>
							</Typography>

							<Typography sx={titleStyleObj}>2022</Typography>
							<Typography sx={textStyleObj}>
								<p>
									A entrada líquida de recursos estrangeiros
									na B3 já supera todo o saldo positivo de R$
									70,76 bilhões de 2021, quando houve um
									recorde na série histórica, iniciada em
									2004.
								</p>
								<br />
								<p>
									Março caminha para ser o sexto mês
									consecutivo de fluxo positivo: até a última
									segunda-feira (21), a injeção de capital
									estrangeiro somou R$ 18,4 bilhões, explicado
									a queda recente do dólar.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								Afinal, o que é B3?
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									B3 (Brasil, Bolsa, Balcão) é a sigla para a
									Bolsa de Valores oficial do Brasil. Com sede
									em São Paulo, a companhia ganhou esse nome
									em março de 2017, com a fusão das antigas
									Bovespa (Bolsa de Valores de São Paulo),
									BM&F (Bolsa de Mercadorias e Futuros de São
									Paulo) e Cetip (Central de Custódia e de
									Liquidação Financeira de Títulos).
								</p>
								<br />
								<p>
									Com a fusão, a B3 se tornou uma das dez
									maiores Bolsas de Valores do mundo e a maior
									da América Latina, sendo reconhecida como
									uma das melhores empresas do mercado
									financeiro nos quesitos tecnologia e
									estrutura.
								</p>
								<br />
								<p>
									A companhia é responsável por movimentar uma
									capitalização de mercado (valor total
									estimado em circulação das ações de uma
									empresa) de trilhões de reais.
								</p>
								<br />
								<p>
									A B3 é majoritariamente conhecida por
									realizar operações do mercado financeiro,
									como custódia ou compra e venda de ações,
									mas também opera atividades como negociação,
									liquidação, registros, depósitos e processos
									de financiamento e aprovação de crédito.
								</p>
								<br />
								<p>
									A companhia é o lugar onde acontecem as
									negociações de compra e venda de ativos
									financeiros, funcionando como um mercado de
									negociações. Ao investir na Bolsa de
									Valores, é possível negociar variados tipos
									de investimentos disponíveis no mercado.
								</p>
								<br />
								<p>
									Atualmente, existem cerca de 350 empresas
									listadas na B3, em diversos setores da
									economia, como o do mercado financeiro, os
									industriais, de petróleo e gás, saúde,
									biocombustíveis e tecnologia.
								</p>
							</Typography>
						</Container>
					</Box>
					<ResponsiveFooter />
				</React.Fragment>
			)}
		</Box>
	);
}
