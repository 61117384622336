import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

export default function Post18() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const titleStyleObj = {
		fontSize: width > 720 ? "1.9em" : "1.6em",
		fontWeight: "700",
		mb: "calc(0.2em)",
		mt: "calc(13px + 1.2em)",
		lineHeight: "1.5",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "#111",
	};

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	const secondTitleStyleObj = {
		fontSize: width > 720 ? "1.5em" : "1.2em",
		fontWeight: "500",
		mb: "calc(0.2em)",
		mt: "calc(13px + 1.2em)",
		lineHeight: "1.5",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "#111",
	};

	const tableCell = {
		fontSize: width > 720 ? "1.3em" : "1em",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			<Container
				sx={{
					width: "100%",
					height: "30%",
					paddingTop: width < 720 ? "150px" : "200px",
					paddingBottom: width < 720 ? "50px" : "100px",
					paddingLeft: width < 720 ? "15px" : "auto",
					paddingRight: width < 720 ? "15px" : "auto",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					backgroundColor: "#161616",
					maxWidth: "none !important",
				}}
			>
				<Typography
					align="center"
					sx={{
						fontSize: width < 720 ? "1.5rem" : "2.5rem",
						lineHeight: width < 720 ? "2rem" : "3rem",
						textAlign: "center",
						maxWidth: "700px",
						fontWeight: "700",
						color: "#FFF",
						padding: "20px",
					}}
				>
					O que é CRI e CRA? Entenda qual o melhor investimento
				</Typography>
			</Container>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
					mt: "100px",
					mb: "200px",
					backgroundColor: "#FFF",
				}}
			>
				<Container
					sx={{
						maxWidth: "770px !important",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "flex-start",
						paddingLeft: width < 720 ? "22px" : "auto",
						paddingRight: width < 720 ? "22px" : "auto",
					}}
				>
					<Typography sx={textStyleObj}>
						Bom, os investimentos que são isentos de Imposto de
						Renda são duas opções viáveis para os brasileiros
						atualmente. Dessa forma, os Certificados de Recebíveis
						Imobiliários (CRI) e do Agronegócio (CRA) estão entre as
						duas opções que ganham destaque.
					</Typography>
					<Typography sx={textStyleObj}>
						- O que é CRI e CRA <br />
						- Características dos Certificados de Recebíveis
						<br />
						- Diferença entre LCI/LCA e CRI/CRA
						<br />
						- Vantagens e Desvantagens
						<br />
						- Rentabilidade do CRI e do CRA
						<br />- Riscos do investimento
					</Typography>
					<Typography sx={titleStyleObj}>
						O que é CRI e CRA?
					</Typography>
					<Typography sx={textStyleObj}>
						CRI e CRA são os títulos de renda fixa que significam
						Certificado de Recebíveis Imobiliários e Certificado de
						Recebíveis do Agronegócio, respectivamente. Em resumo,
						são formas que algumas empresas utilizam para captar os
						seus recursos.
					</Typography>
					<Typography sx={textStyleObj}>
						A título de ilistração, por exemplo: uma empresa do
						setor imobiliário ou do agronegócio, ao realizar suas
						atividades, acabou fazendo vendas parceladas, mas
						precisa do dinheiro à vista no momento. Para isso, uma
						companhia securitizadora vai estruturar a dívida do
						cliente final, transformando os créditos a receber em
						títulos negociáveis no mercado.
					</Typography>
					<Typography sx={textStyleObj}>
						Dessa forma, como foi ilustrado, CRIs são lastreados em
						direitos creditórios do mercado imobiliário. Os CRAs são
						lastreados em recebíveis dos negócios realizados entre
						produtores rurais e terceiros, que englobam empréstimos
						e financiamentos relacionados a projetos do agronegócio
						durante as fases de produção, industrialização e
						comercialização.
					</Typography>
					<Typography sx={textStyleObj}>
						Ou seja, ao investir em um CRI ou em um CRA, o
						investidor está comprando pagamentos futuros acrescidos
						de juros, isso tudo dentro de um prazo estipulado no
						momento da compra do título.
					</Typography>
					<Typography sx={titleStyleObj}>
						Características dos CRI e CRA
					</Typography>
					<Typography sx={textStyleObj}>
						Ambos os títulos são emitidos exclusivamente por
						<i>companhias securitizadoras</i>. Essas companhias são
						instituições não financeiras. Dessa forma, os
						certificados não têm garantia do FGC, mas possuem
						garantias reais.
					</Typography>
					<Typography sx={textStyleObj}>
						CRI e CRA são investimentos para o médio e longo prazos,
						pois costumam ter vencimentos a partir de 3 anos. Esse
						tipo de investimento é ideal para perfil moderado e para
						investidores qualificados. Nesse tipo de investimento,
						você pode resgatar o dinheiro investido na data de
						vencimento do título. Por essa característica, é ideal
						para os investidores que já possuem uma reserva de
						emergência.
					</Typography>
					<Typography sx={textStyleObj}>
						As duas modalidades de investimentos são isentas de
						imposto de renda e podem ser mais rentáveis que
						poupança, LCI e LCA.
					</Typography>
					<Typography sx={titleStyleObj}>
						Vantagens e Desvantagens do CRI e do CRA
					</Typography>
					<TableContainer sx={{ mt: "30px" }}>
						<Table size="small" aria-label="a dense table">
							<TableBody>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										<b>Vantagens</b>
									</TableCell>
									<TableCell sx={tableCell} align="right">
										<b>Desvantagens</b>
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Previsibilidade
									</TableCell>
									<TableCell sx={tableCell} align="right">
										Liquidez restrita
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Isenção de tributação
									</TableCell>
									<TableCell sx={tableCell} align="right">
										Não é coberto pelo Fundo Garantidor de
										Créditos (FGC)
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
					<Typography sx={titleStyleObj}>
						Rentabilidade do CRI e do CRA
					</Typography>
					<TableContainer sx={{ mt: "30px" }}>
						<Table size="small" aria-label="a dense table">
							<TableBody>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										<b>Pós-fixados</b>
									</TableCell>
									<TableCell sx={tableCell} align="right">
										O rendimento é indexado ao CDI, e a
										rentabilidade é expressa por meio de um
										percentual desse indexador. Um exemplo:
										CRI com retorno de 90% do CDI.
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										<b>Prefixados</b>
									</TableCell>
									<TableCell sx={tableCell} align="right">
										Quando o retorno é conhecido no momento
										da aplicação, ou seja, você tem um
										retorno fixo. Sendo assim, a
										movimentação de qualquer indicador, como
										o CDI ou a Selic, não interfere na
										rentabilidade do investimento.
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										<b>Híbridos/atrelados à inflação</b>
									</TableCell>
									<TableCell sx={tableCell} align="right">
										Investimentos híbridos têm um componente
										prefixado e outro pós-fixado. Desta
										forma, uma parte da rentabilidade é
										estabelecida no momento da aplicação e a
										outra é atrelada a um índice econômico,
										que costuma ser o IPCA. Por exemplo: CRI
										com IPCA + 6%.
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
					<Typography sx={titleStyleObj}>
						Riscos do CRI e CRA
					</Typography>
					<Typography sx={textStyleObj}>
						Assim como todo investimento, o CRI e o CRA também
						possuem riscos.
					</Typography>
					<Typography sx={textStyleObj}>
						Em primeiro lugar, existe o risco de liquidez. Isso
						acontece porque esses investimentos têm prazo de resgate
						apenas no vencimento do título, e o mercado secundário é
						pouco desenvolvido no Brasil. Ou seja, para que você
						consiga transformar o ativo em dinheiro quando desejar,
						é necessário pagar uma taxa de resgate por isso.
					</Typography>
					<Typography sx={textStyleObj}>
						Sobre o risco de crédito do emissor, apesar de CRIs e
						CRAs não serem garantidos pelo FGC, esse tipo de risco é
						minimizado. Isso ocorre pois é comum que seja instituído
						o regime fiduciário. Além disso, os recebíveis são
						separados do patrimônio da securitizadora e destinados
						exclusivamente à liquidação dos títulos. Dessa forma,
						caso a securitizadora tenha dificuldades financeiras, o
						fluxo de pagamento para os investidores não será
						afetado.
					</Typography>
					<Typography sx={textStyleObj}>
						Para finalizar, há o risco inerente ao fato que originou
						o título. Caso a pessoa que financiou um apartamento
						(parte do CRI) deixar de pagar as parcelas, toda a
						cadeia pode ficar prejudicada. Mas afinal, como se
						proteger dos riscos?
					</Typography>
					<Typography sx={textStyleObj}>
						- Análise de Rating: O rating é equivalente a uma nota
						de confiabilidade da empresa, e é realizado por agências
						especializadas. Então, quanto melhor for o rating, mais
						segura é a emissão e menor o risco de calote.
						<br />- Diversificação da carteira: Não coloque todo o
						seu dinheiro em CRI e CRA. Ao diversificar sua carteira
						com diferentes investimentos, você está diminuindo o
						risco de concentração do seu dinheiro.
					</Typography>
					<Typography sx={titleStyleObj}>
						Passo a passo de como investir:
					</Typography>
					<Typography sx={secondTitleStyleObj}>
						<b>1) Abra a sua conta em uma corretora</b>
					</Typography>
					<Typography sx={textStyleObj}>
						Ao escolher a corretora, considere aspectos como custos
						de operação, variedade de opções de investimentos,
						qualidade da plataforma de negociação e acesso a suporte
						para dúvidas.
					</Typography>
					<Typography sx={secondTitleStyleObj}>
						<b>2) Verifique o prospecto</b>
					</Typography>
					<Typography sx={textStyleObj}>
						É um documento que apresenta todas as informações
						importantes sobre a oferta. Ele informa, por exemplo, os
						detalhes dos recebíveis incluídos em cada certificado, a
						remuneração prometida aos investidores, os prazos, as
						condições da distribuição, e muito mais.
					</Typography>
					<Typography sx={secondTitleStyleObj}>
						<b>3) Solicite uma reserva</b>
					</Typography>
					<Typography sx={textStyleObj}>
						Se as informações convencer você a investir no CRI e
						CRA, será preciso solicitar uma reserva, informando à
						corretora quantos papéis pretende comprar. Isso deve ser
						feito durante o chamado “período de reserva”.
					</Typography>
					<Typography sx={textStyleObj}>
						A corretora também é o caminho para adquirir CRIs e CRAs
						nessa situação.
					</Typography>
					<Typography sx={secondTitleStyleObj}>
						<b>4) Avalie o valor mínimo</b>
					</Typography>
					<Typography sx={textStyleObj}>
						Dependendo do nível de risco e do potencial de retorno
						de cada papel, pode ser exigido um valor mínimo de
						aplicação. Algumas ofertas podem estabelecer aplicações
						iniciais de R$ 300 mil ou mais. É possível comprar CRIs
						e CRAs com valores tão baixos quanto R$ 10 mil.
					</Typography>
					<Typography sx={secondTitleStyleObj}>
						<b>5) Analise o risco</b>
					</Typography>
					<Typography sx={textStyleObj}>
						Tanto no caso das ofertas públicas quanto no mercado
						secundário, é fundamental analisar com atenção a
						composição de cada certificado de recebíveis. Conheça a
						securitizadora que emitiu os papéis e cheque se foi
						adotado o regime fiduciário.
					</Typography>
					<Typography sx={textStyleObj}>
						CRIs e CRAs também costumam receber um <i>rating</i>,
						nota atribuída aos papéis por uma agência independente
						que busca avaliar o seu risco de crédito. Essa nota
						indica se o produto envolve um risco de calote alto ou
						baixo.
					</Typography>
					<Typography sx={secondTitleStyleObj}>
						<b>6) Transfira o dinheiro e comece a investir</b>
					</Typography>
					<Typography sx={textStyleObj}>
						Agora tudo ok! Basta transferir o valor para a sua conta
						na corretora e executar a ordem de investimento.
					</Typography>
				</Container>
			</Box>
			<ResponsiveFooter />
		</Box>
	);
}
