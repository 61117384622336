import {
	Box,
	Typography,
	Container,
	Divider,
	ListItem,
	List,
	Button,
} from "@mui/material";
import React from "react";
import ResponsiveAppBar from "../../components/AppBar";
import useWindowDimensions from "../../hooks/windowDimension";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ResponsiveFooter from "../../components/Footer";
import itau_uniclass from "../../assets/itau_uni.png";
import Preloader from "../../components/Preloader";
import useLoader from "../../hooks/useLoader";

export default function ItauUniclassExt() {
	const { width } = useWindowDimensions();
	const loading = useLoader();

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && <Preloader />}
			<Container
				sx={{
					width: "100vw",
					height: "100%",
					mt: width < 720 ? "150px" : "200px",
					mb: "40px",
				}}
			>
				<Typography variant="h5" align="center">
					Cartão de Crédito Uniclass Visa Signature
				</Typography>
				<Typography
					align="center"
					sx={{
						fontSize: "1.1rem",
						fontWeight: "600",
						mt: "10px",
					}}
				>
					O cartão sem anuidade que facilita o seu dia a dia e está ao
					seu lado em suas conquistas.
				</Typography>
				<Box
					sx={{
						width: "100%",
						height: "auto",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						mt: "30px",
					}}
				>
					<img
						alt="itau_uniclass"
						src={itau_uniclass}
						loading="lazy"
						style={{
							width: "80%",
							height: "100%",
							maxWidth: "450px",
						}}
					/>
				</Box>
			</Container>
			<Divider variant="middle" />
			<Container
				sx={{
					display: width < 720 ? "flex" : "none",
					alignItems: "center",
					justifyContent: "center",
					mt: "100px",
					mb: "100px",
				}}
			>
				<div id="cartoes.informacash_top_mob"></div>
			</Container>
			<Container
				sx={{
					display: width > 720 ? "flex" : "none",
					alignItems: "center",
					justifyContent: "center",
					mt: "100px",
					mb: "100px",
				}}
			>
				<div id="cartoes.informacash_top_desk"></div>
			</Container>
			<Container
				sx={{
					width: "100vw",
					height: "100%",
					mt: "70px",
					mb: "30px",
					pl: "7%",
					pr: "4%",
				}}
			>
				<Typography
					variant="h5"
					align="left"
					sx={{
						lineHeight: "2rem",
						fontWeight: "700",
						mb: "20px",
					}}
				>
					Por que escolher o Cartão de Crédito Uniclass Visa
					Signature?
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mt: "10px",
						mb: "10px",
					}}
				>
					Se você tem renda mínima de R$ 5 mil, ao contratar o cartão
					Itaú Uniclass Visa Signature e abrir sua conta corrente no
					Uniclass, você conta com os seguintes benefícios:
					<br />
				</Typography>

				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Até 3 adicionais gratuitos, com os mesmos benefícios do
					titular do cartão.
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Tag Itaú: Pagando com seu cartão de crédito Itaú, você tem
					tag de passagem automática em pedágios e estacionamentos sem
					mensalidade.
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Aproxime e pague: não precisa nem inserir na maquininha para
					realizar suas compras.
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Benefícios variante Signature: Garantia estendida original,
					Proteção de compras, Proteção de preços, Vai de Visa e muito
					mais!
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Carteiras digitais: pague aproximando o seu celular com as
					carteiras digitais Apple Pay, Samsung Pay, Google Pay e
					Paypal.
				</Typography>
				<Container
					sx={{
						display: width < 720 ? "flex" : "none",
						alignItems: "center",
						justifyContent: "center",
						mt: "100px",
						mb: "100px",
					}}
				>
					<div
						class="pg-lazy"
						data-gpt-parent="cartoes.informacash_mid_mob"
					></div>
				</Container>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					O cartão também oferece os benefícios das bandeiras
					Mastercard e Visa, que podem oferecer seguro viagem, seguro
					para bagagem despachada, concierge, proteção de preço,
					dentre outros.
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Mensalidade gratuita no pacote de serviços da conta corrente
					Uniclass por prazo indeterminado.
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "30px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Benefício dos 10 dias sem juros no cheque especial por 12
					meses.
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					Clique no botão para obter o seu cartão e comece a controlar
					os seus gastos de uma forma simples, econômica e cheia de
					benefícios!
				</Typography>
			</Container>
			<Container
				sx={{
					display: width > 720 ? "flex" : "none",
					alignItems: "center",
					justifyContent: "center",
					mt: "100px",
					mb: "100px",
				}}
			>
				<div
					class="pg-lazy"
					data-gpt-parent="cartoes.informacash_mid_desk"
				></div>
			</Container>
			<Container
				sx={{
					width: "100vw",
					height: "100%",
					mt: "60px",
					mb: "30px",
					pl: "7%",
					pr: "4%",
				}}
			>
				<Typography
					variant="h5"
					align="left"
					sx={{
						lineHeight: "2rem",
						fontWeight: "700",
						mb: "10px",
					}}
				>
					Qual a melhor escolha para o seu perfil?
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mt: "10px",
						mb: "10px",
					}}
				>
					Para obter o cartão de crédito Uniclass Visa Signature e
					aproveitar todos os seus benefícios é necessário abrir uma
					conta Itaú Uniclass. Confira as opções de conta e veja o que
					faz sentido para você.
					<br />
				</Typography>
				<Container
					sx={{
						display: width < 720 ? "flex" : "none",
						alignItems: "center",
						justifyContent: "center",
						mt: "100px",
						mb: "100px",
					}}
				>
					<div
						class="pg-lazy"
						data-gpt-parent="cartoes.informacash_mid_mob"
					></div>
				</Container>
				<Typography
					align="left"
					sx={{
						fontSize: "1.3rem",
						lineHeight: "2rem",
						fontWeight: "700",
						mb: "20px",
						mt: "20px",
						color: "#434343",
					}}
				>
					Itaú Uniclass Pacote 3.0
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "5px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Acesso aos serviços exclusivos Itaú Uniclass
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "5px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Saques ilimitados
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "20px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					{"Transferências ilimitadas (exceto DOC/TED)"}
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "20px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Quatro DOCs ou TEDs via canais eletrônicos
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "5px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Extratos impressos ilimitados
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "5px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					{"Mensagens de conta por celular ilimitadas (SMS)"}
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Entrega de talão de cheque na casa do cliente
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "700",
						mb: "20px",
						color: "#434343",
					}}
				>
					Valor mensal de R$ 68,90
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.3rem",
						lineHeight: "2rem",
						fontWeight: "700",
						mb: "20px",
						mt: "30px",
						color: "#434343",
					}}
				>
					Itaú Uniclass Pacote 4.0
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "5px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Acesso aos serviços exclusivos Itaú Uniclass
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "5px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Saques ilimitados
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "20px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					{"Transferências ilimitadas (exceto DOC/TED)"}
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "20px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Seis DOCs ou TEDs via canais eletrônicos
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "5px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Extratos impressos ilimitados
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "5px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					{"Mensagens de conta por celular ilimitadas (SMS)"}
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Duas entregas de talão de cheque na sua casa
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "700",
						mb: "20px",
						color: "#434343",
					}}
				>
					Valor mensal de R$ 82,90
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "30px",
					}}
				>
					Clique no botão para obter sua conta e, assim, usufruir do
					cartão de crédito Uniclass Visa Signature!
				</Typography>
				<Container
					sx={{
						display: width < 720 ? "flex" : "none",
						alignItems: "center",
						justifyContent: "center",
						mt: "100px",
						mb: "100px",
					}}
				>
					<div
						class="pg-lazy"
						data-gpt-parent="cartoes.informacash_mid_mob"
					></div>
				</Container>
				<Container
					sx={{
						display: width > 720 ? "flex" : "none",
						alignItems: "center",
						justifyContent: "center",
						mt: "100px",
						mb: "100px",
					}}
				>
					<div
						class="pg-lazy"
						data-gpt-parent="cartoes.informacash_mid_desk"
					></div>
				</Container>
				<Typography
					align="left"
					variant="h5"
					sx={{
						lineHeight: "2.1rem",
						mb: "10px",
						fontWeight: "700",
					}}
				>
					Como solicitar o seu cartão de crédito Itaú Uniclass Visa
					Signature?
				</Typography>
				<List
					sx={{
						listStyleType: "decimal",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<ListItem
						sx={{
							justifyContent: "left",
							pb: "0 !important",
							pt: "0 !important",
							pl: "20px",
							mb: "5px",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "1rem",
								display: "list-item",
							}}
						>
							Clique em <b>solicitar agora</b>
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "left",
							pb: "0 !important",
							pt: "0 !important",
							pl: "20px",
							mb: "5px",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "1rem",
								display: "list-item",
							}}
						>
							Abra sua conta no Itaú Uniclass
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "left",
							pb: "0 !important",
							pt: "0 !important",
							pl: "20px",
							mb: "5px",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "1rem",
								display: "list-item",
							}}
						>
							Preencha os seus dados
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "left",
							pb: "0 !important",
							pt: "0 !important",
							pl: "20px",
							mb: "5px",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "1rem",
								display: "list-item",
							}}
						>
							Aguarde a aprovação do banco
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "left",
							pb: "0 !important",
							pt: "0 !important",
							pl: "20px",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "1rem",
								display: "list-item",
							}}
						>
							Solicite seu cartão de crédito Uniclass Visa
							Signature
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "left",
							pb: "0 !important",
							pt: "0 !important",
							pl: "20px",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "1rem",
								display: "list-item",
							}}
						>
							Receba-o em casa!
						</Typography>
					</ListItem>
				</List>
			</Container>
			<Container
				sx={{
					width: "100%",
					height: "100%",
					mb: "50px",
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Button
					variant="contained"
					size="large"
					disableElevation
					sx={{
						fontWeight: "700",
						backgroundColor: "var(--infoCashBlue)",
					}}
					href="https://pln.brtrk2.com/aff_c?offer_id=7730&aff_id=14205"
				>
					Solicitar agora
				</Button>
			</Container>
			<ResponsiveFooter />
		</Box>
	);
}
