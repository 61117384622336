import { Box, Typography, Container, Grid, Divider } from "@mui/material";
import React from "react";
import ResponsiveAppBar from "../../components/AppBar";
import ResponsiveFooter from "../../components/Footer";
import useWindowDimensions from "../../hooks/windowDimension";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import itau_uniclass from "../../assets/itau_uni.png";
import superdigital from "../../assets/superdigital.png";
import PaoDeAcucar1 from "../../assets/PaoDeAcucar1.png";
import PortoSeguro1 from "../../assets/PortoSeguro1.png";
import picpay_frente_e_verso from "../../assets/picpay_frente_e_verso.png";
import atacadao_home from "../../assets/atacadao_home.png";
import latam_home from "../../assets/latam_home.png";
import itauclick_home from "../../assets/itauclick_home.png";
import will_bank_home from "../../assets/will_bank_home.png";
import cartaopan_home from "../../assets/cartaopan_home.png";
import itau_iti_home from "../../assets/itau_iti_home.png";
import santander_sx_home from "../../assets/santander_sx_home.png";
import cartao_c6_home from "../../assets/cartao_c6_home.png";
import buscape_home from "../../assets/buscape_home.png";
import meliuz_home from "../../assets/meliuz_home.png";
import next from "../../assets/next.png";
import { useNavigate } from "react-router-dom";

export default function Home() {
	const { width } = useWindowDimensions();
	const navigate = useNavigate();
	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />

			<Container
				sx={{
					width: "100%",
					height: "100%",
					mt: width < 720 ? "150px" : "150px",
					mb: "200px",
					display: "flex",
					flexDirection: "column",
					justifyContent: "flex-start",
					alignItems: "center",
				}}
			>
				<Box
					sx={{
						width: "100%",
						height: "auto",
						mb: "40px",
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
						maxWidth: "670px",
					}}
				>
					<Typography
						align="left"
						sx={{
							fontWeight: "600",
							fontSize: "2.1rem",
							color: "var(--textGray)",
						}}
					>
						Cartões
					</Typography>
					<Divider
						variant="middle"
						sx={{ width: "100%", mt: "10px" }}
					/>
				</Box>

				<Grid
					container
					spacing={3}
					sx={{
						width: "100%",
						height: "auto",
						maxWidth: "1080px",
						justifyContent: "center",
					}}
					alignItems="stretch"
				>
					<Grid
						item
						xs={12}
						sm={3}
						sx={{ maxWidth: "85%", mb: "20px", display: "flex" }}
					>
						<Card
							elevation={0}
							variant="outlined"
							sx={{ borderRadius: "11px", cursor: "pointer" }}
							onClick={() => navigate("/itau-uniclass")}
						>
							<CardMedia
								component="img"
								height="auto"
								image={itau_uniclass}
								alt="itau uniclass card"
							/>
							<CardContent>
								<Typography
									variant="h6"
									align="left"
									sx={{
										fontWeight: "400",
										color: "var(--textGray)",
									}}
								>
									Cartão de Crédito Uniclass Visa Signature
								</Typography>
							</CardContent>
						</Card>
					</Grid>
					<Grid
						item
						xs={12}
						sm={3}
						sx={{ maxWidth: "85%", mb: "20px", display: "flex" }}
					>
						<Card
							elevation={0}
							variant="outlined"
							sx={{ borderRadius: "11px", cursor: "pointer" }}
							onClick={() => navigate("/super-digital")}
						>
							<CardMedia
								component="img"
								height="auto"
								image={superdigital}
								alt="super digital card"
								sx={{ padding: "5px 5px" }}
							/>
							<CardContent>
								<Typography
									variant="h6"
									align="left"
									sx={{
										fontWeight: "400",
										color: "var(--textGray)",
									}}
								>
									Cartão da Superdigital
								</Typography>
							</CardContent>
						</Card>
					</Grid>
					<Grid
						item
						xs={12}
						sm={3}
						sx={{ maxWidth: "85%", mb: "20px", display: "flex" }}
					>
						<Card
							elevation={0}
							variant="outlined"
							sx={{ borderRadius: "11px", cursor: "pointer" }}
							onClick={() => navigate("/itau-pao-de-acucar")}
						>
							<CardMedia
								component="img"
								height="auto"
								image={PaoDeAcucar1}
								alt="pao de açucar card"
							/>
							<CardContent>
								<Typography
									variant="h6"
									align="left"
									sx={{
										fontWeight: "400",
										color: "var(--textGray)",
									}}
								>
									Cartão Pão de Açúcar Itaucard
								</Typography>
							</CardContent>
						</Card>
					</Grid>
					<Grid
						item
						xs={12}
						sm={3}
						sx={{ maxWidth: "85%", mb: "20px", display: "flex" }}
					>
						<Card
							elevation={0}
							variant="outlined"
							sx={{ borderRadius: "11px", cursor: "pointer" }}
							onClick={() => navigate("/porto-seguro")}
						>
							<CardMedia
								component="img"
								height="auto"
								image={PortoSeguro1}
								alt="porto seguro card"
							/>
							<CardContent>
								<Typography
									variant="h6"
									align="left"
									sx={{
										fontWeight: "400",
										color: "var(--textGray)",
									}}
								>
									Cartão de Crédito Porto Seguro Bank
								</Typography>
							</CardContent>
						</Card>
					</Grid>
					<Grid
						item
						xs={12}
						sm={3}
						sx={{ maxWidth: "85%", mb: "20px", display: "flex" }}
					>
						<Card
							elevation={0}
							variant="outlined"
							sx={{ borderRadius: "11px", cursor: "pointer" }}
							onClick={() => navigate("/picpay")}
						>
							<CardMedia
								component="img"
								height="auto"
								image={picpay_frente_e_verso}
								alt="picpay_frente_e_verso"
								sx={{ padding: "10px 10px" }}
							/>
							<CardContent>
								<Typography
									variant="h6"
									align="left"
									sx={{
										fontWeight: "400",
										color: "var(--textGray)",
									}}
								>
									Picpay Card
								</Typography>
							</CardContent>
						</Card>
					</Grid>
					<Grid
						item
						xs={12}
						sm={3}
						sx={{ maxWidth: "85%", mb: "20px", display: "flex" }}
					>
						<Card
							elevation={0}
							variant="outlined"
							sx={{ borderRadius: "11px", cursor: "pointer" }}
							onClick={() => navigate("/latam")}
						>
							<CardMedia
								component="img"
								height="auto"
								image={latam_home}
								alt="latam_home"
							/>
							<CardContent>
								<Typography
									variant="h6"
									align="left"
									sx={{
										fontWeight: "400",
										color: "var(--textGray)",
									}}
								>
									Latam Pass
								</Typography>
							</CardContent>
						</Card>
					</Grid>
					<Grid
						item
						xs={12}
						sm={3}
						sx={{ maxWidth: "85%", mb: "20px", display: "flex" }}
					>
						<Card
							elevation={0}
							variant="outlined"
							sx={{ borderRadius: "11px", cursor: "pointer" }}
							onClick={() => navigate("/atacadao")}
						>
							<CardMedia
								component="img"
								height="auto"
								image={atacadao_home}
								alt="atacadao_home"
								sx={{ padding: "5px 5px" }}
							/>
							<CardContent>
								<Typography
									variant="h6"
									align="left"
									sx={{
										fontWeight: "400",
										color: "var(--textGray)",
									}}
								>
									{"Cartão Atacadão"}
								</Typography>
							</CardContent>
						</Card>
					</Grid>
					<Grid
						item
						xs={12}
						sm={3}
						sx={{ maxWidth: "85%", mb: "20px", display: "flex" }}
					>
						<Card
							elevation={0}
							variant="outlined"
							sx={{ borderRadius: "11px", cursor: "pointer" }}
							onClick={() => navigate("/clickitau")}
						>
							<CardMedia
								component="img"
								height="auto"
								image={itauclick_home}
								alt="itauclick_home"
							/>
							<CardContent>
								<Typography
									variant="h6"
									align="left"
									sx={{
										fontWeight: "400",
										color: "var(--textGray)",
									}}
								>
									{"Cartão Click Itaú"}
								</Typography>
							</CardContent>
						</Card>
					</Grid>
					<Grid
						item
						xs={12}
						sm={3}
						sx={{ maxWidth: "85%", mb: "20px", display: "flex" }}
					>
						<Card
							elevation={0}
							variant="outlined"
							sx={{ borderRadius: "11px", cursor: "pointer" }}
							onClick={() => navigate("/willbank")}
						>
							<CardMedia
								component="img"
								height="auto"
								image={will_bank_home}
								alt="will_bank_home"
								sx={{ padding: "5px 5px" }}
							/>
							<CardContent>
								<Typography
									variant="h6"
									align="left"
									sx={{
										fontWeight: "400",
										color: "var(--textGray)",
									}}
								>
									{"Cartão Will Bank"}
								</Typography>
							</CardContent>
						</Card>
					</Grid>
					<Grid
						item
						xs={12}
						sm={3}
						sx={{ maxWidth: "85%", mb: "20px", display: "flex" }}
					>
						<Card
							elevation={0}
							variant="outlined"
							sx={{ borderRadius: "11px", cursor: "pointer" }}
							onClick={() => navigate("/bancopan")}
						>
							<CardMedia
								component="img"
								height="auto"
								image={cartaopan_home}
								alt="atacadao_cartaopan_homehome"
							/>
							<CardContent>
								<Typography
									variant="h6"
									align="left"
									sx={{
										fontWeight: "400",
										color: "var(--textGray)",
									}}
								>
									{"Cartão Banco Pan"}
								</Typography>
							</CardContent>
						</Card>
					</Grid>
					<Grid
						item
						xs={12}
						sm={3}
						sx={{ maxWidth: "85%", mb: "20px", display: "flex" }}
					>
						<Card
							elevation={0}
							variant="outlined"
							sx={{ borderRadius: "11px", cursor: "pointer" }}
							onClick={() => navigate("/itiitau")}
						>
							<CardMedia
								component="img"
								height="auto"
								image={itau_iti_home}
								alt="itau_iti_home"
							/>
							<CardContent>
								<Typography
									variant="h6"
									align="left"
									sx={{
										fontWeight: "400",
										color: "var(--textGray)",
									}}
								>
									{"Cartão Iti Itaú"}
								</Typography>
							</CardContent>
						</Card>
					</Grid>
					<Grid
						item
						xs={12}
						sm={3}
						sx={{ maxWidth: "85%", mb: "20px", display: "flex" }}
					>
						<Card
							elevation={0}
							variant="outlined"
							sx={{ borderRadius: "11px", cursor: "pointer" }}
							onClick={() => navigate("/santandersx")}
						>
							<CardMedia
								component="img"
								height="auto"
								image={santander_sx_home}
								alt="santander_sx_home"
							/>
							<CardContent>
								<Typography
									variant="h6"
									align="left"
									sx={{
										fontWeight: "400",
										color: "var(--textGray)",
									}}
								>
									{"Cartão Santander SX"}
								</Typography>
							</CardContent>
						</Card>
					</Grid>
					<Grid
						item
						xs={12}
						sm={3}
						sx={{ maxWidth: "85%", mb: "20px", display: "flex" }}
					>
						<Card
							elevation={0}
							variant="outlined"
							sx={{ borderRadius: "11px", cursor: "pointer" }}
							onClick={() => navigate("/c6bank")}
						>
							<CardMedia
								component="img"
								height="auto"
								image={cartao_c6_home}
								alt="cartao_c6_home"
							/>
							<CardContent>
								<Typography
									variant="h6"
									align="left"
									sx={{
										fontWeight: "400",
										color: "var(--textGray)",
									}}
								>
									{"Cartão C6"}
								</Typography>
							</CardContent>
						</Card>
					</Grid>
					<Grid
						item
						xs={12}
						sm={3}
						sx={{ maxWidth: "85%", mb: "20px", display: "flex" }}
					>
						<Card
							elevation={0}
							variant="outlined"
							sx={{ borderRadius: "11px", cursor: "pointer" }}
							onClick={() => navigate("/buscape")}
						>
							<CardMedia
								component="img"
								height="auto"
								image={buscape_home}
								alt="buscape_home"
							/>
							<CardContent>
								<Typography
									variant="h6"
									align="left"
									sx={{
										fontWeight: "400",
										color: "var(--textGray)",
									}}
								>
									{"Cartão Buscapé"}
								</Typography>
							</CardContent>
						</Card>
					</Grid>
					<Grid
						item
						xs={12}
						sm={3}
						sx={{ maxWidth: "85%", mb: "20px", display: "flex" }}
					>
						<Card
							elevation={0}
							variant="outlined"
							sx={{ borderRadius: "11px", cursor: "pointer" }}
							onClick={() => navigate("/meliuz")}
						>
							<CardMedia
								component="img"
								height="auto"
								image={meliuz_home}
								alt="meliuz_home"
							/>
							<CardContent>
								<Typography
									variant="h6"
									align="left"
									sx={{
										fontWeight: "400",
										color: "var(--textGray)",
									}}
								>
									{"Cartão Méliuz"}
								</Typography>
							</CardContent>
						</Card>
					</Grid>
					<Grid
						item
						xs={12}
						sm={3}
						sx={{ maxWidth: "85%", mb: "20px", display: "flex" }}
					>
						<Card
							elevation={0}
							variant="outlined"
							sx={{ borderRadius: "11px", cursor: "pointer" }}
							onClick={() => navigate("/next")}
						>
							<CardMedia
								component="img"
								height="auto"
								image={next}
								alt="next_home"
							/>
							<CardContent>
								<Typography
									variant="h6"
									align="left"
									sx={{
										fontWeight: "400",
										color: "var(--textGray)",
									}}
								>
									{"Cartão Next"}
								</Typography>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Container>
			<ResponsiveFooter />
		</Box>
	);
}
