import {
	Box,
	Typography,
	Container,
	Divider,
	ListItem,
	List,
	Button,
} from "@mui/material";
import React from "react";
import ResponsiveAppBar from "../../components/AppBar";
import useWindowDimensions from "../../hooks/windowDimension";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ResponsiveFooter from "../../components/Footer";
import next from "../../assets/next.png";
import useLoader from "../../hooks/useLoader";
import Preloader from "../../components/Preloader";

export default function NextExt() {
	const { width } = useWindowDimensions();
	const loading = useLoader();

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && <Preloader />}
			<Container
				sx={{
					width: "100vw",
					height: "100%",
					mt: width < 720 ? "150px" : "200px",
					mb: "40px",
				}}
			>
				<Typography variant="h5" align="center">
					Cartão de Crédito Next
				</Typography>
				<Typography
					align="center"
					sx={{
						fontSize: "1.1rem",
						fontWeight: "600",
						mt: "10px",
					}}
				>
					Um cartão gratuito e cheio de benefícios para você fazer
					acontecer!
				</Typography>
				<Box
					sx={{
						width: "100%",
						height: "auto",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						mt: "30px",
					}}
				>
					<img
						alt="next card"
						src={next}
						loading="lazy"
						style={{
							width: "80%",
							height: "100%",
							maxWidth: "450px",
						}}
					/>
				</Box>
			</Container>
			<Divider variant="middle" />
			<Container
				sx={{
					display: width < 720 ? "flex" : "none",
					alignItems: "center",
					justifyContent: "center",
					mt: "100px",
					mb: "100px",
				}}
			>
				<div id="cartoes.informacash_top_mob"></div>
			</Container>
			<Container
				sx={{
					display: width > 720 ? "flex" : "none",
					alignItems: "center",
					justifyContent: "center",
					mt: "100px",
					mb: "100px",
				}}
			>
				<div id="cartoes.informacash_top_desk"></div>
			</Container>
			<Container
				sx={{
					width: "100vw",
					height: "100%",
					mt: "70px",
					mb: "30px",
					pl: "7%",
					pr: "4%",
				}}
			>
				<Typography
					variant="h5"
					align="left"
					sx={{
						lineHeight: "2rem",
						fontWeight: "700",
						mb: "10px",
					}}
				>
					Por que escolher o Cartão de Crédito Next?
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mt: "10px",
						mb: "10px",
					}}
				>
					Entre os principais benefícios do cartão, estão:
					<br />
				</Typography>

				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Conta 100% digital: pague pelo Apple Pay, Google Pay ou
					Samsung Pay e, sempre que quiser comprar online, gere um
					cartão de crédito virtual direto no app. Além disso, pague
					por aproximação e faça suas transferências diretamente pelo
					WhatsApp.
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Tag Itaú: Pagando com seu cartão de crédito Itaú, você tem
					tag de passagem automática em pedágios e estacionamentos sem
					mensalidade.
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Cobertura internacional: o cartão é aceito em qualquer lugar
					do mundo, trazendo mais praticidade e opções para comprar
					aonde você estiver!
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Descontos em estabelecimentos parceiros: com o cartão de
					crédito Next você consegue desconto em franquias como Mundo
					Pixar, Hype, Cinemark, Uber, Domino`s, Nestlé, Liv Up,
					Natura, Sephora, CNA, Descomplica, Electrolux, Motorola,
					Centauro e Decolar.
				</Typography>
				<Container
					sx={{
						display: width < 720 ? "flex" : "none",
						alignItems: "center",
						justifyContent: "center",
						mt: "100px",
						mb: "100px",
					}}
				>
					<div
						class="pg-lazy"
						data-gpt-parent="cartoes.informacash_mid_mob"
					></div>
				</Container>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Saques ilimitados e gratuitos: você pode sacar diretamente
					nos caixas Bradesco e Banco24Horas em todo Brasil.
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Visa Causas: ao comprar com o cartão de crédito Next você
					doa, automaticamente e sem pagar nada a mais por isso, para
					uma instituição parceira do Visa. Você pode escolher entre
					causas animais, educacionais, de crianças e adolescentes,
					para saúde ou idosos.
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "20px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Conta Universitários: uma conta grátis com benefícios
					exclusivos para universitários.
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					Clique no botão para obter o seu cartão e comece a controlar
					os seus gastos de uma forma simples, econômica e cheia de
					benefícios!
				</Typography>
			</Container>
			<Container
				sx={{
					width: "100vw",
					height: "100%",
					mt: "40px",
					mb: "30px",
					pl: "7%",
					pr: "4%",
				}}
			>
				<Typography
					variant="h5"
					align="left"
					sx={{
						lineHeight: "2rem",
						fontWeight: "700",
						mb: "10px",
					}}
				>
					Qual a melhor escolha para o seu perfil?
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mt: "10px",
						mb: "10px",
					}}
				>
					Para saber qual versão do cartão combina com você, é
					necessário comparar e analisar qual lista de benefícios se
					encaixa com a sua realidade. Entre os cartões de débito e
					crédito Next, estão:
					<br />
				</Typography>
				<Container
					sx={{
						display: width < 720 ? "flex" : "none",
						alignItems: "center",
						justifyContent: "center",
						mt: "100px",
						mb: "100px",
					}}
				>
					<div
						class="pg-lazy"
						data-gpt-parent="cartoes.informacash_mid_mob"
					></div>
				</Container>
				<Container
					sx={{
						display: width > 720 ? "flex" : "none",
						alignItems: "center",
						justifyContent: "center",
						mt: "100px",
						mb: "100px",
					}}
				>
					<div
						class="pg-lazy"
						data-gpt-parent="cartoes.informacash_mid_desk"
					></div>
				</Container>
				<Typography
					align="left"
					sx={{
						fontSize: "1.3rem",
						lineHeight: "2rem",
						fontWeight: "700",
						mb: "10px",
						mt: "20px",
						color: "#434343",
					}}
				>
					Next Visa Internacional
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "5px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Anuidade: grátis
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "5px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Mimos exclusivos
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "20px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Compras internacionais
				</Typography>

				<Typography
					align="left"
					sx={{
						fontSize: "1.3rem",
						lineHeight: "2rem",
						fontWeight: "700",
						mb: "10px",
						mt: "30px",
						color: "#434343",
					}}
				>
					Next Visa Platinum
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "5px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Anuidade: gratuita nos primeiros 5 meses
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "5px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Descontos progressivos da anuidade
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "5px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Mimos exclusivos
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "5px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Compras internacionais
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "5px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Benefícios Visa Platinum
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "30px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Programa Pontos Livelo
				</Typography>

				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "20px",
					}}
				>
					Já entre as opções e tipos de conta, o Next oferece:
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.3rem",
						lineHeight: "2rem",
						fontWeight: "700",
						mb: "10px",
						mt: "10px",
						color: "#434343",
					}}
				>
					Next Visa Platinum
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "5px",
					}}
				>
					Conta grátis com serviços ilimitados.
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.3rem",
						lineHeight: "2rem",
						fontWeight: "700",
						mb: "10px",
						mt: "30px",
						color: "#434343",
					}}
				>
					Conta Universitários
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "5px",
					}}
				>
					Conta grátis com benefícios exclusivos para universitários.
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.3rem",
						lineHeight: "2rem",
						fontWeight: "700",
						mb: "10px",
						mt: "30px",
						color: "#434343",
					}}
				>
					nextJoy
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "30px",
					}}
				>
					Conta digital e gratuita para menores de idade, com toda
					magia da Disney.
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "20px",
					}}
				>
					Abra já sua conta e solicite o Cartão de Crédito Next que
					mais combina com você!
				</Typography>
				<Container
					sx={{
						display: width < 720 ? "flex" : "none",
						alignItems: "center",
						justifyContent: "center",
						mt: "100px",
						mb: "100px",
					}}
				>
					<div
						class="pg-lazy"
						data-gpt-parent="cartoes.informacash_mid_mob"
					></div>
				</Container>
				<Container
					sx={{
						display: width > 720 ? "flex" : "none",
						alignItems: "center",
						justifyContent: "center",
						mt: "100px",
						mb: "100px",
					}}
				>
					<div
						class="pg-lazy"
						data-gpt-parent="cartoes.informacash_mid_desk"
					></div>
				</Container>
				<Typography
					align="left"
					variant="h5"
					sx={{
						lineHeight: "2.1rem",
						mb: "10px",
						fontWeight: "700",
					}}
				>
					Como solicitar o seu Cartão de Crédito Next?
				</Typography>
				<List
					sx={{
						listStyleType: "decimal",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<ListItem
						sx={{
							justifyContent: "left",
							pb: "0 !important",
							pt: "0 !important",
							pl: "20px",
							mb: "5px",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "1rem",
								display: "list-item",
							}}
						>
							Clique em <b>solicitar agora</b>
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "left",
							pb: "0 !important",
							pt: "0 !important",
							pl: "20px",
							mb: "5px",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "1rem",
								display: "list-item",
							}}
						>
							Preencha os seus dados
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "left",
							pb: "0 !important",
							pt: "0 !important",
							pl: "20px",
							mb: "5px",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "1rem",
								display: "list-item",
							}}
						>
							Abra sua conta Next grátis
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "left",
							pb: "0 !important",
							pt: "0 !important",
							pl: "20px",
							mb: "5px",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "1rem",
								display: "list-item",
							}}
						>
							Aguarde a análise e aprovação de crédito
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "left",
							pb: "0 !important",
							pt: "0 !important",
							pl: "20px",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "1rem",
								display: "list-item",
							}}
						>
							Receba o seu Cartão de Crédito Next em casa
						</Typography>
					</ListItem>
				</List>
			</Container>
			<Container
				sx={{
					width: "100%",
					height: "100%",
					mb: "50px",
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Button
					variant="contained"
					size="large"
					disableElevation
					sx={{
						fontWeight: "700",
						backgroundColor: "var(--infoCashBlue)",
					}}
					href="https://igoal.go2cloud.org/aff_c?offer_id=5532&aff_id=1673"
				>
					Solicitar agora
				</Button>
			</Container>
			<ResponsiveFooter />
		</Box>
	);
}
