import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";

export default function Post3() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const titleStyleObj = {
		fontSize: width > 720 ? "1.9em" : "1.6em",
		fontWeight: "700",
		mb: "calc(0.2em)",
		mt: "calc(13px + 1.2em)",
		lineHeight: "1.5",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "#111",
	};

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			<Container
				sx={{
					width: "100%",
					height: "30%",
					paddingTop: width < 720 ? "150px" : "200px",
					paddingBottom: width < 720 ? "50px" : "100px",
					paddingLeft: width < 720 ? "15px" : "auto",
					paddingRight: width < 720 ? "15px" : "auto",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					backgroundColor: "#161616",
					maxWidth: "none !important",
				}}
			>
				<Typography
					align="center"
					sx={{
						fontSize: width < 720 ? "1.5rem" : "2.5rem",
						lineHeight: width < 720 ? "2rem" : "3rem",
						textAlign: "center",
						maxWidth: "700px",
						fontWeight: "700",
						color: "#FFF",
						padding: "20px",
					}}
				>
					Aposta para 2022: Apps para saúde mental permanecem em alta
				</Typography>
			</Container>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
					mt: "100px",
					mb: "200px",
					backgroundColor: "#FFF",
				}}
			>
				<Container
					sx={{
						maxWidth: "770px !important",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "flex-start",
						paddingLeft: width < 720 ? "22px" : "auto",
						paddingRight: width < 720 ? "22px" : "auto",
					}}
				>
					<Typography sx={textStyleObj}>
						Uma boa aposta para empreendimentos em 2022 e na área de
						tecnologia, estão os aplicativos de saúde mental. Pelo
						menos 800 milhões de pessoas ao redor do mundo sofrem
						algum tipo de transtorno mental, o espaço para novos
						empreendimentos neste mercado ainda é relevante. O
						Brasil é considerado o mais ansioso do mundo, segundo a
						OMS (Organização Mundial da Saúde).
					</Typography>
					<Typography sx={textStyleObj}>
						Segundo os dados em 2019, pelo menos 9,3% da população
						vivia com essa condição. No mesmo ano, dados da agência
						de saúde mostram que 5,8% dos brasileiros sofrem de
						depressão, o que representa a maior taxa da América
						Latina e a segunda maior das Américas, atrás apenas dos
						Estados Unidos
					</Typography>
					<Typography sx={textStyleObj}>
						De acordo com a pesquisa feita pela Deloitte Global,
						eles esperam que o total gasto com aplicativos
						relacionados à saúde mental chegue a US$ 500 milhões em
						2022. O aumento se baseia em um crescimento de cerca de
						20%, abaixo dos 32% verificados em 2020 (em relação a
						2019).
					</Typography>
					<Typography sx={titleStyleObj}>
						Benefícios dos Apps de Saúde Mental
					</Typography>
					<Typography sx={textStyleObj}>
						É fato de que sempre estamos na maior parte do tempo com
						o celular inseridos em nossa vida, inclusive na maioria
						do tempo. Com a pandemia, a maioria das pessoas passaram
						a fazer terapia online, por exemplo. Diante do ocorrido,
						os aplicativos de saúde mental também acabaram sendo o
						foco da sociedade que tinha acesso aos apps.
					</Typography>
					<Typography sx={textStyleObj}>
						Dessa forma, a maioria dos profissionais de saúde
						concordam que os apps não substituem os tratamentos
						tradicionais, mas que possuem alguns pontos positivos, a
						exemplo do paciente não precisar se expor, tentar
						diferentes opções até achar a mais adequada e não ter um
						calendário muito rigoroso.
					</Typography>
					<Typography sx={textStyleObj}>
						Ao mesmo tempo, não há a menor garantia de que tais apps
						foram criados com a supervisão ou por profissionais da
						área, o que relativiza a sua credibilidade. Ou seja, se
						você pretende começar a empreender nesses aplicativos,
						você já pode criar maneiras para mudar esse cenário. Uma
						opção viável é colocar na descrição quais são as
						instituições ou profissionais por trás dos aplicativos,
						se eles são recomendados por profissionais certificados,
						etc.
					</Typography>
					<Typography sx={titleStyleObj}>
						Oportunidades no mercado em apps de saúde mental
					</Typography>
					<Typography sx={textStyleObj}>
						Ainda há muito espaço para novos empreendimentos neste
						ramo, observando as melhorias e melhor experiência do
						usuário. Considerando que cerca de 800 milhões de
						pessoas ao redor do mundo possuem algum tipo de
						transtorno mental, 11% da população global, trata-se de
						um mercado com grande potencial de crescimento ainda.
					</Typography>
					<Typography sx={textStyleObj}>
						Além disso, nesses aplicativos, há momentos em que eles
						encorajam o usuário a realizar terapias mais
						tradicionais, tanto de forma síncrona como assíncrona,
						com profissionais de saúde mental, seja por
						videoconferência, chat ou ligação telefônica.
					</Typography>
					<Typography sx={textStyleObj}>
						Os novos empreendedores estão diretamente ligados com
						tecnologia, unindo o serviço da saúde mental. As emoções
						e sentimentos ganham um forte aliado para lidar contra a
						tristeza, ansiedade, estresse, falta de foco e energia.
						Dessa forma,os aplicativos de saúde e bem-estar,
						tendência em todo o planeta, proporcionam diversas
						possibilidades de desacelerar a mente, o corpo e
						praticar o autocuidado.
					</Typography>
					<Typography sx={titleStyleObj}>
						Conheça 5 apps de saúde mental para se inspirar e abrir
						o seu empreendimento
					</Typography>
					<Typography sx={titleStyleObj}>1 – Calm</Typography>
					<Typography sx={textStyleObj}>
						O Calm oferece exercícios de respiração, músicas
						tranquilizantes e programas de meditação guiada que
						ajudam a combater a ansiedade, melhorar a qualidade do
						sono e auxiliar na concentração. O aplicativo conta
						ainda com sons de ondas do mar, chuva e fogueira,
						conteúdos de atenção plena, treinos de alongamento do
						corpo e uma biblioteca de histórias para dormir. A
						plataforma é gratuita, mas possui recursos exclusivos na
						versão paga, e está disponível para iOS e Android.
					</Typography>
					<Typography sx={titleStyleObj}>2 – Cíngulo</Typography>
					<Typography sx={textStyleObj}>
						Através de sessões de terapia guiada, o Cíngulo busca
						aliviar crises de ansiedade, diminuir o estresse,
						melhorar a autoestima, trabalhar o medo e a falta de
						ânimo. O aplicativo oferece testes de personalidade e
						monta programas específicos para o usuário, com o
						objetivo de tratar as questões identificadas. A
						plataforma está disponível para iOS eAndroid, nas
						versões gratuita e paga.
					</Typography>
					<Typography sx={titleStyleObj}>3 – Happify</Typography>
					<Typography sx={textStyleObj}>
						Projetado com o apoio de cientistas e especialistas em
						psicologia cognitivo-comportamental, psicologia positiva
						e mindfulness, o Happify tem como promessa ajudar
						usuários a viver mais felizes, através de programas
						simples e interativos, com exercícios práticos, jogos e
						atividades divertidas que contribuem para liberar o
						estresse e aumentar o bem-estar emocional.
					</Typography>
					<Typography sx={textStyleObj}>
						O app oferece uma série personalizada de ferramentas e
						artigos científicos para atingir as trilhas de
						crescimento pessoal, atenção plena e meditação, trabalho
						e dinheiro, relacionamento, saúde, dentre outras.
						Disponível para iOS e Android, a plataforma é gratuita,
						mas possui recursos exclusivos na versão paga.
					</Typography>
					<Typography sx={titleStyleObj}>4 – Daylio</Typography>
					<Typography sx={textStyleObj}>
						O Daylio é um aplicativo que funciona como um diário de
						emoções, onde o usuário registra seu estado emocional
						diariamente e o justifica através da escolha de emojis,
						sem precisar escrever nada. Com isso, a plataforma é
						capaz de monitorar o humor e fornecer relatórios mensais
						sobre as variações, ajudando a entender hábitos e ter
						maior controle sobre atividades sociais, tarefas
						diárias, saúde, qualidade de sono e alimentação. O
						aplicativo está disponível para iOS e Android, nas
						versões gratuita e paga.
					</Typography>
					<Typography sx={titleStyleObj}>5 – Brain.fm</Typography>
					<Typography sx={textStyleObj}>
						Música para melhorar o foco, ajudar na concentração,
						relaxamento e até o sono. Esse é o objetivo do Brain.fm,
						um app que une a neurociência à inteligência artificial
						e personaliza uma lista de reprodução de músicas, com
						modulação rítmica específica, que interagem diretamente
						com o cérebro, impedindo que o usuário se distraia. A
						plataforma é paga e está disponível em browser e para
						iOS e Android.{" "}
					</Typography>
				</Container>
			</Box>
			<ResponsiveFooter />
		</Box>
	);
}
