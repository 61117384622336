import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";

export default function Post10() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const titleStyleObj = {
		fontSize: width > 720 ? "1.9em" : "1.6em",
		fontWeight: "700",
		mb: "calc(0.2em)",
		mt: "calc(13px + 1.2em)",
		lineHeight: "1.5",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "#111",
	};

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			<Container
				sx={{
					width: "100%",
					height: "30%",
					paddingTop: width < 720 ? "150px" : "200px",
					paddingBottom: width < 720 ? "50px" : "100px",
					paddingLeft: width < 720 ? "15px" : "auto",
					paddingRight: width < 720 ? "15px" : "auto",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					backgroundColor: "#161616",
					maxWidth: "none !important",
				}}
			>
				<Typography
					align="center"
					sx={{
						fontSize: width < 720 ? "1.5rem" : "2.5rem",
						lineHeight: width < 720 ? "2rem" : "3rem",
						textAlign: "center",
						maxWidth: "700px",
						fontWeight: "700",
						color: "#FFF",
						padding: "20px",
					}}
				>
					Comeback: Orkut está de volta para a internet, afirma
					criador da plataforma
				</Typography>
			</Container>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
					mt: "100px",
					mb: "200px",
					backgroundColor: "#FFF",
				}}
			>
				<Container
					sx={{
						maxWidth: "770px !important",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "flex-start",
						paddingLeft: width < 720 ? "22px" : "auto",
						paddingRight: width < 720 ? "22px" : "auto",
					}}
				>
					<Typography sx={textStyleObj}>
						O Orkut está de volta para a internet, é isso mesmo. O
						programador turco que desenvolveu a plataforma relata
						que está construindo algo novo em um endereço agora
						original. A rede social deixou de existir em 2014,
						porém, foi tendência no mundo tecnológico e na vida dos
						brasileiros nos anos 2000.
					</Typography>
					<Typography sx={textStyleObj}>
						Quem nunca gostou de passar uma parte do seu dia
						entrando em comunidades do Orkut ou até mesmo cuidando
						da sua Colheita Feliz? Os brasileiros dos anos 2000
						prestigiaram a rede social de forma mais completa e
						tecnológica possível. E nesta quarta-feira (27), o site
						orkut.br foi reativado e passou a exibir um comunicado
						em que Orkut Buyukkokten, criador da rede social,
						informa estar “construindo algo novo”.
					</Typography>
					<Typography sx={textStyleObj}>
						A nota lembra ainda o sucesso que a rede social fez e
						promete novidades em breve. Por mais que não haja muitos
						detalhes sobre o novo projeto, o comunicado ganhou
						destaque nas redes sociais, principalmente no Twitter e
						vários internautas estão felizes com o possível retorno
						da plataforma.
					</Typography>
					<Typography sx={titleStyleObj}>
						E você ainda não conhece o Orkut?
					</Typography>
					<Typography sx={textStyleObj}>
						Lançado em 2004, o Orkut chegou a ter 30 milhões de
						usuários ativos somente no Brasil, o que representa uma
						parcela considerável da população do país com acesso à
						internet na época. Os brasileiros eram os principais
						usuários da plataforma, que se popularizou
						principalmente no país e na Índia.
					</Typography>
					<Typography sx={textStyleObj}>
						Além disso, o site funcionava como um misto de página
						pessoal, parecido com os feeds do Facebook e do
						Instagram, porém, com uma estrutura de fóruns,
						comunidades, onde pessoas com interesses comuns se
						reuniam. O Google, que comprou a rede social em 2008,
						anunciou o fim oficial do Orkut em 2014 por questões
						estratégicas de mercado.
					</Typography>
					<Typography sx={titleStyleObj}>
						O que o comunicado oficial nos conta?
					</Typography>
					<Typography sx={textStyleObj}>
						No comunicado, publicado em inglês e português,
						Buyukkokten critica o cenário de ódio presente no
						ambiente online atualmente e lembra o sucesso do{" "}
						<b>Orkut.</b>
					</Typography>
					<Typography sx={textStyleObj}>
						“Eu sou uma pessoa otimista. Acredito no poder da
						conexão para mudar o mundo. Acredito que o mundo é um
						lugar melhor quando nos conhecemos um pouco mais”, disse
						o programador.
					</Typography>
					<Typography sx={textStyleObj}>
						“É por isso que eu trouxe o orkut.com para tantos de
						vocês ao redor do mundo. E é por isso que estou
						construindo algo novo. Vejo você em breve!”. Estamos
						pausando as operações do hello enquanto preparamos algo
						ainda melhor”, acrescenta.
					</Typography>
					<Typography sx={titleStyleObj}>Metaverso?</Typography>
					<Typography sx={textStyleObj}>
						O Metaverso já existia na época do Orkut? Na época, o
						nome dado ao enorme volume de games disponíveis nas
						redes sociais era tudo de menos importante, a realidade
						eram ter jogos. Algumas das empresas mais poderosas do
						ramo, como Vostu e Mentez conseguiram lucros milionários
						com seus joguinhos para Orkut.
					</Typography>
					<Typography sx={textStyleObj}>
						Mentez era responsável pelos jogos Colheita Feliz e Vida
						nas Passarelas, e atingiu a marca de 35 milhões de
						dólares com a venda de objetos e moedas específicas dos
						games, somente em 2010. Entretanto, a Vostu, criadora do
						MiniFazenda, possuía cerca de 20 milhões de perfis
						ativos, sendo que neste jogo são plantadas 2,5 bilhões
						de sementes por mês.
					</Typography>
					<Typography sx={textStyleObj}>
						O Orkut, sendo assim, foi a rede social dos brasileiros.
						Houve a tradução para nosso idioma, migração das
						decisões para a Google Brasil, jogos criados para
						públicos daqui e a facilidade na utilização são alguns
						pontos do sucesso da rede social nos anos 2000. Será que
						a moda volta?
					</Typography>
				</Container>
			</Box>
			<ResponsiveFooter />
		</Box>
	);
}
