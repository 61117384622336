import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";

export default function Post17() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const titleStyleObj = {
		fontSize: width > 720 ? "1.9em" : "1.6em",
		fontWeight: "700",
		mb: "calc(0.2em)",
		mt: "calc(13px + 1.2em)",
		lineHeight: "1.5",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "#111",
	};

	const secondTitleStyleObj = {
		fontSize: width > 720 ? "1.5em" : "1.2em",
		fontWeight: "500",
		mb: "calc(0.2em)",
		mt: "calc(13px + 1.2em)",
		lineHeight: "1.5",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "#111",
	};

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			<Container
				sx={{
					width: "100%",
					height: "30%",
					paddingTop: width < 720 ? "150px" : "200px",
					paddingBottom: width < 720 ? "50px" : "100px",
					paddingLeft: width < 720 ? "15px" : "auto",
					paddingRight: width < 720 ? "15px" : "auto",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					backgroundColor: "#161616",
					maxWidth: "none !important",
				}}
			>
				<Typography
					align="center"
					sx={{
						fontSize: width < 720 ? "1.5rem" : "2.5rem",
						lineHeight: width < 720 ? "2rem" : "3rem",
						textAlign: "center",
						maxWidth: "700px",
						fontWeight: "700",
						color: "#FFF",
						padding: "20px",
					}}
				>
					DeFi: Como ter renda passiva com criptomoedas?
				</Typography>
			</Container>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
					mt: "100px",
					mb: "200px",
					backgroundColor: "#FFF",
				}}
			>
				<Container
					sx={{
						maxWidth: "770px !important",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "flex-start",
						paddingLeft: width < 720 ? "22px" : "auto",
						paddingRight: width < 720 ? "22px" : "auto",
					}}
				>
					<Typography sx={textStyleObj}>
						As finanças descentralizadas, ou DeFi, são serviços e
						produtos que dispensam o controle de intermediários,
						como bancos, corretoras e instituições financeiras e, ao
						invés disso, usam contratos inteligentes em redes
						blockchain.
					</Typography>
					<Typography sx={textStyleObj}>
						Sendo representadas pela primeira vez com o surgimento
						da criptomoeda Bitcoin, as DeFi têm ganhado cada vez
						mais espaço dentro do ecossistema dos criptoativos.
					</Typography>
					<Typography sx={textStyleObj}>
						Por possibilitar novos produtos e serviços financeiros
						que antes só eram ofertados por bancos e entidades
						centralizadas, as DeFi oferecem aos usuários muitas
						possibilidades de uso de seus critoativos – que,
						inclusive, podem gerar renda passiva para seus
						investidores.
					</Typography>
					<Typography sx={textStyleObj}>
						Como todo mercado financeiro, a volatilidade das
						criptomoedas é bastante comum. Por isso, a renda passiva
						pode ser uma possibilidade com bom retorno. Mas, sem um
						estudo prévio sobre o mercado, o investidor pode sair
						prejudicado.
					</Typography>
					<Typography sx={titleStyleObj}>
						Principais métodos
					</Typography>
					<Typography sx={textStyleObj}>
						Os principais métodos para criar fluxos e conseguir
						renda passiva com criptomoedas são: mineração,
						<i>staking, lending </i>(empréstimo),{" "}
						<i>liquidity pools</i>e sendo afiliado de programas.
					</Typography>
					<Typography sx={secondTitleStyleObj}>Mineração</Typography>
					<Typography sx={textStyleObj}>
						Mineração é o processo de “emprestar” seu poder
						computacional para proteger uma rede, registrando as
						transações do blockchain, em troca de uma recompensa. É
						o modo mais antigo de gerar renda passiva no setor das
						criptomoedas, apesar de não exigir reservas das mesmas.
					</Typography>
					<Typography sx={textStyleObj}>
						A forma mais comum de minerar é com o Bitcoin, mas esse
						não é um cenário fácil, já que são necessários
						computadores específicos, potentes e caros.
					</Typography>
					<Typography sx={textStyleObj}>
						Assim, é possível realizar o processo de mineração com
						outras operações dos ativos digitais, como para
						solucionar dúvidas matemáticas para a validação do
						blockchain.
					</Typography>
					<Typography sx={secondTitleStyleObj}>Staking</Typography>
					<Typography sx={textStyleObj}>
						<i>Staking</i> é o processo de validação de uma rede,
						chamado de “proof-of-stake” (PoS), empregado em redes da
						criptomoeda Ethereum (ETH), por exemplo. É uma forma de
						utilizar tokens para validar as transações de uma rede
						ou conceder esse processo para terceiros.
					</Typography>
					<Typography sx={textStyleObj}>
						Para que ocorra a validação, as criptomoedas ficam
						paralisadas na rede até a conclusão do processo. Quem as
						valida recebe taxas como forma de recompensa pelo
						processo.
					</Typography>
					<Typography sx={textStyleObj}>
						O risco, nesse caso, ocorre se o validador cometer um
						erro durante o movimento. No caso de uma punição, os
						tokens são retirados do validador. Este erro no processo
						de <i>staking</i> é chamado <i>slashing</i>.
					</Typography>
					<Typography sx={secondTitleStyleObj}>
						Lending (ou empréstimo)
					</Typography>
					<Typography sx={textStyleObj}>
						Muitos serviços de empréstimo ponto a ponto (P2P)
						permitem que você bloqueie seu dinheiro por um
						determinado período para receber o pagamento com juros
						no futuro.
					</Typography>
					<Typography sx={textStyleObj}>
						A taxa de juros pode ser fixa (determinada antes, pela
						plataforma) ou variável (determinada por você,
						dependendo das taxas atuais do mercado).
					</Typography>
					<Typography sx={textStyleObj}>
						Essa técnica é adequada para investidores de longo prazo
						que desejam aumentar suas carteiras sem muito esforço, e
						vale mencionar que garantir dinheiro, por meio de um bom
						contrato, pode ser arriscado.
					</Typography>
					<Typography sx={secondTitleStyleObj}>
						Liquidity Pools
					</Typography>
					<Typography sx={textStyleObj}>
						Os <i>liquidy pools</i> (traduzido como “piscinas de
						liquidez”) é um processo que acontece em corretoras
						descentralizadas, produzindo liquidez. O investidor pode
						bloquear as criptomoedas em um liquidity pool.
					</Typography>
					<Typography sx={textStyleObj}>
						O ativo alcança maior liquidez em transações de ponta a
						ponta, uma vez que não há necessidade de compra
						ordenada. Assim, o investidor pode retirar os tokens da
						“piscina” para acrescentar na transação.
					</Typography>
					<Typography sx={secondTitleStyleObj}>
						Programas de “afiliação”
					</Typography>
					<Typography sx={textStyleObj}>
						Algumas empresas de criptomoedas oferecem recompensas se
						mais pessoas entrarem em sua rede. Links de afiliados,
						referências ou qualquer desconto para novos usuários são
						exemplos disso.
					</Typography>
					<Typography sx={textStyleObj}>
						Esses programas podem ser um ótimo método para
						complementar a renda de pessoas com influência nas redes
						sociais. Para evitar a divulgação de empresas de baixa
						qualidade, é importante realizar pesquisas prévias sobre
						o trabalho que elas realizam.
					</Typography>
					<Typography sx={titleStyleObj}>
						Principais métodos
					</Typography>
					<Typography sx={textStyleObj}>
						Um dos riscos de tentar gerar renda passiva com
						criptomoedas é a compra de ativos de baixa qualidade.
						Isso ocorre quando as taxas de retorno são infladas
						artificialmente ou os investidores são enganados a
						comprar um ativo que, na verdade, possui pouco valor.
					</Typography>
					<Typography sx={textStyleObj}>
						Outro ponto é quando ocorre um erro do usuário. Como a
						indústria de DeFi ainda está em crescimento, configurar
						e manter essas fontes de renda requer bastante
						conhecimento técnico, por isso, a importância de estudar
						o assunto.
					</Typography>
					<Typography sx={textStyleObj}>
						Nesse caso, para alguns investidores mais experientes,
						pode ser melhor esperar até que esses serviços se tornem
						mais práticos ou usar aqueles que exigem menor
						conhecimento técnico.
					</Typography>
				</Container>
			</Box>
			<ResponsiveFooter />
		</Box>
	);
}
