import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";

export default function Post11() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const titleStyleObj = {
		fontSize: width > 720 ? "1.9em" : "1.6em",
		fontWeight: "700",
		mb: "calc(0.2em)",
		mt: "calc(13px + 1.2em)",
		lineHeight: "1.5",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "#111",
	};

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			<Container
				sx={{
					width: "100%",
					height: "30%",
					paddingTop: width < 720 ? "150px" : "200px",
					paddingBottom: width < 720 ? "50px" : "100px",
					paddingLeft: width < 720 ? "15px" : "auto",
					paddingRight: width < 720 ? "15px" : "auto",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					backgroundColor: "#161616",
					maxWidth: "none !important",
				}}
			>
				<Typography
					align="center"
					sx={{
						fontSize: width < 720 ? "1.5rem" : "2.5rem",
						lineHeight: width < 720 ? "2rem" : "3rem",
						textAlign: "center",
						maxWidth: "700px",
						fontWeight: "700",
						color: "#FFF",
						padding: "20px",
					}}
				>
					Dólar valoriza 7,5% em uma semana
				</Typography>
			</Container>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
					mt: "100px",
					mb: "200px",
					backgroundColor: "#FFF",
				}}
			>
				<Container
					sx={{
						maxWidth: "770px !important",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "flex-start",
						paddingLeft: width < 720 ? "22px" : "auto",
						paddingRight: width < 720 ? "22px" : "auto",
					}}
				>
					<Typography sx={textStyleObj}>
						Desde o dia 20 de abril, o dólar registra um aumento de
						7,57%. Na última semana, com fechamento na quarta-feira
						(27), a moeda subiu de R$4,62 para R$4,97. Vários
						fatores macroeconômicos, em conjunto com o cenário
						fiscal, foram responsáveis pela desvalorização do real.
					</Typography>
					<Typography sx={textStyleObj}>
						Segundo Luciano Costa, economista e sócio da Monte Bravo
						Investimentos, um dos principais motivadores é a conduta
						econômica mais dura (chamada de <i>hawkish</i>) do Fed
						(Federal Reserve), banco central dos Estados Unidos.
					</Typography>
					<Typography sx={textStyleObj}>
						Luciano aponta que os juros mais altos nos EUA elevam a
						atratividade de se investir na Renda Fixa
						norte-americana, o que aumenta os recursos no país e
						beneficia a moeda.
					</Typography>
					<Typography sx={textStyleObj}>
						Luciano aponta que os juros mais altos nos EUA elevam a
						atratividade de se investir na Renda Fixa
						norte-americana, o que aumenta os recursos no país e
						beneficia a moeda.
					</Typography>
					<Typography sx={textStyleObj}>
						Economistas também projetam um aperto cada vez mais
						intenso na política monetária, já que o movimento do
						banco central dos EUA faz com que investidores não
						tenham interesse por economias emergentes, como é o caso
						do Brasil.
					</Typography>
					<Typography sx={textStyleObj}>
						Carlos Macedo, economista e especialista em alocação de
						investimentos na Warren, diz que os movimentos de
						lockdown na China, principal parceiro comercial do
						Brasil, tendem a desvalorizar o real, quando comparado
						ao dólar, porque diminui a demanda externa por
						commodities brasileiras. “Movimento contrário ao que
						aconteceu no começo da guerra entre a Rússia e a
						Ucrânia”, diz.
					</Typography>
					<Typography sx={titleStyleObj}>Cenário interno</Typography>
					<Typography sx={textStyleObj}>
						A chefe de economia da Rico, Rachel de Sá, disse que o
						cenário fiscal também pode estar influenciando na
						valorização do dólar.
					</Typography>
					<Typography sx={textStyleObj}>
						“Com a incerteza de quanto o governo irá gastar, os
						investidores internacionais temem investir no Brasil e
						perder ganhos”, o que acaba desvalorizando o real.
					</Typography>
					<Typography sx={textStyleObj}>
						Rachel comenta que o assunto já estava em pauta para os
						mercados há tempos, mas tinha perdido o destaque, até
						que voltou nos últimos dias com o governo federal
						apontando que irá estudar a possibilidade de ceder mais
						de 5% no reajuste linear – por meio de aumentos em vale
						alimentação e diárias de viagens –, após funcionários
						públicos mostrarem insatisfação com a porcentagem.
					</Typography>
					<Typography sx={textStyleObj}>
						O secretário especial de Tesouro e Orçamento, Esteves
						Colnago, disse que um reajuste de 5% para funcionários
						públicos federais, em 2022, significaria cerca de R$
						12,6 bilhões no Orçamento de 2023, que será enviado em
						agosto. Segundo Esteves, com o cenário atual, a equipe
						econômica prevê uma reserva de R$ 11,7 bilhões.
					</Typography>
					<Typography sx={titleStyleObj}>
						Perspectivas para o dólar
					</Typography>
					<Typography sx={textStyleObj}>
						Especialistas afirmam que o dólar deve continuar se
						valorizando nos próximos meses por conta da alta da
						inflação, que aumenta os juros no Brasil e no mundo, e
						por conta do período eleitoral próximo, que costuma
						gerar instabilidade e volatilidade.
					</Typography>
					<Typography sx={textStyleObj}>
						De acordo com Luciano Costa, a inflação e a eleição são
						aspectos que reduzem a quantidade de fluxo internacional
						no Brasil, já que os investidores estrangeiros não
						costumam aplicar quando há muito risco. “Movimento esse
						que pode também reduzir a velocidade de apreciação do
						real”.
					</Typography>
				</Container>
			</Box>
			<ResponsiveFooter />
		</Box>
	);
}
