import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";

export default function Post9() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const titleStyleObj = {
		fontSize: width > 720 ? "1.9em" : "1.6em",
		fontWeight: "700",
		mb: "calc(0.2em)",
		mt: "calc(13px + 1.2em)",
		lineHeight: "1.5",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "#111",
	};

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			<Container
				sx={{
					width: "100%",
					height: "30%",
					paddingTop: width < 720 ? "150px" : "200px",
					paddingBottom: width < 720 ? "50px" : "100px",
					paddingLeft: width < 720 ? "15px" : "auto",
					paddingRight: width < 720 ? "15px" : "auto",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					backgroundColor: "#161616",
					maxWidth: "none !important",
				}}
			>
				<Typography
					align="center"
					sx={{
						fontSize: width < 720 ? "1.5rem" : "2.5rem",
						lineHeight: width < 720 ? "2rem" : "3rem",
						textAlign: "center",
						maxWidth: "700px",
						fontWeight: "700",
						color: "#FFF",
						padding: "20px",
					}}
				>
					Telegram permite transferência de criptomoedas via app
				</Typography>
			</Container>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
					mt: "100px",
					mb: "200px",
					backgroundColor: "#FFF",
				}}
			>
				<Container
					sx={{
						maxWidth: "770px !important",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "flex-start",
						paddingLeft: width < 720 ? "22px" : "auto",
						paddingRight: width < 720 ? "22px" : "auto",
					}}
				>
					<Typography sx={textStyleObj}>
						É isso mesmo, o Telegram vai permitir a transferência de
						criptomoedas via o App. Para quem não sabe, o Telegram é
						um dos maiores aplicativos de mensagens instantâneas do
						mundo. Nesta quinta-feira (28), eles anunciaram um novo
						recurso que permite aos seus 550 milhões de usuários
						transferirem criptomoedas entre si.
					</Typography>
					<Typography sx={textStyleObj}>
						Até então, somente o token TON pode ser enviado de forma
						nativa, porém, com o auxílio de um programa
						automatizado, será possível comprar bitcoin dentro do
						app. O The Open Network (TON) é um blockchain de
						terceira geração criado pelos irmãos Durov, que são os
						mesmos criadores do app de mensagens.
					</Typography>
					<Typography sx={textStyleObj}>
						Segundo o site do projeto, seu controle foi mais tarde
						transferido para a comunidade aberta TON, que desde
						então continua com seu desenvolvimento.
					</Typography>
					<Typography sx={titleStyleObj}>
						Por que ter a transferência de criptomoedas via
						Telegram?
					</Typography>
					<Typography sx={textStyleObj}>
						Segundo a Fundação TON, o objetivo da nova
						funcionalidade, que também é um bot, é permitir com que
						o envio de Toncoin seja como mandar uma mensagem de
						texto, de forma rápida e simples. Além disso, o novo
						serviço já foi usado por 800 mil contas do app, disse a
						organização.
					</Typography>
					<Typography sx={textStyleObj}>
						“Antecipamos que essa funcionalidade vai se estender
						além de serviços para o consumidor para pagamentos para
						negócios, para que as pessoas adquiram bens e serviços
						enviando toncoin pelo bot no app do Telegram”, declarou
						a comunidade.
					</Typography>
					<Typography sx={titleStyleObj}>
						Não só o telegram…
					</Typography>
					<Typography sx={textStyleObj}>
						Além do Telegram, outras empresas também estão aderindo
						às criptomoedas no seu universo, como Wine e Rappi.
					</Typography>
					<Typography sx={textStyleObj}>
						A expectativa dos especialistas é que a social acabe
						virando uma corretora de criptomoedas, mas o caminho do
						aplicativo ainda não está certo. Entretanto, a ideia
						parece deixar o Telegram em uma categoria dos SuperApps,
						tem que se pode fazer tudo em somente um lugar. Simples
						assim.
					</Typography>
					<Typography sx={textStyleObj}>
						Em 2021, o Twitter, agora com posse do Elon Musk,
						permitiu que os usuários pagassem gorjetas aos seus
						criadores de conteúdo favoritos. Dessa forma, eles
						utilizaram a Lightning Network, e o bitcoin poderia ser
						utilizado para essa ação.
					</Typography>
					<Typography sx={textStyleObj}>
						Ainda na semana passada, a plataforma de pagamentos
						Stripe anunciou que vai testar o “escalador” de ether
						Polygon, e que a rede social do homem mais rico do mundo
						seria seu primeiro cliente.
					</Typography>
					<Typography sx={titleStyleObj}>
						Quem você deve acompanhar?
					</Typography>
					<Typography sx={textStyleObj}>
						No Telegram, existem alguns grupos de investidores em
						criptomoedas. Se você tem interesse em utilizar a rede
						social e criar uma rede de networking nesse universo.
					</Typography>
					<Typography sx={titleStyleObj}>
						<b>1- Cypherpunks Brasil</b>
					</Typography>
					<Typography sx={textStyleObj}>
						O Bitcoin foi uma criação que surgiu no meio dos
						cypherpunks, pessoas fascinadas por tecnologia e
						criptografia. Os cypherpunks buscam mais liberdades por
						meio do uso de algoritmos, programação e da ciência da
						computação.
					</Typography>
					<Typography sx={textStyleObj}>
						Há um grupo e um canal, ambos com amplo conteúdo sobre
						privacidade, criptomoedas e liberdade. Se você quer
						realmente sentir o espírito ou ethos do Bitcoin, entre
						no grupo Cypherpunks Brasil na rede social.
					</Typography>
					<Typography sx={titleStyleObj}>
						<b>2- Canal do Cointimes News</b>
					</Typography>
					<Typography sx={textStyleObj}>
						Trazendo ainda o ethos do movimento cypherpunk, mas com
						foco em notícias está o grupo <b>Cointimes News.</b>{" "}
						Nesse grupo, você vai encontrar todas as notícias que
						saem no Cointimes, além de análises, memes e vídeos. Se
						você quer informação, entre no canal{" "}
						<b>Cointimes News.</b>
					</Typography>
					<Typography sx={titleStyleObj}>
						<b>3- Grupo da Huobi Brasil </b>
					</Typography>
					<Typography sx={textStyleObj}>
						A Huobi é uma das maiores corretoras de ativos digitais
						do mundo, sua presença no Brasil está se expandindo e
						eles estão fazendo isso por meio de um grupo bem
						estruturado no Telegram. Dentro do grupo, você vai
						encontrar análises exclusivas, notícias do mercado e
						promoções exclusivas da Huobi no Brasil e no mundo. Além
						disso, poderá contar com o auxílio de uma comunidade
						forte e crescente.
					</Typography>
					<Typography sx={titleStyleObj}>
						<b>4- CointraderMonitor</b>
					</Typography>
					<Typography sx={textStyleObj}>
						O <b>CointraderMonitor</b> é um dos sites que coletam as
						cotações e dados importantes do mercado brasileiro de
						criptomoedas.
					</Typography>
				</Container>
			</Box>
			<ResponsiveFooter />
		</Box>
	);
}
