import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export default function Post42() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const titleStyleObj = {
		fontSize: width > 720 ? "1.9em" : "1.6em",
		fontWeight: "700",
		mb: "calc(0.2em)",
		mt: "calc(13px + 1.2em)",
		lineHeight: "1.5",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "#111",
	};

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	const textTableStyleObj = {
		fontSize: width > 720 ? "1.3em" : "0.9em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.4",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			{!loading && (
				<React.Fragment>
					<Container
						sx={{
							width: "100%",
							height: "30%",
							paddingTop: width < 720 ? "150px" : "200px",
							paddingBottom: width < 720 ? "50px" : "100px",
							paddingLeft: width < 720 ? "15px" : "auto",
							paddingRight: width < 720 ? "15px" : "auto",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							backgroundColor: "#161616",
							maxWidth: "none !important",
						}}
					>
						<Typography
							align="center"
							sx={{
								fontSize: width < 720 ? "1.5rem" : "2.5rem",
								lineHeight: width < 720 ? "2rem" : "3rem",
								textAlign: "center",
								maxWidth: "700px",
								fontWeight: "700",
								color: "#FFF",
								padding: "20px",
							}}
						>
							Inflação: Preço dos carros possuem aumento de
							17,03%, segundo o IPC-10
						</Typography>
					</Container>
					<Box
						sx={{
							width: "100%",
							height: "100%",
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
							mt: "100px",
							mb: "200px",
							backgroundColor: "#FFF",
						}}
					>
						<Container
							sx={{
								maxWidth: "770px !important",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "flex-start",
								paddingLeft: width < 720 ? "22px" : "auto",
								paddingRight: width < 720 ? "22px" : "auto",
							}}
						>
							<Typography sx={textStyleObj}>
								<p>
									A inflação dos carros aumentou em 17,03%
									durante a pandemia, segundo o levantamento
									da análise da base de dados do IPC-10
									(Índice de Preços ao Consumidor-10). O valor
									da inflação inclui os preços dos veículos,
									combustíveis, peças, serviços correlatos e
									tarifas públicas, a exemplo das multas e
									licenciamento.
								</p>
								<br />
								<p>
									Além disso, o conflito da Rússia e Ucrânia
									acrescenta uma pressão adicional nas
									cotações do petróleo. Se nada mais impactar
									e aumentar em abril, o reajuste de
									combustíveis feito em março pela Petrobras
									elevará essa taxa para 22,08%.
								</p>
								<br />
								<p>
									Segundo Matheus Peçanha, pesquisador do
									Instituto Brasileiro de Economia (FGV/Ibre),
									o combustível é o foco da inflação em abril,
									mas, com a retomada das atividades
									pós-pandemia, pode acontecer novos reajustes
									em serviços que estavam meio congelados,
									como as oficinas.
								</p>
							</Typography>

							<Typography sx={titleStyleObj}>
								Dados do aumento
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Diante dos dados mais recentes do IPCA,
									dando destaque ao mês de fevereiro,
									visualizamos que os automóveis novos já
									acumulam alta de 22,94% em 18 meses de
									aumentos consecutivos, segundo o Instituto
									Brasileiro de Geografia e Estatística
									(IBGE).
								</p>
								<br />
								<p>
									Dessa forma, os automóveis usados já sobem
									há 20 meses, com alta acumulada de 22 66%.
									Além dos automóveis, as motocicletas sobem
									há 15 meses seguidos e já ficaram 17,72%
									mais caras. Outros serviços correlacionados
									também tiveram aumento, como seguro
									voluntário, emplacamento e conserto.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								Automóvel próprio como uma realidade distante
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Diante da inflação dos carros, o sonho do
									automóvel próprio torna-se cada vez mais
									distante para o povo brasileiro. Com os
									preços mais elevados e com a alta das taxas
									de juros, a realização da compra acaba cada
									vez mais se distanciando. Ou seja, a demanda
									tende a cair.
								</p>
								<br />
								<p>
									A taxa média de juros para aquisição de
									veículos foi de 26,86% em janeiro, segundo
									dados do Banco Central compilados por
									Bentes.
								</p>
								<br />
								<p>
									Além disso, depois de dois anos de fortes
									reajustes, o preço médio dos “hatchbacks”
									(sendo os carros mais baratos) ficou em R$
									79 mil em janeiro deste ano, segundo Cassio
									Pagliarini, da Bright Consulting,
									consultoria especializada no setor
									automotivo. Em 2016, o preço médio era de R$
									48 mil (ou R$ 62 mil se o valor for
									corrigido pela inflação).
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								Próximos passos
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Com a problemática da inflação, o crédito
									mais caro deve servir para desacelerar os
									aumentos nos próximos meses, mesmo com a
									persistência de encarecimento de custos,
									estima o economista Fabio Bentes, da
									Confederação Nacional do Comércio de Bens,
									Serviços e Turismo (CNC).
								</p>
								<br />
								<p>
									“Nenhum segmento do varejo depende tanto do
									crédito quanto do automotivo. Essa tentativa
									de recomposição de margem (de lucro) não vai
									longe.”
								</p>
							</Typography>
							<TableContainer component={Paper} elevation={0}>
								<Table
									sx={{ minWidth: 340 }}
									size="small"
									aria-label="a dense table"
								>
									<TableBody>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
												verticalAlign: "top",
											}}
										>
											<TableCell scope="row" align="left">
												<Typography
													sx={textTableStyleObj}
												>
													<b>1° – KIA</b>
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography
													sx={textTableStyleObj}
												>
													<b> 16,82%</b>
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
												verticalAlign: "top",
											}}
										>
											<TableCell scope="row" align="left">
												<Typography
													sx={textTableStyleObj}
												>
													2° – VOLKSWAGEN
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography
													sx={textTableStyleObj}
												>
													16,71%
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
												verticalAlign: "top",
											}}
										>
											<TableCell scope="row" align="left">
												<Typography
													sx={textTableStyleObj}
												>
													3° – TOYOTA
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography
													sx={textTableStyleObj}
												>
													15,07%
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
												verticalAlign: "top",
											}}
										>
											<TableCell scope="row" align="left">
												<Typography
													sx={textTableStyleObj}
												>
													4° – CHEVROLET
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography
													sx={textTableStyleObj}
												>
													13,94%
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
												verticalAlign: "top",
											}}
										>
											<TableCell scope="row" align="left">
												<Typography
													sx={textTableStyleObj}
												>
													5° – CAOA CHERY
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography
													sx={textTableStyleObj}
												>
													13,89%
												</Typography>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</Container>
					</Box>
					<ResponsiveFooter />
				</React.Fragment>
			)}
		</Box>
	);
}
