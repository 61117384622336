import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

export default function Post15() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const titleStyleObj = {
		fontSize: width > 720 ? "1.9em" : "1.6em",
		fontWeight: "700",
		mb: "calc(0.2em)",
		mt: "calc(13px + 1.2em)",
		lineHeight: "1.5",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "#111",
	};

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	const tableCell = {
		fontSize: width > 720 ? "1.3em" : "1em",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			<Container
				sx={{
					width: "100%",
					height: "30%",
					paddingTop: width < 720 ? "150px" : "200px",
					paddingBottom: width < 720 ? "50px" : "100px",
					paddingLeft: width < 720 ? "15px" : "auto",
					paddingRight: width < 720 ? "15px" : "auto",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					backgroundColor: "#161616",
					maxWidth: "none !important",
				}}
			>
				<Typography
					align="center"
					sx={{
						fontSize: width < 720 ? "1.5rem" : "2.5rem",
						lineHeight: width < 720 ? "2rem" : "3rem",
						textAlign: "center",
						maxWidth: "700px",
						fontWeight: "700",
						color: "#FFF",
						padding: "20px",
					}}
				>
					Imposto de renda: Entenda como declarar as criptomoedas
				</Typography>
			</Container>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
					mt: "100px",
					mb: "200px",
					backgroundColor: "#FFF",
				}}
			>
				<Container
					sx={{
						maxWidth: "770px !important",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "flex-start",
						paddingLeft: width < 720 ? "22px" : "auto",
						paddingRight: width < 720 ? "22px" : "auto",
					}}
				>
					<Typography sx={textStyleObj}>
						Chegou o momento de declarar imposto de renda no ano de
						2022, e também é válida para quem possui criptomoedas na
						carteira de investimentos. Diante da popularização de
						criptoativos no mundo virtual e no mercado financeiro,
						os investidores que aplicam em Bitcoin (BTC), Ethereum
						(ETH), entre outras moedas digitais, também são chamados
						a declarar o imposto de renda.
					</Typography>
					<Typography sx={textStyleObj}>
						A exigência para os investidores de criptomoedas quanto
						à declaração do imposto de renda é válida desde 2019
						pela Receita Federal, segundo a Instrução Normativa
						n°1.888. Essa nova exigência ainda passa por algumas
						formulações. Hoje em dia existem códigos personalizados
						para a declaração de acordo com os diferentes tipos de
						moedas digitais.
					</Typography>
					<Typography sx={titleStyleObj}>
						Afinal, o que é imposto de renda?
					</Typography>
					<Typography sx={textStyleObj}>
						Bom, o Imposto de Renda é um tributo cobrado anualmente
						pelo Governo Federal sobre os ganhos das pessoas e de
						empresas. Além disso, o valor é pago conforme os
						rendimentos declarados, a fim de entender que os
						cidadãos com renda maior pagam mais impostos.
					</Typography>
					<Typography sx={textStyleObj}>
						O IR é cobrado pelo desconto de um valor mensal sobre os
						rendimentos dos trabalhadores e das empresas no Brasil.
						Na lista de rendimentos tributáveis, entram ganhos como
						salários, aluguéis, prêmios de loterias e investimentos.
					</Typography>
					<Typography sx={textStyleObj}>
						Como o IR é uma tributação aplicada para cidadãos e
						companhias, ele se divide em duas categorias: o Imposto
						de Renda Pessoa Física (IRPF) e o Imposto de Renda
						Pessoa Jurídica (IRPJ).
					</Typography>
					<Typography sx={titleStyleObj}>
						Entenda como declarar criptomoedas
					</Typography>
					<Typography sx={textStyleObj}>
						A título de informação, os ganhos de capital obtidos com
						negociação de criptoativos também são tributados sempre
						que as vendas totais superam R$ 35 mil por mês. E com
						esse lucro, incidem as regras gerais de ganhos de
						capital. Segue a tabela da tributação anual progressiva:
					</Typography>
					<TableContainer sx={{ mt: "30px" }}>
						<Table size="small" aria-label="a dense table">
							<TableBody>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										<b>Ganhos</b>
									</TableCell>
									<TableCell sx={tableCell} align="right">
										<b>Tributo</b>
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Abaixo de R$ 5 milhões
									</TableCell>
									<TableCell sx={tableCell} align="right">
										15%
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Entre R$ 5 milhões e R$ 10 milhões
									</TableCell>
									<TableCell sx={tableCell} align="right">
										17,50%
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Entre R$ 10 milhões e R$ 30 milhões
									</TableCell>
									<TableCell sx={tableCell} align="right">
										20%
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Acima de R$ 30 milhões
									</TableCell>
									<TableCell sx={tableCell} align="right">
										22,50%
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
					<Typography sx={textStyleObj}>
						Além disso, o recolhimento do imposto precisa ser feito
						até o último dia útil do mês seguinte ao das transações,
						por meio de um Darf, usando código de receita 4600.
					</Typography>
					<Typography sx={textStyleObj}>
						Investindo em até R$ 35 mil, as vendas mensais de
						criptomoedas são isentas de Imposto de Renda.
						Entretanto, o limite considera o conjunto de
						criptoativos ou moedas virtuais negociados no Brasil ou
						no exterior, seja Bitcoin, Ethereum, Litecoin, Tether.
						Se as operações geram ganho de capital acima de R$ 35
						mil no mês, o montante sofrerá a tributação.
					</Typography>
					<Typography sx={textStyleObj}>
						<b>Atenção:</b> Criptomoedas que não foram vendidas e
						estão em posse do declarante não são taxadas pela
						Receita Federal, mas devem ser inseridas na declaração
						de Imposto de Renda. Veja o passo a passo:
					</Typography>
					<TableContainer sx={{ mt: "30px" }}>
						<Table size="small" aria-label="a dense table">
							<TableBody>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										<b>Código</b>
									</TableCell>
									<TableCell sx={tableCell} align="right">
										<b>Descrição</b>
									</TableCell>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										<b>Obrigatório a declarar</b>
									</TableCell>
									<TableCell sx={tableCell} align="right">
										<b>Exemplos</b>
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										01
									</TableCell>
									<TableCell sx={tableCell} align="right">
										Bitcoin (BTC)
									</TableCell>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Valor de aquisição for igual ou superior
										a R$ 5.000,00
									</TableCell>
									<TableCell
										sx={tableCell}
										align="right"
									></TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										02
									</TableCell>
									<TableCell sx={tableCell} align="right">
										Altcoins
									</TableCell>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Valor de aquisição for igual ou superior
										a <b>R$ 5.000,00</b>
									</TableCell>
									<TableCell sx={tableCell} align="right">
										Ether (ETH), Ripple (XRP), Bitcoin Cash
										(BCH), Litecoin (LTC), Cardano (ADA),
										Binance USD (BUSD), Binance Coin (BNB),
										Chiliz (CHZ), entre outros
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										03
									</TableCell>
									<TableCell sx={tableCell} align="right">
										Stablecoins
									</TableCell>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Valor de aquisição for igual ou superior
										a <b>R$ 5.000,00</b>
									</TableCell>
									<TableCell sx={tableCell} align="right">
										Tether (USDT), USD Coin (USDC),
										Brazilian Digital Token (BRZ), Binance
										USD (BUSD), DAI, True USD (TUSD), Gemini
										USD (GUSD, Paxos USD (PAX), Paxos Gold
										(PAXG), etc)
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										10
									</TableCell>
									<TableCell sx={tableCell} align="right">
										NFTS
									</TableCell>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Valor de aquisição for igual ou superior
										a <b>R$ 5.000,00</b>
									</TableCell>
									<TableCell sx={tableCell} align="right">
										Artes digitais, itens do jogo Axie
										Infinity e similares, terrenos no
										metaverso e outros ativos no formato de
										tokens não-fungíveis
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										99
									</TableCell>
									<TableCell sx={tableCell} align="right">
										Outros
									</TableCell>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Valor de aquisição for igual ou superior
										a <b>R$ 5.000,00</b>
									</TableCell>
									<TableCell sx={tableCell} align="right">
										Tokens de Precatório (MBPRK03), Tokens
										de Consórcio (MBCONS02), WiBZ (WBZ), etc
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
					<Typography sx={textStyleObj}>
						1- Acesse a ficha “Bens e Direitos”, selecione o grupo
						“08 – Criptoativos” e escolha um dos códigos
						disponíveis. São os seguintes:
					</Typography>
					<Typography sx={textStyleObj}>
						2- Informe o valor de aquisição dos criptoativos, e não
						o valor atual de mercado. Em caso de criptomoedas
						adquiridas por meio de mineração ou staking (recompensas
						em DeFi), o contribuinte deve informar valor de
						aquisição zerado.
					</Typography>
					<Typography sx={textStyleObj}>
						3- No campo “Discriminação”, detalhe o tipo e a
						quantidade do ativo, além do nome e CNPJ da empresa onde
						está custodiado. Em caso de custódia própria, informe o
						modelo de carteira digital usado (Ledger nano, Ledger X,
						Trezor, entre outras).
					</Typography>
					<Typography sx={textStyleObj}>
						Além disso, o valor de aquisição{" "}
						<b>deve ser igual ou superior a R$ 5.000,00</b>
					</Typography>
				</Container>
			</Box>
			<ResponsiveFooter />
		</Box>
	);
}
