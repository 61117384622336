import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";

export default function Post2() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const titleStyleObj = {
		fontSize: width > 720 ? "1.9em" : "1.6em",
		fontWeight: "700",
		mb: "calc(0.2em)",
		mt: "calc(13px + 1.2em)",
		lineHeight: "1.5",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "#111",
	};

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			<Container
				sx={{
					width: "100%",
					height: "30%",
					paddingTop: width < 720 ? "150px" : "200px",
					paddingBottom: width < 720 ? "50px" : "100px",
					paddingLeft: width < 720 ? "15px" : "auto",
					paddingRight: width < 720 ? "15px" : "auto",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					backgroundColor: "#161616",
					maxWidth: "none !important",
				}}
			>
				<Typography
					align="center"
					sx={{
						fontSize: width < 720 ? "1.5rem" : "2.5rem",
						lineHeight: width < 720 ? "2rem" : "3rem",
						textAlign: "center",
						maxWidth: "700px",
						fontWeight: "700",
						color: "#FFF",
						padding: "20px",
					}}
				>
					Frete marítimo brasileiro registra alta de preços
				</Typography>
			</Container>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
					mt: "100px",
					mb: "200px",
					backgroundColor: "#FFF",
				}}
			>
				<Container
					sx={{
						maxWidth: "770px !important",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "flex-start",
						paddingLeft: width < 720 ? "22px" : "auto",
						paddingRight: width < 720 ? "22px" : "auto",
					}}
				>
					<Typography sx={textStyleObj}>
						As recentes medidas de isolamento social na China,
						causadas pelo aumento do número de casos de Covid-19 no
						país nos últimos dias, trouxeram impactos diretos para a
						economia do Brasil. Um dos problemas atuais enfrentados
						no Brasil que é um reflexo direto do contexto asiático é
						a alta no custo do frete de navios cargueiros. Sejam
						eles com destino ao Brasil ou à China.
					</Typography>
					<Typography sx={textStyleObj}>
						Segundo entidades do segmento, em entrevista à CNN
						Brasil, a restauração total da cadeia produtiva global
						não deve acontecer até o fim deste ano. Fato esse que
						também impacta diretamente a vida dos próprios
						consumidores.
					</Typography>
					<Typography sx={textStyleObj}>
						Em Xangai, cidade chinesa que possui o maior porto do
						mundo, o lockdown já é adotado rigidamente há três
						semanas. Cerca de 25 milhões de pessoas foram afetadas
						pela medida e tiveram suas rotinas alteradas.
					</Typography>
					<Typography sx={titleStyleObj}>
						Alta no valor do frete
					</Typography>
					<Typography sx={textStyleObj}>
						Jesualdo Silva, diretor presidente da Associação
						Brasileira dos Terminais Portuários (ABTP), afirmou que
						a paralisação de navios cargueiros na China gerou
						escassez de oferta global e, como consequência, alta no
						valor do frete.
					</Typography>
					<Typography sx={textStyleObj}>
						Ele explicou que, no auge da pandemia, um container
						vindo da China para o Brasil custava em média US$ 6.800.
						Com a diminuição dos casos da doença, o preço médio do
						frete desceu para US$ 6.200.
					</Typography>
					<Typography sx={textStyleObj}>
						Porém, o valor, atualmente, já voltou ao seu patamar
						mais alto. Antes da pandemia, o valor de um container
						era de, aproximadamente, US$ 3.000.
					</Typography>
					<Typography sx={textStyleObj}>
						“Essa elevação no preço já começa a acontecer. Navios
						que vinham da Ásia para cá e vice-versa não sabem se vão
						poder descarregar e seguir o percurso. Não sai e nem
						entra container, praticamente, na China. E sem dúvidas
						que uma escassez gera um aumento [no custo]. Muitos
						produtos são fabricados no país asiático e esse fluxo
						não está acontecendo, por causa da Covid-19. A gente vai
						começar a ver falta de containers”, afirmou o diretor
						presidente da ABTP.
					</Typography>
					<Typography sx={textStyleObj}>
						Jesualdo disse ainda que o prejuízo econômico já é uma
						realidade nos principais portos do Brasil, como o de
						Santos e do Paranaguá (PR). Segundo ele, a situação se
						intensificará cada vez mais, enquanto as restrições na
						China se mantiverem rígidas.
					</Typography>
					<Typography sx={textStyleObj}>
						“Os primeiros que estão sentindo esses efeitos são os
						portos de Santos e Paranaguá. E o agravamento da
						situação depende de quanto tempo o lockdown vai demorar
						a ser totalmente liberado. O mercado reage à
						possibilidade da manutenção das medidas de isolamento
						social. Se durar mais de 15 dias, o cenário começa a
						aumentar mais ainda o preço de tudo. Temos que torcer
						que a situação seja regularizada o mais rápido
						possível”, complementou.
					</Typography>
					<Typography sx={titleStyleObj}>Produção chinesa</Typography>
					<Typography sx={textStyleObj}>
						Ainda sobre a situação na China, o Centro Nacional de
						Navegação Transatlântica (Centronave) afirmou que as
						medidas restritivas causaram “interrupções
						significativas nas atividades locais de fabricação e
						transporte, o que inevitavelmente adiciona uma pressão
						extra no sistema logístico do país”.
					</Typography>
					<Typography sx={textStyleObj}>
						Claudio Loureiro de Souza, diretor-executivo da
						Centronave, destacou que o desempenho geral da produção
						chineses é o menor desde 2020, início da crise do
						coronavírus. E isso influencia diretamente a economia
						brasileira.
					</Typography>
					<Typography sx={textStyleObj}>
						“Ainda é prematuro prever quando ocorrerá a normalização
						da cadeia logística na China, o que continuará
						impactando todo o mercado global. O índice Caixin China
						General Manufacturing PMI, que mede o desempenho geral
						da produção do país, teve queda em março de 2022, com as
						taxas mais baixas vistas desde fevereiro de 2020”,
						afirmou Claudio.
					</Typography>
					<Typography sx={textStyleObj}>
						“Outra preocupação a ser considerada, no futuro, será a
						possível sobrecarga de volumes acumulados de contêineres
						e o possível congestionamento na armazenagem de
						mercadorias assim que os lockdowns forem sendo
						encerrados e as restrições removidas”, concluiu.
					</Typography>
					<Typography sx={titleStyleObj}>Consequências</Typography>
					<Typography sx={textStyleObj}>
						Segundo o coordenador do MBA em Gestão Financeira da
						FGV, Ricardo Teixeira, a alta no custo do frete tem
						impacto direto para os brasileiros. Ele afirma que, caso
						o lockdown permaneça por muito tempo, o Brasil pode
						presenciar uma inflação de demanda. Ou seja, quando o
						preço dos produtos sobe por falta de oferta.{" "}
					</Typography>
					<Typography sx={textStyleObj}>
						“Além da dificuldade em fazer o carregamento dos navios,
						você tem a escassez dos produtos a serem carregados na
						China. Os dois pontos fazem o preço do frete aumentar,
						que faz o preço dos produtos subir. Essa alta no custo
						para o consumidor final se dá pela escassez de produtos
						aqui no Brasil”, diz o professor.
					</Typography>
					<Typography sx={textStyleObj}>
						“A produção caiu muito na China, gerando uma queda na
						importação para os outros países, assim como aqui no
						país. Nós começamos a receber menos produtos, o que faz
						uma inflação de demanda no Brasil. Nós temos mais
						procura que oferta, e por isso o preço sobre. Tudo isso
						acontece pelo avanço da Covid-19 na China novamente”,
						explica.
					</Typography>
					<Typography sx={textStyleObj}>
						Teixeira também destacou que a indústria de carros do
						Brasil é a que mais vai ser afetada pela situação atual
						de Xangai. A cidade registra novas contaminações mesmo
						com as medidas restritivas.
					</Typography>
					<Typography sx={textStyleObj}>
						Em relação à exportação, segundo Ricardo Teixeira, o
						setor com a maior perda no Brasil deve ser a pecuária.
					</Typography>
					<Typography sx={textStyleObj}>
						“O que mais importamos da China, sem dúvida, são peças
						automotivas. Importamos uma gama muito grande de
						produtos que vêm de Xangai, mas o principal, sim, são
						partes e sistemas dos nossos veículos. Essas peças vão
						ficar mais caras, com certeza, para o público caso a
						situação não melhore por lá”, finalizou.
					</Typography>
				</Container>
			</Box>
			<ResponsiveFooter />
		</Box>
	);
}
