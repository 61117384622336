import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";

export default function Post50() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const titleStyleObj = {
		fontSize: width > 720 ? "1.9em" : "1.6em",
		fontWeight: "700",
		mb: "calc(0.2em)",
		mt: "calc(13px + 1.2em)",
		lineHeight: "1.5",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "#111",
	};

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			{!loading && (
				<React.Fragment>
					<Container
						sx={{
							width: "100%",
							height: "30%",
							paddingTop: width < 720 ? "150px" : "200px",
							paddingBottom: width < 720 ? "50px" : "100px",
							paddingLeft: width < 720 ? "15px" : "auto",
							paddingRight: width < 720 ? "15px" : "auto",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							backgroundColor: "#161616",
							maxWidth: "none !important",
						}}
					>
						<Typography
							align="center"
							sx={{
								fontSize: width < 720 ? "1.5rem" : "2.5rem",
								lineHeight: width < 720 ? "2rem" : "3rem",
								textAlign: "center",
								maxWidth: "700px",
								fontWeight: "700",
								color: "#FFF",
								padding: "20px",
							}}
						>
							Previsões do Banco Central para 2022 têm alta da
							inflação
						</Typography>
					</Container>
					<Box
						sx={{
							width: "100%",
							height: "100%",
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
							mt: "100px",
							mb: "200px",
							backgroundColor: "#FFF",
						}}
					>
						<Container
							sx={{
								maxWidth: "770px !important",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "flex-start",
								paddingLeft: width < 720 ? "22px" : "auto",
								paddingRight: width < 720 ? "22px" : "auto",
							}}
						>
							<Typography sx={textStyleObj}>
								<p>
									Nesta segunda-feira (21) o Banco Central
									anunciou que os analistas do mercado
									financeiro aumentaram a estimativa da
									inflação para este ano – de 6,45% para
									6,59%. Os dados foram obtidos na última
									semana, em pesquisa com mais de 100
									instituições financeiras, e o resultado
									representou a décima alta seguida nas
									previsões do mercado.
								</p>
								<br />
								<p>
									A meta de inflação para este ano, definida
									pelo Conselho Monetário Nacional (CMN), é de
									3,5%, e será considerada “formalmente
									cumprida” se oscilar entre 2% e 5%.
									Entretanto, desde o ano passado, analistas
									do mercado já previam a inflação de 2022
									acima do teto tido como meta.
								</p>
								<br />
								<p>
									Se confirmadas as previsões do mercado para
									a inflação em 2022, este será o segundo ano
									consecutivo de estouro na meta da inflação.
									No ano passado, o IPCA somou 10,06%, o maior
									desde 2015.
								</p>
								<br />
								<p>
									Para alcançar a meta de inflação definida
									pelo Conselho Monetário Nacional (CMN), o
									Banco Central aumenta ou diminui a taxa
									básica de juros da economia no país, a
									Selic.
								</p>
								<br />
								<p>
									As previsões de alta na inflação começaram
									com mais intensidade após o aumento do preço
									dos combustíveis, anunciado na semana
									retrasada pela Petrobras, em meio à elevação
									do petróleo, reflexo da guerra na Ucrânia.
								</p>
								<br />
								<p>
									Para 2023, o mercado subiu a estimativa da
									inflação de 3,70% para 3,75%. A meta foi
									fixada em 3,25% e será considerada
									formalmente cumprida se oscilar entre 1,75%
									e 4,75%.{" "}
								</p>
							</Typography>

							<Typography sx={titleStyleObj}>
								Produto Interno Bruto (PIB)
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Segundo dados do Banco Central, o mercado
									também elevou a previsão de crescimento do
									Produto Interno Bruto deste ano, de 0,49%
									para 0,50%.
								</p>
								<br />
								<p>
									O PIB representa a soma de todos os bens e
									serviços produzidos no país, servindo para
									medir a evolução de uma economia.
								</p>
								<br />
								<p>
									A elevação aconteceu após a divulgação, pelo
									Instituto Brasileiro de Geografia e
									Estatística (IBGE), do crescimento do PIB do
									ano passado de 4,6%. Porém, para 2023, o
									mercado abaixou a expectativa da alta do PIB
									– de 1,43% para 1,30%.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								Taxa de Juros
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Com a alta da inflação, o mercado financeiro
									também projeta um aumento na taxa básica de
									juros da economia, a Selic, neste ano.
								</p>
								<br />
								<p>
									Analistas estimavam, até então, que a Selic
									avançaria para até 12,75% ao ano no fim de
									2022. Agora, eles projetam que a taxa pode
									chegar, até o fim do ano, a 13%. Atualmente,
									a Selic se encontra em 11,75% ao ano.
								</p>
								<br />
								<p>
									Já para 2023, a expectativa do mercado para
									a Selic é que ela suba de 8,75% para 9% ao
									ano. Assim, o mercado segue estimando queda
									dos juros no ano que vem.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>Dólar</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Segundo especialistas, a projeção para o fim
									de 2022 é que a taxa de câmbio permaneça em
									R$ 5,30. Já para o fim de 2023, a projeção
									subiu de R$ 5,21 para R$ 5,22 por dólar.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								Balança Comercial
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Para o saldo da balança comercial – que
									representa o resultado do total de
									exportações menos o total de importações – a
									projeção em 2022 subiu de US$ 63,50 bilhões
									para US$ 64,50 bilhões de resultado
									positivo. Para 2023, a estimativa dos
									especialistas é um superávit de US$ 51
									bilhões
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								Investimento Estrangeiro
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									A previsão do relatório do Banco Central
									para a entrada de investimentos estrangeiros
									no Brasil neste ano permaneceu estável, em
									US$ 59 bilhões. Para o próximo ano, a
									estimativa subiu, de US$ 69,2 bilhões de
									ingresso para US$ 69,5 bilhões.
								</p>
							</Typography>
						</Container>
					</Box>
					<ResponsiveFooter />
				</React.Fragment>
			)}
		</Box>
	);
}
