import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";

export default function Post8() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const titleStyleObj = {
		fontSize: width > 720 ? "1.9em" : "1.6em",
		fontWeight: "700",
		mb: "calc(0.2em)",
		mt: "calc(13px + 1.2em)",
		lineHeight: "1.5",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "#111",
	};

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			<Container
				sx={{
					width: "100%",
					height: "30%",
					paddingTop: width < 720 ? "150px" : "200px",
					paddingBottom: width < 720 ? "50px" : "100px",
					paddingLeft: width < 720 ? "15px" : "auto",
					paddingRight: width < 720 ? "15px" : "auto",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					backgroundColor: "#161616",
					maxWidth: "none !important",
				}}
			>
				<Typography
					align="center"
					sx={{
						fontSize: width < 720 ? "1.5rem" : "2.5rem",
						lineHeight: width < 720 ? "2rem" : "3rem",
						textAlign: "center",
						maxWidth: "700px",
						fontWeight: "700",
						color: "#FFF",
						padding: "20px",
					}}
				>
					Na China, Apple perde 1º lugar na venda de celulares
				</Typography>
			</Container>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
					mt: "100px",
					mb: "200px",
					backgroundColor: "#FFF",
				}}
			>
				<Container
					sx={{
						maxWidth: "770px !important",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "flex-start",
						paddingLeft: width < 720 ? "22px" : "auto",
						paddingRight: width < 720 ? "22px" : "auto",
					}}
				>
					<Typography sx={textStyleObj}>
						Há alguns meses, a Apple se tornou, pela primeira vez em
						seis anos, a marca de smarthphones mais vendida na
						China. Porém, recentemente, perdeu o posto para seus
						rivais chineses, depois de sofrer uma grande queda de
						vendas no primeiro trimestre.
					</Typography>
					<Typography sx={textStyleObj}>
						Segundo relatórios publicados esta semana, a Apple ocupa
						o terceiro lugar de vendas, atrás de marcas chineses de
						aparelhos Android. Essa mudança de classificação do
						mercado começou a ocorrer com a desaceleração econômica
						e as restrições da Covid-19 na China, que diminuíram os
						gastos do consumidor.
					</Typography>
					<Typography sx={textStyleObj}>
						No primeiro trimestre, as vendas de celulares na China
						caíram 14%, uma queda próxima “dos níveis vistos durante
						o grave impacto da pandemia no primeiro trimestre de
						2020”, disse a Counterpoint Research em um relatório na
						quinta-feira (28).
					</Typography>
					<Typography sx={titleStyleObj}>Vendas da Apple</Typography>
					<Typography sx={textStyleObj}>
						As vendas da Apple caíram 23% nos três primeiros meses
						do ano, em comparação com o trimestre anterior,
						acrescentou a pesquisa do Counterpoint Research.
					</Typography>
					<Typography sx={textStyleObj}>
						A empresa teve um crescimento considerável na China em
						2021, logo após o lançamento do iPhone 13. Sua
						participação no mercado chinês agora é de 17,9%, em
						comparação com 21,7% no último trimestre do ano passado,
						encerrado em dezembro.
					</Typography>
					<Typography sx={textStyleObj}>
						Um relatório da Canalys, publicado nesta sexta-feira
						(29), também mostrou que a Apple caiu de líder do
						mercado de celulares na China para o terceiro lugar.
					</Typography>
					<Typography sx={textStyleObj}>
						Segundo os dados do relatório – que rastreia as remessas
						dos fabricantes para os pontos de venda, ao invés das
						vendas diretamente para os consumidores –, os embarques
						do primeiro trimestre do ano caíram 36% em relação ao
						trimestre anterior.
					</Typography>
					<Typography sx={titleStyleObj}>Motivos</Typography>
					<Typography sx={textStyleObj}>
						O analista sênior da Counterpoint Research, Ivam Lam,
						atribui o declínio de vendas da Apple com a
						desaceleração econômica na China, que afetou a forma
						como a população consome.
					</Typography>
					<Typography sx={textStyleObj}>
						Marcas chinesas, como Vivo Honor e Oppo, se saíram
						melhor que a Apple nos últimos meses porque suas vendas
						já se recuperaram depois de sofrerem com o forte
						desempenho do iPhone 13, lançado no último semestre de
						2021.
					</Typography>
					<Typography sx={textStyleObj}>
						Assim, pode-se dizer que um declínio sazonal na demanda
						e uma grande incerteza econômica arrastaram o mercado
						nos primeiros meses deste ano.
					</Typography>
					<Typography sx={textStyleObj}>
						Mas, não é apenas a fraca demanda que tem impactado a
						Apple na China. A empresa também está enfrentando
						desafios na produção, decorrentes dos bloqueios
						econômicos no país.
					</Typography>
					<Typography sx={textStyleObj}>
						A Foxconn, uma grande fornecedora da Apple, interrompeu
						a produção em sua fábrica de Shenzhen por alguns dias no
						último mês, quando a cidade impôs um bloqueio de Covid.
						A Pegatron, uma montadora de iPhone, também suspendeu as
						operações em suas fábricas de Xangai e Kunshan no início
						de abril.
					</Typography>
					<Typography sx={titleStyleObj}>Perspectivas</Typography>
					<Typography sx={textStyleObj}>
						“Não acho que os dados do segundo trimestre melhorarão
						muito, pois os bloqueios contínuos continuarão afetando
						a disposição dos consumidores de gastar”, disse Lam em
						entrevista à CNN Business. Atualmente, existem bloqueios
						totais ou parciais em 27 cidades da China, o que afeta
						aproximadamente 165 milhões de pessoas.
					</Typography>
					<Typography sx={textStyleObj}>
						Essas restrições forçaram o fechamento de muitas
						empresas e afetaram, em grandes proporções, a atividade
						econômica da região. Xangai, maior cidade do país e um
						núcleo financeiro global, está fechada há mais de um
						mês.
					</Typography>
					<Typography sx={textStyleObj}>
						Nos últimos dois meses, a economia da China teve um
						desaceleramento acentuado, com uma diminuição das vendas
						em março, pela primeira vez em mais de um ano. Ao mesmo
						tempo, o desemprego subiu para 6%, um número recorde, em
						31 grandes cidades.
					</Typography>
					<Typography sx={textStyleObj}>
						“Esses fatores, combinados com a tendência de queda da
						demanda já visível no mercado de smartphones da China
						antes da nova onda de pandemia, impactaram
						significativamente o setor”, afirmou Mengmeng Zhang,
						analista de pesquisa da Counterpoint Research.
					</Typography>
					<Typography sx={textStyleObj}>
						A analista prevê que a demanda por smarthphones na China
						permaneça “insatisfatória”, em decorrência de um
						sentimento fraco por parte dos consumidores e da falta
						de inovações para estimular esse mercado.
					</Typography>
					<Typography sx={textStyleObj}>
						O CEO da Apple, Tim Cook, disse na quinta (28), durante
						uma teleconferência de resultados, que as crescentes
						restrições à Covid na China, em conjunto com a escassez
						de silício no setor, afetariam o próximo trimestre da
						empresa entre US$ 4 bilhões e US$ 8 bilhões.
					</Typography>
					<Typography sx={textStyleObj}>
						“Os problemas da cadeia de suprimentos continuam sendo
						um vento contrário na China e isso pesará no crescimento
						do trimestre de junho”, afirmou Dan Ives, analista da
						Wedbush Securities.
					</Typography>
				</Container>
				<Typography sx={textStyleObj}>
					No começo de abril, a Canalys alertou que os fornecedores de
					smarthphones de todo o mundo enfrentariam grandes incertezas
					devido aos bloqueios contínuos da China, à guerra entre
					Rússia e Ucrânia e à ameaça de inflação.
				</Typography>
			</Box>
			<ResponsiveFooter />
		</Box>
	);
}
