import {
	Box,
	Typography,
	Container,
	Divider,
	Button,
	Grid,
	List,
	ListItem,
} from "@mui/material";
import React from "react";
import ResponsiveAppBar from "../../components/AppBar";
import ResponsiveFooter from "../../components/Footer";
import useWindowDimensions from "../../hooks/windowDimension";
import { useLocation } from "react-router-dom";
import cartao_c6_home from "../../assets/cartao_c6_home.png";
import useLoader from "../../hooks/useLoader";
import Preloader from "../../components/Preloader";

const divGridStyle = {
	height: "80px",
	width: "100%",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	backgroundColor: "var(--bgGray)",
	borderRadius: "5px",
	textAlign: "center",
	padding: "0 15px",
};

const typographyGridStyle = {
	fontSize: "1.1rem",
	fontWeight: "bold",
	color: "var(--textGray)",
};

export default function C6Bank() {
	const { width } = useWindowDimensions();
	const { search } = useLocation();
	const loading = useLoader();

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && <Preloader />}

			<Container
				sx={{
					width: "100vw",
					height: "100%",
					mt: width < 720 ? "150px" : "200px",
					mb: "40px",
				}}
			>
				<Typography variant="h5" align="center">
					Cartão de Crédito C6 Bank
				</Typography>
				<Typography
					align="center"
					sx={{
						fontSize: "1.1rem",
						fontWeight: "600",
						mt: "10px",
					}}
				>
					Mais de 16 milhões de pessoas já escolheram o C6 Bank. Só
					falta você.
					<br />
					Escolha o cartão de crédito que é a sua cara!
				</Typography>
				<Box
					sx={{
						width: "100%",
						height: "auto",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						mt: "30px",
					}}
				>
					<img
						alt="cartao_c6_home"
						src={cartao_c6_home}
						loading="lazy"
						style={{
							width: "80%",
							height: "100%",
							maxWidth: "450px",
						}}
					/>
				</Box>
			</Container>
			<Divider variant="middle" />
			<Container
				sx={{
					display: width < 720 ? "flex" : "none",
					alignItems: "center",
					justifyContent: "center",
					mt: "100px",
					mb: "100px",
				}}
			>
				<div id="cartoes.informacash_top_mob"></div>
			</Container>
			<Container
				sx={{
					display: width > 720 ? "flex" : "none",
					alignItems: "center",
					justifyContent: "center",
					mt: "100px",
					mb: "100px",
				}}
			>
				<div id="cartoes.informacash_top_desk"></div>
			</Container>
			<Container
				sx={{
					width: "100vw",
					height: "100%",
					mt: "100px",
					mb: "80px",
					pl: "7%",
					pr: "4%",
				}}
			>
				<Typography
					variant="p"
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						color: "var(--textGray)",
					}}
				>
					Mais de 16 milhões de pessoas já escolheram o C6 Bank. Só
					falta você. Peça seu cartão de crédito e débito com a cor e
					o nome que preferir, pontos que não expiram, até 6 cartões
					adicionais sem anuidade, acesso a salas VIP em aeroportos,
					pagamento por aproximação e muito mais!
				</Typography>
			</Container>
			<Container
				sx={{
					width: "100%",
					height: "auto",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					maxWidth: "810px",
				}}
			>
				<Button
					variant="contained"
					size="large"
					disableElevation
					sx={{
						fontWeight: "700",
						backgroundColor: "var(--infoCashBlue)",
					}}
					href={
						window.location.hostname !== "localhost"
							? `https://cartoes.informacash.com.br/c6bank-solicitar${search}`
							: `./c6bank-solicitar`
					}
				>
					Quero saber mais
				</Button>
			</Container>
			<Container
				sx={{
					width: "100%",
					height: "100%",
					mt: "80px",
					mb: "40px",
					pl: "7%",
					pr: "4%",
				}}
			>
				<Typography
					variant="h5"
					align="center"
					sx={{
						lineHeight: "2rem",
						fontWeight: "700",
						mb: "10px",
					}}
				>
					Tipos de Cartões
				</Typography>
				<Divider variant="middle" sx={{ mb: "20px" }} />
				<Typography
					align="left"
					sx={{
						mt: "20px",
						fontSize: "1.2rem",
						fontWeight: "700",
					}}
				>
					Cartão C6 Débito
				</Typography>
				<List
					sx={{
						listStyleType: "disc",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							Anuidade: grátis
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							Função: somente débito
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							1 C6 Tag gratuita
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							Mastercard Surpreenda
						</Typography>
					</ListItem>
				</List>
				<Typography
					align="left"
					sx={{
						mt: "20px",
						fontSize: "1.2rem",
						fontWeight: "700",
					}}
				>
					Cartão C6
				</Typography>
				<List
					sx={{
						listStyleType: "disc",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							Anuidade: grátis
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							Função: débito e crédito
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							Cartão adicional grátis
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							1 C6 Tag gratuita
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							Mastercard Surpreenda
						</Typography>
					</ListItem>
				</List>
				<Typography
					align="left"
					sx={{
						mt: "20px",
						fontSize: "1.2rem",
						fontWeight: "700",
					}}
				>
					Cartão C6 Platinum
				</Typography>
				<List
					sx={{
						listStyleType: "disc",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							Anuidade: grátis
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							Função: débito e crédito
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							Cartão adicional grátis
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							1 C6 Tag gratuita
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							Mastercard Surpreenda; Isenção de rolha em
							restaurantes; Priceless Cities e muito mais.
						</Typography>
					</ListItem>
				</List>
				<Typography
					align="left"
					sx={{
						mt: "20px",
						fontSize: "1.2rem",
						fontWeight: "700",
					}}
				>
					Cartão C6 Carbon
				</Typography>
				<List
					sx={{
						listStyleType: "disc",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							Anuidade: 12x R$85
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							Função: débito e crédito
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							Até 6 cartões adicionais grátis
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							US$1 = 2,5 pontos
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							4 C6 Tags gratuita
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							Mastercard Surpreenda; Acesso ilimitado a sala VIP
							Mastercard Black em guarulhos; Isenção de rolha em
							restaurantes e muito mais.
						</Typography>
					</ListItem>
				</List>
			</Container>
			<Container
				sx={{
					display: width < 720 ? "flex" : "none",
					alignItems: "center",
					justifyContent: "center",
					mt: "100px",
					mb: "100px",
				}}
			>
				<div id="cartoes.informacash_mid_mob"></div>
			</Container>
			<Container
				sx={{
					display: width > 720 ? "flex" : "none",
					alignItems: "center",
					justifyContent: "center",
					mt: "100px",
					mb: "100px",
				}}
			>
				<div id="cartoes.informacash_mid_desk"></div>
			</Container>
			<Container sx={{ width: "90%", mb: "40px", mt: "30px" }}>
				<Grid container spacing={2} alignItems="stretch">
					<Grid item xs={12} sm={6}>
						<div style={divGridStyle}>
							<Typography
								variant="p"
								align="center"
								sx={typographyGridStyle}
							>
								Escolha a cor e personalize o seu nome no cartão
							</Typography>
						</div>
					</Grid>
					<Grid item xs={12} sm={6}>
						<div style={divGridStyle}>
							<Typography
								variant="p"
								align="center"
								sx={typographyGridStyle}
							>
								Programa de pontos que não expiram
							</Typography>
						</div>
					</Grid>
					<Grid item xs={12} sm={6}>
						<div style={divGridStyle}>
							<Typography
								variant="p"
								align="center"
								sx={typographyGridStyle}
							>
								Cartões adicionais
							</Typography>
						</div>
					</Grid>
					<Grid item xs={12} sm={6}>
						<div style={divGridStyle}>
							<Typography
								variant="p"
								align="center"
								sx={typographyGridStyle}
							>
								Cobertura internacional e pagamento por
								aproximação
							</Typography>
						</div>
					</Grid>
				</Grid>
			</Container>
			<Container
				sx={{
					width: "100%",
					height: "auto",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					maxWidth: "810px",
					mb: "20%",
				}}
			>
				<Button
					variant="contained"
					size="large"
					disableElevation
					sx={{
						fontWeight: "700",
						backgroundColor: "var(--infoCashBlue)",
					}}
					href={
						window.location.hostname !== "localhost"
							? `https://cartoes.informacash.com.br/c6bank-solicitar${search}`
							: `./c6bank-solicitar`
					}
				>
					Quero solicitar meu cartão
				</Button>
			</Container>
			<ResponsiveFooter />
		</Box>
	);
}
