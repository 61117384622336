import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";

export default function Post26() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const titleStyleObj = {
		fontSize: width > 720 ? "1.9em" : "1.6em",
		fontWeight: "700",
		mb: "calc(0.2em)",
		mt: "calc(13px + 1.2em)",
		lineHeight: "1.5",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "#111",
	};

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			{!loading && (
				<React.Fragment>
					<Container
						sx={{
							width: "100%",
							height: "30%",
							paddingTop: width < 720 ? "150px" : "200px",
							paddingBottom: width < 720 ? "50px" : "100px",
							paddingLeft: width < 720 ? "15px" : "auto",
							paddingRight: width < 720 ? "15px" : "auto",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							backgroundColor: "#161616",
							maxWidth: "none !important",
						}}
					>
						<Typography
							align="center"
							sx={{
								fontSize: width < 720 ? "1.5rem" : "2.5rem",
								lineHeight: width < 720 ? "2rem" : "3rem",
								textAlign: "center",
								maxWidth: "700px",
								fontWeight: "700",
								color: "#FFF",
								padding: "20px",
							}}
						>
							Inadimplência ultrapassou a marca de 65 milhões de
							pessoas
						</Typography>
					</Container>
					<Box
						sx={{
							width: "100%",
							height: "100%",
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
							mt: "100px",
							mb: "200px",
							backgroundColor: "#FFF",
						}}
					>
						<Container
							sx={{
								maxWidth: "770px !important",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "flex-start",
								paddingLeft: width < 720 ? "22px" : "auto",
								paddingRight: width < 720 ? "22px" : "auto",
							}}
						>
							<Typography sx={textStyleObj}>
								<p>
									Em fevereiro, o nível de inadimplência no
									Brasil ultrapassou a marca de 65 milhões de
									pessoas pela primeira vez desde maio de
									2020, momento em que o mundo estava no auge
									da primeira onda da pandemia do Coronavírus.
								</p>
								<br />
								<p>
									Divulgado na última semana, o último
									levantamento do Serasa, o Mapa da
									Inadimplência e Renegociação de Dívidas do
									Brasil, mostra que 65.169.146 de pessoas
									encontravam-se inadimplentes no país. Nos
									últimos dois anos, o número só é menor que
									outros dois períodos: em abril de 2020, com
									65.908.612 de devedores, e em maio do mesmo
									ano, com 65.231.943.
								</p>
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									O dado de fevereiro deste ano é maior que o
									registrado em todos os outros meses entre
									2020 e 2022, inclusive ao período antes da
									pandemia. Há 3 anos, em fevereiro de 2019,
									por exemplo, 62.172.903 estavam em situação
									de dívida — 3 milhões a menos.{" "}
								</p>
								<br />
								<p>
									Segundo analistas, em entrevista à CNN
									Brasil, atualmente, a inadimplência no
									Brasil possui características diferentes
									daquelas observadas em 2020, no início das
									crises trazidas pela pandemia.
								</p>
								<br />
								<p>
									“Nós atingimos um número de pessoas
									semelhantes ao do início da pandemia, mas as
									causas são diferentes”, disse Patrícia
									Camillo, gerente da Serasa.
								</p>
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Segundo ela, o aumento da inflação e dos
									juros a partir do começo da pandemia fez com
									que, atualmente, a inadimplência atingisse
									patamares elevados.
								</p>
								<br />
								<p>
									Em junho de 2020, a Selic chegava em uma
									mínima histórica de 2,25% ao ano, enquanto o
									IPCA (Índice de Preços ao Consumidor-Amplo)
									no acumulado de 12 meses era 2,13%.
									Atualmente, a taxa básica de juros é 11,75%
									ao ano, enquanto a inflação dos últimos 12
									meses chegou a 11,30%.
								</p>
								<br />
								<p>
									De acordo com Patrícia, apesar de o início
									da pandemia ter sido difícil para a
									economia, o período contou com incentivos e
									estímulos governamentais – o que impediu que
									os dados fossem ainda piores.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								Cenário atual
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									No cenário atual, com a reabertura do
									comércio e a retirada das medidas de
									restrições, a economia, aos poucos, se
									estabiliza, com segmentos com taxas de
									inadimplência historicamente maiores, como
									no caso de bancos e cartões.
								</p>
								<br />
								<p>
									“O segmento de bancos e cartões geralmente
									registra índices mais elevados de
									inadimplentes ao comparar com outros
									setores, mas ultimamente esse movimento tem
									sido um pouco mais acentuado, pois as
									pessoas usam muito o cartão para
									alimentação, que foi muito impactada pela
									inflação”, explicou Patrícia.
								</p>
								<br />
								<p>
									Segundo o Mapa do Serasa, em fevereiro de
									2022, o segmento de bancos e cartões
									registrou 28,63% no nível de inadimplência.
									Seguido pelos serviços de utilidade
									(23,19%), varejo (12,54%) e telecomunicações
									(7,43%).
								</p>
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									A categoria dos bancos e cartões sempre
									liderou as pesquisas, mas teve um pequeno
									crescimento neste ano, se comparado com o
									auge da pandemia em 2020. Em abril e maio de
									2020, o índice era 27,58% e 27,59%,
									respectivamente.
								</p>
								<br />
								<p>
									Segundo a Serasa, a somatória das categorias
									não chega em 100% pois há diversos outros
									setores com indicadores bem pequenos –
									insignificantes para a divulgação dos
									resultados.
								</p>
								<br />
								<p>
									Patrícia Camillo também destacou que o total
									das dívidas está chegando perto do que era
									antes da pandemia. Em 2019, o valor chegava
									a R$ 230 milhões, mas isso mudou em 2020,
									levando o índice a patamares menores, entre
									R$ 208 milhões e R$ 210 milhões.{" "}
								</p>
								<br />
								<p>
									No último levantamento, em fevereiro de
									2022, o valor da dívida total foi de R$ 221
									milhões, acima do registrado nos meses
									anteriores e se aproximando dos números
									anteriores a 2020.
								</p>
								<br />
							</Typography>
							<Typography sx={titleStyleObj}>
								Cenário atual
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									O economista Gesner Oliveira disse que,
									atualmente, os motivos da inadimplência
									diferem daquelas observadas pelos
									especialistas durante o primeiro semestre de
									2020. “Naquele período houve uma queda
									súbita na renda da população, na perda de
									emprego e na retirada de trabalho”, afirmou.
								</p>
								<br />
								<p>
									“Agora, temos uma certa recuperação na
									economia, ainda muito modesta, mas o que
									muda é que tivemos também um encarecimento
									do crédito. Os juros cresceram muito rápido,
									em nível recorde desde 2021”, completou.
								</p>
								<br />
								<p>
									Segundo ele, o principal problema é que a
									renda e o emprego da população não
									acompanharam o aumento dos juros. Com a
									renda estagnada, o brasileiro perde seu
									poder de compra. Porém, Oliveira
									complementou que ainda existe uma demanda
									pelo comércio, reprimida pelo contexto da
									pandemia.
								</p>
								<br />
								<p>
									“É natural que haja uma demanda reprimida,
									mas não acho que seja uma falsa sensação de
									poder de compra. No setor de vestuários,
									tecidos e calcados, por exemplo,
									compreensível que as pessoas estejam
									voltando a sair e fazer compras”, afirmou.
								</p>
								<br />
								<p>
									Para ele, é necessário um projeto de
									educação financeira mais forte no Brasil,
									explicando todo processo de compras,
									créditos e juros.{" "}
								</p>
								<br />
								<p>
									“A educação financeira é um problema no
									mundo, observamos o comportamento dos
									consumidores nos EUA e há muito
									endividamento imprudente. Existem pessoas
									marginalizadas que não têm acesso a esse
									tipo de conhecimento”, ressaltou.
								</p>
								<br />
								<p>
									A Serasa divulgará o próximo índice de
									inadimplência do Brasil, referente ao mês de
									março, no próximo domingo, dia 17 de abril.
								</p>
							</Typography>
						</Container>
					</Box>
					<ResponsiveFooter />
				</React.Fragment>
			)}
		</Box>
	);
}
