import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";

export default function Post1() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const titleStyleObj = {
		fontSize: width > 720 ? "1.9em" : "1.6em",
		fontWeight: "700",
		mb: "calc(0.2em)",
		mt: "calc(13px + 1.2em)",
		lineHeight: "1.5",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "#111",
	};

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			<Container
				sx={{
					width: "100%",
					height: "30%",
					paddingTop: width < 720 ? "150px" : "200px",
					paddingBottom: width < 720 ? "50px" : "100px",
					paddingLeft: width < 720 ? "15px" : "auto",
					paddingRight: width < 720 ? "15px" : "auto",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					backgroundColor: "#161616",
					maxWidth: "none !important",
				}}
			>
				<Typography
					align="center"
					sx={{
						fontSize: width < 720 ? "1.5rem" : "2.5rem",
						lineHeight: width < 720 ? "2rem" : "3rem",
						textAlign: "center",
						maxWidth: "700px",
						fontWeight: "700",
						color: "#FFF",
						padding: "20px",
					}}
				>
					EUA eleva a sua taxa de juros: Entenda o impacto da economia
					mundial
				</Typography>
			</Container>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
					mt: "100px",
					mb: "200px",
					backgroundColor: "#FFF",
				}}
			>
				<Container
					sx={{
						maxWidth: "770px !important",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "flex-start",
						paddingLeft: width < 720 ? "22px" : "auto",
						paddingRight: width < 720 ? "22px" : "auto",
					}}
				>
					<Typography sx={textStyleObj}>
						Balançando a economia e batendo o recorde de inflação e
						elevando a taxa de juros, o país enfrenta a maior
						inflação em mais de 40 anos, e iniciou ciclo de alta de
						juros em março.
					</Typography>
					<Typography sx={textStyleObj}>
						O Comitê Federal de Mercado Aberto (FOMC) do Federal
						Reserve, o banco central dos Estados Unidos, anunciou
						nesta quarta-feira (4) que elevou a taxa de juros do
						país em 0,5 ponto porcentual, para 0,75% a 1% ao ano.
					</Typography>
					<Typography sx={textStyleObj}>
						A decisão ocorreu, na verdade, para conter a inflação
						nos Estados Unidos, que já está no maior nível em mais
						de 40 anos, e foi mais prejudicado pelo conflito entre
						Rússia e Ucrânia.
					</Typography>
					<Typography sx={textStyleObj}>
						Além disso, o Fed afirmou que elevações adicionais na
						meta da faixa de juros seriam apropriadas, e que
						pretende desacelerar, e posteriormente suspender, a
						redução de tamanho do seu balanço apenas quando as
						reservas estiverem “um pouco acima” do nível considerado
						consistente com as reservas amplas.
					</Typography>
					<Typography sx={textStyleObj}>
						Dessa forma, os limites de reduções mensais de seu
						balanço subirão após três meses, de US$ 30 bilhões para
						US$ 60 bilhões para os títulos do Tesouro (Treasuries) e
						de US$ 17,5 bilhões para US$ 35 bilhões para os títulos
						MBS.
					</Typography>
					<Typography sx={titleStyleObj}>
						Impacto para a economia mundial
					</Typography>
					<Typography sx={textStyleObj}>
						Quando se trata da maior economia do mundo, uma taxa
						mais alta pode causar uma desaceleração da atividade
						global. Ou seja, com juros mais altos, os títulos do
						Tesouro americano ficam mais atraentes e{" "}
						<b>
							os investidores tendem a colocar menos dinheiro em
							países emergentes
						</b>
						, a exemplo do Brasil.
					</Typography>
					<Typography sx={textStyleObj}>
						E qual o impacto disso? Se tiver menos dinheiro vindo de
						fora, pode fazer com que o dólar suba e,
						consequentemente, deixar os
						<b> produtos importados mais caros.</b>
					</Typography>
					<Typography sx={titleStyleObj}>
						Impactos para a economia no dia a dia brasileiro
					</Typography>
					<Typography sx={textStyleObj}>
						Diante do atual cenário, a alta dos juros no Brasil e
						nos Estados Unidos pode influenciar em diversas questões
						na vida dos brasileiros. Com o aumento na América do
						Norte, produtos importados devem ficar mais caros, como
						já mencionado acima.
					</Typography>
					<Typography sx={textStyleObj}>
						Além disso, uma taxa básica de juros próxima de 5% dos
						Fed Funds poderia causar uma forte desaceleração da
						atividade global, o que resultaria na reversão dos
						preços de commodities, com prejuízos ao crescimento do
						Produto Interno Bruto (PIB) brasileiro.
					</Typography>
					<Typography sx={textStyleObj}>
						Entretanto, a alteração do Banco Central do Brasil na
						taxa Selic também deve mexer com a economia. Com juros
						mais altos, fica mais caro pegar crédito. Ou seja, isso
						afeta não só os consumidores, como também as empresas e
						o próprio governo, desestimulando o consumo.
					</Typography>
					<Typography sx={titleStyleObj}>
						O que acontece nos próximos capítulos?
					</Typography>
					<Typography sx={textStyleObj}>
						Diante do cenário de incertezas, Powell afirmou que os
						dirigentes não buscarão adicionar mais incertezas ao
						mercado, e que a economia norte-americana possui
						condições de aguentar uma política monetária mais
						apertada.
					</Typography>
					<Typography sx={textStyleObj}>
						Ou seja, a avaliação de Powell é que a economia dos EUA
						não parece estar próxima de uma recessão, mas que o
						combate à inflação deve ser “desafiador”.
					</Typography>
					<Typography sx={textStyleObj}>
						Para o presidente do Fed, há indícios de que o núcleo do
						PCE, indicador de inflação referência da autarquia, está
						atingindo o seu pico, e que a inflação deve começar a
						arrefecer. “Mas não queremos apenas evidências, sobre a
						inflação, queremos progresso”, finalizou.
					</Typography>
				</Container>
			</Box>
			<ResponsiveFooter />
		</Box>
	);
}
