import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";

export default function Post35() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const titleStyleObj = {
		fontSize: width > 720 ? "1.9em" : "1.6em",
		fontWeight: "700",
		mb: "calc(0.2em)",
		mt: "calc(13px + 1.2em)",
		lineHeight: "1.5",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "#111",
	};

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			{!loading && (
				<React.Fragment>
					<Container
						sx={{
							width: "100%",
							height: "30%",
							paddingTop: width < 720 ? "150px" : "200px",
							paddingBottom: width < 720 ? "50px" : "100px",
							paddingLeft: width < 720 ? "15px" : "auto",
							paddingRight: width < 720 ? "15px" : "auto",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							backgroundColor: "#161616",
							maxWidth: "none !important",
						}}
					>
						<Typography
							align="center"
							sx={{
								fontSize: width < 720 ? "1.5rem" : "2.5rem",
								lineHeight: width < 720 ? "2rem" : "3rem",
								textAlign: "center",
								maxWidth: "700px",
								fontWeight: "700",
								color: "#FFF",
								padding: "20px",
							}}
						>
							Expansão do e-commerce: Pix parcelado cresce nas
							instituições financeiras
						</Typography>
					</Container>
					<Box
						sx={{
							width: "100%",
							height: "100%",
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
							mt: "100px",
							mb: "200px",
							backgroundColor: "#FFF",
						}}
					>
						<Container
							sx={{
								maxWidth: "770px !important",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "flex-start",
								paddingLeft: width < 720 ? "22px" : "auto",
								paddingRight: width < 720 ? "22px" : "auto",
							}}
						>
							<Typography sx={textStyleObj}>
								<p>
									A expansão do e-commerce na pandemia faz com
									que o Pix e o Carnê Digital atinjam um ápice
									de crescimento dentro do mercado financeiro.
									Instituições financeiras, como bancos e
									fintechs, já introduziram os novos serviços
									de créditos digitais de forma mais ágil e
									popular.
								</p>
								<br />
								<p>
									O Pix foi lançado em 2020, por incrível que
									pareça. De uma forma bem recente, o Pix se
									transformou em um dos principais meios de
									pagamento na sociedade. Esse sistema foi
									pensado para pessoas físicas, de pequenas a
									grandes empresas. Recentemente, bancos e
									fintechs fizeram algo bom, ficando ainda
									melhor: o lançamento dos parcelamentos via
									Pix.
								</p>
								<br />
								<p>
									Segundo a especialista em educação
									financeira Cássia D’aquino, a pandemia fez
									com que o e-commerce viralizasse ainda mais
									dentro do mercado financeiro. As novas
									modalidades sugeridas pelos bancos e
									fintechs, faz com que esse parcelamento se
									torne ainda mais acessível para a população.
								</p>
							</Typography>

							<Typography sx={titleStyleObj}>
								Inovação das empresas e fintechs
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Muitas empresas quando decidiram entrar no
									mercado de trabalho, ao mesmo tempo que no
									mercado financeiro, o Pix ainda não havia
									sido lançado. Ou seja, a nível de novidade,
									o foco acabava sendo no parcelamento de
									boletos, como é o caso da BoletoFlex.
								</p>
								<br />
								<p>
									Diante da criação dos parcelamentos de
									boletos, a fintech agora oferece esse meio
									de pagamento através do Pix, sendo agora o
									carro-chefe da empresa. A nível de
									curiosidade, a empresa da BoletoFlex oferece
									a opção de parcelamento em 4 vezes sem juros
									ou em 24 vezes com juros a partir de 1,99%.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								Dados e utilização do Pix
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Segundo dados, pessoas entre 25 e 34 anos
									são as que mais utilizam a opção de
									parcelamento por meio da ferramenta. Isso
									equivale a cerca de 40% do público-alvo.
									Além disso, o público que mais utiliza o Pix
									parcelado está na faixa-etária entre 35 e 44
									anos. Isso equivale a 37%
								</p>
								<br />
								<p>
									Em relação ao perfil das empresas, o
									levantamento de dados sugere que as pequenas
									e médias empresas focam nessa modalidade.
									Além disso, entre as 46 varejistas
									analisadas, as de pequeno porte representam
									35% do total, enquanto as médias são 30%, e
									as grandes, 13%.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								A conquista do Pix
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									O Pix, sendo uma ferramenta de pagamento
									instantâneo, registra mais de 1 bilhão de
									transações todo mês no país. O recorde ,
									segundo o Banco Central, ocorreu em dezembro
									de 2021, quando alcançou 1.463.265.548. A
									nível de atualizações, no mês de fevereiro,
									foram computadas 1.367.981.508 operações.
								</p>
								<br />
								<p>
									Para uma ferramenta mais nova e digital, não
									foi menos eficiente que os outros métodos.
									Até mesmo bancos mais tradicionais estão
									utilizando a nova modalidade, trazendo o
									serviço de crédito por meio da ferramenta.
								</p>
								<br />
								<p>
									O Bradesco foi o primeiro a aderir a nova
									modalidade de crédito via Pix, a nível dos
									maiores bancos do Brasil. Segundo os dados
									do CNN Brasil Business, o banco atendeu 850
									mil clientes, somando R$ 170 milhões. O
									banco cobra juros a partir de 2,36% ao mês
									pelo serviço, e com prazo de até 60 meses
									para pagar, conforme o perfil do cliente e
									características da operação.
								</p>
								<br />
								<p>
									O Santander, ao contrário, acabou lançando
									no mês passado a ferramenta “Divida o seu
									Pix”, que possui a opção de crédito
									disponível exclusivamente pelo aplicativo do
									banco.
								</p>
								<br />
								<p>
									O valor mínimo de contratação é de R$ 100 e
									o valor máximo, limitado às condições
									pré-aprovadas para o cliente. O banco
									comunicou ser possível dividir em até 24
									vezes, com 59 dias para que as parcelas
									comecem a ser debitadas diretamente na conta
									corrente.
								</p>
								<br />
								<p>
									As taxas variam a partir de 2,09% ao mês. O
									serviço é destinado para clientes pessoas
									físicas do Santander e a transferência pode
									ser direcionada para contas de pessoas
									físicas e pessoas jurídicas.
								</p>
							</Typography>
						</Container>
					</Box>
					<ResponsiveFooter />
				</React.Fragment>
			)}
		</Box>
	);
}
