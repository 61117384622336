import {
	Box,
	Typography,
	Container,
	Divider,
	ListItem,
	List,
	Button,
} from "@mui/material";
import React from "react";
import ResponsiveAppBar from "../../components/AppBar";
import useWindowDimensions from "../../hooks/windowDimension";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ResponsiveFooter from "../../components/Footer";
import superdigital from "../../assets/superdigital.png";
import Preloader from "../../components/Preloader";
import useLoader from "../../hooks/useLoader";

export default function SecondPage() {
	const { width } = useWindowDimensions();
	const loading = useLoader();

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && <Preloader />}
			<Container
				sx={{
					width: "100vw",
					height: "100%",
					mt: width < 720 ? "150px" : "200px",
					mb: "10%",
				}}
			>
				<Typography variant="h5" align="center">
					Cartão da Superdigital é a sua melhor escolha! Simples,
					rápido, digital e sem burocracia!
				</Typography>
				<Box
					sx={{
						width: "100%",
						height: "auto",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						mt: "30px",
					}}
				>
					<img
						alt="superdigital"
						src={superdigital}
						loading="lazy"
						style={{
							width: "80%",
							height: "100%",
							maxWidth: "450px",
						}}
					/>
				</Box>
			</Container>
			<Divider variant="middle" />
			<Container
				sx={{
					display: width > 720 ? "flex" : "none",
					alignItems: "center",
					justifyContent: "center",
					mt: "100px",
					mb: "100px",
				}}
			>
				<div id="cartoes.informacash_top_mob"></div>
			</Container>
			<Container
				sx={{
					display: width < 720 ? "flex" : "none",
					alignItems: "center",
					justifyContent: "center",
					mt: "100px",
					mb: "100px",
				}}
			>
				<div id="cartoes.informacash_top_desk"></div>
			</Container>
			<Container
				sx={{
					width: "100vw",
					height: "100%",
					mt: "70px",
					mb: "30px",
					pl: "7%",
					pr: "4%",
				}}
			>
				<Typography
					variant="h5"
					align="left"
					sx={{
						lineHeight: "2rem",
						fontWeight: "700",
						mb: "20px",
					}}
				>
					Por que escolher a SuperDigital?
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					A Superdigital pensa em alguns tipos de perfis que estão na
					sociedade brasileira, e eles sabem que obter um cartão de
					crédito é uma necessidade populacional. Dessa forma, o banco
					digital possui dois tipos de cartões de crédito, a exemplo
					dos que vem a seguir.
					<br />
				</Typography>

				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "5px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Você não corre riscos de ter a conta negativa, pois a compra
					é debitada no mesmo instante.
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "5px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Você possui uma maior praticidade na compra de serviços
					online, como compras em sites, assinaturas e em aplicativos.
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "5px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Cartão de crédito físico sem anuidade.
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "30px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Programa de parcerias com o Extra, PontoFrio, Casas Bahia,
					Natura, Renner e outras lojas para aproveitar os benefícios
					de um cliente da Superdigital.
				</Typography>
				<Container
					sx={{
						display: width < 720 ? "flex" : "none",
						alignItems: "center",
						justifyContent: "center",
						mt: "100px",
						mb: "100px",
					}}
				>
					<div
						class="pg-lazy"
						data-gpt-parent="cartoes.informacash_mid_mob"
					></div>
				</Container>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					Além de todos esses benefícios, você consegue usar o seu
					cartão de crédito tanto físico, quanto online. Clique no
					botão para obter o seu cartão gratuitamente. Comece a
					controlar os seus gastos de uma forma mais simples e
					econômica agora!
				</Typography>
			</Container>
			<Container
				sx={{
					width: "100vw",
					height: "100%",
					mt: "60px",
					mb: "40px",
					pl: "7%",
					pr: "4%",
				}}
			>
				<Typography
					variant="h5"
					align="left"
					sx={{
						lineHeight: "2rem",
						fontWeight: "700",
						mb: "10px",
					}}
				>
					Qual a melhor escolha para o seu perfil?
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.3rem",
						lineHeight: "2rem",
						fontWeight: "700",
						mb: "20px",
						mt: "20px",
						color: "#434343",
					}}
				>
					Cartão de crédito para pessoas negativadas:
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "5px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Para esse perfil, a Superdigital possui um cartão de crédito
					pré-pago, que não há consulta do SPC/SERASA.
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "5px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					O cartão é usado em lojas físicas ou online, tanto no
					Brasil, como em compras do exterior.
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "20px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					O cartão de crédito é sem anuidade e sem juros no pagamento.
				</Typography>
				<Container
					sx={{
						display: width < 720 ? "flex" : "none",
						alignItems: "center",
						justifyContent: "center",
						mt: "100px",
						mb: "100px",
					}}
				>
					<div
						class="pg-lazy"
						data-gpt-parent="cartoes.informacash_mid_mob"
					></div>
				</Container>
				<Container
					sx={{
						display: width > 720 ? "flex" : "none",
						alignItems: "center",
						justifyContent: "center",
						mt: "100px",
						mb: "100px",
					}}
				>
					<div
						class="pg-lazy"
						data-gpt-parent="cartoes.informacash_mid_desk"
					></div>
				</Container>
				<Typography
					align="left"
					sx={{
						fontSize: "1.3rem",
						lineHeight: "2rem",
						fontWeight: "700",
						mb: "20px",
						mt: "30px",
						color: "#434343",
					}}
				>
					Cartão de crédito pré-pago:
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "5px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Para esse tipo de perfil, você consegue recarregar o seu
					cartão com o valor que deseja, sem precisar carregar
					dinheiro no seu bolso.
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "5px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Planejamento no controle de gastos.
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "30px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Maior segurança na hora do pagamento.
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "40px",
					}}
				>
					Além de todos esses perfis, o cartão de crédito da
					Superdigital é internacional. Tá afim de fazer um pedido do
					exterior? O seu cartão é aceito em qualquer compra, de todo
					e em qualquer lugar. Dessa forma, você consegue fazer
					reservas em hotéis de forma mais simples e segura com a
					Superdigital Mastercard. Ah, e sem taxas de câmbio!
				</Typography>
				<Typography
					align="left"
					variant="h5"
					sx={{
						lineHeight: "2.1rem",
						mb: "10px",
						fontWeight: "700",
					}}
				>
					Como solicitar o seu cartão de crédito SuperDigital?
				</Typography>
				<Container
					sx={{
						display: width < 720 ? "flex" : "none",
						alignItems: "center",
						justifyContent: "center",
						mt: "100px",
						mb: "100px",
					}}
				>
					<div
						class="pg-lazy"
						data-gpt-parent="cartoes.informacash_mid_mob"
					></div>
				</Container>
				<List
					sx={{
						listStyleType: "decimal",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<ListItem
						sx={{
							justifyContent: "left",
							pb: "0 !important",
							pt: "0 !important",
							pl: "20px",
							mb: "5px",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "1rem",
								display: "list-item",
							}}
						>
							Clique em <b>solicitar agora</b>
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "left",
							pb: "0 !important",
							pt: "0 !important",
							pl: "20px",
							mb: "5px",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "1rem",
								display: "list-item",
							}}
						>
							Abra sua conta na Superdigital
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "left",
							pb: "0 !important",
							pt: "0 !important",
							pl: "20px",
							mb: "5px",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "1rem",
								display: "list-item",
							}}
						>
							Preencha os seus dados
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "left",
							pb: "0 !important",
							pt: "0 !important",
							pl: "20px",
							mb: "5px",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "1rem",
								display: "list-item",
							}}
						>
							Faça o seu 1º depósito na conta
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "left",
							pb: "0 !important",
							pt: "0 !important",
							pl: "20px",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "1rem",
								display: "list-item",
							}}
						>
							Receba em até 10 dias úteis o seu cartão Mastercard®
							em casa
						</Typography>
					</ListItem>
				</List>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					Quer ter benefícios no cartão de crédito 100% gratuito? Faça
					parte da Superdigital!
				</Typography>
			</Container>
			<Container
				sx={{
					width: "100%",
					height: "100%",
					mb: "50px",
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Button
					variant="contained"
					size="large"
					disableElevation
					sx={{
						fontWeight: "700",
						backgroundColor: "var(--infoCashBlue)",
					}}
					href="https://igoal.go2cloud.org/aff_c?offer_id=5533&aff_id=1673&url_id=44090"
				>
					Solicitar agora
				</Button>
			</Container>
			<ResponsiveFooter />
		</Box>
	);
}
