import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";

export default function Post36() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const titleStyleObj = {
		fontSize: width > 720 ? "1.9em" : "1.6em",
		fontWeight: "700",
		mb: "calc(0.2em)",
		mt: "calc(13px + 1.2em)",
		lineHeight: "1.5",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "#111",
	};

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			{!loading && (
				<React.Fragment>
					<Container
						sx={{
							width: "100%",
							height: "30%",
							paddingTop: width < 720 ? "150px" : "200px",
							paddingBottom: width < 720 ? "50px" : "100px",
							paddingLeft: width < 720 ? "15px" : "auto",
							paddingRight: width < 720 ? "15px" : "auto",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							backgroundColor: "#161616",
							maxWidth: "none !important",
						}}
					>
						<Typography
							align="center"
							sx={{
								fontSize: width < 720 ? "1.5rem" : "2.5rem",
								lineHeight: width < 720 ? "2rem" : "3rem",
								textAlign: "center",
								maxWidth: "700px",
								fontWeight: "700",
								color: "#FFF",
								padding: "20px",
							}}
						>
							Poluição via satélites pode alterar a visão do céu
							noturno
						</Typography>
					</Container>
					<Box
						sx={{
							width: "100%",
							height: "100%",
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
							mt: "100px",
							mb: "200px",
							backgroundColor: "#FFF",
						}}
					>
						<Container
							sx={{
								maxWidth: "770px !important",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "flex-start",
								paddingLeft: width < 720 ? "22px" : "auto",
								paddingRight: width < 720 ? "22px" : "auto",
							}}
						>
							<Typography sx={textStyleObj}>
								<p>
									O céu noturno é um dos fenômenos mais
									bonitos e admiráveis entre os seres humanos
									da terra. Porém, de acordo com os
									astrônomos, eles acreditam que em menos de
									uma década, um em cada 15 pontos de luz no
									céu noturno será um satélite em movimento.
								</p>
								<br />
								<p>
									Diante da proliferação de satélites no céu,
									em breve será possível avistar em apenas
									algumas centenas de quilômetros acima da
									Terra. Além disso, aumenta a questão de lixo
									espacial.
								</p>
								<br />
								<p>
									“Pela primeira vez na história da
									humanidade, não teremos acesso ao céu
									noturno da maneira que vimos”, disse
									Samantha Lawler, professora assistente de
									astronomia da Universidade de Regina, no
									Canadá.
								</p>
							</Typography>

							<Typography sx={titleStyleObj}>
								Expectativas dos satélites no céu
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									A professora de astronomia, Samantha Lawler,
									está observando de sua fazenda em
									Saskatchewan, Canadá, como o número de
									satélites ativos têm se multiplicado em
									cerca de mil em 2017 para mais de cinco
									hoje.
								</p>
								<br />
								<p>
									Além disso, Lawler e dois outros astrônomos
									canadenses publicaram um artigo em dezembro
									no The Astronomical Journal prevendo que, em
									menos de uma década, um em cada 15 pontos de
									luz no céu noturno será realmente um
									satélite em movimento.
								</p>
								<br />
								<p>
									De acordo com os astrônomos, os satélites
									são ainda mais bizarros quando são vistos
									pelo telescópio. Em termos de regulamentação
									internacional de números de satélite,
									refletividade e transmissão, especialistas
									como Lawler acreditam que o impacto na
									pesquisa astronômica se intensificará.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								Empreendedores estrelários
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									A nível de empreendedorismo, a SpaceX de
									Elon Musk é responsável por cerca de um
									terço de todos os satélites ativos em
									órbita, mais do que qualquer outra empresa
									ou país, incluindo o governo dos EUA.
								</p>
								<br />
								<p>
									A SpaceX já lançou mais de dois mil
									satélites com planos de lançar pelo menos
									mais 42 mil para sua megaconstelação chamada
									Starlink. Outros concorrentes distantes
									incluem o Projeto Kuiper, da Amazon, e a
									empresa de satélites OneWeb, com sede em
									Londres.
								</p>
								<br />
								<p>
									Embora milhares de pequenos satélites
									Starlink sejam problemáticos para os
									astrônomos, eles também estão fornecendo
									acesso à Internet muito necessário para
									pessoas em partes rurais ou devastadas pela
									guerra do mundo.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								E sobre os famosos asteroides?
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Para Kutkov e outros ucranianos, Starlink é
									um salva-vidas. Além disso, a Nasa está
									preocupada que o Starlink de segunda
									geração, que pode começar a ser lançado
									ainda este mês, possa um dia contribuir para
									acabar com a vida na Terra como a
									conhecemos.
								</p>
								<br />
								<p>
									Inclusive, a Nasa usa telescópios terrestres
									para caçar asteroides potencialmente
									assassinos.
								</p>
								<br />
								<p>
									A SpaceX não respondeu aos pedidos de
									comentários para esta história, mas a
									empresa abordou as preocupações dos
									astrônomos sobre os satélites impactando as
									observações sobre essas poluições e impactos
									negativos no céu.
								</p>
								<br />
								<p>
									Segundo os astrônomos, não há regras
									internacionais vinculantes monitorando as
									megaconstelações. A SpaceX não está
									esperando que os reguladores se atualizem.
								</p>
							</Typography>
						</Container>
					</Box>
					<ResponsiveFooter />
				</React.Fragment>
			)}
		</Box>
	);
}
