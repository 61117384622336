import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

export default function Post14() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const titleStyleObj = {
		fontSize: width > 720 ? "1.9em" : "1.6em",
		fontWeight: "700",
		mb: "calc(0.2em)",
		mt: "calc(13px + 1.2em)",
		lineHeight: "1.5",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "#111",
	};

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	const tableCell = {
		fontSize: width > 720 ? "1.3em" : "1em",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			<Container
				sx={{
					width: "100%",
					height: "30%",
					paddingTop: width < 720 ? "150px" : "200px",
					paddingBottom: width < 720 ? "50px" : "100px",
					paddingLeft: width < 720 ? "15px" : "auto",
					paddingRight: width < 720 ? "15px" : "auto",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					backgroundColor: "#161616",
					maxWidth: "none !important",
				}}
			>
				<Typography
					align="center"
					sx={{
						fontSize: width < 720 ? "1.5rem" : "2.5rem",
						lineHeight: width < 720 ? "2rem" : "3rem",
						textAlign: "center",
						maxWidth: "700px",
						fontWeight: "700",
						color: "#FFF",
						padding: "20px",
					}}
				>
					INSS: Pagamento do 13º salário dos aposentados do começou
					nesta segunda (25)
				</Typography>
			</Container>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
					mt: "100px",
					mb: "200px",
					backgroundColor: "#FFF",
				}}
			>
				<Container
					sx={{
						maxWidth: "770px !important",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "flex-start",
						paddingLeft: width < 720 ? "22px" : "auto",
						paddingRight: width < 720 ? "22px" : "auto",
					}}
				>
					<Typography sx={textStyleObj}>
						O pagamento do 13° salário dos aposentados pelo
						Instituto Nacional do Seguro Social (INSS) começou a ser
						pago nesta segunda-feira (25/4). Cerca de 30 milhões de
						aposentados e pensionistas no Brasil estão recebendo os
						seus devidos pagamentos.
					</Typography>
					<Typography sx={textStyleObj}>
						O pagamento do INSS antigamente, era feito em duas
						parcelas, nos meses de agosto e novembro. Entretanto, o
						governo federal antecipou o pagamento como parte de um
						programa chamado Renda e Oportunidade, que estimula a
						economia. Além disso, o governo prevê uma injeção de R$
						56 bilhões na economia brasileira.
					</Typography>
					<Typography sx={titleStyleObj}>O que é o INSS?</Typography>
					<Typography sx={textStyleObj}>
						Em resumo, a sigla INSS está disponível na folha de
						pagamento das empresas, no holerite de trabalhadores
						formais, no documento de arrecadação dos MEIs e em
						outros lugares.
					</Typography>
					<Typography sx={textStyleObj}>
						Basicamente, o INSS é a sigla para Instituto Nacional do
						Seguro Social. É um órgão público responsável pelo
						pagamento da aposentadoria e outros benefícios aos
						trabalhadores brasileiros e demais segurados, a exemplo
						dos microempreendedores individuais e contribuintes
						individuais.
					</Typography>
					<Typography sx={textStyleObj}>
						Além disso, o INSS foi criado em 1990 a partir da junção
						de outros dois órgãos: o Instituto de Administração
						Financeira da Previdência e Assistência Social (IAPAS) e
						o Instituto Nacional de Previdência Social (INPS).
					</Typography>
					<Typography sx={titleStyleObj}>
						Quando os benefícios do INSS serão pagos?
					</Typography>
					<Typography sx={textStyleObj}>
						Se você recebe o benefício, não precisa fazer nada pois
						o pagamento é automático. Entretanto, para saber quando
						você terá direito a ele, é preciso ter conhecimento de
						dois fatores:
					</Typography>
					<Typography sx={textStyleObj}>
						1. O número do benefício (Número de Inscrição Social ou
						NIS)
						<br />
						2. A quantia (se recebe até um salário mínimo (R$
						1.212)) ou mais
					</Typography>
					<Typography sx={textStyleObj}>
						Além disso, o número do benefício vem em um formato do
						tipo “123.456.789-0”. Para verificar a data de
						pagamento, o beneficiário precisa saber o último dígito
						desse número antes do traço — no exemplo acima, o dígito
						é o 9.
					</Typography>
					<Typography sx={textStyleObj}>
						<b>
							<i>ATENÇÃO:</i>
						</b>{" "}
						Consultas sobre o benefício devem ser feitas pelo
						telefone 135 ou pelo site ou app Meu INSS.
					</Typography>
					<Typography sx={textStyleObj}>
						Entenda os calendários dos pagamentos abaixo:
					</Typography>
					<Typography sx={titleStyleObj}>
						Para pessoas que recebem até um salário mínimo:
					</Typography>
					<TableContainer sx={{ mt: "30px" }}>
						<Table size="small" aria-label="a dense table">
							<TableBody>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Final 1 do NIS: 25 de abril (1ª parcela)
									</TableCell>
									<TableCell sx={tableCell} align="right">
										25 de maio (2ª parcela)
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Final 2 do NIS: 26 de abril (1ª parcela)
									</TableCell>
									<TableCell sx={tableCell} align="right">
										26 de maio (2ª parcela)
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Final 3 do NIS: 27 de abril (1ª parcela)
									</TableCell>
									<TableCell sx={tableCell} align="right">
										27 de maio (2ª parcela)
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Final 4 do NIS: 28 de abril (1ª parcela)
									</TableCell>
									<TableCell sx={tableCell} align="right">
										30 de maio (2ª parcela)
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Final 5 do NIS: 29 de abril (1ª parcela)
									</TableCell>
									<TableCell sx={tableCell} align="right">
										31 de maio (2ª parcela)
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Final 6 do NIS: 2 de maio (1ª parcela)
									</TableCell>
									<TableCell sx={tableCell} align="right">
										1 de junho (2ª parcela)
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Final 7 do NIS: 3 de maio (1ª parcela)
									</TableCell>
									<TableCell sx={tableCell} align="right">
										2 de junho (2ª parcela)
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Final 8 do NIS: 4 de maio (1ª parcela)
									</TableCell>
									<TableCell sx={tableCell} align="right">
										3 de junho (2ª parcela)
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Final 9 do NIS: 5 de maio (1ª parcela)
									</TableCell>
									<TableCell sx={tableCell} align="right">
										6 de junho (2ª parcela)
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Final 0 do NIS: 6 de maio (1ª parcela)
									</TableCell>
									<TableCell sx={tableCell} align="right">
										7 de junho (2ª parcela)
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
					<Typography sx={titleStyleObj}>
						Para pessoas que recebem mais de um salário mínimo:
					</Typography>
					<TableContainer sx={{ mt: "30px" }}>
						<Table size="small" aria-label="a dense table">
							<TableBody>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Final 1 ou 6 do NIS: 2 de maio (1ª
										parcela)
									</TableCell>
									<TableCell sx={tableCell} align="right">
										1 de junho (2ª parcela)
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Final 2 ou 7 do NIS: 3 de maio (1ª
										parcela)
									</TableCell>
									<TableCell sx={tableCell} align="right">
										2 de junho (2ª parcela)
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Final 3 ou 8 do NIS: 4 de maio (1ª
										parcela)
									</TableCell>
									<TableCell sx={tableCell} align="right">
										2 de junho (2ª parcela)
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Final 4 ou 9 do NIS: 5 de maio (1ª
										parcela)
									</TableCell>
									<TableCell sx={tableCell} align="right">
										6 de junho (2ª parcela)
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Final 5 ou 0 do NIS: 6 de maio (1ª
										parcela)
									</TableCell>
									<TableCell sx={tableCell} align="right">
										7 de junho (2ª parcela)
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				</Container>
			</Box>
			<ResponsiveFooter />
		</Box>
	);
}
