import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

export default function Post7() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const titleStyleObj = {
		fontSize: width > 720 ? "1.9em" : "1.6em",
		fontWeight: "700",
		mb: "calc(0.2em)",
		mt: "calc(13px + 1.2em)",
		lineHeight: "1.5",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "#111",
	};

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	const tableCell = {
		fontSize: width > 720 ? "1.3em" : "1em",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			<Container
				sx={{
					width: "100%",
					height: "30%",
					paddingTop: width < 720 ? "150px" : "200px",
					paddingBottom: width < 720 ? "50px" : "100px",
					paddingLeft: width < 720 ? "15px" : "auto",
					paddingRight: width < 720 ? "15px" : "auto",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					backgroundColor: "#161616",
					maxWidth: "none !important",
				}}
			>
				<Typography
					align="center"
					sx={{
						fontSize: width < 720 ? "1.5rem" : "2.5rem",
						lineHeight: width < 720 ? "2rem" : "3rem",
						textAlign: "center",
						maxWidth: "700px",
						fontWeight: "700",
						color: "#FFF",
						padding: "20px",
					}}
				>
					Fim de abril: Entenda as altas e baixas das criptomoedas
				</Typography>
			</Container>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
					mt: "100px",
					mb: "200px",
					backgroundColor: "#FFF",
				}}
			>
				<Container
					sx={{
						maxWidth: "770px !important",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "flex-start",
						paddingLeft: width < 720 ? "22px" : "auto",
						paddingRight: width < 720 ? "22px" : "auto",
					}}
				>
					<Typography sx={textStyleObj}>
						O final de abril chegou, e com esse mês fechando,
						trouxemos algumas atualizações sobre o universo das
						criptomoedas. Abril chega ao fim com mais um mês
						negativo para o mercado, a Bitcoin (BTC) recuou em
						15,90%, estando na casa de US$ 38.400. Por outro lado, a
						Ethereum (ETH) caiu 15,20%, ficando em torno de US$
						2.800.
					</Typography>
					<Typography sx={textStyleObj}>
						O mercado das criptos segue com o clima de maior
						apreensão, tendo destaque para uma grande volatilidade,
						conforme as criptomoedas registram uma correlação com o
						mercado de ações, que também recuaram em abril.
					</Typography>
					<Typography sx={textStyleObj}>
						Um dos motivos para essa queda se dá na invasão da
						Ucrânia e sobre a alta de juros nos EUA é um dos
						principais motivos negativos para o mercado. Entretanto,
						enquanto todas as maiores criptomoedas em valor de
						mercado caíram no último mês, outros ativos digitais
						chamaram atenção do mercado, como a ApeCoin, do projeto
						de NFTs Bored Ape Yatch Club, e a Stepn, subindo mais de
						50% cada.
					</Typography>
					<Typography sx={titleStyleObj}>
						Maiores altas de Abril
					</Typography>
					<Typography sx={textStyleObj}>
						A criptomoeda Kyber Network Crystal (KNC), que alimenta
						a exchange descentralizada Kyber Network, foi a que mais
						subiu em abril, com alta na ordem de 60%. O resultado
						vem em meio à boa aceitação da plataforma entre traders
						pela compatibilidade do produto com múltiplas
						blockchains e ajuda a reduzir custos de transação.
					</Typography>
					<Typography sx={textStyleObj}>
						Em seguida, o foco é na ApeCoin (APE), criptomoeda
						ligada ao famoso projeto NFT Bored Ape Yatch Club que
						foi lançada em abril e acumulou alta de mais de 50%. Bem
						próximo à ApeCoin, ficou a Stepn (GMT), criptomoeda de
						um jogo que se propõe a pagar pela realização de
						exercícios físicos.
					</Typography>
					<Typography sx={textStyleObj}>
						Ou seja, quanto mais o usuário corre usando o app do
						projeto, mais moedas GMT ganha. Para participar, o
						interessado deve comprar um NFT que pode custar milhares
						de dólares. A exchange Binance é uma das investidoras.
					</Typography>
					<Typography sx={titleStyleObj}>
						Veja as criptomoedas com as maiores altas do mês de
						abril:
					</Typography>
					<TableContainer sx={{ mt: "30px" }}>
						<Table size="small" aria-label="a dense table">
							<TableHead>
								<TableRow>
									<TableCell sx={tableCell}>
										Criptomoeda
									</TableCell>
									<TableCell sx={tableCell} align="right">
										Fechamento do mês
									</TableCell>
									<TableCell sx={tableCell} align="right">
										Variação
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Kyber Network Crystal (KNC)
									</TableCell>
									<TableCell sx={tableCell} align="right">
										US$ 5,16
									</TableCell>
									<TableCell sx={tableCell} align="right">
										{" "}
										+58,77%
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										ApeCoin (APE)
									</TableCell>
									<TableCell sx={tableCell} align="right">
										US$ 19,90
									</TableCell>
									<TableCell sx={tableCell} align="right">
										+56,82%
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Stepn (GMT)
									</TableCell>
									<TableCell sx={tableCell} align="right">
										US$ 3,81
									</TableCell>
									<TableCell sx={tableCell} align="right">
										+53,01%
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Kava (KAVA)
									</TableCell>
									<TableCell sx={tableCell} align="right">
										US$ 5,04
									</TableCell>
									<TableCell sx={tableCell} align="right">
										+12,00%
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										XDC (XDC)
									</TableCell>
									<TableCell sx={tableCell} align="right">
										US$ 0,06313
									</TableCell>
									<TableCell sx={tableCell} align="right">
										+10,27%
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
					<Typography sx={titleStyleObj}>
						Maiores quedas de Abril
					</Typography>
					<Typography sx={textStyleObj}>
						O principal destaque ficou com a cripto ucraniana Waves
						(WAVES), uma criptomoeda inflacionária oferecida como
						recompensa por blocos de mineração em sua blockchain
						descentralizada e de código aberto. A queda da
						criptomoeda ocorreu em um cenário de bastante
						desconfiança sobre os usuários em relação ao seu
						projeto.
					</Typography>
					<Typography sx={textStyleObj}>
						Isso levou o Neutrino (USDN), uma stablecoin algorítmica
						do ecossistema Waves, a desabar e perder sua paridade
						com o dólar americano. Além de toda a desconfiança, pesa
						também o fato da WAVES ter disparado mais de 200% em
						março, o que pesa para uma correção ainda mais forte
						quando uma notícia negativa como essa ocorre.
					</Typography>
					<Typography sx={textStyleObj}>
						Em seguida, temos a Zilliqa (ZIL), que se mostrou mais u
						m caso da famosa máxima do Completando o Top 3 das
						quedas em abril apareceu uma das criptos mais comentadas
						dos últimos meses, a Axie Infinity (AXS), do jogo de
						mesmo nome.
					</Typography>
					<Typography sx={titleStyleObj}>
						Veja as criptomoedas com as maiores baixas do mês de
						abril:
					</Typography>
					<TableContainer sx={{ mt: "30px" }}>
						<Table size="small" aria-label="a dense table">
							<TableHead>
								<TableRow>
									<TableCell sx={tableCell}>
										Criptomoeda
									</TableCell>
									<TableCell sx={tableCell} align="right">
										Fechamento do mês
									</TableCell>
									<TableCell sx={tableCell} align="right">
										Variação
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Waves (WAVES)
									</TableCell>
									<TableCell sx={tableCell} align="right">
										US$ 14,23
									</TableCell>
									<TableCell sx={tableCell} align="right">
										-73,95%
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Zilliqa (ZIL)
									</TableCell>
									<TableCell sx={tableCell} align="right">
										US$ 0,08249
									</TableCell>
									<TableCell sx={tableCell} align="right">
										-53,55%
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Axie Infinity (AXS)
									</TableCell>
									<TableCell sx={tableCell} align="right">
										US$ 33,65
									</TableCell>
									<TableCell sx={tableCell} align="right">
										-46,97%
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Harmony (ONE)
									</TableCell>
									<TableCell sx={tableCell} align="right">
										US$ 0,0891
									</TableCell>
									<TableCell sx={tableCell} align="right">
										-44,56%
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										THORChain (RUNE)
									</TableCell>
									<TableCell sx={tableCell} align="right">
										US$ 6,75
									</TableCell>
									<TableCell sx={tableCell} align="right">
										-41,10%
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				</Container>
			</Box>
			<ResponsiveFooter />
		</Box>
	);
}
