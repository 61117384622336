import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";

export default function Post19() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const titleStyleObj = {
		fontSize: width > 720 ? "1.9em" : "1.6em",
		fontWeight: "700",
		mb: "calc(0.2em)",
		mt: "calc(13px + 1.2em)",
		lineHeight: "1.5",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "#111",
	};

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			<Container
				sx={{
					width: "100%",
					height: "30%",
					paddingTop: width < 720 ? "150px" : "200px",
					paddingBottom: width < 720 ? "50px" : "100px",
					paddingLeft: width < 720 ? "15px" : "auto",
					paddingRight: width < 720 ? "15px" : "auto",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					backgroundColor: "#161616",
					maxWidth: "none !important",
				}}
			>
				<Typography
					align="center"
					sx={{
						fontSize: width < 720 ? "1.5rem" : "2.5rem",
						lineHeight: width < 720 ? "2rem" : "3rem",
						textAlign: "center",
						maxWidth: "700px",
						fontWeight: "700",
						color: "#FFF",
						padding: "20px",
					}}
				>
					Wine: Agora é possível pagar vinhos com criptomoedas
				</Typography>
			</Container>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
					mt: "100px",
					mb: "200px",
					backgroundColor: "#FFF",
				}}
			>
				<Container
					sx={{
						maxWidth: "770px !important",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "flex-start",
						paddingLeft: width < 720 ? "22px" : "auto",
						paddingRight: width < 720 ? "22px" : "auto",
					}}
				>
					<Typography sx={textStyleObj}>
						Sim, agora é possível pagar vinhos por meio das
						criptomoedas. A empresa Wine, que possui um clube de
						assinatura para vinhos, fechou uma parceria com a
						fintech Redecoin para iniciar os pagamentos via criptos.
						A Wine anunciou a novidade nesta última terça-feira (19)
						que passará a aceitar Bitcoin (BTC) como forma de
						pagamento em sua plataforma.
					</Typography>
					<Typography sx={textStyleObj}>
						O novo meio de pagamento é formado através de uma
						parceria da Wine com a Redecoin, uma fintech do grupo
						SCF Brazil. Durante o momento da compra na plataforma do
						Clube Wine, o usuário escolhe a criptomoeda como forma
						de pagamento, e dessa forma, será gerado um QR Code para
						o cliente efetuar o pagamento. O processo é semelhante
						ao processo do pagamento via Pix, de forma rápida,
						simples e segura.
					</Typography>
					<Typography sx={textStyleObj}>
						“Sabemos que os consumidores estão cada vez mais
						conectados e as criptomoedas já fazem parte do dia a dia
						de grande parte deles. Resolvemos investir nessa
						inovação para trazer uma experiência ainda mais completa
						para nossos sócios e clientes e também porque
						acreditamos que, em um futuro próximo, essa forma de
						pagamento será frequente”, afirma Clayton Freire,
						Diretor de Tecnologia da Wine.
					</Typography>
					<Typography sx={titleStyleObj}>O que é a Wine?</Typography>
					<Typography sx={textStyleObj}>
						A Wine foi fundada, em 2008, por Rogério Salune e
						Anselmo Endlich. Além disso, a empresa fez sucesso desde
						o começo e, desde o primeiro ano de lançamento, já tinha
						14 mil clientes, resultando em mais de 250 mil garrafas
						de vinho vendidas.
					</Typography>
					<Typography sx={textStyleObj}>
						A empresa começou como uma empresa tradicional de vendas
						de vinho, e conseguiu expandir e revolucionar no
						mercado. Em 2010, foi lançado o clube de assinaturas
						Wine. Com essa revolução, fez com que a empresa gerasse
						um diferencial único, transformando no ponto chave da
						empresa.
					</Typography>
					<Typography sx={textStyleObj}>
						Atualmente, a companhia possui mais de 170 mil
						assinantes do clube.
					</Typography>
					<Typography sx={titleStyleObj}>
						Como a Wine funciona
					</Typography>
					<Typography sx={textStyleObj}>
						A Wine é uma empresa do segmento do varejo voltada para
						a comercialização de vinhos e espumantes. A atuação da
						Companhia ocorre por meio de um clube de assinatura e
						e-commerce de vinhos que opera através de uma plataforma
						omnicanal.
					</Typography>
					<Typography sx={textStyleObj}>
						Em resumo, o principal foco de atuação da empresa é o
						clube de assinaturas (Clube Wine), que corresponde a
						78,6% da receita da empresa. No Clube Wine você recebe,
						todo mês, 2 rótulos escolhidos a dedo das melhores
						vinícolas do mundo.
					</Typography>
					<Typography sx={textStyleObj}>
						São 6 clubes diferentes, e você escolhe a WineBox que
						mais combina com você. Todo mês os clientes recebem
						amostras de exemplares e fazem diversas análises para
						seleção dos melhores vinhos, além de brindes exclusivos.
					</Typography>
					<Typography sx={titleStyleObj}>
						Apostas em Bitcoins
					</Typography>
					<Typography sx={textStyleObj}>
						Outras empresas já estão abordando esse tipo de
						pagamento em suas plataformas, assim como a Rappi. A
						Wine, por exemplo, tem apostado cada vez mais numa
						estratégia de omnichannel (venda por diversos canais),
						proporcionando uma experiência unificada entre loja
						física e online, incluindo criptomoedas.
					</Typography>
					<Typography sx={textStyleObj}>
						“Assim como os usuários podem usar o app para fazer
						compras na loja física e com a leitura do QR Code dos
						rótulos incluir os produtos no carrinho, eles farão com
						o pagamento via Bitcoin. Basta selecionar os produtos,
						finalizar e pagar direto pelo celular de forma rápida e
						fácil”, explica Freire.
					</Typography>
					<Typography sx={textStyleObj}>
						Segundo a empresa Wine, oito em cada dez compras nas
						lojas físicas da empresa são feitas usando o aplicativo.
						Entretanto, apesar da novidade se iniciar apenas com
						compras feitas usando o aplicativo, a ideia da Wine é
						implementar o modelo em outros canais de venda ainda
						este ano.
					</Typography>
				</Container>
			</Box>
			<ResponsiveFooter />
		</Box>
	);
}
