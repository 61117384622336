import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";

export default function Post31() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const titleStyleObj = {
		fontSize: width > 720 ? "1.9em" : "1.6em",
		fontWeight: "700",
		mb: "calc(0.2em)",
		mt: "calc(13px + 1.2em)",
		lineHeight: "1.5",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "#111",
	};

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			{!loading && (
				<React.Fragment>
					<Container
						sx={{
							width: "100%",
							height: "30%",
							paddingTop: width < 720 ? "150px" : "200px",
							paddingBottom: width < 720 ? "50px" : "100px",
							paddingLeft: width < 720 ? "15px" : "auto",
							paddingRight: width < 720 ? "15px" : "auto",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							backgroundColor: "#161616",
							maxWidth: "none !important",
						}}
					>
						<Typography
							align="center"
							sx={{
								fontSize: width < 720 ? "1.5rem" : "2.5rem",
								lineHeight: width < 720 ? "2rem" : "3rem",
								textAlign: "center",
								maxWidth: "700px",
								fontWeight: "700",
								color: "#FFF",
								padding: "20px",
							}}
						>
							Fim de tarifa extra: Conta de energia cairá 18% em
							maio, diz ministro da economia
						</Typography>
					</Container>
					<Box
						sx={{
							width: "100%",
							height: "100%",
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
							mt: "100px",
							mb: "200px",
							backgroundColor: "#FFF",
						}}
					>
						<Container
							sx={{
								maxWidth: "770px !important",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "flex-start",
								paddingLeft: width < 720 ? "22px" : "auto",
								paddingRight: width < 720 ? "22px" : "auto",
							}}
						>
							<Typography sx={textStyleObj}>
								<p>
									Segundo o ministro de economia, Paulo
									Guedes, o fim da tarifa extra está chegando
									ao fim, pois a expectativa é que a conta de
									energia residencial podem cair em até 18%,
									sem prejudicar a saúde financeira das
									empresas de energia.
								</p>
								<br />
								<p>
									Durante um evento de lançamentos do Banco do
									Brasil, o ministro declarou a retirada da
									bandeira de escassez hídrica, e considerou
									que os níveis de reservatórios não foi
									decidida com base em “canetadas”.
								</p>
								<br />
								<p>
									Além disso, o presidente Jair Bolsonaro
									anunciou o fim das bandeiras tarifárias
									sobre as contas de luz. Através das redes
									sociais, ele informou que a recuperação dos
									reservatórios das usinas hidrelétricas fará
									o governo conceder bandeira verde para todos
									os consumidores de energia a partir deste
									mês. A expectativa é de que diminua até 20%
									nas tarifas.
								</p>
								<br />
							</Typography>

							<Typography sx={titleStyleObj}>
								Contexto das tarifas extras na conta de energia
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Para contexto histórico, a bandeira de
									escassez hídrica entrou em vigor em agosto
									de 2021, durante o período de seca. Na
									prática, ela representou um aumento nas
									contas de energia dos consumidores, ainda
									mais após a crise financeira do país.
								</p>
								<br />
								<p>
									Durante o evento, o ministro de economia
									ressaltou que o governo está mudando o
									contexto sobre o meio ambiente, a nível
									internacional. Além disso, ele afirma que o
									Brasil está tendo um papel importante para
									garantir a segurança energética em escala
									global. O ministro se refere ao fim da
									bandeira de escassez hídrica desde setembro
									de 2021.
								</p>
								<br />
								<p>
									Apesar dessa afirmação, com redução em cerca
									de 20% com essa medida, muitos especialistas
									alertam que a queda de taxas será diluída
									com os reajustes tarifários das
									distribuidoras, ainda não definidos neste
									ano.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								Apesar dos reservatórios cheios, ainda há
								diminuição nas contas de energia?
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Segundo especialistas, as contratações já
									feitas para o uso obrigatório de
									termelétricas pelos próximos três anos
									permanecem pressionando o preço da tarifa
									extra de energia. Apesar da melhoria nos
									níveis dos reservatórios do país e da
									diminuição do uso das termelétricas, as
									expectativas para o consumidor em relação à
									redução da conta de energia não são
									positivas.
								</p>
								<br />
								<p>
									Diante das pesquisas, os brasileiros podem
									esperar, a partir de maio, o fim da tarifa
									de escassez hídrica com custo de R$ 14,20 a
									cada 100 KWh consumidos. Dessa forma, a
									conta final de luz a ser paga não deve
									sofrer impactos significativos e vai
									permanecer cara, mesmo com o fim da bandeira
									tarifária.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								Entenda as bandeiras tarifárias da conta de
								energia
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Além disso, ao longo da crise hídrica do ano
									passado, o governo federal fez contratações
									emergenciais bilionárias para o uso de
									termelétricas, que entrarão em operação a
									partir de maio deste ano até dezembro de
									2025. Ou seja, essa conta ainda será
									repassada para os consumidores finais
									durante todo esse período.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								Dados do reservatório
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Atualmente, os reservatórios do
									Sudeste/Centro-Oeste estão com 64,50% de sua
									capacidade, maior nível desde 2013. Já os
									reservatórios do Nordeste estão com 96,65%
									de sua capacidade e os do subsistema Norte
									com 98,59%.
								</p>
								<br />
								<p>
									Além disso, outro fator que deve interferir
									nas altas tarifas pagas pelo consumidor
									brasileiro, é o aumento do custo do preço
									dos combustíveis, principalmente em
									decorrência da guerra na Ucrânia.
								</p>
								<br />
								<p>
									A conta de luz é composta por tarifas
									definidas e regulamentadas pela Agência
									Nacional de Energia Elétrica (Aneel). Essas
									tarifas são compostas pelos impostos,
									encargos setoriais, custos de geração,
									transmissão e distribuição de energia.
								</p>
								<br />
								<p>
									Com isso, as tarifas são acréscimos à conta
									de luz usados para compensar e equilibrar os
									custos das distribuidoras, como é o caso da
									bandeira de escassez hídrica.
								</p>
							</Typography>
						</Container>
					</Box>
					<ResponsiveFooter />
				</React.Fragment>
			)}
		</Box>
	);
}
