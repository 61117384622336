import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";

export default function Post41() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const titleStyleObj = {
		fontSize: width > 720 ? "1.9em" : "1.6em",
		fontWeight: "700",
		mb: "calc(0.2em)",
		mt: "calc(13px + 1.2em)",
		lineHeight: "1.5",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "#111",
	};

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			{!loading && (
				<React.Fragment>
					<Container
						sx={{
							width: "100%",
							height: "30%",
							paddingTop: width < 720 ? "150px" : "200px",
							paddingBottom: width < 720 ? "50px" : "100px",
							paddingLeft: width < 720 ? "15px" : "auto",
							paddingRight: width < 720 ? "15px" : "auto",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							backgroundColor: "#161616",
							maxWidth: "none !important",
						}}
					>
						<Typography
							align="center"
							sx={{
								fontSize: width < 720 ? "1.5rem" : "2.5rem",
								lineHeight: width < 720 ? "2rem" : "3rem",
								textAlign: "center",
								maxWidth: "700px",
								fontWeight: "700",
								color: "#FFF",
								padding: "20px",
							}}
						>
							Veículos elétricos crescem em até 80% durante o ano
							de 2022
						</Typography>
					</Container>
					<Box
						sx={{
							width: "100%",
							height: "100%",
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
							mt: "100px",
							mb: "200px",
							backgroundColor: "#FFF",
						}}
					>
						<Container
							sx={{
								maxWidth: "770px !important",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "flex-start",
								paddingLeft: width < 720 ? "22px" : "auto",
								paddingRight: width < 720 ? "22px" : "auto",
							}}
						>
							<Typography sx={textStyleObj}>
								<p>
									Segundo a Associação Brasileira dos Veículos
									Elétricos (ABVE), esses automóveis crescem
									em até 80% durante o ano de 2022. Não apenas
									sobre carros elétricos, mas as bicicletas
									também estão em alta. A produção e venda dos
									veículos que não precisam de gasolina estão
									cada vez mais crescendo no mercado.
								</p>
								<br />
								<p>
									Falando em números e segundo os dados da
									Associação Brasileira dos Fabricantes de
									Motocicletas, Motonetas, Bicicletas e
									Similares (Abraciclo), o mercado de
									bicicleta pretende crescer em 17,4% em
									comparação ao ano de 2021. Da mesma forma,
									as bicicletas elétricas, que utilizam a
									eletricidade, possuem a expectativa de
									crescer em aproximadamente 40% só neste ano.
								</p>
							</Typography>

							<Typography sx={titleStyleObj}>
								Por que escolher os veículos elétricos?
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									De acordo com o vice-presidente do segmento
									de bicicletas da Abraciclo, Cyro Gazola, o
									aumento da procura começou durante a
									pandemia, período em que as pessoas buscavam
									atividades para fazer fora de casa.
								</p>
								<br />
								<p>
									De acordo com a entidade, as vendas de
									veículos eletrificados no Brasil tiveram o
									melhor mês de fevereiro da série histórica,
									o que equivale a um aumento de 147% sobre
									fevereiro de 2021 (1.389) e de 34% sobre
									janeiro de 2022 (2.558).
								</p>
								<br />
								<p>
									Para os especialistas, os principais fatores
									para o público buscar esse tipo de veículo
									está dentro da conscientização da emissão de
									carbono, que passou a ser um assunto
									cotidiano na sociedade. Além disso, a
									questão da elevação dos preços da gasolina,
									que tem assustado ainda mais a população.
								</p>
								<br />
								<p>
									“Quando você passa a comparar um preço de um
									carro elétrico, é necessário olhar o custo
									total da operação. Ou seja, quando se soma
									os impostos, custo de manutenção, o preço do
									veículo e do combustível, o cliente repara
									que os eletrificados são muito mais
									vantajosos”, ressalta o especialista.
								</p>
								<br />
								<p>
									Basicamente, usando um carro comum, com um
									tanque cheio, você gasta cerca de R$250.
									Mas, com o elétrico, seu custo diminui para
									cerca de R$15. Ou seja, a diferença de
									gastos impacta diretamente no custo final.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								Impulsionamento dos veículos elétricos
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									No caso desses veículos, o diretor de
									Infraestrutura da ABVE acredita que o país
									precisa estimular o ramo para que haja um
									aumento na produção de veículos elétricos.
									Além disso, apesar do país não ter uma
									grande estrutura nessa área, Severine afirma
									que o Brasil possui um grande potencial de
									crescimento.
								</p>
								<br />
								<p>
									Além das bicicletas convencionais, o
									destaque também alcança os aumentos nas
									vendas de bicicletas elétricas. Segundo o
									vice-presidente da Abraciclo, eles tiveram
									um aumento de 2% no segmento de bicicletas
									elétricas.
								</p>
								<br />
								<p>
									“Esse ano elas têm potencial de crescer
									aproximadamente 40% comparado com o ano
									passado, não só por conta do interesse de um
									novo tipo de locomoção, mas também pelos
									modelos. O grande desafio do mercado será em
									fabricar bicicletas elétricas que caibam no
									bolso do consumidor.”
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								A problemática do mercado
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Segundo os especialistas, nos últimos dois
									anos, as indústrias apresentaram uma grande
									falta de insumos em relação às fábricas e
									peças. Diante da pandemia e o fechamento de
									muitas empresas, além da alta do dólar,
									acabou que os insumos importados ficaram
									mais caros e deixaram o mercado
									internacional mais atrativo.
								</p>
								<br />
								<p>
									A Abraciclo afirmou que essa problemática da
									ausência de insumos prejudicou a taxa de
									crescimento no ramo de bicicletas, mas mesmo
									assim, a expectativa é que o mercado
									continue a crescer.
								</p>
								<br />
								<p>
									“Essa falta tem sido ruim para o mercado,
									até para os veículos elétricos, que o
									principal insumo, hoje, é a bateria do
									carro. Isso pode virar um freio. Estaríamos
									recebendo menos carros do que a demanda que
									temos hoje. O crescimento vai estar limitado
									pela oferta de veículos, não pela procura”,
									ressalta o diretor.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								Bicicletas elétricas nas cidades
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Diante desse mercado, a dica que demos hoje
									é a questão do Bike Itaú, sendo
									caracterizado por um sistema de aluguel de
									bicicletas a preços acessíveis,
									disponibilizado por meio de um aplicativo
									para celulares.
								</p>
								<br />
								<p>
									Com tarifas a partir de R$ 8, as bicicletas
									do Itaú ficam espalhadas em diversos bairros
									das cidades de São Paulo, Rio de Janeiro,
									Porto Alegre e Salvador, além de algumas
									cidades de Pernambuco.
								</p>
								<br />
								<p>
									Para utilizar é muito simples. É necessário
									o usuário ir até o ponto de empréstimo mais
									próximo, liberar a bicicleta pelo aplicativo
									e retirar.
								</p>
								<br />
								<p>
									Você pode utilizar por até 60 minutos cada
									vez, depois será necessário devolver em
									alguma estação, que pode até ser a mesma da
									retirada, e aguardar 15 minutos. Na
									sequência você pode alugar novamente a
									bicicleta por mais uma hora. Caso você
									ultrapasse o tempo, será cobrado direto no
									seu cartão um valor adicional de R$ 3,90 a
									cada meia hora.
								</p>
							</Typography>
						</Container>
					</Box>
					<ResponsiveFooter />
				</React.Fragment>
			)}
		</Box>
	);
}
