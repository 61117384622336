import {
	Box,
	Typography,
	Container,
	Divider,
	Button,
	Grid,
	List,
	ListItem,
} from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../components/AppBar";
import ResponsiveFooter from "../../components/Footer";
import useWindowDimensions from "../../hooks/windowDimension";
import { useLocation, useNavigate } from "react-router-dom";
import buscape_home from "../../assets/buscape_home.png";
import { MoonLoader } from "react-spinners";
import useLoader from "../../hooks/useLoader";
import Preloader from "../../components/Preloader";

const divGridStyle = {
	height: "80px",
	width: "100%",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	backgroundColor: "var(--bgGray)",
	borderRadius: "5px",
	textAlign: "center",
	padding: "0 15px",
};

const typographyGridStyle = {
	fontSize: "1.1rem",
	fontWeight: "bold",
	color: "var(--textGray)",
};

export default function Buscape() {
	const { width } = useWindowDimensions();
	const loading = useLoader();
	const { search } = useLocation();

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && <Preloader />}

			<Container
				sx={{
					width: "100vw",
					height: "100%",
					mt: width < 720 ? "150px" : "200px",
					mb: "40px",
				}}
			>
				<Typography variant="h5" align="center">
					Cartão de Crédito Buscapé
				</Typography>
				<Typography
					align="center"
					sx={{
						fontSize: "1.1rem",
						fontWeight: "600",
						mt: "10px",
					}}
				>
					O cartão sem anuidade, com menor preço garantido e até 2% de
					cashback!
				</Typography>
				<Box
					sx={{
						width: "100%",
						height: "auto",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						mt: "30px",
					}}
				>
					<img
						alt="buscape_home"
						src={buscape_home}
						loading="lazy"
						style={{
							width: "80%",
							height: "100%",
							maxWidth: "450px",
						}}
					/>
				</Box>
			</Container>
			<Divider variant="middle" />
			<Container
				sx={{
					display: width < 720 ? "flex" : "none",
					alignItems: "center",
					justifyContent: "center",
					mt: "100px",
					mb: "100px",
				}}
			>
				<div id="cartoes.informacash_top_mob"></div>
			</Container>
			<Container
				sx={{
					display: width > 720 ? "flex" : "none",
					alignItems: "center",
					justifyContent: "center",
					mt: "100px",
					mb: "100px",
				}}
			>
				<div id="cartoes.informacash_top_desk"></div>
			</Container>
			<Container
				sx={{
					width: "100vw",
					height: "100%",
					mt: "100px",
					mb: "80px",
					pl: "7%",
					pr: "4%",
				}}
			>
				<Typography
					variant="p"
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						color: "var(--textGray)",
					}}
				>
					O cartão Buscapé, em parceria com o Banco PAN e a bandeira
					Mastercard, oferece diversos benefícios aos seus usuários. A
					anuidade gratuita é garantida e o cashback na fatura pode
					chegar até 1%, dependendo dos gastos. Como benefícios
					exclusivos Mastercard, você tem proteção de preço, garantia
					estendida e compra protegida!
				</Typography>
			</Container>
			<Container
				sx={{
					width: "100%",
					height: "auto",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					maxWidth: "810px",
				}}
			>
				<Button
					variant="contained"
					size="large"
					disableElevation
					sx={{
						fontWeight: "700",
						backgroundColor: "var(--infoCashBlue)",
					}}
					href={
						window.location.hostname !== "localhost"
							? `https://cartoes.informacash.com.br/buscape-solicitar${search}`
							: `./buscape-solicitar`
					}
				>
					Quero saber mais
				</Button>
			</Container>
			<Container
				sx={{
					width: "100%",
					height: "100%",
					mt: "80px",
					mb: "40px",
					pl: "7%",
					pr: "4%",
				}}
			>
				<Typography
					variant="h5"
					align="center"
					sx={{
						lineHeight: "2rem",
						fontWeight: "700",
						mb: "10px",
					}}
				>
					Tipos de Cartões
				</Typography>
				<Divider variant="middle" sx={{ mb: "20px" }} />
				<Typography
					align="left"
					sx={{
						mt: "20px",
						fontSize: "1.2rem",
						fontWeight: "700",
					}}
				>
					Cartão de Crédito Buscapé
				</Typography>
				<List
					sx={{
						listStyleType: "disc",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							Anuidade: grátis
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							Cobertura internacional
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							Versão: débito e crédito
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							Cashback de 1% nas compras no Buscapé e até 1% nos
							demais estabelecimentos
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							Participação no programa Mastercard Surpreenda
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "flex-start",
							pb: "0 !important",
							pt: "0 !important",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "0.9rem",
								display: "list-item",
							}}
						>
							R$ 1 real gasto = 1 ponto para trocar por produtos
						</Typography>
					</ListItem>
				</List>
			</Container>
			<Container
				sx={{
					display: width < 720 ? "flex" : "none",
					alignItems: "center",
					justifyContent: "center",
					mt: "100px",
					mb: "100px",
				}}
			>
				<div id="cartoes.informacash_mid_mob"></div>
			</Container>
			<Container
				sx={{
					display: width > 720 ? "flex" : "none",
					alignItems: "center",
					justifyContent: "center",
					mt: "100px",
					mb: "100px",
				}}
			>
				<div id="cartoes.informacash_mid_desk"></div>
			</Container>
			<Container sx={{ width: "90%", mb: "40px", mt: "30px" }}>
				<Grid container spacing={2} alignItems="stretch">
					<Grid item xs={12} sm={6}>
						<div style={divGridStyle}>
							<Typography
								variant="p"
								align="center"
								sx={typographyGridStyle}
							>
								Aplicativo e pagamento por aproximação
							</Typography>
						</div>
					</Grid>
					<Grid item xs={12} sm={6}>
						<div style={divGridStyle}>
							<Typography
								variant="p"
								align="center"
								sx={typographyGridStyle}
							>
								Programa de cashback e pontos
							</Typography>
						</div>
					</Grid>
					<Grid item xs={12} sm={6}>
						<div style={divGridStyle}>
							<Typography
								variant="p"
								align="center"
								sx={typographyGridStyle}
							>
								Descontos especiais em lojas parceiras
							</Typography>
						</div>
					</Grid>
					<Grid item xs={12} sm={6}>
						<div style={divGridStyle}>
							<Typography
								variant="p"
								align="center"
								sx={typographyGridStyle}
							>
								Cobertura internacional e garantia estendida
							</Typography>
						</div>
					</Grid>
				</Grid>
			</Container>
			<Container
				sx={{
					width: "100%",
					height: "auto",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					maxWidth: "810px",
					mb: "20%",
				}}
			>
				<Button
					variant="contained"
					size="large"
					disableElevation
					sx={{
						fontWeight: "700",
						backgroundColor: "var(--infoCashBlue)",
					}}
					href={
						window.location.hostname !== "localhost"
							? `https://cartoes.informacash.com.br/buscape-solicitar${search}`
							: `./buscape-solicitar`
					}
				>
					Quero solicitar meu cartão
				</Button>
			</Container>
			<ResponsiveFooter />
		</Box>
	);
}
