import React from "react";
import { GlobalStyle } from "./styles/global";
import PublicRoutes from "./Routes/index";

function App() {
	return (
		<React.Fragment>
			<GlobalStyle />
			<PublicRoutes />
		</React.Fragment>
	);
}

export default App;
