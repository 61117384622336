import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

export default function Post24() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const titleStyleObj = {
		fontSize: width > 720 ? "1.9em" : "1.6em",
		fontWeight: "700",
		mb: "calc(0.2em)",
		mt: "calc(13px + 1.2em)",
		lineHeight: "1.5",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "#111",
	};

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	const tableCell = {
		fontSize: width > 720 ? "1.3em" : "1em",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			<Container
				sx={{
					width: "100%",
					height: "30%",
					paddingTop: width < 720 ? "150px" : "200px",
					paddingBottom: width < 720 ? "50px" : "100px",
					paddingLeft: width < 720 ? "15px" : "auto",
					paddingRight: width < 720 ? "15px" : "auto",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					backgroundColor: "#161616",
					maxWidth: "none !important",
				}}
			>
				<Typography
					align="center"
					sx={{
						fontSize: width < 720 ? "1.5rem" : "2.5rem",
						lineHeight: width < 720 ? "2rem" : "3rem",
						textAlign: "center",
						maxWidth: "700px",
						fontWeight: "700",
						color: "#FFF",
						padding: "20px",
					}}
				>
					Tesouro Direto: Entenda como funciona e saiba como iniciar
					os seus investimentos
				</Typography>
			</Container>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
					mt: "100px",
					mb: "200px",
					backgroundColor: "#FFF",
				}}
			>
				<Container
					sx={{
						maxWidth: "770px !important",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "flex-start",
						paddingLeft: width < 720 ? "22px" : "auto",
						paddingRight: width < 720 ? "22px" : "auto",
					}}
				>
					<Typography sx={textStyleObj}>
						Agora que você já entendeu sobre o Guia Prático para
						começar a investir, vamos abordar um pouco sobre cada
						tipo de investimento mais detalhadamente.
					</Typography>
					<Typography sx={textStyleObj}>
						O Tesouro Direto já atraiu milhares de brasileiros e se
						tornou um dos recursos mais utilizados entre os
						investidores que buscam dar o primeiro passo para fora
						da tradicional forma de investir na poupança. Esse tipo
						de investimento é uma boa maneira e estratégia de
						diversidade e proteção do seu dinheiro.
					</Typography>
					<Typography sx={textStyleObj}>
						• O que é o Tesouro Direto?
						<br />
						• Tipos de Títulos
						<br />
						• Como funciona o Tesouro Direto
						<br />
						• Posso perder dinheiro com Tesouro Direto?
						<br />• Como escolher o melhor título?
					</Typography>
					<Typography sx={titleStyleObj}>
						O que é o Tesouro Direto?
					</Typography>
					<Typography sx={textStyleObj}>
						O Tesouro Direto é um programa criado em 2002 pelo
						Tesouro Nacional – órgão responsável pela gestão da
						dívida pública – para permitir que pessoas físicas
						possam comprar papéis do governo federal pela internet.
					</Typography>
					<Typography sx={textStyleObj}>
						Em resumo, podemos entender que ao comprar um título do
						Tesouro Direto, o investidor está emprestando dinheiro
						ao governo.
					</Typography>
					<Typography sx={textStyleObj}>
						Além disso, o Tesouro Direto ganhou popularidade por ser
						uma das modalidades de investimento mais práticas. Eles
						permitem fazer aplicações com valores a partir de R$ 30
						e oferecem liquidez diária para todos os papéis.
					</Typography>
					<Typography sx={textStyleObj}>
						Dessa forma, o Tesouro Direto não é restrito a poucas
						instituições financeiras. Os investidores podem aplicar
						por meio de diversos bancos e corretoras de valores.
						Além disso, na plataforma do Tesouro Direto, existem
						algumas opções de títulos públicos à venda para perfis
						diferentes de investidores.
					</Typography>
					<Typography sx={titleStyleObj}>
						Tipos de títulos públicos
					</Typography>
					<Typography sx={textStyleObj}>
						A escolha do melhor título público para você comprar
						serve de acordo com o seu perfil. O primeiro passo é
						definir o prazo em que você deseja deixar o dinheiro
						investido e o nível de risco que está disposto a correr.
						Além disso, existem três grupos de títulos públicos à
						venda no Tesouro Direto:{" "}
						<b>prefixados, pós-fixados e híbridos.</b>
					</Typography>
					<TableContainer sx={{ mt: "30px" }}>
						<Table size="small" aria-label="a dense table">
							<TableBody>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Prefixados
									</TableCell>
									<TableCell sx={tableCell} align="right">
										Neste, você sabe exatamente quanto vai
										receber de retorno, desde que faça o
										resgate apenas no vencimento do título.
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Pós-fixados
									</TableCell>
									<TableCell sx={tableCell} align="right">
										Neste, você conhece os critérios de
										remuneração, mas só saberá o retorno
										total do investimento no momento do
										resgate, uma vez que esses papéis são
										atrelados a um indexador que pode
										variar.
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Híbridos
									</TableCell>
									<TableCell sx={tableCell} align="right">
										Neste, eles têm parte da remuneração
										definida no momento da compra e o
										restante atrelado à variação da
										inflação.
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
					<Typography sx={titleStyleObj}>
						Como funciona o Tesouro Direto?
					</Typography>
					<Typography sx={titleStyleObj}>
						Taxas e valor mínimo
					</Typography>
					<Typography sx={textStyleObj}>
						Quando você faz um investimento pelo Tesouro Direto,
						automaticamente você está comprando um título que
						garante o recebimento daquele empréstimo em determinado
						prazo e com determinada remuneração.
					</Typography>
					<Typography sx={textStyleObj}>
						O preço é o valor de cada título posto em negociação. A
						rentabilidade do papel nada mais é do que o juro que o
						governo paga ao investidor para que ele financie sua
						dúvida.
					</Typography>
					<Typography sx={textStyleObj}>
						A quantidade mínima por investimento é a fração de 0,01%
						de cada título emitido pelo governo, ou seja, 1% do
						valor do papel. No entanto, foi definido que o mínimo de
						investimento aceito é R$30, o que torna o Tesouro Direto
						acessível para a maior parte dos brasileiros.
					</Typography>
					<Typography sx={titleStyleObj}>Liquidez</Typography>
					<Typography sx={textStyleObj}>
						Esse termo está relacionado à facilidade com que você
						pode resgatar o dinheiro de um investimento. Em todos os
						títulos vendidos pelo Tesouro Direto, o investidor pode
						resgatar quando quiser, dependendo da característica do
						papel.
					</Typography>
					<Typography sx={textStyleObj}>
						Além disso, você sempre tem o direito de resgatar o
						valor investido a qualquer momento, recebendo na conta
						um dia após a solicitação.
					</Typography>
					<Typography sx={titleStyleObj}>Custos</Typography>
					<Typography sx={textStyleObj}>
						A taxa de custódia do Tesouro Direto é o valor pago pelo
						serviço da B3 que mantém a guarda dos títulos públicos e
						também disponibiliza as informações e movimentações de
						saldos ao investidor. A taxa de custódia equivale a
						0,25% ao ano sobre o valor investido. A cobrança é
						semestral.
					</Typography>
					<Typography sx={textStyleObj}>
						A taxa de administração é cobrada de forma antecipada no
						momento da compra do papel. Depois, a cobrança ocorre
						anualmente.
					</Typography>
					<Typography sx={titleStyleObj}>Tributação</Typography>
					<Typography sx={textStyleObj}>
						O imposto cobrado nos investimentos em títulos públicos
						é regressivo. Dessa forma, quanto mais tempo você deixar
						o dinheiro na aplicação, menos pagará de IR. A alíquota
						que incide sobre o investimento em títulos públicos é de
						22,5% sobre o lucro obtido em aplicações de até 180
						dias.
					</Typography>
					<Typography sx={textStyleObj}>
						Para resgates feitos em curtíssimo prazo, abaixo de 30
						dias, há também a cobrança do Imposto sobre Operações
						Financeiras (IOF). Ele também é calculado sobre os
						rendimentos do período.
					</Typography>
					<Typography sx={titleStyleObj}>
						Horário de funcionamento
					</Typography>
					<Typography sx={textStyleObj}>
						Os investidores podem realizar compras ou resgates no
						Tesouro Direto todos os dias úteis, no horário
						comercial, entre às 9h30 horas e 18 horas, com os preços
						e taxas operados no momento da transação.
					</Typography>
					<Typography sx={textStyleObj}>
						Das 18h às 5h, nos finais de semana ou feriados, os
						preços e taxas exibidos no site do Tesouro Direto são
						apenas para referência. Você pode realizar investimentos
						e resgates, mas serão considerados os preços e taxas de
						abertura do mercado do próximo dia útil.
					</Typography>
					<Typography sx={titleStyleObj}>Tipos de título</Typography>
					<Typography sx={textStyleObj}>
						Entenda os tipos de títulos e simule em qual título você
						deseja investir no site do Tesouro Direto
					</Typography>
					<Typography sx={titleStyleObj}>
						É possível perder dinheiro com o Tesouro Direto?
					</Typography>
					<Typography sx={textStyleObj}>
						Sim, todo investimento há riscos. A situação mais comum
						é você comprar um título prefixado ou indexado à
						inflação de longo prazo, e precisar vender antes da
						hora. Se o resgate for feito em um momento ruim de
						mercado, quando o valor do papel está em queda, ele pode
						estar valendo menos do que você pagou.
					</Typography>
					<Typography sx={titleStyleObj}>
						Como escolher o melhor título?
					</Typography>
					<Typography sx={textStyleObj}>
						O primeiro passo é saber o que você quer fazer com o
						dinheiro. Afinal, se for apenas para compor uma reserva,
						permaneça no Tesouro Selic.
					</Typography>
					<Typography sx={textStyleObj}>
						Agora para aplicar em projetos mais definidos e a longo
						prazo, como a compra de uma casa ou aposentadoria, há
						várias formas e opções de escolha de acordo com os seus
						objetivos. Entenda o seu perfil e como aplicar melhor o
						seu dinheiro.
					</Typography>
					<Typography sx={titleStyleObj}>
						Como investir no Tesouro Direto?
					</Typography>
					<Typography sx={textStyleObj}>
						<b>
							1. Abra uma conta em uma corretora
							<br />
							2. Complete o cadastro na plataforma do Tesouro
							Direto
							<br />
							3. Escolha o título que atende os seus objetivos
							<br />
							4. Dê a ordem de compra e comece a investir
						</b>
					</Typography>
					<Typography sx={textStyleObj}>
						Pronto, agora você já pode começar a investir o seu
						dinheiro de forma prática e segura, conhecendo o seu
						perfil de investidor e entendendo os riscos que podem
						acontecer, caso você não tenha lido tão bem. Volte
						sempre que necessário!
					</Typography>
				</Container>
			</Box>
			<ResponsiveFooter />
		</Box>
	);
}
