import {
	Box,
	Typography,
	Container,
	Divider,
	ListItem,
	List,
	Button,
	Grid,
} from "@mui/material";
import React from "react";
import ResponsiveAppBar from "../../components/AppBar";
import useWindowDimensions from "../../hooks/windowDimension";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ResponsiveFooter from "../../components/Footer";
import cartao_c6 from "../../assets/cartao_c6.png";
import cartao_c6_platinum from "../../assets/cartao_c6_platinum.png";
import cartao_c6_carbon from "../../assets/cartao_c6_carbon.png";
import cartao_c6_debito from "../../assets/cartao_c6_debito.png";
import cartao_c6_home from "../../assets/cartao_c6_home.png";
import useLoader from "../../hooks/useLoader";
import Preloader from "../../components/Preloader";

const divGridStyle = {
	height: "100%",
	width: "100%",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	textAlign: "left",
};

const typographyGridStyle = {
	fontSize: "0.9rem",
	color: "var(--textGray)",
	lineHeight: "1.5rem",
	mb: "30px",
};

export default function C6BankExt() {
	const { width } = useWindowDimensions();
	const loading = useLoader();

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && <Preloader />}
			<Container
				sx={{
					width: "100vw",
					height: "100%",
					mt: width < 720 ? "150px" : "200px",
					mb: "40px",
				}}
			>
				<Typography variant="h5" align="center">
					Cartão de Crédito C6 Bank
				</Typography>
				<Typography
					align="center"
					sx={{
						fontSize: "1.1rem",
						fontWeight: "600",
						mt: "10px",
					}}
				>
					Mais de 16 milhões de pessoas já escolheram o C6 Bank. Só
					falta você.
					<br />
					Escolha o cartão de crédito que é a sua cara!
				</Typography>
				<Box
					sx={{
						width: "100%",
						height: "auto",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						mt: "30px",
					}}
				>
					<img
						alt="cartao_c6_home"
						src={cartao_c6_home}
						loading="lazy"
						style={{
							width: "80%",
							height: "100%",
							maxWidth: "450px",
						}}
					/>
				</Box>
			</Container>
			<Divider variant="middle" />
			<Container
				sx={{
					display: width < 720 ? "flex" : "none",
					alignItems: "center",
					justifyContent: "center",
					mt: "100px",
					mb: "100px",
				}}
			>
				<div id="cartoes.informacash_top_mob"></div>
			</Container>
			<Container
				sx={{
					display: width > 720 ? "flex" : "none",
					alignItems: "center",
					justifyContent: "center",
					mt: "100px",
					mb: "100px",
				}}
			>
				<div id="cartoes.informacash_top_desk"></div>
			</Container>
			<Container
				sx={{
					width: "100vw",
					height: "100%",
					mt: "70px",
					mb: "30px",
					pl: "7%",
					pr: "4%",
				}}
			>
				<Typography
					variant="h5"
					align="left"
					sx={{
						lineHeight: "2rem",
						fontWeight: "700",
						mb: "5px",
					}}
				>
					Por que escolher o Cartão C6 Bank?
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "1.5rem",
						fontWeight: "400",
						mb: "20px",
					}}
				>
					Entre os principais benefícios dos cartões C6 Bank, estão:
					<br />
				</Typography>

				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Sem monotonia: na hora de pedir seu cartão de crédito e
					débito C6 você pode escolher entre mais de 10 cores! Além
					disso, você pode decidir o nome que vem impresso no seu
					cartão de crédito ou débito.
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Programa Átomos: todos os cartões C6 acumulam pontos sem
					custo adicional. O programa é simples de usar, com pontos
					sem prazo de validade. Troque seus pontos por passagens,
					produtos ou cashback. Tudo em poucos toques pelo app do C6
					Bank.
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Pagamento por aproximação: os cartões C6 funcionam por
					aproximação. Encostou, pagou!
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Cartão virtual: com o cartão de crédito virtual você pode
					comprar online com mais segurança.
				</Typography>
				<Container
					sx={{
						display: width < 720 ? "flex" : "none",
						alignItems: "center",
						justifyContent: "center",
						mt: "100px",
						mb: "100px",
					}}
				>
					<div
						class="pg-lazy"
						data-gpt-parent="cartoes.informacash_mid_mob"
					></div>
				</Container>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Cartão adicional: emita cartões adicionais e compartilhe a
					experiência de usar o C6 Bank com quem você gosta.
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Mais autonomia: bloqueie e desbloqueie o cartão quando
					quiser. Pague antecipadamente para liberar limite em
					segundos. Parcelamento de fatura? Faça pelo app em poucos
					toques.
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Mais consciência: acompanhe seus gastos de um jeito simples
					de entender e tenha visibilidade sobre parcelamentos
					futuros.
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Samsung Pay: Cadastre o seu cartão de crédito ou débito do
					C6 Bank no Samsung Pay e faça pagamentos com mais
					praticidade e segurança, sem ter o cartão em mãos.
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mt: "30px",
					}}
				>
					Mais de 16 milhões de pessoas já escolheram o C6 Bank. Só
					falta você. Clique no botão para obter o seu cartão e comece
					a controlar os seus gastos de uma forma simples e cheia de
					benefícios, de uma forma que é a sua cara!
				</Typography>
			</Container>
			<Container
				sx={{
					display: width < 720 ? "flex" : "none",
					alignItems: "center",
					justifyContent: "center",
					mt: "100px",
					mb: "100px",
				}}
			>
				<div
					class="pg-lazy"
					data-gpt-parent="cartoes.informacash_mid_mob"
				></div>
			</Container>
			<Container
				sx={{
					width: "100vw",
					height: "100%",
					mt: "40px",
					mb: "30px",
					pl: "7%",
					pr: "4%",
				}}
			>
				<Typography
					variant="h5"
					align="left"
					sx={{
						lineHeight: "2rem",
						fontWeight: "700",
						mb: "10px",
					}}
				>
					Qual a melhor escolha para o seu perfil?
				</Typography>
				<Container
					sx={{
						display: width > 720 ? "flex" : "none",
						alignItems: "center",
						justifyContent: "center",
						mt: "100px",
						mb: "100px",
					}}
				>
					<div
						class="pg-lazy"
						data-gpt-parent="cartoes.informacash_mid_desk"
					></div>
				</Container>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mt: "10px",
						mb: "10px",
					}}
				>
					Para saber qual versão do cartão combina com você, é
					necessário comparar e analisar qual a relação de benefícios
					se encaixa com a sua realidade, já que o C6 Bank oferece
					quatro opções de cartão:
					<br />
				</Typography>
				<Container sx={{ width: "90%", mb: "20px", mt: "30px" }}>
					<Grid container spacing={2} alignItems="stretch">
						<Grid item xs={12} sm={6}>
							<Box
								sx={{
									width: "100%",
									height: "100%",
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<img
									alt="cartao_c6_debito"
									src={cartao_c6_debito}
									loading="lazy"
									style={{
										width: "50%",
										height: "100%",
										maxWidth: "350px",
										marginBottom: "20px",
									}}
								/>
								<Typography variant="h6" align="center">
									<b>Cartão C6 Débito</b>
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6}>
							<div style={divGridStyle}>
								<Typography
									variant="p"
									align="left"
									sx={typographyGridStyle}
								>
									<b>Anuidade:</b> grátis
									<br />
									<b>Possibilidades:</b> Diversas cores
									disponíveis e personalização do nome
									<br />
									<b>Função:</b> somente débito
									<br />
									<b>Tag:</b> 1 C6 Tag gratuita
									<br />
									<b>Compras:</b> nacionais
									<br />
									<b>Incluso:</b> Pagamento por aproximação e
									Samsung Pay
									<br />
									<b>Benefícios:</b> Mastercard Surpreenda
								</Typography>
							</div>
						</Grid>
						<Container
							sx={{
								display: width < 720 ? "flex" : "none",
								alignItems: "center",
								justifyContent: "center",
								mt: "100px",
								mb: "100px",
							}}
						>
							<div
								class="pg-lazy"
								data-gpt-parent="cartoes.informacash_mid_mob"
							></div>
						</Container>
						<Grid item xs={12} sm={6}>
							<Box
								sx={{
									width: "100%",
									height: "100%",
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<img
									alt="cartao_c6"
									src={cartao_c6}
									loading="lazy"
									style={{
										width: "50%",
										height: "100%",
										maxWidth: "350px",
										marginBottom: "20px",
									}}
								/>
								<Typography variant="h6" align="center">
									<b>Cartão C6</b>
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6}>
							<div style={divGridStyle}>
								<Typography
									variant="p"
									align="left"
									sx={typographyGridStyle}
								>
									<b>Anuidade:</b> grátis
									<br />
									<b>Possibilidades:</b> Diversas cores
									disponíveis e personalização do nome
									<br />
									<b>Função:</b> débito e crédito
									<br />
									<b>Tag:</b> 1 C6 Tag gratuita
									<br />
									<b>Compras:</b> nacionais e internacionais
									<br />
									<b>Incluso:</b> 1 cartão adicional gratuito,
									pagamento por aproximação e Samsung Pay
									<br />
									<b>Benefícios:</b> Mastercard Surpreenda
								</Typography>
							</div>
						</Grid>
						<Container
							sx={{
								display: width > 720 ? "flex" : "none",
								alignItems: "center",
								justifyContent: "center",
								mt: "100px",
								mb: "100px",
							}}
						>
							<div
								class="pg-lazy"
								data-gpt-parent="cartoes.informacash_mid_desk"
							></div>
						</Container>
						<Grid item xs={12} sm={6}>
							<Box
								sx={{
									width: "100%",
									height: "100%",
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<img
									alt="cartao_c6_platinum"
									src={cartao_c6_platinum}
									loading="lazy"
									style={{
										width: "50%",
										height: "100%",
										maxWidth: "350px",
										marginBottom: "20px",
									}}
								/>
								<Typography variant="h6" align="center">
									<b>Cartão C6 Platinum</b>
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6}>
							<div style={divGridStyle}>
								<Typography
									variant="p"
									align="left"
									sx={typographyGridStyle}
								>
									<b>Anuidade:</b> grátis
									<br />
									<b>Possibilidades:</b> Diversas cores
									disponíveis e personalização do nome
									<br />
									<b>Função:</b> débito e crédito
									<br />
									<b>Tag:</b> 1 C6 Tag gratuita
									<br />
									<b>Compras:</b> nacionais e internacionais
									<br />
									<b>Incluso:</b> 1 cartão adicional gratuito,
									pagamento por aproximação e Samsung Pay
									<br />
									<b>Benefícios:</b> Mastercard Surpreenda;
									Isenção de rolha em restaurantes; Priceless
									Cities; Mastercard Travel Rewards;
									Assistente de viagens; Serviço de Concierge;
									Seguro de automóveis; MasterAssist Plus e
									todos os benefícios Mastercard Platinum
								</Typography>
							</div>
						</Grid>
						<Container
							sx={{
								display: width < 720 ? "flex" : "none",
								alignItems: "center",
								justifyContent: "center",
								mt: "100px",
								mb: "100px",
							}}
						>
							<div
								class="pg-lazy"
								data-gpt-parent="cartoes.informacash_mid_mob"
							></div>
						</Container>
						<Grid item xs={12} sm={6}>
							<Box
								sx={{
									width: "100%",
									height: "100%",
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<img
									alt="cartao_c6_carbon"
									src={cartao_c6_carbon}
									loading="lazy"
									style={{
										width: "50%",
										height: "100%",
										maxWidth: "350px",
										marginBottom: "20px",
									}}
								/>
								<Typography variant="h6" align="center">
									<b>Cartão C6 Carbon</b>
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6}>
							<div style={divGridStyle}>
								<Typography
									variant="p"
									align="left"
									sx={typographyGridStyle}
								>
									<b>Anuidade:</b> 12x de R$85
									<br />
									<b>Possibilidades:</b> Diversas cores
									disponíveis e personalização do nome
									<br />
									<b>Função:</b> débito e crédito
									<br />
									<b>Tag:</b> 4 C6 Tags gratuitas
									<br />
									<b>Compras:</b> nacionais e internacionais
									<br />
									<b>Incluso:</b> Até 6 cartões adicionais
									gratuitos, pagamento por aproximação e
									Samsung Pay
									<br />
									<b>Benefícios:</b> Mastercard Surpreenda;
									Acesso ilimitado a sala VIP Mastercard Black
									em guarulhos; Isenção de rolha em
									restaurantes; Priceless Cities; Mastercard
									Travel Rewards; Assistente de viagens;
									Serviço de Concierge; Seguro de automóveis;
									MasterAssist Plus e todos os benefícios
									Mastercard Platinum
								</Typography>
							</div>
						</Grid>
					</Grid>
				</Container>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2.1rem",
						mb: "20px",
						fontWeight: "400",
					}}
				>
					Agora é só escolher qual Cartão C6 Bank é o melhor para você
					e suas necessidades! Além disso, na hora de solicitar seu
					cartão você pode escolher entre mais de 10 cores e
					personalizar seu nome do jeito que quiser!
				</Typography>
				<Container
					sx={{
						display: width < 720 ? "flex" : "none",
						alignItems: "center",
						justifyContent: "center",
						mt: "100px",
						mb: "100px",
					}}
				>
					<div
						class="pg-lazy"
						data-gpt-parent="cartoes.informacash_mid_mob"
					></div>
				</Container>
				<Container
					sx={{
						display: width > 720 ? "flex" : "none",
						alignItems: "center",
						justifyContent: "center",
						mt: "100px",
						mb: "100px",
					}}
				>
					<div
						class="pg-lazy"
						data-gpt-parent="cartoes.informacash_mid_desk"
					></div>
				</Container>
				<Typography
					align="left"
					variant="h5"
					sx={{
						lineHeight: "2.1rem",
						mb: "10px",
						fontWeight: "700",
					}}
				>
					Como solicitar o seu Cartão C6 Bank?
				</Typography>
				<List
					sx={{
						listStyleType: "decimal",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<ListItem
						sx={{
							justifyContent: "left",
							pb: "0 !important",
							pt: "0 !important",
							pl: "20px",
							mb: "5px",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "1rem",
								display: "list-item",
							}}
						>
							Clique em <b>solicitar agora</b>
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "left",
							pb: "0 !important",
							pt: "0 !important",
							pl: "20px",
							mb: "5px",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "1rem",
								display: "list-item",
							}}
						>
							Preencha o formulário com suas informações
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "left",
							pb: "0 !important",
							pt: "0 !important",
							pl: "20px",
							mb: "5px",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "1rem",
								display: "list-item",
							}}
						>
							Abra sua conta no C6 Bank
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "left",
							pb: "0 !important",
							pt: "0 !important",
							pl: "20px",
							mb: "5px",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "1rem",
								display: "list-item",
							}}
						>
							Envie sua solicitação do Cartão e aguarde a
							confirmação do banco
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "left",
							pb: "0 !important",
							pt: "0 !important",
							pl: "20px",
							mb: "5px",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "1rem",
								display: "list-item",
							}}
						>
							Receba o seu Cartão C6 Bank em casa
						</Typography>
					</ListItem>
				</List>
			</Container>
			<Container
				sx={{
					width: "100%",
					height: "100%",
					mb: "50px",
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Button
					variant="contained"
					size="large"
					disableElevation
					sx={{
						fontWeight: "700",
						backgroundColor: "var(--infoCashBlue)",
					}}
					href="https://play.google.com/store/apps/details?id=com.c6bank.app&hl=pt_BR&gl=US"
				>
					Solicitar agora
				</Button>
			</Container>
			<ResponsiveFooter />
		</Box>
	);
}
