import {
	Box,
	Typography,
	Container,
	Divider,
	ListItem,
	List,
	Button,
	Grid,
} from "@mui/material";
import React from "react";
import ResponsiveAppBar from "../../components/AppBar";
import useWindowDimensions from "../../hooks/windowDimension";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ResponsiveFooter from "../../components/Footer";
import buscape_home from "../../assets/buscape_home.png";
import cartao_buscape from "../../assets/cartao_buscape.png";
import useLoader from "../../hooks/useLoader";
import Preloader from "../../components/Preloader";

const divGridStyle = {
	height: "auto",
	width: "100%",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "flex-start",
	textAlign: "left",
};

const typographyGridStyle = {
	fontSize: "0.9rem",
	color: "var(--textGray)",
	lineHeight: "1.5rem",
	mb: "20px",
};

export default function BuscapeExt() {
	const { width } = useWindowDimensions();
	const loading = useLoader();

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && <Preloader />}

			<Container
				sx={{
					width: "100vw",
					height: "100%",
					mt: width < 720 ? "150px" : "200px",
					mb: "40px",
				}}
			>
				<Typography variant="h5" align="center">
					Cartão de Crédito Buscapé
				</Typography>
				<Typography
					align="center"
					sx={{
						fontSize: "1.1rem",
						fontWeight: "600",
						mt: "10px",
					}}
				>
					O cartão sem anuidade, com menor preço garantido e até 2% de
					cashback!
				</Typography>
				<Box
					sx={{
						width: "100%",
						height: "auto",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						mt: "30px",
					}}
				>
					<img
						alt="buscape_home"
						src={buscape_home}
						loading="lazy"
						style={{
							width: "80%",
							height: "100%",
							maxWidth: "450px",
						}}
					/>
				</Box>
			</Container>
			<Divider variant="middle" />
			<Container
				sx={{
					display: width < 720 ? "flex" : "none",
					alignItems: "center",
					justifyContent: "center",
					mt: "100px",
					mb: "100px",
				}}
			>
				<div id="cartoes.informacash_top_mob"></div>
			</Container>
			<Container
				sx={{
					display: width > 720 ? "flex" : "none",
					alignItems: "center",
					justifyContent: "center",
					mt: "100px",
					mb: "100px",
				}}
			>
				<div id="cartoes.informacash_top_desk"></div>
			</Container>
			<Container
				sx={{
					width: "100vw",
					height: "100%",
					mt: "70px",
					mb: "30px",
					pl: "7%",
					pr: "4%",
				}}
			>
				<Typography
					variant="h5"
					align="left"
					sx={{
						lineHeight: "2rem",
						fontWeight: "700",
						mb: "5px",
					}}
				>
					Por que escolher o Cartão de Crédito Buscapé?
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "1.5rem",
						fontWeight: "400",
						mb: "20px",
					}}
				>
					Entre os principais benefícios do cartão Buscapé, estão:
					<br />
				</Typography>

				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Anuidade totalmente gratuita.
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Cashback: o cartão Buscapé pode garantir um retorno de até
					2% do valor das compras, que é devolvido diretamente na
					conta digital PAN, a qual o cartão está associado.
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Aplicativo e conta digital: com o aplicativo do Banco Pan, é
					possível controlar a fatura e acompanhar as movimentações do
					seu cartão Buscapé de uma forma simples, completa, intuitiva
					e sem burocracias, diretamente na palma da sua mão!
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Cobertura internacional: o cartão Buscapé é aceito ao redor
					do mundo!
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Pagamento por aproximação: o cartão Buscapé funciona por
					aproximação. Encostou, pagou!
				</Typography>
				<Container
					sx={{
						display: width < 720 ? "flex" : "none",
						alignItems: "center",
						justifyContent: "center",
						mt: "100px",
						mb: "100px",
					}}
				>
					<div
						class="pg-lazy"
						data-gpt-parent="cartoes.informacash_mid_mob"
					></div>
				</Container>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mb: "10px",
					}}
				>
					<CheckBoxIcon
						sx={{
							color: "var(--greenCheck)",
							verticalAlign: "middle",
							marginRight: "5px",
						}}
					/>
					Proteção de preços e garantia estendida original para suas
					compras!
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mt: "10px",
						mb: "10px",
					}}
				>
					Clique no botão para obter o seu cartão e comece a controlar
					os seus gastos de uma forma simples, cheia de benefícios e
					sem burocracia!
					<br />
				</Typography>
			</Container>
			<Container
				sx={{
					width: "100vw",
					height: "100%",
					mt: "40px",
					mb: "30px",
					pl: "7%",
					pr: "4%",
				}}
			>
				<Typography
					variant="h5"
					align="left"
					sx={{
						lineHeight: "2rem",
						fontWeight: "700",
						mb: "10px",
					}}
				>
					Como saber se é a melhor escolha para o seu perfil?
				</Typography>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mt: "10px",
						mb: "10px",
					}}
				>
					Para saber se o cartão combina com você, é necessário
					comparar e analisar se relação de benefícios se encaixa com
					a sua realidade:
					<br />
				</Typography>
				<Container sx={{ width: "90%", mb: "20px", mt: "30px" }}>
					<Grid container spacing={2} alignItems="stretch">
						<Grid item xs={12} sm={6}>
							<Box
								sx={{
									width: "100%",
									height: "100%",
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<img
									alt="cartao_buscape"
									src={cartao_buscape}
									loading="lazy"
									style={{
										width: "90%",
										height: "100%",
										maxWidth: "350px",
										marginBottom: "20px",
									}}
								/>
								<Typography variant="h6" align="center">
									<b>Internacional Visa</b>
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6}>
							<div style={divGridStyle}>
								<Typography
									variant="p"
									align="left"
									sx={typographyGridStyle}
								>
									<b>Anuidade:</b> grátis
									<br />
									<b>Cobertura:</b> nacional e internacional
									<br />
									<b> Versão:</b> débito e crédito
									<br />
									<b> Cashback:</b> 1% nas compras no Buscapé
									e até 1% nos demais estabelecimentos
									<br />
									<b> Pontuação:</b> R$ 1 = 1 ponto
									<br />
									<b>Benefícios:</b> participação no programa
									Mastercard Surpreenda; aplicativo e
									pagamento por aproximação; proteção de preço
									e garantia estendida.
								</Typography>
							</div>
						</Grid>
					</Grid>
				</Container>
				<Container
					sx={{
						display: width < 720 ? "flex" : "none",
						alignItems: "center",
						justifyContent: "center",
						mt: "100px",
						mb: "100px",
					}}
				>
					<div
						class="pg-lazy"
						data-gpt-parent="cartoes.informacash_mid_mob"
					></div>
				</Container>
				<Container
					sx={{
						display: width > 720 ? "flex" : "none",
						alignItems: "center",
						justifyContent: "center",
						mt: "100px",
						mb: "100px",
					}}
				>
					<div
						class="pg-lazy"
						data-gpt-parent="cartoes.informacash_mid_desk"
					></div>
				</Container>
				<Typography
					align="left"
					sx={{
						fontSize: "1.1rem",
						lineHeight: "2rem",
						fontWeight: "400",
						mt: "10px",
						mb: "10px",
					}}
				>
					Dê alguma olhada também na proporção de retorno do Cashback
					e nas principais tarifas do cartão Buscapé:
					<br />
				</Typography>
				<Container sx={{ width: "90%", mb: "20px", mt: "30px" }}>
					<Grid container spacing={2} alignItems="stretch">
						<Grid item xs={12} sm={6}>
							<Box
								sx={{
									width: "100%",
									height: "100%",
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<Typography
									align="left"
									variant="h5"
									sx={{
										lineHeight: "2.1rem",
										mb: "10px",
										fontWeight: "700",
									}}
								>
									Cashback
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6}>
							<div style={divGridStyle}>
								<Typography
									variant="p"
									align="left"
									sx={typographyGridStyle}
								>
									<b>Fatura de até R$750 mensais:</b> não há
									cashback
									<br />
									<b>
										Fatura de R$ 751 à R$1.500 mensais:
									</b>{" "}
									0,5% de cashback
									<br />
									<b>
										Fatura a partir de R$1.501 mensais:
									</b>{" "}
									1% de cashback
								</Typography>
							</div>
						</Grid>
					</Grid>
					<Grid container spacing={2} alignItems="stretch">
						<Grid item xs={12} sm={6}>
							<Box
								sx={{
									width: "100%",
									height: "100%",
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<Typography
									align="left"
									variant="h5"
									sx={{
										lineHeight: "2.1rem",
										mb: "10px",
										fontWeight: "700",
									}}
								>
									Principais tarifas
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6}>
							<div style={divGridStyle}>
								<Typography
									variant="p"
									align="left"
									sx={typographyGridStyle}
								>
									<b>Anuidade:</b> Isenta
									<br />
									<b>Parcelamento de fatura::</b> 10,10% a.m
									<br />
									<b>Segunda via do cartão:</b> R$ 17
									<br />
									<b>Crédito rotativo:</b> 17,83% a.m
									<br />
									<b>Saques no crédito:</b> R$18,15 no Brasil
									e R$40 no exterior
									<br />
									<b>Pagamento de contas no crédito:</b> R$
									4,90
									<br />
									<b>Avaliação do limite emergencial: </b> R$
									18,90
								</Typography>
							</div>
						</Grid>
					</Grid>
				</Container>
				<Typography
					align="left"
					variant="h5"
					sx={{
						lineHeight: "2.1rem",
						mb: "10px",
						fontWeight: "700",
					}}
				>
					Como solicitar o seu Cartão Buscapé?
				</Typography>
				<List
					sx={{
						listStyleType: "decimal",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<ListItem
						sx={{
							justifyContent: "left",
							pb: "0 !important",
							pt: "0 !important",
							pl: "20px",
							mb: "5px",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "1rem",
								display: "list-item",
							}}
						>
							Clique em <b>solicitar agora</b>
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "left",
							pb: "0 !important",
							pt: "0 !important",
							pl: "20px",
							mb: "5px",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "1rem",
								display: "list-item",
							}}
						>
							Abra sua conta digital do Banco Pan
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "left",
							pb: "0 !important",
							pt: "0 !important",
							pl: "20px",
							mb: "5px",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "1rem",
								display: "list-item",
							}}
						>
							Preencha o formulário com suas informações
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "left",
							pb: "0 !important",
							pt: "0 !important",
							pl: "20px",
							mb: "5px",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "1rem",
								display: "list-item",
							}}
						>
							Envie sua proposta e aguarde a confirmação do banco
						</Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "left",
							pb: "0 !important",
							pt: "0 !important",
							pl: "20px",
							mb: "5px",
						}}
					>
						<Typography
							align="left"
							style={{
								fontSize: "1rem",
								display: "list-item",
							}}
						>
							Receba o seu Cartão Buscapé em casa
						</Typography>
					</ListItem>
				</List>
			</Container>
			<Container
				sx={{
					width: "100%",
					height: "100%",
					mb: "50px",
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Button
					variant="contained"
					size="large"
					disableElevation
					sx={{
						fontWeight: "700",
						backgroundColor: "var(--infoCashBlue)",
					}}
					href="https://igoal.go2cloud.org/aff_c?offer_id=5452&aff_id=1673"
				>
					Solicitar agora
				</Button>
			</Container>
			<ResponsiveFooter />
		</Box>
	);
}
