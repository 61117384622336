import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";

export default function Post46() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const titleStyleObj = {
		fontSize: width > 720 ? "1.9em" : "1.6em",
		fontWeight: "700",
		mb: "calc(0.2em)",
		mt: "calc(13px + 1.2em)",
		lineHeight: "1.5",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "#111",
	};

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			{!loading && (
				<React.Fragment>
					<Container
						sx={{
							width: "100%",
							height: "30%",
							paddingTop: width < 720 ? "150px" : "200px",
							paddingBottom: width < 720 ? "50px" : "100px",
							paddingLeft: width < 720 ? "15px" : "auto",
							paddingRight: width < 720 ? "15px" : "auto",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							backgroundColor: "#161616",
							maxWidth: "none !important",
						}}
					>
						<Typography
							align="center"
							sx={{
								fontSize: width < 720 ? "1.5rem" : "2.5rem",
								lineHeight: width < 720 ? "2rem" : "3rem",
								textAlign: "center",
								maxWidth: "700px",
								fontWeight: "700",
								color: "#FFF",
								padding: "20px",
							}}
						>
							Clear Corretora vs Toro Investimento: qual a melhor
						</Typography>
					</Container>
					<Box
						sx={{
							width: "100%",
							height: "100%",
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
							mt: "100px",
							mb: "200px",
							backgroundColor: "#FFF",
						}}
					>
						<Container
							sx={{
								maxWidth: "770px !important",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "flex-start",
								paddingLeft: width < 720 ? "22px" : "auto",
								paddingRight: width < 720 ? "22px" : "auto",
							}}
						>
							<Typography sx={textStyleObj}>
								<p>
									A Clear Corretora e a Toro Investimentos
									estão entre as melhores instituições para
									quem quer investir no mercado financeiro,
									por causa de suas várias vantagens.
								</p>
								<br />
								<p>
									Para chegar à resposta de qual das duas
									corretoras com taxa zero é a melhor, bem
									como escolher com consciência, é necessário
									comparar seus contextos e perfis, produtos e
									serviços oferecidos, taxas, plataformas,
									atendimentos e as vantagens e desvantagens
									de cada uma.
								</p>
							</Typography>

							<Typography sx={titleStyleObj}>
								Clear Corretora
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Fundada em 2012, por empreendedores com
									experiência na indústria de investimentos
									eletrônicos no Brasil, a Clear Corretora
									rapidamente se tornou líder de mercado no
									segmento que estava presente.
								</p>
								<br />
								<p>
									Em 2014, teve 100% de suas ações adquiridas
									pela XP Inc, que possui, hoje, operações em
									10 escritórios situados em 4 países. Desde
									então, milhões de reais foram investidos em
									infraestrutura e sistemas, além do embarque
									de novas linhas de negócio, como
									distribuição de ativos de renda fixa e
									plataformas de negociação.
								</p>
								<br />
								<p>
									A Clear Corretora é uma marca da XP Inc. e
									tem seus procedimentos incorporados à XP
									Investimentos CCTVM S.A., uma instituição
									financeira validada pelo Banco Central do
									Brasil, membra efetivo da B3 e continuamente
									submetida às regulações e auditorias do BC,
									CVM e BSM.
								</p>
								<br />
								<p>
									<b>Serviços oferecidos:</b> Conteúdo
									educacional, recomendações e plataformas de
									análise técnica.
								</p>
								<br />
								<p>
									<b>Principais produtos:</b> Bolsa de valores
									e títulos públicos.
								</p>
								<br />
								<p>
									<b>Taxas:</b> Há isenção em todas as taxas.
								</p>
								<br />
								<p>
									<b>Reputação:</b> Nota de 8.0 no Reclame
									Aqui
								</p>
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Segundo a própria empresa, “aliando nosso
									amplo conhecimento de mercado a uma
									infraestrutura de alto nível, proporcionamos
									aos nossos clientes ferramentas de
									negociação inovadoras e com os melhores
									preços”. Eles afirmam que buscam atualizar
									constantemente as tecnologias, com
									compromisso às regulações vigentes,
									transmitindo aos traders atributos de
									inovação e eficiência.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								Toro Investimentos
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									O primeiro passo da empresa foi dado em
									2008, quando Gabriel Kallas deu o primeiro
									curso sobre investimentos no escritório de
									casa. Márcio Placedino cria um serviço de
									cálculo de Imposto de Renda na Bolsa online
									e João Resende cultiva com os amigos de
									infância o desejo de empreender e fazer a
									diferença.
								</p>
								<br />
								<p>
									Dois anos depois, os três se tornam amigos
									na faculdade fundam oficialmente a Toro
									Investimentos, como uma empresa de educação
									em investimentos, em uma sala de 25m². Além
									de cursos, a Toro passa a oferecer serviço
									de análise e consultoria em investimentos.
								</p>
								<br />
								<p>
									Em 2018, se torna uma das primeiras fintechs
									de investimento do mundo a ser dona da sua
									própria instituição financeira e lança para
									o público, além de cursos, uma “nova solução
									completa de investimentos, oferecendo
									investimentos em renda fixa e renda
									variável”.
								</p>
								<br />
								<p>
									<b>Serviços oferecidos:</b> Conteúdo
									educacional, recomendações, home broker e
									plataformas.
								</p>
								<br />
								<p>
									<b>Principais produtos:</b> Bolsa de
									valores, títulos públicos e privados e
									fundos de investimento.
								</p>
								<br />
								<p>
									<b>Taxas:</b> Isenção em todas as operações
									(a depender do plano escolhido).
								</p>
								<br />
								<p>
									<b>Reputação:</b> Nota 7.8 no Reclame Aqui
								</p>
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Segundo a própria empresa, “queremos tornar
									a sua vida financeira mais simples, próspera
									e prazerosa através da melhor experiência de
									investimentos do mundo. Para isso, te
									ajudamos a encontrar as melhores
									oportunidades de investimento do mercado e a
									investir de um jeito fácil, seguro e com
									taxa 0 de verdade”.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								Clear vs Toro
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Para entender qual melhor instituição para o
									seu perfil de investidor, iremos abordar as
									principais diferenças das corretoras, no que
									diz respeito aos produtos e serviços
									oferecidos, encerrando com os prós e contras
									de ambas.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>Produtos</Typography>
							<Typography sx={textStyleObj}>
								<p>
									A diferença entre os principais produtos
									oferecidos pelas corretoras é bem grande.
								</p>
								<br />
								<p>
									Isso porque, na Clear, só é possível operar
									com ativos negociados em Bolsa e títulos
									públicos, enquanto na Toro, além das duas
									citadas, são oferecidos títulos privados e
									fundos de investimento, com uma grande
									diversidade em cada um deles.
								</p>
								<br />
								<p>
									Mesmo que a maioria de seus clientes só
									queira investir na Bolsa de Valores, esse
									acaba sendo um ponto negativo para a Clear
									Corretora, principalmente para quem deseja
									ter todos os investimentos em um só lugar.
								</p>
								<br />
								<p>
									Quanto às taxas, as duas corretoras são bem
									equivalentes, pois em ambas existe a opção
									de ter corretagem 100% gratuita nas
									operações.
								</p>
								<br />
								<p>
									A abertura e manutenção de conta, custódia,
									saque de conta, renda fixa, tesouro direto e
									fundos imobiliários são serviços isentos em
									ambas as corretoras. Já a bolsa de valores,
									é totalmente isento na Clear, mas só é
									isento na Toro dependendo do plano do
									cliente.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>Serviços</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Os principais serviços oferecidos pela Clear
									e pela Toro são conteúdos educacionais e
									recomendações de investimento.
								</p>
								<br />
								<br />
								<p>
									<b>Conteúdo Educacional</b>
								</p>
								<br />
								<p>
									O conteúdo educacional das duas é bem
									completo, mas a Clear leva vantagem com uma
									gama muito maior de cursos – inclusive, com
									alguns de nível avançado, servindo como uma
									graduação.
								</p>
								<br />
								<p>
									Alguns dos cursos da <b>Clear</b> são:
									Combo: Cursos de Educação Financeira;
									Dinheiro Sem Tabu: Crenças Limitantes;
									Educação Financeira para Jovens; O Poder do
									Autoconhecimento Financeiro; Furando a
									bolha: Transformando a liberdade financeira
									em realidade; Viver, Curtir, Investir:
									Tirando Sonhos do Papel; Aprenda a investir
									na bolsa de valores; Renda fixa: Ganhos com
									Baixo Risco; Combo: Jornada do trader;
									Análise Técnica Clássica – Entenda os
									gráficos para operar em qualquer mercado;
									Start na renda variável; Long & Short Pro;
									MBA de Gestão Exponencial; MBA Investimentos
									e Private Banking; MBA em Ações e Stock
									Picking.
								</p>
								<br />
								<p>
									Enquanto isso, os cursos oferecidos pela
									<b>Toro</b> são: Primeiros passos para
									investir; Bolsa de valores sem mistério;
									Trader de 0 a 100; Desvendando ganhos;
									Trader, um novo estilo de vida; Mapa do
									investidor de sucesso; Day trade para
									iniciantes; Como investir no curto prazo; e
									O jogo da vida.
								</p>
								<br />
								<p>
									<b>Recomendações de Investimento</b>
								</p>
								<br />
								<p>
									Além disso, ambas as corretoras possuem
									carteiras recomendadas: de ações, fundos
									imobiliários, dividendos, etc. A Toro
									Investimentos, contudo, leva vantagem neste
									ponto, pois conta com mais informações em
									seus relatórios de recomendações. O canal do
									YouTube das duas instituições também possui
									conteúdos bem completos, sendo uma excelente
									fonte de conhecimento.
								</p>
								<br />
								<p>
									As carteiras recomendadas pela{" "}
									<b>Toro Investimentos</b> são: Turbo Trader;
									Toro+; Pioneiros da Bolsa; Proprietários do
									futuro.
								</p>
								<br />
								<p>
									Já as recomendadas pela{" "}
									<b>Clear Corretora</b> são: Renda variável;
									Vivendo de Renda; Carteiras de BDRs;
									Carteiras mensais; Onde investir em 2022.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								Prós e Contras
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Para analisar a viabilidade de abrir uma
									conta em uma das corretoras, é possível
									também analisar suas principais vantagens e
									desvantagens.
								</p>
								<br />
								<p>
									Na Clear Corretora, os principais{" "}
									<b>benefícios</b>
									são:
								</p>
							</Typography>
							<Typography
								sx={{
									ml: "25px",
									lineHeight: "2rem",
									pr: "10px",
								}}
							>
								<ul>
									<li>
										Taxa Zero: a Clear é uma das pioneiras
										no mercado de corretoras sem taxa – de
										custódia, corretagem e administração.
										Ela também possui preços acessíveis para
										quem investe na Bolsa. Porém, algumas
										cobranças podem ocorrer em casos de
										zeragem compulsória durante operações
										alavancadas.{" "}
									</li>
									<li>
										Facilidade de Acesso: o cadastro na
										corretora pode ser feito de forma muito
										simples e rápida, pela internet. Em
										relação à outras empresas do mesmo
										segmento, a Clear ganha destaque pela
										eficiência e agilidade. A empresa também
										está focada em trazer novas tecnologias
										para melhorar a experiência dos
										clientes.
									</li>
									<li>
										Segurança: nesse quesito, a Clear é
										referência no Brasil, já que é
										regulamentada pela Comissão de Valores
										Mobiliários (CVM) e conta com
										certificação da Central de Custódia e
										Liquidação Financeira de Títulos
										Privados (CETIP).
									</li>
								</ul>
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Em contrapartida, as principais{" "}
									<b>desvantagens</b>
									são:
								</p>
							</Typography>
							<Typography
								sx={{
									ml: "25px",
									lineHeight: "2rem",
									pr: "10px",
								}}
							>
								<ul>
									<li>
										Foco em renda variável: quem investe em
										renda fixa, pode se decepcionar, porque
										a empresa só trabalha com dois tipos de
										ativos, o Tesouro Direto e o CDB. Assim,
										pessoas com perfil mais conservador ou
										que buscam diversificar a carteira não
										encontram grandes possibilidades.
									</li>
									<li>
										Instabilidade na plataforma: é um
										problema recorrente na Clear, de acordo
										com relatos do Reclame Aqui, que apontam
										a lentidão no site e impossibilidade de
										acesso. Também já houve problemas de
										suspensão temporária devido ao tráfego
										de usuários.
									</li>
									<li>
										Bugs no aplicativo: muitos usuários já
										reclamaram de erros no aplicativo da
										corretora, o Clear Mobile. Tanto na
										Google Play Store como na Apple Store, o
										aplicativo não é bem avaliado, com
										reclamações sobre a falta de recursos e
										erros constantes devido a novas
										atualizações.
									</li>
								</ul>
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Quanto à Toro, as principais{" "}
									<b>vantagens</b> são:
								</p>
							</Typography>
							<Typography
								sx={{
									ml: "25px",
									lineHeight: "2rem",
									pr: "10px",
								}}
							>
								<ul>
									<li>
										Corretagem zero: na corretora, o cliente
										não paga nenhuma taxa de corretagem,
										abertura, manutenção ou saque. Só será
										necessário pagar taxa se obtiver lucro
										na bolsa de valores – 10% sobre os
										rendimentos.
									</li>
									<li>
										Buscador de investimentos: é uma boa
										ferramenta, oferecida pela Toro,
										principalmente para quem aplica em renda
										fixa e tem como objetivo auxiliar o
										cliente na tomada de decisões. Basta
										inserir informações sobre seu perfil e
										descobrir qual investimento está
										alinhado com seus objetivos.
									</li>
									<li>
										Servidores distribuídos em três
										capitais: esse fato intensifica a
										segurança das operações, já que, se
										ocorrer ataque a um dos servidores,
										outros dois estarão disponíveis. Assim,
										os dados podem ser recuperados.
									</li>
								</ul>
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									As principais <b>desvantagens</b>, no
									entanto, são:
								</p>
							</Typography>
							<Typography
								sx={{
									ml: "25px",
									lineHeight: "2rem",
									pr: "10px",
								}}
							>
								<ul>
									<li>
										Cobrança em transações de ganho: o
										sistema da Toro é chamado pricing, onde,
										se o cliente ganha, a empresa também
										ganha. Assim, quem investe na Toro por
										meio de recomendação dos especialistas,
										paga corretagem se lucrar.
									</li>
									<li>
										Home broker apresenta instabilidade:
										muitos usuários reclamam da
										instabilidade e lentidão do sistema, o
										que pode gerar diversos prejuízos,
										principalmente durante operações day
										trade.
									</li>
								</ul>
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									No que diz respeito a opinião dos usuários,
									as duas instituições têm uma excelente
									avaliação no Reclame aqui, com uma nota de
									7.7 para a Toro e 8.0 para a Clear. Dentre
									os clientes da Toro entrevistados, 63,4%
									dizem que voltariam a fazer negócios com a
									empresa. Já no caso dos clientes da Clear,
									77,3%.
								</p>
							</Typography>

							<Typography sx={titleStyleObj}>
								Conclusão: qual a melhor?
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Quando se trata da escolha de uma corretora,
									não existe certo ou errado ou, propriamente,
									a melhor. É necessário analisar os produtos
									e serviços oferecidas por ambas, bem como as
									plataformas e taxas – você pode usar este
									artigo para fazer isso! – e chegar a uma
									conclusão do que se encaixa melhor no seu
									perfil de investidor.
								</p>
								<br />
								<p>
									Também é importante consultar as opiniões de
									outros clientes da corretora, que
									geralmente, apontam as principais vantagens
									e desvantagens observadas no dia a dia.
								</p>
								<br />
								<p>
									E, claro, também é preciso relembrar que as
									corretoras não são excludentes. Caso
									prefira, você pode abrir conta nas duas e
									desfrutar dos benefícios de ambas!
								</p>
							</Typography>
						</Container>
					</Box>
					<ResponsiveFooter />
				</React.Fragment>
			)}
		</Box>
	);
}
