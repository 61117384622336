import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export default function Post38() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const titleStyleObj = {
		fontSize: width > 720 ? "1.9em" : "1.6em",
		fontWeight: "700",
		mb: "calc(0.2em)",
		mt: "calc(13px + 1.2em)",
		lineHeight: "1.5",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "#111",
	};

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	const textTableStyleObj = {
		fontSize: width > 720 ? "1.3em" : "0.9em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.4",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "center",
		color: "rgba(0,0,0,.74)",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			{!loading && (
				<React.Fragment>
					<Container
						sx={{
							width: "100%",
							height: "30%",
							paddingTop: width < 720 ? "150px" : "200px",
							paddingBottom: width < 720 ? "50px" : "100px",
							paddingLeft: width < 720 ? "15px" : "auto",
							paddingRight: width < 720 ? "15px" : "auto",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							backgroundColor: "#161616",
							maxWidth: "none !important",
						}}
					>
						<Typography
							align="center"
							sx={{
								fontSize: width < 720 ? "1.5rem" : "2.5rem",
								lineHeight: width < 720 ? "2rem" : "3rem",
								textAlign: "center",
								maxWidth: "700px",
								fontWeight: "700",
								color: "#FFF",
								padding: "20px",
							}}
						>
							Real e Ibovespa têm segunda maior valorização do
							mundo
						</Typography>
					</Container>
					<Box
						sx={{
							width: "100%",
							height: "100%",
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
							mt: "100px",
							mb: "200px",
							backgroundColor: "#FFF",
						}}
					>
						<Container
							sx={{
								maxWidth: "770px !important",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "flex-start",
								paddingLeft: width < 720 ? "22px" : "auto",
								paddingRight: width < 720 ? "22px" : "auto",
							}}
						>
							<Typography sx={textStyleObj}>
								<p>
									Segundo levantamento da agência de
									classificação de risco Austin Rating, cedido
									à CNN Brasil, no primeiro trimestre do ano,
									o real valorizou 17,8% em relação ao dólar –
									o segundo maior índice dentre 120 países –,
									enquanto o Ibovespa cresceu 34,6%, com o
									segundo melhor desempenho dentre 79 bolsas.
								</p>
								<br />
								<p>
									Segundo fechamento da última sexta-feira
									(1), o dólar opera na casa dos R$ 4,66 ante
									o real. O desempenho da moeda brasileira
									supera a média dos países emergentes, com
									valorização de 5,06% em relação ao dólar,
									assim cono o grupo dos Brics (Brasil,
									Rússia, Índia, China e África do Sul), que
									teve um recuo de 1,01%.
								</p>
								<br />
								<p>
									Assim, o real ficou atrás apenas do Kwanza,
									moeda da Angola, que valorizou 24,4%. Os
									motivos são semelhantes aos da alta do real,
									em especial, o fluxo de investimento
									estrangeiro que busca produtores de
									commodities. As dez moedas de melhor
									desempenho são de países ligados a esses
									produtos, principalmente na África e na
									América Latina.
								</p>
							</Typography>
							<TableContainer component={Paper} elevation={0}>
								<Table
									sx={{ minWidth: 340 }}
									size="small"
									aria-label="a dense table"
								>
									<TableHead>
										<TableRow>
											<TableCell align="left">
												<Typography
													sx={textTableStyleObj}
												>
													<b>Ranking</b>
												</Typography>
											</TableCell>
											<TableCell align="center">
												<Typography
													sx={textTableStyleObj}
												>
													<b>País</b>
												</Typography>
											</TableCell>
											<TableCell align="center">
												<Typography
													sx={textTableStyleObj}
												>
													<b>Moeda</b>
												</Typography>
											</TableCell>
											<TableCell align="center">
												<Typography
													sx={textTableStyleObj}
												>
													<b>
														Variação no ano até 31
														de março
													</b>
												</Typography>
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
												verticalAlign: "top",
											}}
										>
											<TableCell scope="row" align="left">
												<Typography
													sx={textTableStyleObj}
												>
													1º
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography
													sx={textTableStyleObj}
												>
													Angola
												</Typography>
											</TableCell>
											<TableCell scope="row" align="left">
												<Typography
													sx={textTableStyleObj}
												>
													Kwanza
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography
													sx={textTableStyleObj}
												>
													24,4%
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
												verticalAlign: "top",
											}}
										>
											<TableCell scope="row" align="left">
												<Typography
													sx={textTableStyleObj}
												>
													2º
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography
													sx={textTableStyleObj}
												>
													Brasil
												</Typography>
											</TableCell>
											<TableCell scope="row" align="left">
												<Typography
													sx={textTableStyleObj}
												>
													Real
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography
													sx={textTableStyleObj}
												>
													17,8%
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
												verticalAlign: "top",
											}}
										>
											<TableCell scope="row" align="left">
												<Typography
													sx={textTableStyleObj}
												>
													3º
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography
													sx={textTableStyleObj}
												>
													Afeganistão
												</Typography>
											</TableCell>
											<TableCell scope="row" align="left">
												<Typography
													sx={textTableStyleObj}
												>
													Afegane
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography
													sx={textTableStyleObj}
												>
													17,2%
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
												verticalAlign: "top",
											}}
										>
											<TableCell scope="row" align="left">
												<Typography
													sx={textTableStyleObj}
												>
													4º
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography
													sx={textTableStyleObj}
												>
													Lesoto
												</Typography>
											</TableCell>
											<TableCell scope="row" align="left">
												<Typography
													sx={textTableStyleObj}
												>
													Loti
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography
													sx={textTableStyleObj}
												>
													9,3%
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
												verticalAlign: "top",
											}}
										>
											<TableCell scope="row" align="left">
												<Typography
													sx={textTableStyleObj}
												>
													5º
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography
													sx={textTableStyleObj}
												>
													Namíbia
												</Typography>
											</TableCell>
											<TableCell scope="row" align="left">
												<Typography
													sx={textTableStyleObj}
												>
													Dólar da Namíbia
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography
													sx={textTableStyleObj}
												>
													9,3%
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
												verticalAlign: "top",
											}}
										>
											<TableCell scope="row" align="left">
												<Typography
													sx={textTableStyleObj}
												>
													6º
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography
													sx={textTableStyleObj}
												>
													Essuatíni
												</Typography>
											</TableCell>
											<TableCell scope="row" align="left">
												<Typography
													sx={textTableStyleObj}
												>
													Lilangeni
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography
													sx={textTableStyleObj}
												>
													9,2%
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
												verticalAlign: "top",
											}}
										>
											<TableCell scope="row" align="left">
												<Typography
													sx={textTableStyleObj}
												>
													7º
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography
													sx={textTableStyleObj}
												>
													África do Sul
												</Typography>
											</TableCell>
											<TableCell scope="row" align="left">
												<Typography
													sx={textTableStyleObj}
												>
													Rande
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography
													sx={textTableStyleObj}
												>
													9,2%
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
												verticalAlign: "top",
											}}
										>
											<TableCell scope="row" align="left">
												<Typography
													sx={textTableStyleObj}
												>
													8º
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography
													sx={textTableStyleObj}
												>
													Uruguai
												</Typography>
											</TableCell>
											<TableCell scope="row" align="left">
												<Typography
													sx={textTableStyleObj}
												>
													Peso
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography
													sx={textTableStyleObj}
												>
													9,1%
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
												verticalAlign: "top",
											}}
										>
											<TableCell scope="row" align="left">
												<Typography
													sx={textTableStyleObj}
												>
													9º
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography
													sx={textTableStyleObj}
												>
													Chile
												</Typography>
											</TableCell>
											<TableCell scope="row" align="left">
												<Typography
													sx={textTableStyleObj}
												>
													Peso
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography
													sx={textTableStyleObj}
												>
													8,1%
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
												verticalAlign: "top",
											}}
										>
											<TableCell scope="row" align="left">
												<Typography
													sx={textTableStyleObj}
												>
													10º
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography
													sx={textTableStyleObj}
												>
													Peru{" "}
												</Typography>
											</TableCell>
											<TableCell scope="row" align="left">
												<Typography
													sx={textTableStyleObj}
												>
													Novo Sol
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography
													sx={textTableStyleObj}
												>
													7,4%
												</Typography>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
							<Typography sx={textStyleObj}>
								<p>
									Enquanto isso, as moedas de pior desempenho
									são as de países que tiveram instabilidades
									políticas, sociais ou econômicas, o que
									afastou investidores. É o caso da Lira turca
									(115º) e da Rupia do Sri Lanka (120º), e do
									Rublo da Rússia (114º, com recuo de 11,1%),
									do Rublo de Belarus (119º, com recuo de
									21,7%) e da Hryvnia ucraniana (111º, com
									queda de 7,5%).
								</p>
								<br />
								<p>
									Os três últimos países estão envolvidos em
									um conflito no Leste Europeu, que ocorre há
									mais de um mês. As economias da Rússia e do
									Belarus estão severamente impactadas pelas
									sanções sofridas, por parte de países
									ocidentais. Moedas de outros países europeus
									também tiveram baixo desempenho, já que o
									continente foi um dos mais afetados pelo
									conflito.
								</p>
							</Typography>

							<Typography sx={titleStyleObj}>
								Índices da Bolsa de Valores
							</Typography>

							<Typography sx={textStyleObj}>
								<p>
									O Ibovespa, principal índice da bolsa de
									valores, ficou atrás apenas do ALSZI, da
									bolsa do Zimbábue, um país relevante na
									produção de commodities, onde a alta
									trimestral foi de 46,5%.
								</p>
								<br />
								<p>
									As outras oito bolsas com melhor desempenho
									também são de países exportadores de
									produtos básicos, principalmente minerais e
									petróleo, como é o caso do Chile, Qatar e
									Arábia Saudita.
								</p>
							</Typography>
							<TableContainer component={Paper} elevation={0}>
								<Table
									sx={{ minWidth: 340 }}
									size="small"
									aria-label="a dense table"
								>
									<TableHead>
										<TableRow>
											<TableCell align="left">
												<Typography
													sx={textTableStyleObj}
												>
													<b>Ranking</b>
												</Typography>
											</TableCell>
											<TableCell align="center">
												<Typography
													sx={textTableStyleObj}
												>
													<b>
														Principal índice da
														Bolsa{" "}
													</b>
												</Typography>
											</TableCell>
											<TableCell align="center">
												<Typography
													sx={textTableStyleObj}
												>
													<b>País </b>
												</Typography>
											</TableCell>
											<TableCell align="center">
												<Typography
													sx={textTableStyleObj}
												>
													<b>Variação em dólar </b>
												</Typography>
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
												verticalAlign: "top",
											}}
										>
											<TableCell scope="row" align="left">
												<Typography
													sx={textTableStyleObj}
												>
													1º
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography
													sx={textTableStyleObj}
												>
													ALSZI
												</Typography>
											</TableCell>
											<TableCell scope="row" align="left">
												<Typography
													sx={textTableStyleObj}
												>
													Zimbábue
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography
													sx={textTableStyleObj}
												>
													46,5%
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
												verticalAlign: "top",
											}}
										>
											<TableCell scope="row" align="left">
												<Typography
													sx={textTableStyleObj}
												>
													2º
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography
													sx={textTableStyleObj}
												>
													Ibovespa
												</Typography>
											</TableCell>
											<TableCell scope="row" align="left">
												<Typography
													sx={textTableStyleObj}
												>
													Brasil
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography
													sx={textTableStyleObj}
												>
													34,6%
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
												verticalAlign: "top",
											}}
										>
											<TableCell scope="row" align="left">
												<Typography
													sx={textTableStyleObj}
												>
													3º
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography
													sx={textTableStyleObj}
												>
													S&P/BVL
												</Typography>
											</TableCell>
											<TableCell scope="row" align="left">
												<Typography
													sx={textTableStyleObj}
												>
													Peru
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography
													sx={textTableStyleObj}
												>
													29,4%
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
												verticalAlign: "top",
											}}
										>
											<TableCell scope="row" align="left">
												<Typography
													sx={textTableStyleObj}
												>
													4º
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography
													sx={textTableStyleObj}
												>
													FTSE
												</Typography>
											</TableCell>
											<TableCell scope="row" align="left">
												<Typography
													sx={textTableStyleObj}
												>
													Colômbia
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography
													sx={textTableStyleObj}
												>
													27,6%
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
												verticalAlign: "top",
											}}
										>
											<TableCell scope="row" align="left">
												<Typography
													sx={textTableStyleObj}
												>
													5º
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography
													sx={textTableStyleObj}
												>
													S&P CLX IPSA
												</Typography>
											</TableCell>
											<TableCell scope="row" align="left">
												<Typography
													sx={textTableStyleObj}
												>
													Chile
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography
													sx={textTableStyleObj}
												>
													24,3%
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
												verticalAlign: "top",
											}}
										>
											<TableCell scope="row" align="left">
												<Typography
													sx={textTableStyleObj}
												>
													6º
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography
													sx={textTableStyleObj}
												>
													QEAS
												</Typography>
											</TableCell>
											<TableCell scope="row" align="left">
												<Typography
													sx={textTableStyleObj}
												>
													Qatar
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography
													sx={textTableStyleObj}
												>
													17,2%
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
												verticalAlign: "top",
											}}
										>
											<TableCell scope="row" align="left">
												<Typography
													sx={textTableStyleObj}
												>
													7º
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography
													sx={textTableStyleObj}
												>
													TASI
												</Typography>
											</TableCell>
											<TableCell scope="row" align="left">
												<Typography
													sx={textTableStyleObj}
												>
													Arábia Saudita
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography
													sx={textTableStyleObj}
												>
													16,1%
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
												verticalAlign: "top",
											}}
										>
											<TableCell scope="row" align="left">
												<Typography
													sx={textTableStyleObj}
												>
													8º
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography
													sx={textTableStyleObj}
												>
													BAX
												</Typography>
											</TableCell>
											<TableCell scope="row" align="left">
												<Typography
													sx={textTableStyleObj}
												>
													Bahren
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography
													sx={textTableStyleObj}
												>
													15,3%
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
												verticalAlign: "top",
											}}
										>
											<TableCell scope="row" align="left">
												<Typography
													sx={textTableStyleObj}
												>
													9º
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography
													sx={textTableStyleObj}
												>
													BKA
												</Typography>
											</TableCell>
											<TableCell scope="row" align="left">
												<Typography
													sx={textTableStyleObj}
												>
													Kuwait
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography
													sx={textTableStyleObj}
												>
													15%
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
												verticalAlign: "top",
											}}
										>
											<TableCell scope="row" align="left">
												<Typography
													sx={textTableStyleObj}
												>
													10º
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography
													sx={textTableStyleObj}
												>
													JSE FTSE TOP 40
												</Typography>
											</TableCell>
											<TableCell scope="row" align="left">
												<Typography
													sx={textTableStyleObj}
												>
													África do Sul
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography
													sx={textTableStyleObj}
												>
													11,9%
												</Typography>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
							<Typography sx={textStyleObj}>
								<p>
									Enquanto isso, o Sri Lanka teve o pior
									desempenho entre as bolsas mundiais, com uma
									queda de 49,8%. Em segundo lugar vem a
									Rússia, onde o índice MOEX caiu 35,9%,
									novamente um reflexo das sanções econômicas.
								</p>
								<br />
								<p>
									Países europeus como a Finlândia, a Suécia,
									a Alemanha e a Áustria também apresentam
									índices baixos, devido a guerra na Ucrânia.
									A China também está entre os dez piores
									desempenhos, reflexo da fuga de
									investimentos devido a intervenções do
									estado em empresas, principalmente as de
									tecnologia e do setor imobiliário.
								</p>
								<br />
								<p>
									Nos Estados Unidos, a bolsa de Nasdaq,
									ligada a área de tecnologia, perdeu 9,1% no
									trimestre (ocupando 62º lugar), e o índice
									Dow Jones também recuou 4,6% (ocupando 45º
									lugar). Tudo isso, como reflexo do início do
									ciclo da alta de juros, onde investidores
									tiram seus investimentos na renda variável.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								Desempenho Brasileiro: alta do Real e do
								Ibovespa
							</Typography>

							<Typography sx={textStyleObj}>
								<p>
									A alta do real e do Ibovespa refletem uma
									tendência de valorização iniciada em 2022,
									baseada no fluxo de investimentos
									estrangeiros. A taxa de juros, hoje em
									11,75%, uma das maiores do mundo, também
									contribui para a moeda brasileira e torna a
									renda fixa do país atrativa.
								</p>
								<br />
								<p>
									O ciclo de elevação para combater a inflação
									ainda não acabou, e o Banco Central projeta
									que a Selic terminará em 12,75% ao ano.
								</p>
								<br />
								<p>
									O real e o mercado de ações são beneficiados
									por essa busca de investidores por grandes
									produções de commodities, principalmente no
									contexto de valorização desses produtos –
									devido a um descompasso entre oferta e
									demanda, gerado pela pandemia e pela guerra
									na Ucrânia.
								</p>
								<br />
								<p>
									Existe também uma procura por mercados com
									participação de setores menos vulneráveis no
									cenário global atual, caso das commodities e
									dos bancos, dois setores de peso na bolsa
									brasileira.
								</p>
								<br />
								<p>
									Desde 2021 os investidores estrangeiros
									estão se retirando dos mercados asiáticos, e
									agora da Rússia, devido às sanções. Nesse
									cenário, a economia do Brasil se torna uma
									alterativa.
								</p>
								<br />
								<p>
									Com um peso menor, especialistas apontam
									também um alívio nas perspectivas das
									eleições presidenciais de 2022, com uma
									redução na preocupação de um possível
									descontrole da política fiscal no Brasil.
								</p>
								<br />
								<p>
									O início do ciclo da alta de juros nos
									Estados Unidos ainda não ultrapassou os
									benefícios do fluxo e, para alguns
									especialistas, até o ajuda inicialmente, já
									que investidores saem da renda variável
									norte-americana à procura de investimentos
									mais vantajosos. Conforme os juros no país
									sobem, o cenário pode mudar.
								</p>
							</Typography>
						</Container>
					</Box>
					<ResponsiveFooter />
				</React.Fragment>
			)}
		</Box>
	);
}
