import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export default function Post29() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const titleStyleObj = {
		fontSize: width > 720 ? "1.9em" : "1.6em",
		fontWeight: "700",
		mb: "calc(0.2em)",
		mt: "calc(13px + 1.2em)",
		lineHeight: "1.5",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "#111",
	};

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	const textTableStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "right",
		color: "rgba(0,0,0,.74)",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			{!loading && (
				<React.Fragment>
					<Container
						sx={{
							width: "100%",
							height: "30%",
							paddingTop: width < 720 ? "150px" : "200px",
							paddingBottom: width < 720 ? "50px" : "100px",
							paddingLeft: width < 720 ? "15px" : "auto",
							paddingRight: width < 720 ? "15px" : "auto",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							backgroundColor: "#161616",
							maxWidth: "none !important",
						}}
					>
						<Typography
							align="center"
							sx={{
								fontSize: width < 720 ? "1.5rem" : "2.5rem",
								lineHeight: width < 720 ? "2rem" : "3rem",
								textAlign: "center",
								maxWidth: "700px",
								fontWeight: "700",
								color: "#FFF",
								padding: "20px",
							}}
						>
							CDB: veja o que é, quais os tipos e como investir
						</Typography>
					</Container>
					<Box
						sx={{
							width: "100%",
							height: "100%",
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
							mt: "100px",
							mb: "200px",
							backgroundColor: "#FFF",
						}}
					>
						<Container
							sx={{
								maxWidth: "770px !important",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "flex-start",
								paddingLeft: width < 720 ? "22px" : "auto",
								paddingRight: width < 720 ? "22px" : "auto",
							}}
						>
							<Typography sx={textStyleObj}>
								<p>
									Uma das principais recomendações para quem
									está começando investir é aplicar em
									produtos de renda fixa – um tipo de
									investimento mais seguro, que mostra, logo
									no início, quanto você terá de juros ao
									final dele. Entre eles, o CDB é um dos mais
									procurados, por ser tão seguro quanto a
									poupança e ter uma rentabilidade ainda
									maior.
								</p>
								<br />
								<p>
									Mas, afinal, o que é CDB? Quais são os
									tipos? Como ele funciona e como faz para
									investir? Continue lendo este artigo para
									entender mais sobre o investimento!
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								O que é CDB?
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									CDB é a sigla para Certificado de Depósito
									Bancário, um investimento de renda fixa
									emitido por instituições financeiras. É um
									título que funciona como um empréstimo ao
									banco: você empresta seu dinheiro para a
									instituição e o recebe, depois, com juros.
								</p>
								<br />
								<p>
									Esses títulos são emitidos por diversos
									bancos e corretoras de investimentos, que
									usam o dinheiro para custear operações ou
									financiar empréstimos para outros clientes.
									Essas instituições têm autonomia para
									definir o valor mínimo de cada aplicação,
									sua rentabilidade e o valor do vencimento.
								</p>
								<br />
								<p>
									Pessoas físicas e jurídicas podem adquirir o
									CDB e, quando decidem pelo ativo, por se
									tratar de renda fixa, já sabem quais as
									características do título e como será sua
									rentabilidade.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								Tipos de CDB
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Existem três grandes tipos de CDBs, e eles
									são classificados pela forma como pagam os
									juros, ou, em outras palavras, quanto
									rendem: o prefixado, o pós fixado e o
									híbrido (que mistura os dois primeiros).
								</p>
							</Typography>
							<TableContainer component={Paper} elevation={0}>
								<Table
									sx={{ minWidth: 340 }}
									size="small"
									aria-label="a dense table"
								>
									<TableBody>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
												verticalAlign: "top",
											}}
										>
											<TableCell scope="row" align="left">
												<Typography sx={textStyleObj}>
													<b>Prefixado</b>
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography sx={textStyleObj}>
													Quando os juros já estão,
													como o nome diz, prefixados.
													Ou seja, quando você compra
													o título de CDB já saberá
													exatamente qual será o
													rendimento dele. Para
													investir neste tipo de CDB,
													é bom observar a situação
													atual do mercado e avaliar
													os indicadores econômicos do
													país. Exemplo: 10% ao ano.
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
												verticalAlign: "top",
											}}
										>
											<TableCell scope="row" align="left">
												<Typography sx={textStyleObj}>
													<b>Pós fixado</b>
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography sx={textStyleObj}>
													Quando os juros não são
													fixos, mas sim podem variar
													de acordo com outros
													indicadores da economia, ou
													de acordo com índices, como
													o IPCA, a Selic ou outro. No
													caso do CDB, o mais comum é
													que esse índice seja o CDI e
													a taxa DI. Com CDBs pós
													fixados, é comum ver
													propostas em que ele renda
													uma porcentagem da taxa DI.
													Exemplo: 100% do CDI.
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
												verticalAlign: "top",
											}}
										>
											<TableCell scope="row" align="left">
												<Typography sx={textStyleObj}>
													<b>Híbrido</b>
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography sx={textStyleObj}>
													O CDB híbrido nada mais é
													que uma união do CDB
													pré-fixado com o pós-fixado:
													uma parte da rentabilidade é
													estabelecida no momento da
													aplicação e a outra parte é
													atrelada a um índice
													econômico, como o IPCA.
													Exemplo: CDI + 3% ao ano.
												</Typography>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
							<Typography sx={textStyleObj}>
								<p>
									As opções podem variar conforme a
									instituição financeira. O mais indicado é
									pesquisar e comparar as alternativas entre
									os bancos e corretoras, avaliando quais
									títulos são mais adequados para o seu
									objetivo, considerando: data de vencimento,
									risco de crédito, e rendimento.{" "}
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								Como investir?
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Para investir no CDB é preciso ter CPF em
									situação regular; abrir uma conta em um
									banco ou corretora, que funcionarão como
									intermediários; escolher o tipo de CDB entre
									os listados anteriormente; e investir,
									considerando a data de vencimento.
								</p>
								<br />
								<h3 style={{ marginBottom: "10px" }}>
									<b>
										1. Abrir uma conta em um banco ou
										corretora{" "}
									</b>
								</h3>

								<p>
									O investimento no CDB é feito por meio de um
									banco ou corretora de valores. Os dois são
									boas opções, mas, no caso do banco, é
									possível que você fique preso às opções de
									CDB oferecidas por ele, enquanto a corretora
									pode ofertar diferentes títulos.
								</p>
								<br />
								<p>
									Para o CDB, o aporte inicial, ou seja, valor
									mínimo para o investimento, geralmente é de
									R$ 5 mil, mas existem valores mínimos entre
									R$ 1 mil e R$ 2 mil.
								</p>
								<br />
								<h3 style={{ marginBottom: "10px" }}>
									<b>2. Escolha o tipo de CDB desejado </b>
								</h3>
								<p>
									Após criar uma conta, é necessário acessar a
									tela de investimentos do aplicativo, buscar
									por renda fixa, clicar na opção de CDB e
									escolher o tipo desejado entre as opções
									disponíveis.
								</p>
								<br />
								<p>A escolha vai depender de vários fatores:</p>
							</Typography>
							<Typography
								sx={{
									ml: "25px",
									lineHeight: "2rem",
									pr: "10px",
								}}
							>
								<ul>
									<li>Qual valor mínimo?</li>
									<li>Qual liquidez você precisa?</li>
									<li>
										Qual índice econômico você quer que sua
										aplicação seja atrelada?{" "}
									</li>
									<li>
										Qual a credibilidade da instituição que
										emitiu o título?
									</li>
								</ul>
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									É importante prestar atenção na liquidez do
									seu CDB, isso implica na facilidade e
									rapidez em resgatar o dinheiro aplicado.
									Existem títulos com liquidez diária (onde é
									possível resgatar o dinheiro no mesmo dia da
									solicitação) e alguns com liquidez de mais
									de 90 dias.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								É seguro?
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									O CDB é o investimento que o mercado
									financeiro chama de “aplicação menos
									volátil”, ou seja, está atrelado a índices
									que varia menos (como a Selic e o CDI),
									então, é mais estável. Além disso, no
								</p>
								<br />
								<p>
									Além disso, no caso do CDB pré-fixado, o
									investidor já sabe de quanto será seu
									rendimento logo no início, deixando a
									aplicação mais segura e sólida.
								</p>
								<br />
								<p>
									Outro ponto é a segurança do “devedor”.
									Existem poucas chances de o banco quebrar e
									não te pagar. E, caso isso ocorra, o CDB
									também conta com cobertura do Fundo
									Garantidor de Crédito, o FGC. O órgão
									assegura o reembolso de valores investidos
									em caso de liquidação do banco emissor, com
									o limite de até R$ 250 mil por conta e até
									R$ 1 milhão por CPF ou CNPJ.
								</p>
							</Typography>
							<Typography sx={titleStyleObj}>
								Taxas do CDB{" "}
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Nesse investimento, pode haver incidência de
									dois impostos, que diminuem ao passar do
									tempo: o Imposto de Renda e o Imposto sobre
									Operações Financeiras (IOF). Ambos são
									descontados do rendimento, não do dinheiro
									inicial que você investiu.
								</p>
								<br />
								<p>
									O IOF é cobrado caso haja um resgate com
									menos de 30 dias do início da aplicação. Ele
									segue uma tabela regressiva que varia entre
									100% e 3%.
								</p>
								<br />
								<p>
									O Imposto de Renda também é descontado de
									forma regressiva, que vai de 22,5% a 15% a
									partir do primeiro mês, conforme a tabela
									abaixo:
								</p>
							</Typography>
							<TableContainer component={Paper} elevation={0}>
								<Table
									sx={{ minWidth: 340 }}
									size="small"
									aria-label="a dense table"
								>
									<TableHead>
										<TableRow>
											<TableCell align="left">
												<Typography sx={textStyleObj}>
													<b>
														Período de CDB investido
													</b>
												</Typography>
											</TableCell>
											<TableCell align="center">
												<Typography
													sx={textTableStyleObj}
												>
													<b>
														Alíquota (percentual
														cobrado)
													</b>
												</Typography>
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell scope="row" align="left">
												<Typography sx={textStyleObj}>
													Até 180 dias
												</Typography>
											</TableCell>
											<TableCell align="right">
												<Typography
													sx={textTableStyleObj}
													align="right"
												>
													22,5%
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell scope="row" align="left">
												<Typography sx={textStyleObj}>
													De 181 a 360 dias
												</Typography>
											</TableCell>
											<TableCell align="right">
												<Typography
													sx={textTableStyleObj}
												>
													20%
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell scope="row" align="left">
												<Typography sx={textStyleObj}>
													De 361 a 720 dias
												</Typography>
											</TableCell>
											<TableCell align="right">
												<Typography
													sx={textTableStyleObj}
												>
													17,5%
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell scope="row" align="left">
												<Typography sx={textStyleObj}>
													Acima de 720 dias
												</Typography>
											</TableCell>
											<TableCell align="right">
												<Typography
													sx={textTableStyleObj}
												>
													15%
												</Typography>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
							<Typography sx={textStyleObj}>
								<p>
									Agora você já sabe mais sobre o CDB, ou
									Certificado de Depósito Bancário! Caso
									esteja pensando em aplicar, sugerimos que
									leia cada vez mais sobre o investimento,
									para decidir se o CDB combina com seu
									perfil, e estude as opções disponíveis com
									seu banco ou corretora.
								</p>
								<br />
								<p>
									Nos baseamos em alguns textos para escrever
									nosso artigo com informações profundas e
									confiáveis: CNN Brasil, Nubank e XP
									Investimentos.
								</p>
							</Typography>
						</Container>
					</Box>
					<ResponsiveFooter />
				</React.Fragment>
			)}
		</Box>
	);
}
