import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";

export default function Post34() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const titleStyleObj = {
		fontSize: width > 720 ? "1.9em" : "1.6em",
		fontWeight: "700",
		mb: "calc(0.2em)",
		mt: "calc(13px + 1.2em)",
		lineHeight: "1.5",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "#111",
	};

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			{!loading && (
				<React.Fragment>
					<Container
						sx={{
							width: "100%",
							height: "30%",
							paddingTop: width < 720 ? "150px" : "200px",
							paddingBottom: width < 720 ? "50px" : "100px",
							paddingLeft: width < 720 ? "15px" : "auto",
							paddingRight: width < 720 ? "15px" : "auto",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							backgroundColor: "#161616",
							maxWidth: "none !important",
						}}
					>
						<Typography
							align="center"
							sx={{
								fontSize: width < 720 ? "1.5rem" : "2.5rem",
								lineHeight: width < 720 ? "2rem" : "3rem",
								textAlign: "center",
								maxWidth: "700px",
								fontWeight: "700",
								color: "#FFF",
								padding: "20px",
							}}
						>
							Setor de energia deve migrar para fontes renováveis
						</Typography>
					</Container>
					<Box
						sx={{
							width: "100%",
							height: "100%",
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
							mt: "100px",
							mb: "200px",
							backgroundColor: "#FFF",
						}}
					>
						<Container
							sx={{
								maxWidth: "770px !important",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "flex-start",
								paddingLeft: width < 720 ? "22px" : "auto",
								paddingRight: width < 720 ? "22px" : "auto",
							}}
						>
							<Typography sx={textStyleObj}>
								<p>
									Segundo o Painel Intergovernamental sobre
									Mudanças Climáticas (IPCC), o setor de
									energia deve migrar, a nível mundial e em
									grande escala, da geração de energia por
									combustíveis fósseis para fontes renováveis.
								</p>
								<br />
								<p>
									O IPCC também alerta para a necessidade de
									grandes transições na produção de energia,
									para que o mundo avance na redução de
									emissão de gases do efeito estufa.
								</p>
								<br />
								<p>
									“Isso envolverá uma redução substancial no
									uso de combustíveis fósseis, eletrificação
									generalizada, eficiência energética
									aprimorada e uso de combustíveis
									alternativos (como hidrogênio)”, disseram os
									especialistas, em relatório publicado nesta
									segunda-feira (4).
								</p>
								<br />
								<p>
									Diante da pesquisa do IPCC, o presidente do
									Instituto Brasileiro de Proteção Ambiental
									(Proam), Carlos Bocuhy, disse que o Brasil
									tem potencial para garantir uma matriz
									energética totalmente renovável.
								</p>
								<br />
								<p>
									Porém, o maior desafio não é a expansão de
									novos sistemas de geração de energia, mas
									preservar o potencial hidrelétrico que já
									funciona no país.
								</p>
								<br />
								<p>
									Segundo a Agência Internacional de Energia
									(AIE), o Brasil é responsável por 45% da
									geração de energia na região das Américas do
									Sul e Central. E, de acordo com o Operador
									Nacional do Sistema Elétrico (ONS), cerca de
									85% das matrizes energéticas do país são
									renováveis, divididas entre usinas
									hidrelétricas, solares, eólicas e térmicas
									movidas a biomassa.
								</p>
								<br />
								<p>
									“Há a perspectiva de diminuição no volume de
									chuvas em determinadas regiões do Brasil
									para o futuro. No Norte do país, por
									exemplo, é prevista uma redução das chuvas
									de acordo com a previsão futura de mudança
									climática. É importante que o Brasil se
									dedique a uma política de preservação do
									ecossistema hidrológico, como as nascentes
									do rio São Francisco, rio Paraná, que
									dependem da umidade da Amazônia”, afirma
									Bocuhy.
								</p>
							</Typography>

							<Typography sx={titleStyleObj}>
								Investimento em fontes renováveis
							</Typography>
							<Typography sx={textStyleObj}>
								<p>
									Em paralelo, o Brasil tem investido cada vez
									mais em energia renovável, como uma
									tentativa de reduzir a dependência do
									petróleo e de seus derivados.
								</p>
								<br />
								<p>
									Com um histórico de crescimento nos últimos
									dez anos, o Brasil chegou a uma posição
									inédita no Ranking Global de Energia Eólica:
									em 2012, o país ocupava a 15ª posição; já em
									2021, passou a ocupar o 6º lugar no Ranking
									de Capacidade Total Instalada de Energia
									ante a 7ª posição em 2020.
								</p>
								<br />
								<p>
									Segundo o ONS, a previsão é que a energia
									eólica no Brasil chegue a uma potência de
									26.864 Megawatts até 2026, um aumento de
									29,4% na capacidade instalada neste ano.
									Assim, a fonte será responsável por 13,9% da
									geração de energia no país.
								</p>
								<br />
								<p>
									A energia solar também está em alta. Segundo
									a Câmara de Comercialização de Energia
									Elétrica (CCEE), em fevereiro deste ano, o
									país teve a maior taxa de geração de energia
									solar de toda história. São mais de 1,2 mil
									MWm (Megawatt-médio) gerados no mês, o dobro
									do mesmo período do ano passado.
								</p>
								<br />
								<p>
									Ainda segundo dados do ONS, a meta é que o
									Brasil tenha o dobro de capacidade instalada
									para a geração de energia solar até 2026,
									passando de 4.672 MW para 9.535 MW. Assim, a
									fonte solar passará a ser responsável por
									4,9% da energia gerada no país.
								</p>
								<br />
								<p>
									De acordo com Carlos Bocuhy, o segundo passo
									para cumprir a meta do IPCC sobre a geração
									de energia é fazer a transição para as
									matrizes eólica e solar. O especialista diz
									que o Brasil tem um grande território e o
									clima tropical ensolarado permite o avanço
									da energia solar, por exemplo.
								</p>
								<br />
								<p>
									O relatório do IPCC diz ainda que a emissão
									mundial de gases do efeito estufa pode ser
									reduzida pela metade até 2030. Na pesquisa,
									cientistas dizem que a meta é realista, já
									que existem evidências do crescimento de
									ações globais contra o aquecimento da Terra,
									como a substituição do petróleo por outras
									fontes renováveis.
								</p>
							</Typography>
						</Container>
					</Box>
					<ResponsiveFooter />
				</React.Fragment>
			)}
		</Box>
	);
}
