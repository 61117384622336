import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";

export default function Post4() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const titleStyleObj = {
		fontSize: width > 720 ? "1.9em" : "1.6em",
		fontWeight: "700",
		mb: "calc(0.2em)",
		mt: "calc(13px + 1.2em)",
		lineHeight: "1.5",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "#111",
	};

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			<Container
				sx={{
					width: "100%",
					height: "30%",
					paddingTop: width < 720 ? "150px" : "200px",
					paddingBottom: width < 720 ? "50px" : "100px",
					paddingLeft: width < 720 ? "15px" : "auto",
					paddingRight: width < 720 ? "15px" : "auto",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					backgroundColor: "#161616",
					maxWidth: "none !important",
				}}
			>
				<Typography
					align="center"
					sx={{
						fontSize: width < 720 ? "1.5rem" : "2.5rem",
						lineHeight: width < 720 ? "2rem" : "3rem",
						textAlign: "center",
						maxWidth: "700px",
						fontWeight: "700",
						color: "#FFF",
						padding: "20px",
					}}
				>
					Poder de compra do Real caiu 30% em 5 anos
				</Typography>
			</Container>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
					mt: "100px",
					mb: "200px",
					backgroundColor: "#FFF",
				}}
			>
				<Container
					sx={{
						maxWidth: "770px !important",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "flex-start",
						paddingLeft: width < 720 ? "22px" : "auto",
						paddingRight: width < 720 ? "22px" : "auto",
					}}
				>
					<Typography sx={textStyleObj}>
						Nos últimos cinco anos, a inflação no Brasil cresceu de
						forma intensa. Em 2018, o IPCA (Índice Nacional de
						Preços ao Consumidor Amplo) registrado no país foi de
						3,75%, taxa que aumentou para 10,06% em 2021. Hoje, ela
						se encontra em 11,30%.
					</Typography>
					<Typography sx={textStyleObj}>
						Com tanta inflação, em um período de três anos, entre
						março de 2017 e março de 2020, o real perdeu 31,32% de
						seu valor e poder de compra. Isso significa que com o
						mesmo valor é possível comprar apenas dois terços do que
						comprava naquela época.
					</Typography>
					<Typography sx={textStyleObj}>
						Essas porcentagens se refletem diretamente na vida da
						população, que encontra preços mais altos para os mesmos
						produtos e serviços. Também é importante citar que a
						inflação afeta de maneira diferente os orçamentos, de
						acordo com a faixa de renda da família.
					</Typography>
					<Typography sx={textStyleObj}>
						Para os mais pobres, o aumento dos preços dos alimentos
						e do gás de cozinha consome boa parte da renda no início
						do ano. Segundo dados do IPCA-15, de março deste ano, em
						pelo menos 11 capitais, somente os itens básicos da
						cesta básica já equivaliam a mais de 50% do salário
						mínimo.
					</Typography>
					<Typography sx={textStyleObj}>
						Já as famílias de renda mais alta sentiram mais os
						reajustes dos preços do transporte, decorrentes do
						aumento da gasolina.
					</Typography>
					<Typography sx={titleStyleObj}>
						Impactos do aumento de preços
					</Typography>
					<Typography sx={textStyleObj}>
						Segundo o economista Fabio Louzada, analista e CEO da
						escola Eu Me Banco, os brasileiros parecem sentir mais o
						peso da inflação por viver “o pior cenário em termos de
						inflação versus reajuste de salários.”
					</Typography>
					<Typography sx={textStyleObj}>
						“O brasileiro sente mais a inflação porque está nos
						produtos e serviços que a população mais consome no dia
						a dia: gasolina, gás de cozinha, alimentos, energia
						elétrica e aluguel. Complementando, vem o salário do
						consumidor que teve poucos reajustes nesse período,
						principalmente por conta da pandemia”, explica Louzada.
					</Typography>
					<Typography sx={textStyleObj}>
						“Nos outros anos, por mais que a inflação subisse, os
						salários também subiam, então afetava um pouco menos o
						poder de compra.”
					</Typography>
					<Typography sx={textStyleObj}>
						O economista também explicou que o aumento da inflação
						pode ser explicado por fatores nacionais e
						internacionais. “Quanto maior a instabilidade global,
						mais os investidores tiram dinheiro dos emergentes. A
						pandemia foi o principal fator nesse período, seguida da
						guerra na Ucrânia”, diz.
					</Typography>
					<Typography sx={textStyleObj}>
						Além disso, a crise hídrica e os fenômenos climáticos de
						2021, que alongaram períodos de secas e cheias, também
						afetaram a produção de alimentos e energia elétrica,
						diminuindo a oferta de produtos nas prateleiras do
						mercado.
					</Typography>
					<Typography sx={textStyleObj}>
						Em paralelo, o aumento da taxa de juros feito pelo Banco
						Central não está sendo efetivo para reduzir a inflação,
						explica Patrícia Campos, supervisora da área de preços
						do Dieese (Departamento Intersindical de Estatística e
						Estudos Socioeconômicos).
					</Typography>
					<Typography sx={textStyleObj}>
						“A política adotada é o aumento da taxa de juros, mas
						ela não trata a inflação na sua causa porque a economia
						brasileira está parada”, explica.
					</Typography>
					<Typography sx={titleStyleObj}>Corte de gastos</Typography>
					<Typography sx={textStyleObj}>
						Os juros altos afetam diretamente o poder de compra do
						brasileiro, que acaba consumindo menos produtos – já que
						os empréstimos também ficam mais caros e difíceis.
					</Typography>
					<Typography sx={textStyleObj}>
						De acordo com uma pesquisa divulgada pela Confederação
						Nacional da Indústria (CNI) no final de abril, com menos
						poder de compra, mais de 60% da população teve que
						cortar gastos nos últimos meses. Segundo a mesma
						pesquisa, mais de 30% teve que fazer cortes “grandes ou
						muito grades” nas despesas.
					</Typography>
				</Container>
			</Box>
			<ResponsiveFooter />
		</Box>
	);
}
