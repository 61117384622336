import { Box, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ResponsiveAppBar from "../../../components/AppBar";
import ResponsiveFooter from "../../../components/Footer";
import useWindowDimensions from "../../../hooks/windowDimension";
import { MoonLoader } from "react-spinners";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

export default function Post23() {
	const { width } = useWindowDimensions();
	const [loading, setLoading] = useState(true);

	const titleStyleObj = {
		fontSize: width > 720 ? "1.9em" : "1.6em",
		fontWeight: "700",
		mb: "calc(0.2em)",
		mt: "calc(13px + 1.2em)",
		lineHeight: "1.5",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "#111",
	};

	const textStyleObj = {
		fontSize: width > 720 ? "1.3em" : "1em",
		fontWeight: "400",
		mb: "1.15em",
		mt: "1em",
		lineHeight: "1.79",
		textRendering: "optimizeLegibility",
		webkitFontSmoothing: "antialiased",
		textAlign: "left",
		color: "rgba(0,0,0,.74)",
	};

	const tableCell = {
		fontSize: width > 720 ? "1.3em" : "1em",
	};

	if (loading) {
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}

	return (
		<Box sx={{ width: "100vw", height: "100%" }}>
			<ResponsiveAppBar />
			{loading && (
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<MoonLoader size={120} color="#1AA3DA" />
					<Typography
						sx={{
							fontSize: "1.8rem",
							mt: "20px",
							fontWeight: "500",
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			)}
			<Container
				sx={{
					width: "100%",
					height: "30%",
					paddingTop: width < 720 ? "150px" : "200px",
					paddingBottom: width < 720 ? "50px" : "100px",
					paddingLeft: width < 720 ? "15px" : "auto",
					paddingRight: width < 720 ? "15px" : "auto",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					backgroundColor: "#161616",
					maxWidth: "none !important",
				}}
			>
				<Typography
					align="center"
					sx={{
						fontSize: width < 720 ? "1.5rem" : "2.5rem",
						lineHeight: width < 720 ? "2rem" : "3rem",
						textAlign: "center",
						maxWidth: "700px",
						fontWeight: "700",
						color: "#FFF",
						padding: "20px",
					}}
				>
					LCI e LCA: entenda mais sobre os investimentos
				</Typography>
			</Container>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
					mt: "100px",
					mb: "200px",
					backgroundColor: "#FFF",
				}}
			>
				<Container
					sx={{
						maxWidth: "770px !important",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "flex-start",
						paddingLeft: width < 720 ? "22px" : "auto",
						paddingRight: width < 720 ? "22px" : "auto",
					}}
				>
					<Typography sx={textStyleObj}>
						Atualmente, no mundo de investimentos, o que não faltam
						são opções para diversificar a carteira. Quando falamos
						de Renda Fixa, as opções vão muito além da poupança, dos
						Certificados de Depósitos Bancários (CDBs) e do Tesouro
						Direto. Existem outros títulos de crédito que podem
						oferecer boas alternativas de rentabilidade e liquidez,
						como as letras de crédito. As principais são as Letras
						de Crédito Imobiliário (LCI) e as Letras de Crédito do
						Agronegócio (LCA).
					</Typography>
					<Typography sx={titleStyleObj}>
						O que é LCI e LCA?
					</Typography>
					<Typography sx={textStyleObj}>
						As Letras de Crédito Imobiliário (LCI) e as Letras de
						Crédito do Agronegócio (LCA) são dois investimentos em
						renda fixa emitidos por instituições financeiras.
					</Typography>
					<Typography sx={textStyleObj}>
						São utilizados para financiar as atividades do mercado
						imobiliário e do agronegócio, e são recomendados para a
						pessoa que deseja um rendimento próximo ao Certificado
						de Depósito Interbancário (CDI) com segurança e isenção
						de impostos.
					</Typography>
					<Typography sx={textStyleObj}>
						Simplificando, quem investe em LCI E LCA está
						“emprestando” seu dinheiro para a instituição financeira
						oferecer créditos que incentivem o desenvolvimento do
						setor imobiliário e agrícola, considerados estratégicos
						pelo governo.
					</Typography>
					<Typography sx={textStyleObj}>
						Em troca do “empréstimo”, o investidor recebe o
						pagamento de juros sobre o valor investido, estabelecido
						no momento de compra do título, juntamente com a data de
						vencimento do mesmo.
					</Typography>
					<Typography sx={titleStyleObj}>
						<i>Letras de Crédito Imobiliário (LCI)</i>
					</Typography>
					<Typography sx={textStyleObj}>
						Esse investimento serve para captar recursos para
						financiamento de atividades do setor imobiliário. Ao
						aplicar nesse título, o banco oferece em troca uma taxa
						de rentabilidade anual em cima do valor inicial.
					</Typography>
					<Typography sx={textStyleObj}>
						Uma LCI que rende 98% do CDI vai entregar ao investidor
						um rendimento bem próximo da taxa Selic do período.
					</Typography>
					<Typography sx={titleStyleObj}>
						<i>Letras de Crédito do Agronegócio (LCA) </i>
					</Typography>
					<Typography sx={textStyleObj}>
						Funciona do mesmo modo que a LCI, mas seu foco é o
						financiamento das atividades do setor de agronegócio. A
						LCA tem, em geral, o rendimento atrelado ao CDI e
						apresentado em pontos percentuais.
					</Typography>
					<Typography sx={titleStyleObj}>
						<i>Principais diferenças</i>
					</Typography>
					<Typography sx={textStyleObj}>
						A principal diferença entre LCI e LCA é o setor que cada
						uma financia, como já explicado anteriormente.
					</Typography>
					<Typography sx={textStyleObj}>
						A rentabilidade, o prazo de aplicação e o aporte inicial
						também são diferentes entre os dois investimentos –
						sendo, inclusive, fatores importantes para considerar na
						hora de decidir qual letra de crédito investir.
					</Typography>
					<Typography sx={textStyleObj}>
						Outra característica dos títulos de ambas as letras de
						crédito é a baixa liquidez. Isso quer dizer que, depois
						de investir, pode ser que demore para você consiga
						resgatar seu dinheiro. No geral, somente na data de
						vencimento.
					</Typography>
					<Typography sx={titleStyleObj}>
						Tipos de LCI e LCA
					</Typography>
					<TableContainer sx={{ mt: "30px" }}>
						<Table size="small" aria-label="a dense table">
							<TableBody>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Prefixadas
									</TableCell>
									<TableCell sx={tableCell} align="right">
										Já têm o rendimento definido com
										antecedência, assim como a data de
										vencimento da aplicação.
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Pós-fixadas
									</TableCell>
									<TableCell sx={tableCell} align="right">
										São determinadas pela rentabilidade de
										algum indicador, como a taxa da Selic ou
										CDI, ou seja, a aplicação está
										diretamente ligada à oscilação deste
										indicador.
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Híbridas
									</TableCell>
									<TableCell sx={tableCell} align="right">
										Letras de Crédito nesta categoria
										mesclam investimentos prefixados e
										pós-fixados em sua aplicação.
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
					<Typography sx={titleStyleObj}>Como funcionam?</Typography>
					<Typography sx={textStyleObj}>
						Os dois investimentos são emitidos por bancos e
						oferecidos aos investidores no mercado financeiro. Sendo
						assim, eles costumam ser aplicações bem acessíveis.
					</Typography>
					<Typography sx={textStyleObj}>
						Por se tratar de investimentos incentivados pelo
						governo, o mesmo não cobra Imposto de Renda sobre o
						lucro do LCI e LCA. Essa é uma das principais vantagens
						desses títulos.
					</Typography>
					<Typography sx={textStyleObj}>
						De acordo com a Lei nº 10.931/2004 e a Circular do Banco
						Central nº 3.614/12, a Letra de Crédito Imobiliário pode
						ser remunerada por uma taxa pré ou pós-fixada. Isso
						significa que o rendimento da LCI varia de acordo com
						cada tipo.
					</Typography>
					<Typography sx={titleStyleObj}>Como investir?</Typography>
					<Typography sx={textStyleObj}>
						1- Busque uma instituição financeira que ofereça crédito
						imobiliário e emita títulos;
						<br />
						2- Verifique o rating e o quanto essa instituição é
						segura;
						<br />
						3- Entre em contato e entenda mais sobre os prazos,
						aporte inicial e rentabilidade;
						<br />
						4- Faça diferentes simulações e compare as ofertas;
						<br />
						5- Compre sua LCI ou LCA
					</Typography>
					<Typography sx={titleStyleObj}>São seguros?</Typography>
					<Typography sx={textStyleObj}>
						Quando se busca investir em LCI e LCA, o emissor do
						título será sempre uma instituição financeira. Cada uma
						possui uma nota de crédito, que indica a capacidade de
						endividamento e pagamento.
					</Typography>
					<Typography sx={textStyleObj}>
						Bancos pequenos e médios tendem a ter uma nota de
						crédito menor, mas também tendem a oferecer retornos
						maiores nas LCIs e LCAs. Bancos maiores, por outro lado,
						têm um risco de crédito menor, mas oferecem prêmios
						menores nos títulos.
					</Typography>
					<Typography sx={textStyleObj}>
						Quem investe nas linhas de crédito também está coberto
						pelo Fundo Garantidor de Crédito (FGC), que garante a
						devolução do dinheiro caso a instituição emissora do
						título deixe de pagar. O limite para essa restituição é
						de 250 mil reais por investidor.
					</Typography>
					<Typography sx={titleStyleObj}>Prós e contras</Typography>
					<TableContainer sx={{ mt: "30px" }}>
						<Table size="small" aria-label="a dense table">
							<TableBody>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										<b>Vantagens </b>
									</TableCell>
									<TableCell
										sx={tableCell}
										align="right"
									></TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Rentabilidade
									</TableCell>
									<TableCell sx={tableCell} align="right">
										A rentabilidade da LCI e da LCA costuma
										ser bem próxima às do CDI, que é
										referência quando o assunto é Renda
										Fixa. Para investir com base nos
										resultados do mercado, elas são
										excelentes alternativas.
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Diversificação
									</TableCell>
									<TableCell sx={tableCell} align="right">
										LCI e LCA são boas para diversificar,
										ponto importante caso você esteja
										montando sua carteira de investimentos.
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Isenção de taxas
									</TableCell>
									<TableCell sx={tableCell} align="right">
										Não é necessário pagar imposto pelo
										rendimento obtido.
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
					<TableContainer sx={{ mt: "30px" }}>
						<Table size="small" aria-label="a dense table">
							<TableBody>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										<b>Desvantagens</b>
									</TableCell>
									<TableCell
										sx={tableCell}
										align="right"
									></TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Carência
									</TableCell>
									<TableCell sx={tableCell} align="right">
										Esse tipo de investimento só se
										transforma em uma desvantagem caso
										precise do dinheiro e resgate antes do
										vencimento.
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={tableCell}
										component="th"
										scope="row"
									>
										Liquidez
									</TableCell>
									<TableCell sx={tableCell} align="right">
										Para evitar perdas nos rendimentos, o
										ideal é manter as LCIs e LCAs até o
										prazo de vencimento.
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
					<Typography sx={textStyleObj}>
						Agora você já sabe mais sobre a LCI e a LCA! Caso esteja
						pensando em aplicar, sugerimos que leia cada vez mais
						sobre o investimento, para decidir se o as letras de
						crédito combinam com seu perfil, e estude as opções
						disponíveis com seu banco.
					</Typography>
				</Container>
			</Box>
			<ResponsiveFooter />
		</Box>
	);
}
